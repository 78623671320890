import axios, { AxiosInstance } from 'axios'
import { createContext, useContext } from 'react'
export interface UserRoleOption {
    id: string
    name: string
}
export interface UserProps {
    userAlias: string | undefined
}
export interface AppContextProps {
    apiClient: AxiosInstance
    userProps: UserProps
}

export const AppContext = createContext<AppContextProps>({
    apiClient: axios.create(),
    userProps: {
        userAlias: '',
    },
})
export const useAppContext = () => useContext(AppContext)
