import { Button, ButtonDropdown } from '@amzn/awsui-components-react'

import xlsx from 'json-as-xlsx'
import { ARRAY_VALUE_COLUMNS } from '../../Constant'
import _ from 'lodash'

interface ExportTableProps {
    tableData: readonly any[]
    tableColumnDef: any[]
    visibleColumns: any[]
    fileName: string
    detailsFileName?: string
    disabled?: boolean
    objectNameAttribute?: string
    enableDropdown?: boolean
    includeAggregatedSubTableData?: boolean
    parentSheetName?: string
    aggregatedSubDataSheetName?: string
    columnsWithObjectValues?: any
    subTableData?: any[]
    subTableColumnDef?: any[]
    subTableVisibleColumns?: any[]
    customizedExportButtons?: any[]
    allColumns?: any[]
}
const SHEET_NAME_LENGTH_LIMIT = 31
const CHARS_TO_EXCLUDE_REGEX = /([:\[\]\\\*/?])+/g
const ExportTable = (props: ExportTableProps) => {
    const {
        tableData,
        tableColumnDef,
        visibleColumns,
        detailsFileName,
        fileName,
        disabled,
        objectNameAttribute = '',
        enableDropdown = false,
        includeAggregatedSubTableData = false,
        parentSheetName = '',
        aggregatedSubDataSheetName = '',
        columnsWithObjectValues = {},
        subTableData = [],
        subTableColumnDef = [],
        subTableVisibleColumns = [],
        customizedExportButtons = [],
        allColumns = [],
    } = props

    const compareSheetNames = (name1, name2) => {
        return name1.sheet.localeCompare(name2.sheet)
    }

    const generateListOfObjects = () => {
        const objectList: string[] = []
        tableData.forEach((data) => {
            objectList.push(data[objectNameAttribute])
        })
        return objectList
    }

    const handleExport = (mainTableVisibleColumns, exportFileName) => {
        const data: any[] = []
        setUpData(tableData, mainTableVisibleColumns, tableColumnDef, data, true)
        exportCsv(data, exportFileName)
    }

    const normalizeParentName = (originalParentName: string) => {
        let normalized = originalParentName.replace(CHARS_TO_EXCLUDE_REGEX, '')
        // remove illegal chars that will throw error
        // if possible, return the program name
        if (normalized.length <= SHEET_NAME_LENGTH_LIMIT) {
            return normalized
        }
        // program name is too long... remove all special characters
        const alphaNumProgram = originalParentName.match(/[\w\d\s]/g)
        if (!alphaNumProgram || _.isEmpty(alphaNumProgram)) {
            console.error(`could not properly process ${originalParentName} during export.`)
            return originalParentName.slice(0, SHEET_NAME_LENGTH_LIMIT)
        }
        normalized = alphaNumProgram.join('')
        // if removing special chars is enough, return the normalized program name
        if (normalized.length <= SHEET_NAME_LENGTH_LIMIT) {
            return normalized
        }
        // gradually remove spaces from end of program name
        // til program name is < limit, otherwise just truncate it and return
        let reversed = normalized.split('').reverse().join('')
        let spacesToProcess = normalized.match(/\s/g)?.length || 0
        while (reversed.length > SHEET_NAME_LENGTH_LIMIT && spacesToProcess) {
            reversed = reversed.replace(/\s{1,}/, '')
            spacesToProcess = spacesToProcess - 1
        }
        // flip back to normal and truncate if needed
        const finalProcessedName = reversed.split('').reverse().join('')
        return finalProcessedName.slice(0, SHEET_NAME_LENGTH_LIMIT)
    }

    const handleExportsWithSubTables = (mainTableVisibleColumns, exportFileName) => {
        const data: any[] = []
        setUpData(tableData, mainTableVisibleColumns, tableColumnDef, data, true)
        const subdata: any[] = []
        const objectNameList = generateListOfObjects()
        Object.keys(subTableData).forEach((parent) => {
            if (objectNameList.includes(parent)) {
                setUpData(
                    subTableData[parent],
                    subTableVisibleColumns,
                    subTableColumnDef,
                    subdata,
                    false,
                    normalizeParentName(parent),
                )
            }
        })
        if (includeAggregatedSubTableData && subdata.length) {
            const aggregatedData: any[] = subdata.flatMap((data) => data.content)
            data.push({
                sheet: normalizeParentName(aggregatedSubDataSheetName),
                columns: subdata[0].columns,
                content: aggregatedData.sort((value1, value2) => {
                    return value1.org_name.localeCompare(value2.org_name)
                }),
            })
        }
        subdata.sort(compareSheetNames)
        exportCsv([...data, ...subdata], exportFileName)
    }

    const setUpData = (data, visibleCol, columnDef, dataList, parentSheet, parent = '') => {
        if (data.length > 0) {
            const csvHeaderNameMapping: { string: string } = generateColumnNameMapping(columnDef)
            const filteredJsonData = visibleCol ? filterUnseenDataColumns(visibleCol, data) : data
            const columns: any[] = []
            visibleCol.forEach((header) => {
                columns.push({
                    label: csvHeaderNameMapping[header],
                    value: ARRAY_VALUE_COLUMNS.includes(header)
                        ? (row) => row[header]?.toString()
                        : header,
                })
            })

            dataList.push({
                sheet: parentSheet ? parentSheetName : parent,
                columns: columns,
                content: filteredJsonData,
            })
        }
    }

    const accessValueInObject = (key, value) => {
        return value[key]
    }

    const filterUnseenDataColumns = (visibleCol, currentData) => {
        const filteredData: any[] = []
        currentData.filter((data) => {
            const filteredSubData = {}
            Object.keys(data).forEach((key) => {
                if (visibleCol.includes(key)) {
                    filteredSubData[key] = Object.keys(columnsWithObjectValues).includes(key)
                        ? accessValueInObject(columnsWithObjectValues[key], data[key])
                        : data[key]
                }
            })
            filteredData.push(filteredSubData)
        })
        return filteredData
    }

    const generateColumnNameMapping = (columnDef) => {
        const nameMapping: any = {}
        for (let i = 0; i < columnDef.length; i++) {
            nameMapping[columnDef[i].id] = columnDef[i].exportColumnName
        }
        return nameMapping
    }

    const exportCsv = (data, exportFileName) => {
        const settings = {
            fileName: `${exportFileName}-${new Date().toISOString()}`,
            extraLength: 3,
            writeMode: 'writeFile',
            writeOptions: {},
            RTL: false,
        }

        xlsx(data, settings)
    }

    return enableDropdown ? (
        <ButtonDropdown
            items={[
                {
                    text: 'Export',
                    id: 'export',
                    disabled: !tableData.length || disabled,
                },
                {
                    text: 'Export with Detail',
                    id: 'export_sub_data',
                    disabled: !tableData.length || disabled,
                },
                ...customizedExportButtons,
            ]}
            onItemClick={({ detail }) => {
                switch (detail.id) {
                    case 'export':
                        handleExport(visibleColumns, fileName)
                        break
                    case 'export_sub_data':
                        handleExportsWithSubTables(
                            visibleColumns,
                            `${detailsFileName ?? fileName}-WithDetails`,
                        )
                        break
                    case 'export_with_program_metadata':
                        handleExportsWithSubTables(
                            allColumns,
                            `${detailsFileName ?? fileName}-WithAllProgramMetadata`,
                        )
                        break
                    default:
                        handleExport(visibleColumns, fileName)
                        break
                }
            }}
        >
            Export
        </ButtonDropdown>
    ) : (
        <Button
            onClick={() => handleExport(visibleColumns, fileName)}
            disabled={!tableData.length || disabled}
        >
            Export
        </Button>
    )
}

export default ExportTable
