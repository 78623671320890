import { OBJECT_TYPES } from './Constant'
import { AUDIT_STATE_ACTIONS } from './useAuditState'
const PLAN_INDEPENDENT_OBJECTS = [OBJECT_TYPES.GROUP, OBJECT_TYPES.ORG, OBJECT_TYPES.PARENT_PROGRAM]

export const fetchArchivedItems = (
    apiClient: any,
    dispatch: any,
    itemType: OBJECT_TYPES,
    mapKey: string,
    planId?,
) => {
    let apiPath = `/archive/${itemType.toLowerCase()}`
    apiPath = !PLAN_INDEPENDENT_OBJECTS.includes(itemType)
        ? `${apiPath}?plan_id=${planId}`
        : apiPath
    apiClient
        .get(apiPath)
        .then((res) => {
            dispatch({
                type: AUDIT_STATE_ACTIONS.ADD_ARCHIVE_ITEMS,
                payload: {
                    archiveData: res.data,
                    mapKey: mapKey,
                    objectType: itemType,
                    planId: planId,
                },
            })
        })
        .catch((err) => {
            console.error(err)
            dispatch({
                type: AUDIT_STATE_ACTIONS.SET_MANY,
                payload: {
                    [mapKey]: {
                        loading: false,
                    },
                },
            })
        })
}

export const addArchivedItems = (currentMap, archivedItems) => {
    const newMapEntries: any[] = []
    currentMap.forEach((val, key, map) => {
        newMapEntries.push([key, val])
    })
    archivedItems.forEach((archivedItem) => {
        const itemId = archivedItem['object_sort'].split('#')[1]
        if (!currentMap.has(itemId)) {
            newMapEntries.push([itemId, archivedItem['attributes']])
        }
    })
    return new Map(newMapEntries)
}
