import { Button, Link, Popover } from '@amzn/awsui-components-react'

const OP2_DECODER_QUIP = 'https://quip-amazon.com/JZzGAFDPQG0y/Job-Function-OP2-Decoder'
const JOB_FUNCTION_TOOLTIP_CONTENT = (
    <>
        For more information on which Job Function to choose, please refer to the{' '}
        <Link external={true} href={OP2_DECODER_QUIP}>
            Job Function OP2 Decoder
        </Link>
        .
    </>
)

export const addJobFunctionTooltip = () => {
    return (
        <Popover
            dismissButton={true}
            triggerType='custom'
            content={JOB_FUNCTION_TOOLTIP_CONTENT}
            size={'large'}
        >
            <Button variant='inline-icon' iconName='status-info'></Button>
        </Popover>
    )
}

export const styleEstimateDisplay = (fieldName) => {
    return <span style={{ marginTop: '7px' }}> {fieldName}</span>
}
