import {
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Flashbar,
    FormField,
    Grid,
    Header,
    Input,
    Link,
    Multiselect,
    Select,
    SpaceBetween,
    Table,
    Textarea,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../../reusable/HeaderTemplate'
import { useEffect, useReducer, useState } from 'react'
import { useAppContext } from '../../../../context'
import useStore from '../../../Store'
import { useNavigate, useLocation } from 'react-router-dom'
import GenericSummaryTable from '../../reusable/GenericSummaryTable'
import {
    ALERT_TYPES,
    CREATE,
    EMPTY_SELECTION,
    MODAL_MODES,
    MONTHS_NUMBER,
    PHONE_TOOL_PREFIX,
} from '../../../Constant'
import {
    getFormattedAttributes,
    getProgramColumnDefinitions,
    getProgramSummaryVisibleColumns,
    PROGRAM_SELECTION_IDS,
    programAttributes,
} from '../ProgramAttributes'
import FormFieldInputTemplate from '../../reusable/FormfieldInputTemplate'
import {
    CAPEX_OPTIONS,
    COST_CENTER_OPTIONS,
    EXPENSE_CATEGORY_OPTIONS,
    MONTHS,
} from './DiscretionarySpendConstant'
import FormFieldTextareaTemplate from '../../reusable/FormFieldTextareaTemplate'
import {
    convertToMoneyFormat,
    filterVisibleColsByTerms,
    getIntValue,
    mapOptions,
    getLeadingNumZeros,
    getMetadataForPlan,
    formatRevisionOptions,
    emptyFn,
} from '../../reusable/Utils'
import { generateShortUuid, getGroupOptions } from '../../../common/Util'
import CreateEditModal from '../CreateEditModal'
import { generateInitialState } from '../ProgramConstants'
import bigDecimal from 'js-big-decimal'
import {
    createCostCenterInfo,
    createInfoContent,
    DS_PREFIX,
    DS_TABLE,
    EXPENSE_ID_STRING_LENGTH,
} from './DiscretionarySpendAttributes'
import { programReducer, REDUCER_ACTIONS } from '../ProgramSharedUtils'

const NUM_ROWS_IN_TEXT_INPUT = 5

const CreateEditDiscretionarySpend = () => {
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const apiClient = appContext.apiClient
    const userGroups = useStore((state) => state.userGroups)
    const canEditPrograms = useStore((state) => state.canEditPrograms)
    const canAdmin = useStore((state) => state.canAdmin)

    const navigate: any = useNavigate()
    const location = useLocation()
    const paths = location.pathname.split('/')
    const planId = paths[2]
    const revisionId = paths[4]
    const programId = paths[6]
    const groupId = paths[8]
    const expenseId = paths[10]
    const isCreating = expenseId === CREATE

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    // formatted so that plan metadata is in "value"
    const [selectedPlan, setSelectedPlan] = useState<any>(EMPTY_SELECTION)
    const [planMetadata, setPlanMetadata] = useState<any>({})

    const [program, setProgram] = useState<any>({})
    const [isSpendLoading, setIsSpendLoading] = useState(true)
    const MAXIMUM_INPUT_LIMIT = 999999999
    const INPUT_REACHED_ERROR = `Please enter an expense value no greater than ${convertToMoneyFormat(
        MAXIMUM_INPUT_LIMIT,
    )}.`
    const [formLimitReached, setFormLimitReached] = useState(false)
    const generateDefaultSpend = () => {
        return {
            mode: isCreating ? MODAL_MODES.CREATE : MODAL_MODES.EDIT,
            plan_id: planId,
            program_id: programId,
            business_entity_id: '',
            group_id: groupId,
            revision_id: revisionId,
            ds_item_id: isCreating ? 'New Expense ID' : expenseId,
            ds_item_id_display: '',
            expense_title: '',
            expense_category: '',
            expense_type: '',
            description: '',
            cost_center: '',
            expense_note: '',
            revision_note: '',
            annual_spend_amount: 0,
            annual_spend_user_history: [0],
            jan_expenditure: 0,
            feb_expenditure: 0,
            mar_expenditure: 0,
            apr_expenditure: 0,
            may_expenditure: 0,
            jun_expenditure: 0,
            jul_expenditure: 0,
            aug_expenditure: 0,
            sep_expenditure: 0,
            oct_expenditure: 0,
            nov_expenditure: 0,
            dec_expenditure: 0,
            total_expenditure: 0,
            q1_justification: '',
            q2_result: '',
            q3_other_programs: [],
        }
    }
    const [spend, setSpend] = useState<any>(generateDefaultSpend())
    const [humanReadableId, setHumanReadableId] = useState('')
    const generateDefaultSpendError = () => {
        return {
            expense_title: '',
            expense_category: '',
            description: '',
            cost_center: '',
            expense_note: '',
            revision_note: '',
            total_expenditure: '',
            jan_expenditure: '',
            feb_expenditure: '',
            mar_expenditure: '',
            apr_expenditure: '',
            may_expenditure: '',
            jun_expenditure: '',
            jul_expenditure: '',
            aug_expenditure: '',
            sep_expenditure: '',
            oct_expenditure: '',
            nov_expenditure: '',
            dec_expenditure: '',
            q1_justification: '',
            q2_result: '',
        }
    }
    const [spendError, setSpendError] = useState<any>(generateDefaultSpendError())
    const [availableProgramOptions, setAvailableProgramOptions] = useState<any[]>([])
    const [groupOptions, setGroupOptions] = useState<any>(
        getGroupOptions(selectedBusinessEntity.business_entity_id, userGroups),
    )
    const [selectedRevision, setSelectedRevision] = useState<any>(EMPTY_SELECTION)
    const [isProgramLoading, setIsProgramLoading] = useState(true)
    const [alertItems, setAlertItems] = useState<any[]>([])
    const [isRevisionLocked, setIsRevisionLocked] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState<any>(
        userGroups.length
            ? userGroups.find((group) => group.object_id === groupId)
            : { object_id: '', object_name: 'Select Group' },
    )
    const [programViewModalVisible, setProgramViewModalVisible] = useState(false)
    const [programModalExpanded, setProgramModalExpanded] = useState(true)
    const [localColumnDefinitions, setLocalColumnDefinitions] = useState<any[]>(
        getProgramColumnDefinitions(
            navigate,
            selectedPlan.value,
            selectedRevision,
            programAttributes,
        ),
    )

    const [programState, dispatchProgram] = useReducer(
        programReducer,
        generateInitialState(programAttributes),
    )

    const loadSelect = (target) => {
        dispatchProgram({
            type: REDUCER_ACTIONS.load,
            target: target,
        })
        return
    }

    const isInputsValid = () => {
        if (formLimitReached) {
            return false
        }
        let isValid = true
        const newSpendError = { ...spendError }

        for (const stateId in spend) {
            if (stateId in spendError) {
                let errorMessage = ''
                const value = spend[stateId]
                if (spend.mode === MODAL_MODES.CREATE && stateId === 'revision_note') {
                    continue
                } else if (stateId === 'total_expenditure') {
                    errorMessage =
                        !value || bigDecimal.compareTo(value, '0') === 0
                            ? "At least one month's spend must be provided."
                            : ''
                } else if (!stateId.includes('expenditure')) {
                    errorMessage = !value ? `Value must be provided.` : ''
                }

                if (errorMessage) {
                    newSpendError[stateId] = errorMessage
                    isValid = false
                }
            }
        }
        setSpendError(newSpendError)

        return isValid
    }

    const handleSave = async (locationToPush, stateToPush) => {
        if (!isInputsValid()) {
            return
        }

        const payload = {}
        const formatPayload = () => {
            for (const stateId in spend) {
                if (stateId === 'ds_item_id' && spend.mode === MODAL_MODES.CREATE) {
                    payload[stateId] = generateShortUuid()
                    continue
                }
                if (stateId === 'cost_center' || stateId === 'expense_category') {
                    payload[stateId] = spend[stateId].value
                    continue
                }
                if (stateId === 'group_id') {
                    payload[stateId] = selectedGroup?.object_id
                    continue
                }
                payload[stateId] = spend[stateId]
            }
            payload['business_entity_id'] = selectedBusinessEntity.business_entity_id
        }
        formatPayload()

        setIsSpendLoading(true)
        apiClient
            .post(
                `/group/${selectedGroup?.object_id}/plan/${planId}/revision/${revisionId}/program/${programId}/discretionary-spend`,
                JSON.stringify(payload),
            )
            .then(() => {
                navigate(locationToPush, { state: stateToPush })
                if (!Object.keys(stateToPush).length) {
                    setSpend(generateDefaultSpend())
                } else {
                    getNextSpendId(true)
                }
                setSpendError(generateDefaultSpendError())
            })
            .catch((error) => {
                if (spend.mode === MODAL_MODES.CREATE) {
                    handleAddAlertItem(
                        `Failed to create expense ${spend.expense_title}: ${error.response.data}.`,
                        ALERT_TYPES.ERROR,
                    )
                } else {
                    handleAddAlertItem(
                        `Failed to update expense ${spend.expense_title}: ${error.response.data}.`,
                        ALERT_TYPES.ERROR,
                    )
                }
                console.error(error)
            })
            .finally(() => {
                setIsSpendLoading(false)
            })
    }

    const handleAddAlertItem = (content: string, type: ALERT_TYPES) => {
        const id = `${userProps.userAlias}-${Date.now()}`
        const alert = {
            onDismiss: () => {
                setAlertItems((items) => items.filter((item) => item.id !== id))
            },
            dismissible: true,
            content: content,
            type: type,
            id: id,
        }
        setAlertItems([alert, ...alertItems])
    }

    const isSpreadDisabled = () => {
        return isRevisionLocked || isSpendLoading || !spend.annual_spend_amount
    }

    const getTotalExpenditure = (state) => {
        return bigDecimal.round(
            MONTHS.map((month) => state[month]).reduce((exp1, exp2) => bigDecimal.add(exp1, exp2)),
        )
    }

    const updateAnnualMonthlySpends = (state, factor) => {
        // factor is either 1 or -1, to add or subtract spend
        const annualSpendAmount = state.annual_spend_amount
        const thresholdCols: any = Object.fromEntries(MONTHS.map((month) => [month, '']))
        const processAddOrSubtract = (stateId, changeAmount, clearInitialValue) => {
            if (factor < 0 && bigDecimal.compareTo(state[stateId], changeAmount) < 0) {
                return '0'
            } else {
                const initialValue = clearInitialValue && factor > 0 ? 0 : state[stateId]
                const newVal =
                    factor > 0
                        ? bigDecimal.add(initialValue, changeAmount)
                        : bigDecimal.subtract(initialValue, changeAmount)
                const limitReached = isAboveLimit(newVal, stateId)
                if (limitReached > 0) {
                    thresholdCols[stateId] = INPUT_REACHED_ERROR
                }
                return newVal
            }
        }
        let quotient = bigDecimal.divide(annualSpendAmount, String(MONTHS_NUMBER), 1)
        quotient = bigDecimal.floor(quotient)
        const remainder = bigDecimal.modulus(annualSpendAmount, String(MONTHS_NUMBER))

        for (const stateId in state) {
            if (MONTHS.includes(stateId)) {
                state[stateId] = processAddOrSubtract(stateId, quotient, true)
            }
        }
        state.jan_expenditure = processAddOrSubtract('jan_expenditure', remainder, false)
        state.total_expenditure = getTotalExpenditure(state)
        return thresholdCols
    }

    const getNextSpendId = (clearSpend) => {
        if (!selectedPlan.value) {
            console.error('Plan is undefined, passing...')
            return
        }
        const planYear = planMetadata.year
        apiClient
            .get(`/counter/table/${DS_TABLE}/item/${DS_PREFIX}?year=${planYear}`)
            .then((res) => {
                const nextSeq = res.data
                if (!nextSeq) {
                    return
                }
                const spendSlug = `${planYear}-${getLeadingNumZeros(
                    nextSeq,
                    EXPENSE_ID_STRING_LENGTH,
                )}`
                setHumanReadableId(spendSlug)
                setSpend({
                    ...(!clearSpend ? spend : generateDefaultSpend()),
                    ds_item_id_display: spendSlug,
                })
                setIsSpendLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsSpendLoading(false)
            })
    }

    const getRevisionOption = () => {
        apiClient
            .get(`/plan/${planId}/revision?revision_id=${revisionId}`)
            .then((res) => {
                const revision = res.data
                setSelectedRevision(formatRevisionOptions(revision))
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(`Unable to retrieve revisions metadata.`, ALERT_TYPES.ERROR)
            })
    }

    const checkRevisionLocked = () => {
        apiClient
            .get(`/plan/${planId}/revision?revision_id=${revisionId}`)
            .then((res) => {
                setIsRevisionLocked(res.data.is_locked)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(`Unable to retrieve revisions is lock.`, ALERT_TYPES.ERROR)
            })
    }

    const getProgram = () => {
        setIsProgramLoading(true)
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/program/${programId}`)
            .then((res) => {
                let tmpProgram = res.data
                tmpProgram = {
                    ...tmpProgram,
                    stl_alias: tmpProgram['stl_alias'] ? [tmpProgram['stl_alias']] : [],
                    registered_users: tmpProgram['registered_users'] || [],
                }
                setProgram(tmpProgram)
                getAvailableProgramOptions(tmpProgram.program_id)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(`Unable to retrieve metadata for program.`, ALERT_TYPES.ERROR)
            })
            .finally(() => setIsProgramLoading(false))
    }

    const getAvailableProgramOptions = (programId: string) => {
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/programs`)
            .then((res) => {
                const programs = res.data
                const result: any[] = []
                const formatAvailableProgramOptions = () => {
                    for (const prog of programs) {
                        if (prog.program_id === programId) {
                            continue
                        }

                        result.push({
                            label: prog.program_name,
                            value: prog.program_id,
                            description: `${prog.stl_alias} - ${prog.stl_name}`,
                        })
                    }
                }
                formatAvailableProgramOptions()
                setAvailableProgramOptions(result)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(
                    `Failed to load available programs: ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
            })
    }

    const getSpend = () => {
        if (spend.mode === MODAL_MODES.CREATE) {
            setIsSpendLoading(false)
            return
        }

        setIsSpendLoading(true)
        apiClient
            .get(`/discretionary_spend/${expenseId}`)
            .then((res) => {
                const spendMetadata = res.data
                const newSpend = { ...spendMetadata }
                newSpend.mode = MODAL_MODES.EDIT
                newSpend.annual_spend_amount = 0
                newSpend.annual_spend_user_history = [0]
                newSpend.expense_category = mapOptions(
                    newSpend.expense_category,
                    EXPENSE_CATEGORY_OPTIONS,
                )
                newSpend.cost_center = mapOptions(newSpend.cost_center, COST_CENTER_OPTIONS)
                setSpend(newSpend)
            })
            .catch((error) => {
                console.error(error)
                handleAddAlertItem(
                    `Unable to retrieve existing expense data for expense id ${expenseId}.`,
                    ALERT_TYPES.ERROR,
                )
            })
            .finally(() => setIsSpendLoading(false))
    }

    const expenseVisibleCols = ['total_expenditure', ...MONTHS]
    const getHeaderName = (col) => {
        const expType = col.split('_')[0]
        return expType[0].toUpperCase() + expType.slice(1)
    }
    const getIsReadOnly = (col) => {
        return col.includes('total')
    }
    const isAboveLimit = (intValue, colId) => {
        const limitReached = bigDecimal.compareTo(intValue, String(MAXIMUM_INPUT_LIMIT))
        const numOtherErrors = Object.keys(spendError).filter(
            (col) =>
                col !== colId &&
                (col.includes('expenditure') || col === 'annual_spend_amount') &&
                spendError[col] === INPUT_REACHED_ERROR,
        ).length
        setFormLimitReached(limitReached > 0 || numOtherErrors > 0)
        return limitReached
    }

    const expenseColumnDefs = expenseVisibleCols.map((colId) => ({
        id: colId,
        header: <p style={{ whiteSpace: 'nowrap' }}>{getHeaderName(colId)}</p>,
        minWidth: colId === 'total_expenditure' ? 220 : 170,
        isRowHeader: getIsReadOnly(colId),
        cell: (item) => (
            <FormField errorText={spendError[colId]}>
                <Input
                    value={convertToMoneyFormat(item[colId])}
                    readOnly={getIsReadOnly(colId)}
                    onChange={({ detail }) => {
                        const value = detail.value.match(/\d/gm)?.join('') || ''
                        if (+value < 0) {
                            return
                        }
                        const newValue = bigDecimal.round(value)
                        const limitReached = isAboveLimit(newValue, colId)
                        const newStateError = { ...spendError }
                        if (limitReached > 0) {
                            newStateError[colId] = INPUT_REACHED_ERROR
                        } else {
                            newStateError[colId] = ''
                        }
                        const newState = { ...spend }
                        const prevValue = newState[colId]

                        newState[colId] = value ? newValue : '0'
                        const diff = bigDecimal.subtract(prevValue, newValue)
                        const newTotal = bigDecimal.subtract(newState.total_expenditure, diff)
                        newState.total_expenditure = bigDecimal.round(newTotal)
                        setSpend(newState)
                        if (bigDecimal.compareTo(newState.total_expenditure, '0') > 0) {
                            newStateError.total_expenditure = ''
                        }
                        setSpendError(newStateError)
                    }}
                    disabled={isRevisionLocked || isSpendLoading}
                ></Input>
            </FormField>
        ),
    }))

    const loadPage = () => {
        getMetadataForPlan(apiClient, planId, setPlanMetadata, setSelectedPlan)
        getProgram()
        getRevisionOption()
        checkRevisionLocked()
        getSpend()
    }

    useEffect(() => {
        if (!userGroups.length) {
            return
        }
        setGroupOptions(getGroupOptions(selectedBusinessEntity.business_entity_id, userGroups))
        setSelectedGroup(
            userGroups.length && groupId
                ? userGroups.find((group) => group.object_id === groupId)
                : { object_id: '', object_name: 'Select Group' },
        )
    }, [userGroups])

    useEffect(() => {
        if (!selectedPlan.value) {
            return
        }
        if (isCreating && !humanReadableId) {
            getNextSpendId(false)
        }
    }, [selectedPlan])

    useEffect(() => {
        loadPage()
    }, [selectedBusinessEntity])

    useEffect(() => {
        if (!selectedPlan.value || !selectedRevision.value) {
            return
        }

        apiClient
            .get(`/settings?keys=${PROGRAM_SELECTION_IDS.join(',')}`)
            .then((res) => {
                const selections = res.data
                setLocalColumnDefinitions(
                    getProgramColumnDefinitions(
                        navigate,
                        selectedPlan.value,
                        selectedRevision,
                        getFormattedAttributes(selections, programAttributes),
                    ).slice(1),
                )
            })
            .catch((err) => {
                console.error(err)
            })
    }, [selectedPlan, selectedRevision])

    return (
        <ContentLayout
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: `Programs`,
                                    href: `/plan/${planId}/revision/${revisionId}`,
                                },
                                {
                                    text: `${
                                        Object.keys(program).length > 0 ? program.program_name : ''
                                    } Discretionary Spend`,
                                    href: `/plan/${planId}/revision/${selectedRevision?.value ?? ''}/program/${program?.program_id}/group/${selectedGroup?.object_id}/discretionary-spend`,
                                },
                                {
                                    text:
                                        spend.mode === MODAL_MODES.CREATE
                                            ? `Create Discretionary Spend`
                                            : `Edit Discretionary Spend`,
                                    href: ``,
                                },
                            ]}
                        />
                        {Boolean(!alertItems.length) && (
                            <Flashbar items={alertItems} stackItems={true} />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <CreateEditModal
                isStlNotAdmin={!canAdmin && canEditPrograms}
                selectedRevision={selectedRevision}
                selectedPlan={selectedPlan}
                state={{
                    ...programState,
                    ['kingpin_goals']: program?.kingpin_goals || [],
                    ['important_links']: program?.important_links || [],
                }}
                inputChange={emptyFn}
                clearAllInputs={emptyFn}
                isDataUnmodified={true}
                isInputInvalid={false}
                onIsInputInvalidChanged={emptyFn}
                onIsDataUnmodifiedChanged={emptyFn}
                isAddingProgram={false}
                onAlertItemAdd={emptyFn}
                onDismiss={() => setProgramViewModalVisible(false)}
                visible={programViewModalVisible}
                onIsLoadingChange={false}
                isModalExpand={programModalExpanded}
                onIsModalExpandChange={setProgramModalExpanded}
                modalMode={MODAL_MODES.VIEW}
                programs={program}
                selectedPrograms={program}
                onSelectedProgramsChange={emptyFn}
                selectedProgramBusinessEntity={false}
                getLocalPrograms={false}
                getParentPrograms={false}
                initialKingpinGoals={program?.kingpin_goals ? program.kingpin_goals : []}
                initialImportantLinks={program?.important_links ? program.important_links : []}
                onImportantLinksChange={emptyFn}
                selectedPlanOptions={[]}
                onSelectPlanChange={emptyFn}
                selectedRevisionOptions={[]}
                onSelectRevisionChange={emptyFn}
                globalAttributesError={''}
                setGlobalAttributesError={emptyFn}
                allParentProgramPlans={[]}
                allParentProgramGlobalAttributes={[]}
                loadSelectProgram={emptyFn}
                hideLocalAttributes={false}
                refreshPage={emptyFn}
                fetchGlobalAttributesForPlan={emptyFn}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <SpaceBetween size={'xxl'} direction={'vertical'}>
                    <GenericSummaryTable
                        wrapLines={false}
                        actions={[]}
                        itemsToShow={program && program.registered_users ? [program] : []}
                        columns={[
                            {
                                id: 'prog_name_display',
                                header: 'Program',
                                cell: (e) => (
                                    <Link
                                        onFollow={() => {
                                            loadSelect(program)
                                            setProgramViewModalVisible(true)
                                        }}
                                    >
                                        {e.program_name}
                                    </Link>
                                ),
                                sortingField: 'program_name',
                            },
                            ...localColumnDefinitions.map((col) => ({
                                ...col,
                                minWidth: col?.id
                                    ? col.id.includes('description')
                                        ? 200
                                        : 'min-content'
                                    : 'auto',
                            })),
                        ]}
                        visibleColumns={filterVisibleColsByTerms(
                            getProgramSummaryVisibleColumns(),
                            ['create', 'update', 'program_name'],
                        )}
                        defaultNameField={'Program'}
                        nameField={'program_name'}
                        isLoading={isProgramLoading}
                        objectType={'Program'}
                    />
                    <Container
                        header={
                            <Header>
                                <SpaceBetween direction={'horizontal'} size={'s'}>
                                    <FormField label={'Group'}>
                                        <Select
                                            selectedOption={{
                                                label: selectedGroup?.object_name,
                                                value: selectedGroup?.object_id,
                                            }}
                                            filteringType={'auto'}
                                            options={groupOptions}
                                            disabled={
                                                location.state?.groupSelectorDisabled !== undefined
                                                    ? location.state?.groupSelectorDisabled
                                                    : true
                                            }
                                            onChange={({ detail }) => {
                                                const value = detail.selectedOption.value
                                                setSelectedGroup({
                                                    object_id: value,
                                                    object_name: detail.selectedOption.label,
                                                })
                                                navigate(
                                                    `/plan/${planId}/revision/${selectedRevision.value ?? ''}/program/${program.program_id}/group/${selectedGroup?.object_id}/discretionary-spend/create`,
                                                    {
                                                        state: {
                                                            groupSelectorDisabled: false,
                                                        },
                                                    },
                                                )
                                            }}
                                        />
                                    </FormField>
                                    <FormField label={'Plan'}>
                                        <Select selectedOption={selectedPlan} disabled={true} />
                                    </FormField>
                                    <FormField label={'Revision'}>
                                        <Select selectedOption={selectedRevision} disabled={true} />
                                    </FormField>
                                </SpaceBetween>
                            </Header>
                        }
                        footer={
                            <Header
                                actions={
                                    <SpaceBetween size={'l'} direction={'horizontal'}>
                                        <Button
                                            onClick={() => {
                                                navigate(
                                                    `/plan/${planId}/revision/${revisionId}/program/${programId}/group/${groupId}/discretionary-spend`,
                                                )
                                            }}
                                            disabled={isRevisionLocked || isSpendLoading}
                                        >
                                            {'Cancel'}
                                        </Button>
                                        <Button
                                            variant='primary'
                                            onClick={() => {
                                                handleSave(
                                                    `/plan/${planId}/revision/${revisionId}/program/${programId}/group/${selectedGroup?.object_id}/discretionary-spend`,
                                                    {},
                                                )
                                            }}
                                            disabled={
                                                isRevisionLocked ||
                                                isSpendLoading ||
                                                formLimitReached
                                            }
                                        >
                                            {'Save'}
                                        </Button>
                                        {spend.mode === MODAL_MODES.CREATE ? (
                                            <Button
                                                variant='primary'
                                                onClick={() => {
                                                    handleSave(
                                                        `/plan/${planId}/revision/${selectedRevision.value ?? ''}/program/${program.program_id}/group/${selectedGroup.object_id}/discretionary-spend/create`,
                                                        {
                                                            groupSelectorDisabled: false,
                                                        },
                                                    )
                                                }}
                                                disabled={
                                                    isRevisionLocked ||
                                                    isSpendLoading ||
                                                    formLimitReached
                                                }
                                            >
                                                {'Save and Create New'}
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                    </SpaceBetween>
                                }
                            ></Header>
                        }
                    >
                        <ColumnLayout columns={1}>
                            <Grid
                                gridDefinition={[
                                    { colspan: 1 },
                                    { colspan: 3 },
                                    { colspan: 3 },
                                    { colspan: 1 },
                                    { colspan: 4 },
                                ]}
                            >
                                <FormField label={'Expense ID'}>
                                    <Input value={spend.ds_item_id_display} disabled={true}></Input>
                                </FormField>
                                <FormFieldInputTemplate
                                    state={spend}
                                    onStateChange={setSpend}
                                    stateError={spendError}
                                    onStateErrorChange={setSpendError}
                                    name={'Expense Title'}
                                    id={'expense_title'}
                                    disable={isRevisionLocked || isSpendLoading}
                                    info={createInfoContent('expense_title')}
                                />
                                <FormField
                                    label={'Expense Category'}
                                    errorText={spendError.expense_category}
                                    info={createInfoContent('expense_category')}
                                >
                                    <Select
                                        selectedOption={spend.expense_category}
                                        options={EXPENSE_CATEGORY_OPTIONS}
                                        onChange={({ detail }) => {
                                            const selectedOption = detail.selectedOption
                                            const newState = { ...spend }
                                            const newStateError = { ...spendError }
                                            newState.expense_category = selectedOption
                                            newStateError.expense_category = ''
                                            if (
                                                selectedOption.value &&
                                                CAPEX_OPTIONS.includes(selectedOption.value)
                                            ) {
                                                newState.expense_type = 'CAPEX'
                                            } else {
                                                newState.expense_type = 'OPEX'
                                            }
                                            setSpend(newState)
                                            setSpendError(newStateError)
                                        }}
                                        disabled={isRevisionLocked || isSpendLoading}
                                        placeholder={'Choose Expense Category'}
                                    ></Select>
                                </FormField>
                                <FormFieldInputTemplate
                                    state={spend}
                                    name={'Expense Type'}
                                    id={'expense_type'}
                                    disable={true}
                                    placeholder={'Choose Expense Category'}
                                />
                                <FormField
                                    label={'Cost Center'}
                                    errorText={spendError.cost_center}
                                    info={createCostCenterInfo(
                                        userProps.userAlias,
                                        PHONE_TOOL_PREFIX + userProps.userAlias,
                                    )}
                                >
                                    <Select
                                        selectedOption={spend.cost_center}
                                        options={COST_CENTER_OPTIONS}
                                        onChange={({ detail }) => {
                                            const newState = { ...spend }
                                            const newStateError = { ...spendError }
                                            newState.cost_center = detail.selectedOption
                                            newStateError.cost_center = ''
                                            setSpend(newState)
                                            setSpendError(newStateError)
                                        }}
                                        disabled={isRevisionLocked || isSpendLoading}
                                        placeholder={'Choose Cost Center'}
                                    ></Select>
                                </FormField>
                            </Grid>
                            <ColumnLayout columns={3}>
                                <FormFieldTextareaTemplate
                                    state={spend}
                                    onStateChange={setSpend}
                                    stateError={spendError}
                                    onStateErrorChange={setSpendError}
                                    name={'Expense Description'}
                                    id={'description'}
                                    disable={isRevisionLocked || isSpendLoading}
                                    rows={NUM_ROWS_IN_TEXT_INPUT}
                                    info={createInfoContent('description')}
                                />
                                <FormFieldTextareaTemplate
                                    state={spend}
                                    onStateChange={setSpend}
                                    stateError={spendError}
                                    onStateErrorChange={setSpendError}
                                    name={'Expense Notes'}
                                    id={'expense_note'}
                                    disable={isRevisionLocked || isSpendLoading}
                                    rows={NUM_ROWS_IN_TEXT_INPUT}
                                    info={createInfoContent('expense_note')}
                                />
                                <FormField
                                    info={createInfoContent('revision_note')}
                                    label={
                                        spend.mode === MODAL_MODES.CREATE ? (
                                            <span>
                                                Revision Notes <i>- optional</i>{' '}
                                            </span>
                                        ) : (
                                            'Revision Notes'
                                        )
                                    }
                                    errorText={spendError ? spendError['revision_note'] : ''}
                                >
                                    <Textarea
                                        value={spend['revision_note']}
                                        onChange={({ detail }) => {
                                            const value = detail.value
                                            const newState = { ...spend }
                                            const newStateError = { ...spendError }

                                            newState['revision_note'] = detail.value
                                            setSpend(newState)

                                            if (
                                                spend.mode === MODAL_MODES.EDIT &&
                                                value.trim().length === 0
                                            ) {
                                                newStateError['revision_note'] =
                                                    `Revision Notes must be provided.`
                                            } else {
                                                newStateError['revision_note'] = ''
                                            }
                                            setSpendError(newStateError)
                                        }}
                                        disabled={isRevisionLocked || isSpendLoading}
                                        rows={NUM_ROWS_IN_TEXT_INPUT}
                                        spellcheck
                                    ></Textarea>
                                </FormField>
                            </ColumnLayout>

                            <Header variant={'h3'}>Justification for expenses</Header>
                            <ColumnLayout columns={3} borders={'horizontal'}>
                                <FormFieldTextareaTemplate
                                    state={spend}
                                    onStateChange={setSpend}
                                    stateError={spendError}
                                    onStateErrorChange={setSpendError}
                                    name={'Justification Summary'}
                                    id={'q1_justification'}
                                    disable={isRevisionLocked || isSpendLoading}
                                    rows={NUM_ROWS_IN_TEXT_INPUT}
                                    info={createInfoContent('q1_justification')}
                                />
                                <FormFieldTextareaTemplate
                                    state={spend}
                                    onStateChange={setSpend}
                                    stateError={spendError}
                                    onStateErrorChange={setSpendError}
                                    name={'What happens if approval is rejected?'}
                                    id={'q2_result'}
                                    disable={isRevisionLocked || isSpendLoading}
                                    rows={NUM_ROWS_IN_TEXT_INPUT}
                                    info={createInfoContent('q2_result')}
                                />
                                <FormField
                                    label={
                                        <span>
                                            {'Select other programs benefiting from this expense'}
                                            {<i> - optional</i>}
                                        </span>
                                    }
                                >
                                    <Multiselect
                                        selectedOptions={spend.q3_other_programs}
                                        onChange={({ detail }) => {
                                            const newState = { ...spend }
                                            newState.q3_other_programs = detail.selectedOptions
                                            setSpend(newState)
                                        }}
                                        options={availableProgramOptions}
                                        placeholder='Choose existing program(s)'
                                        tokenLimit={2}
                                    />
                                </FormField>
                            </ColumnLayout>
                            <ColumnLayout columns={1}>
                                <FormField
                                    label={
                                        <span>
                                            {'Annual Spend Amount'}
                                            {<i> - optional</i>}
                                        </span>
                                    }
                                    errorText={spendError ? spendError.annual_spend_amount : ''}
                                >
                                    <SpaceBetween size={'s'} direction={'horizontal'}>
                                        <Input
                                            value={convertToMoneyFormat(spend.annual_spend_amount)}
                                            onChange={({ detail }) => {
                                                const value =
                                                    detail.value.match(/\d/gm)?.join('') || ''
                                                if (+value < 0) {
                                                    return
                                                }
                                                const newValue = getIntValue(value)
                                                const limitReached = isAboveLimit(
                                                    newValue,
                                                    'annual_spend_amount',
                                                )
                                                if (limitReached > 0) {
                                                    setSpendError({
                                                        ...spendError,
                                                        annual_spend_amount: INPUT_REACHED_ERROR,
                                                    })
                                                } else {
                                                    setSpendError({
                                                        ...spendError,
                                                        annual_spend_amount: '',
                                                    })
                                                }
                                                const newState = { ...spend }
                                                newState.annual_spend_amount = newValue
                                                setSpend(newState)
                                            }}
                                            disabled={isRevisionLocked || isSpendLoading}
                                        ></Input>
                                        <Button
                                            onClick={() => {
                                                const newState = { ...spend }
                                                const newErrors = updateAnnualMonthlySpends(
                                                    newState,
                                                    1,
                                                )
                                                const newStateError = {
                                                    ...spendError,
                                                    ...newErrors,
                                                }

                                                if (newState.total_expenditure) {
                                                    newStateError.total_expenditure = ''
                                                }
                                                setSpendError(newStateError)
                                                newState.annual_spend_user_history = [
                                                    ...newState.annual_spend_user_history,
                                                    newState.annual_spend_amount,
                                                ]
                                                setSpend(newState)
                                            }}
                                            disabled={isSpreadDisabled()}
                                            variant={'primary'}
                                        >
                                            Spread Evenly
                                        </Button>
                                        <Button
                                            variant={'icon'}
                                            iconName={'undo'}
                                            disabled={
                                                isSpreadDisabled() ||
                                                spend.annual_spend_user_history.length == 1
                                            }
                                            onClick={() => {
                                                const newState = { ...spend }
                                                // first item is 0 spend
                                                // last item is the current annual spend value, so pop it off
                                                if (newState.annual_spend_user_history.length > 1) {
                                                    newState.annual_spend_user_history.pop()
                                                }
                                                const historyLength =
                                                    newState.annual_spend_user_history.length
                                                newState.annual_spend_amount =
                                                    newState.annual_spend_user_history[
                                                        historyLength - 1
                                                    ]
                                                updateAnnualMonthlySpends(newState, 1)
                                                setSpend(newState)
                                            }}
                                        ></Button>
                                    </SpaceBetween>
                                </FormField>
                            </ColumnLayout>
                            <Table
                                items={[spend]}
                                columnDefinitions={expenseColumnDefs}
                                sortingDisabled={true}
                                variant={'borderless'}
                                stickyColumns={{
                                    first: 1,
                                }}
                            />
                        </ColumnLayout>
                    </Container>
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default CreateEditDiscretionarySpend
