import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import HomePage from './apps/home/HomePage'
import NavBar from './layout/NavBar'
import { applyDensity, Density } from '@amzn/awsui-global-styles'
import NoRoutePage from './apps/home/NoRoutePage'
import './App.scss'
import { useAppContext } from '../context'
import useStore from './Store'
import { GLOBAL_BUSINESS_ENTITY, NO_USER_BUSINESS_ENTITY, SELECT_BUSINESS_ENTITY } from './Constant'
import SetupPlan from './apps/plan/SetupPlan'
import SetupRevision from './apps/plan/revision/SetupRevision'
import OrgHome from './apps/org/OrgHome'
import ProgramPage from './apps/program/ProgramPage'
import OrgDetail from './apps/org/OrgDetail'
import SetupDeliv from './apps/program/deliverables/SetupDeliv'
import EstimateHeadcount from './apps/program/deliverables/EstimateHeadcount'
import DiscretionarySpendPage from './apps/program/discretionarySpend/DiscretionarySpendPage'
import CreateEditDiscretionarySpend from './apps/program/discretionarySpend/CreateEditDiscretionarySpend'
import GroupSummary from './apps/summary/GroupSummary'
import EgretAuthorizer from '../auth/EgretAuthorizer'
import Unauthorized from './apps/home/Unauthorized'
import NoBusinessEntity from './apps/home/NoBusinessEntity'
import AuditPage from './apps/audit/AuditPage'
import AuditsPage from './apps/audit/AuditsPage'
import PlanSummary from './apps/summary/PlanSummary'
import OrgSummary from './apps/summary/OrgSummary'
import { Sandbox } from './apps/program/Sandbox'
import STLSummaryView from './apps/summary/STLSummaryView'
import HomePageSetUp from './apps/home/HomePageSetUp'
import SummariesAudits from './apps/summary/SummariesAudits'

export default function App({ userAlias, wsURLBase }: any) {
    document.body.classList.add('awsui-visual-refresh')
    applyDensity(Density.Compact)
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const [isLoading, setIsLoading] = useState(true)

    const setBusinessEntities = useStore((state) => state.setBusinessEntities)
    const setBusinessEntityMap = useStore((state) => state.setBusinessEntityMap)
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const setSelectedBusinessEntity = useStore((state) => state.setSelectedBusinessEntity)
    const defaultBE = useStore((state) => state.selectedBusinessEntity)
    const [initialBE, setInitialBE] = useState(defaultBE)
    const [businessEntityBannerContent, setBusinessEntityBannerContent] = useState<any[]>([])

    const getUserBusinessEntity = async () => {
        const userBE = (await apiClient.get(`/falcon/user-business-entity`)).data
        const selected = {
            business_entity_id: !userBE ? NO_USER_BUSINESS_ENTITY : userBE.business_entity_id,
            business_entity_name: !userBE ? SELECT_BUSINESS_ENTITY : userBE.business_entity_name,
        }
        setSelectedBusinessEntity(selected)
        setInitialBE(selected)
        setIsLoading(false)
    }

    const getAllBusinessEntities = () => {
        apiClient
            .get('/falcon/business-entities')
            .then((response) => {
                const businessEntities = response.data
                const businessEntityMap = {}
                businessEntities.forEach((businessEntity) => {
                    businessEntityMap[businessEntity.business_entity_id] =
                        businessEntity.business_entity_name
                })
                setBusinessEntities(
                    businessEntities.filter(
                        (businessEntity) =>
                            businessEntity.business_entity_id !== GLOBAL_BUSINESS_ENTITY,
                    ),
                )
                setBusinessEntityMap(businessEntityMap)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        getAllBusinessEntities()
        getUserBusinessEntity().catch((err) => {
            console.error(err)
        })
    }, [])

    return (
        <Router>
            <NavBar
                setBusinessEntityBannerContent={setBusinessEntityBannerContent}
                initialBusinessEntity={initialBE}
            />
            <Routes>
                <Route
                    path='/'
                    element={<EgretAuthorizer selectedBusinessEntity={selectedBusinessEntity} />}
                >
                    {/* TODO: remove once new home page and it's connections have been approved. */}
                    {/*<Route*/}
                    {/*    path={'/'}*/}
                    {/*    element={*/}
                    {/*        <HomePage*/}
                    {/*            businessEntityBannerContent={businessEntityBannerContent}*/}
                    {/*            isLoading={isLoading}*/}
                    {/*            setBusinessEntityBannerContent={setBusinessEntityBannerContent}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*/>*/}
                    <Route
                        path={'/'}
                        element={
                            <HomePageSetUp
                                businessEntityBannerContent={businessEntityBannerContent}
                                isLoading={isLoading}
                                setBusinessEntityBannerContent={setBusinessEntityBannerContent}
                            />
                        }
                    />
                    <Route path={'/all-plans'} element={<SetupPlan />} />
                    <Route path='/programs' element={<ProgramPage />} />
                    <Route path='/plan/:plan_id/revision/:revision_id' element={<ProgramPage />} />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id/estimate-headcount'
                        element={<EstimateHeadcount />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id/group/:group_id/discretionary-spend'
                        element={<DiscretionarySpendPage />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id/discretionary-spend'
                        element={<DiscretionarySpendPage />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id/group/:group_id/discretionary-spend/:expense_id'
                        element={<CreateEditDiscretionarySpend />}
                    />
                    <Route
                        path='/plan/:plan_id/revision/:revision_id/program/:program_id'
                        element={<SetupDeliv />}
                    />
                    <Route path='/orgs' element={<OrgHome />} />
                    <Route path='/plan/:id/revisions' element={<SetupRevision />} />
                    <Route path='/org/:id' element={<OrgDetail />} />
                    {/* todo: remove /group-summary once the revamped group summary page is approved and user accepted*/}
                    <Route path='/group-summary' element={<GroupSummary isRevamped={false} />} />
                    <Route
                        path='/group-summary-revamped'
                        element={<GroupSummary isRevamped={true} />}
                    />
                    <Route path='/plan-summary' element={<PlanSummary />} />
                    <Route
                        path='/sandbox'
                        element={<Sandbox userAlias={userAlias} wsURLBase={wsURLBase} />}
                    />
                    {/* todo: remove /org-summary once the revamped org summary page is approved and user accepted*/}
                    <Route path='/org-summary' element={<OrgSummary isRevamped={false} />} />
                    <Route
                        path='/org-summary-revamped'
                        element={<OrgSummary isRevamped={true} />}
                    />
                    <Route
                        path='/program-summary/plan/:plan_id/revision/:revision_id/program/:program_id'
                        element={<STLSummaryView isRevamped={false} />}
                    />
                    <Route
                        path='/program-summary-revamped/plan/:plan_id/revision/:revision_id/program/:program_id'
                        element={<STLSummaryView isRevamped={true} />}
                    />
                    {/* todo: remove /program-summary once the revamped program summary page is approved and user accepted*/}
                    <Route
                        path='/program-summary'
                        element={<STLSummaryView isRevamped={false} />}
                    />
                    <Route
                        path='/program-summary-revamped'
                        element={<STLSummaryView isRevamped={true} />}
                    />
                    <Route path='/new-summaries' element={<SummariesAudits />} />
                    <Route path='/audit' element={<AuditPage />} />
                    <Route path='/audits' element={<AuditsPage />} />
                </Route>
                <Route
                    path='/unauthorized'
                    element={<Unauthorized businessEntity={selectedBusinessEntity} />}
                />
                <Route path='/no-business-entity' element={<NoBusinessEntity />} />
                <Route path='*' element={NoRoutePage} />
            </Routes>
        </Router>
    )
}
