import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import useStore from '../../Store'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Spinner,
    Select,
    Table,
} from '@amzn/awsui-components-react'
import {
    LOCAL_PROGRAM_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
    getMatchesCountText,
} from '../reusable/UseCollectionUtil'
import EmptyState from '../reusable/EmptyState'
import { useAppContext } from '../../../context'
import { useNavigate } from 'react-router-dom'
import { GLOBAL_BUSINESS_ENTITY } from '../../Constant'
import ExportTable from '../reusable/ExportTable'
import { getProgramTableExportAllColumns } from './ProgramAttributes'
import {
    getDeliverableColumnDefinitions,
    getDeliverableExportVisibleColumns,
} from './deliverables/DeliverableAttributes'
import { formatSelection } from '../summary/SummaryUtil'
import { sortPlanOptions } from './ProgramSharedUtils'
import CommentIcon from '../comment/CommentIcon'
import { COMMENT_PANEL_MODE } from '../comment/Constant'

interface ProgramTableProps {
    isStlNotAdmin: boolean
    selectedRevision: any
    onSelectedRevisionChange: (revision: any) => void
    selectedRevisionOptions: any[]
    programs: any[]
    selectedPlan: any
    selectedPlanOptions: any[]
    onSelectedPlanChange: (plan: any) => void
    getLocalPrograms: (planId: string, revisionId: string) => void
    getParentPrograms: () => void
    selectedPrograms: any[]
    onSelectedProgramsChange: (programs: any[]) => void
    isRevisionLocked: boolean
    onCreate: () => void
    onEdit: () => void
    onView: (program: any) => void
    onDelete: () => void
    onIsModalExpandChange: (isModalExpand: boolean) => void
    isLoading: boolean
    onIsAddingProgramChange: (isAddingProgram: boolean) => void
    selectedProgramBusinessEntity: any
    onSelectedProgramBusinessEntityChange: (businessEntity: any) => void
    programBusinessEntityOptions: any[]
    columnDefinitions: any[]
    visibleColumns: any[]
    exportVisibleColumns: any[]
    deliverables: any
    fetchGlobalAttributes: () => void
    setIsLoadingGlobalAttrs: (isLoading: boolean) => void
    isSidePanelVisible: boolean
    onSidePanelVisibleChange: Dispatch<SetStateAction<boolean>>
    commentMode: COMMENT_PANEL_MODE
    onCommentModeChange: Dispatch<SetStateAction<COMMENT_PANEL_MODE>>
}

const ProgramTable = ({
    isStlNotAdmin,
    selectedRevision,
    onSelectedRevisionChange,
    selectedRevisionOptions,
    programs,
    selectedPlan,
    selectedPlanOptions,
    onSelectedPlanChange,
    getLocalPrograms,
    getParentPrograms,
    selectedPrograms,
    onSelectedProgramsChange,
    isRevisionLocked,
    onCreate,
    onEdit,
    onView,
    onDelete,
    onIsModalExpandChange,
    isLoading,
    onIsAddingProgramChange,
    selectedProgramBusinessEntity,
    onSelectedProgramBusinessEntityChange,
    programBusinessEntityOptions,
    columnDefinitions,
    visibleColumns,
    exportVisibleColumns,
    deliverables,
    fetchGlobalAttributes,
    setIsLoadingGlobalAttrs,
    isSidePanelVisible,
    onSidePanelVisibleChange,
    commentMode,
    onCommentModeChange,
}: ProgramTableProps) => {
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const canEdit = useStore((state) => state.canEditPrograms)
    const canAdmin = useStore((state) => state.canAdmin)
    const userGroups = canAdmin
        ? useStore((state) => state.userGroups)
        : useStore((state) => state.userGroups).filter((group) => group.is_egret)

    const planId = selectedPlan?.value?.plan_id || ''
    const history = useNavigate()
    const isWriteDisabled = () => {
        return selectedProgramBusinessEntity.value !== GLOBAL_BUSINESS_ENTITY && isRevisionLocked
    }
    const [fileName, setFileName] = useState('Programs')
    const [detailsFileName, setDetailsFileName] = useState('')

    const isEditActionForStl = () => {
        if (!isStlNotAdmin || selectedPrograms.length === 0) {
            return true
        }
        return (
            selectedPrograms[0].stl_alias.includes(userProps.userAlias) ||
            (selectedPrograms[0]?.registered_users || []).includes(userProps.userAlias)
        )
    }

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const [preferences] = useState({ pageSize: 50 })

    const actionButtonDropdown = [
        {
            text: 'Spend Estimate',
            id: 'discretionary_spend',
            disabled:
                !userGroups.length ||
                selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY ||
                selectedPrograms.length !== 1 ||
                isLoading,
        },
        {
            text: 'Program Summary',
            id: 'program_summary',
            disabled:
                selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY ||
                selectedPrograms.length !== 1 ||
                isLoading,
        },
        ...(canEdit
            ? [
                  {
                      text: isEditActionForStl() ? 'Edit' : 'View',
                      id: isEditActionForStl() ? 'edit_program' : 'view_program',
                      disabled: selectedPrograms.length !== 1 || isLoading || isWriteDisabled(),
                  },
              ]
            : []),
        ...(canAdmin
            ? [
                  {
                      text:
                          selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                              ? 'Delete'
                              : 'Remove',
                      id: 'delete_program',
                      disabled: selectedPrograms.length === 0 || isLoading || isWriteDisabled(),
                  },
              ]
            : []),
    ]

    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(programs, {
        propertyFiltering: {
            filteringProperties: LOCAL_PROGRAM_FILTERING_PROPERTIES,
            empty: isLoading ? (
                <Spinner />
            ) : (
                <EmptyState
                    title='No programs'
                    subtitle={
                        selectedBusinessEntity.business_entity_id
                            ? `No programs to display. ${canAdmin ? 'Create one!' : ''}`
                            : 'Select business entity to view program(s).'
                    }
                    action={
                        selectedBusinessEntity.business_entity_id &&
                        canEdit && (
                            <Button disabled={isWriteDisabled()} onClick={onCreate}>
                                Create new program
                            </Button>
                        )
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches programs'
                    subtitle='We can not find a match prgoram.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions.find((def) => def.id === 'program_name') || {},
            },
        },
        selection: {},
    })

    useEffect(() => {
        onSelectedProgramsChange([])
    }, [selectedProgramBusinessEntity])

    const handleProgramBusinessEntityChange = ({ detail }) => {
        const option = detail.selectedOption
        onSelectedProgramBusinessEntityChange(option)
        if (option.value === GLOBAL_BUSINESS_ENTITY) {
            getParentPrograms()
            return
        }
        if (selectedRevisionOptions.length) {
            onSelectedRevisionChange(selectedRevisionOptions[0])
            getLocalPrograms(planId, selectedRevisionOptions[0].value)
        }
        onSelectedProgramsChange([])
    }

    const handlePlanChange = ({ detail }) => {
        const option = detail.selectedOption
        if (option.value === selectedPlan.value) {
            return
        }
        onSelectedPlanChange(option)
        history(`/plan/${option.value.plan_id}/revision/${option.value?.revisions[0].revision_id}`)
        onSelectedProgramsChange([])
    }

    const handleRevisionChange = ({ detail }) => {
        const option = detail.selectedOption
        onSelectedRevisionChange(option)
        if (selectedRevision.value) {
            getLocalPrograms(planId, option.value)
        }
        history(`/plan/${planId}/revision/${option.value}`)
        onSelectedProgramsChange([])
    }

    const formatExportFileName = (includeProgramsKeyword: boolean) => {
        const revisions = selectedPlan.value.revisions || []
        const selectedRevisionMetadata = revisions.find(
            (rev) => rev.revision_id === selectedRevision.value,
        )
        return `${selectedPlan.value?.plan_type}-${selectedPlan.value?.year}-Rev${selectedRevisionMetadata?.revision_number ?? ''}${includeProgramsKeyword ? '-Programs' : ''}-${selectedBusinessEntity.business_entity_name.replaceAll(' ', '')}`
    }

    useEffect(() => {
        if (selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY) {
            setFileName('AllPrograms')
            setDetailsFileName(`AllPrograms+${formatExportFileName(false)}`)
            return
        }
        if (!selectedPlan.value?.plan_id || !selectedRevision.value) {
            return
        }
        setFileName(formatExportFileName(true))
    }, [selectedPlan, selectedRevision, selectedProgramBusinessEntity])

    return (
        <Table
            {...collectionProps}
            items={items}
            wrapLines
            stickyHeader={true}
            selectedItems={selectedPrograms}
            onSelectionChange={({ detail }) => {
                onSelectedProgramsChange(detail.selectedItems)
            }}
            loading={isLoading}
            columnDefinitions={columnDefinitions}
            columnDisplay={visibleColumns}
            loadingText={'Loading Programs'}
            selectionType={'single'}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTER_I18NSTRING}
                        {...propertyFilterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                    <Select
                        selectedOption={formatSelection(selectedProgramBusinessEntity)}
                        onChange={handleProgramBusinessEntityChange}
                        options={programBusinessEntityOptions}
                        disabled={isLoading}
                    />

                    {!(selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY) && [
                        <Select
                            key={1}
                            selectedOption={selectedPlan}
                            onChange={handlePlanChange}
                            options={sortPlanOptions(selectedPlanOptions)}
                            disabled={isLoading}
                        />,
                        <Select
                            key={2}
                            selectedOption={selectedRevision}
                            onChange={handleRevisionChange}
                            options={selectedRevisionOptions}
                            disabled={isLoading}
                        />,
                    ]}
                </SpaceBetween>
            }
            header={
                <Box margin={{ bottom: 's' }}>
                    <Header
                        variant='h2'
                        actions={
                            <SpaceBetween size='xs' direction='horizontal'>
                                <CommentIcon
                                    isVisible={isSidePanelVisible}
                                    onVisibleChange={onSidePanelVisibleChange}
                                    mode={commentMode}
                                    onModeChange={onCommentModeChange}
                                />
                                {canAdmin && (
                                    <Box padding={{ top: 's' }}>
                                        <Button
                                            variant='primary'
                                            onClick={() => {
                                                onCreate()
                                                onIsAddingProgramChange(false)
                                            }}
                                            disabled={isLoading || isWriteDisabled()}
                                        >
                                            {'Create Program'}
                                        </Button>
                                    </Box>
                                )}
                                {canAdmin &&
                                    selectedProgramBusinessEntity.value ===
                                        GLOBAL_BUSINESS_ENTITY && (
                                        <Box padding={{ top: 's' }}>
                                            <Button
                                                variant='primary'
                                                onClick={async () => {
                                                    // fill in global attributes if they exist
                                                    setIsLoadingGlobalAttrs(true)
                                                    fetchGlobalAttributes()
                                                    onCreate()
                                                    onIsAddingProgramChange(true)
                                                    onIsModalExpandChange(true)
                                                }}
                                                disabled={
                                                    selectedPrograms.length !== 1 || isLoading
                                                }
                                            >
                                                {`Add to a Plan`}
                                            </Button>
                                        </Box>
                                    )}
                                <Box padding={{ top: 's' }}>
                                    <ButtonDropdown
                                        items={actionButtonDropdown}
                                        onItemClick={({ detail }) => {
                                            if (detail.id === 'edit_program') {
                                                onEdit()
                                                onIsAddingProgramChange(false)
                                            } else if (detail.id === 'view_program') {
                                                onView(selectedPrograms)
                                            } else if (detail.id === 'delete_program') {
                                                onDelete()
                                            } else if (
                                                detail.id ===
                                                'deliverable_selection_for_hc_estimating'
                                            ) {
                                                history(
                                                    `/plan/${selectedPlan.value.plan_id}/revision/${selectedRevision.value}/program/${selectedPrograms[0].program_id}`,
                                                )
                                            } else if (detail.id === 'discretionary_spend') {
                                                history(
                                                    `/plan/${selectedPlan.value.plan_id}/revision/${selectedRevision.value}/program/${selectedPrograms[0].program_id}/discretionary-spend`,
                                                )
                                            } else if (detail.id === 'program_summary') {
                                                history(
                                                    `/new-summaries?Program=${selectedPrograms[0].program_id}&plan=${selectedPlan.value.plan_id}&revision=${selectedRevision.value}`,
                                                )
                                            }
                                        }}
                                        disabled={isLoading}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                </Box>
                                <Box padding={{ top: 's' }}>
                                    <ExportTable
                                        enableDropdown={true}
                                        parentSheetName={'Programs'}
                                        tableData={allPageItems}
                                        disabled={!selectedPlan.value?.plan_id}
                                        visibleColumns={exportVisibleColumns}
                                        tableColumnDef={columnDefinitions}
                                        fileName={fileName}
                                        detailsFileName={
                                            selectedProgramBusinessEntity.value !==
                                            GLOBAL_BUSINESS_ENTITY
                                                ? undefined
                                                : detailsFileName
                                        }
                                        subTableData={deliverables}
                                        subTableColumnDef={getDeliverableColumnDefinitions()}
                                        subTableVisibleColumns={getDeliverableExportVisibleColumns()}
                                        customizedExportButtons={[
                                            {
                                                text: 'Export with All Program Metadata',
                                                id: 'export_with_program_metadata',
                                                disabled:
                                                    !allPageItems.length ||
                                                    !selectedPlan.value?.plan_id,
                                            },
                                        ]}
                                        allColumns={getProgramTableExportAllColumns()}
                                        objectNameAttribute={'program_name'}
                                    />
                                </Box>
                            </SpaceBetween>
                        }
                    ></Header>
                </Box>
            }
        />
    )
}

export default ProgramTable
