import { useEffect, useReducer, useState } from 'react'
import { useAppContext } from '../../../../context'
import {
    Alert,
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Flashbar,
    Form,
    FormField,
    Grid,
    Header,
    Link,
    Input,
    Pagination,
    Select,
    SpaceBetween,
    Spinner,
    Textarea,
    Toggle,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../../reusable/HeaderTemplate'
import useLocalDeliverableStore from './LocalDeliverableStore'
import { useNavigate, useLocation } from 'react-router-dom'
import { generateUuid, getGroupOptions } from '../../../common/Util'
import GenericSummaryTable from '../../reusable/GenericSummaryTable'
import { NUMERIC_VALIDATION } from '../../plan/Constants'
import useStore from '../../../Store'
import CreateEditModal from '../CreateEditModal'
import {
    EMPTY_HR_DATA,
    EMPTY_SELECTION,
    getDefaultEstimate,
    LIVE_HR_TEAM_DATA_KEY,
    MODAL_MODES,
} from '../../../Constant'
import HRDataTable from './HRDataTable'
import bigDecimal from 'js-big-decimal'
import { roundSingleItem } from './DeliverableConstants'
import { generateInitialState } from '../ProgramConstants'
import {
    emptyFn,
    filterVisibleColsByTerms,
    findGroupTeamID,
    getMetadataForPlan,
} from '../../reusable/Utils'
import HCDetailModal from './HCDetailModal'
import { convertMetadataToDict, formatHrMemberData, getHeadcountEmployeeValues } from './DelivUtil'
import { addJobFunctionTooltip, styleEstimateDisplay } from './EstimateHeadcountAttributes'
import { programReducer, REDUCER_ACTIONS } from '../ProgramSharedUtils'
import { programAttributes } from '../ProgramAttributes'
import RevisionAlert, { SOURCE_PAGE } from '../../reusable/RevisionAlert'

// TODO: this is the old hc estimate set up. Implementations around this workflow can be deprecated once Egret release on 9/16 is successful in production
const EstimateHeadcount = () => {
    const location = useLocation()
    const navigate: any = useNavigate()
    const deliverablePage = `${location.pathname.replace('/estimate-headcount', '')}?type=${
        location?.state?.programType || ''
    }&programs=${location?.state?.localPrograms || ''}`
    const paths = location.pathname.split('/')
    const planId = paths[2]
    const revisionId = paths[4]
    const programId = paths[6]
    const programPage = `/plan/${planId}/revision/${revisionId}`
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const [selectedPlan, setSelectedPlan] = useState<any>(EMPTY_SELECTION)
    const [planMetadata, setPlanMetadata] = useState<any>({})

    const deliverables = useLocalDeliverableStore((state) => state.hcSelectedDeliverables)
    const programSummaryColumnsTmp = useLocalDeliverableStore(
        (state) => state.programSummaryColumns,
    )
    const programSummaryVisibleColumns = useLocalDeliverableStore(
        (state) => state.programSummaryVisibleColumns,
    )
    const deliverableSummaryColumns = useLocalDeliverableStore(
        (state) => state.deliverableSummaryColumns,
    )
    const deliverableSummaryVisibleColumns = useLocalDeliverableStore(
        (state) => state.deliverableSummaryVisibleColumns,
    )
    const selectedRevision = useLocalDeliverableStore((state) => state.selectedRevision)
    const selectedProgram = useLocalDeliverableStore((state) => state.programMetadata)
    // program is subject to OH by default
    const subjectToOverhead =
        selectedProgram?.is_overhead_applied !== undefined
            ? selectedProgram.is_overhead_applied
            : true

    const canScopePrograms = useStore((state) => state.canScopeProgramsDeliverables)
    // estimate HC -> based on revision status; program scope ability based on user roles
    const canAdmin = useStore((state) => state.canAdmin)
    const [hrTeamData, setHrTeamData] = useState<any>({})
    const [hrMemberData, setHrMemberData] = useState<any>({})
    const [hcByEmployeeType, setHcByEmployeeType] = useState<any>({})
    const [programViewModalVisible, setProgramViewModalVisible] = useState(false)
    const [programModalExpanded, setProgramModalExpanded] = useState(true)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [showAlert, setShowAlert] = useState(false)
    const [currentDeliverable, setCurrentDeliverable] = useState<any>(
        deliverables.length ? deliverables[0] : undefined,
    )
    const [pageLoading, setPageLoading] = useState(true)
    const [hcMetadata, setHcMetadata] = useState<any>()
    const appContext = useAppContext()
    const userProps = appContext.userProps
    const apiClient = appContext.apiClient
    const numDelis = deliverables?.length || 0
    const [isFteMonthsSelected, setIsFteMonthsSelected] = useState(false)
    const [saveEnabled, setSaveEnabled] = useState(true)
    const [overheadData, setOverheadData] = useState(0)
    const [overheadToApply, setOverheadToApply] = useState(0)
    const [hrModalVisible, setHRModalVisible] = useState(false)
    const [isRevisionLocked, setIsRevisionLocked] = useState(false)
    const canEditHc = (canScopePrograms || canAdmin) && !isRevisionLocked
    const [hcTotals, setHcTotals] = useState({
        hc_ct: '0',
        hc_ff: '0',
        hc_total: '0',
        fte_month_ct: '0',
        fte_month_ff: '0',
        fte_month_total: '0',
        hc_ct_overhead: '0',
        hc_ff_overhead: '0',
        hc_total_overhead: '0',
        fte_month_ct_overhead: '0',
        fte_month_ff_overhead: '0',
        fte_month_total_overhead: '0',
    })
    const generateDefaultHc = () => {
        return {
            job_function: '',
            hc_ct: '0.00',
            hc_ff: '0.00',
            hc_total: '0.00',
            fte_month_ct: '0.00',
            fte_month_ff: '0.00',
            fte_month_total: '0.00',
            hc_ct_overhead: '0.00',
            hc_ff_overhead: '0.00',
            hc_total_overhead: '0.00',
            fte_month_ct_overhead: '0.00',
            fte_month_ff_overhead: '0.00',
            fte_month_total_overhead: '0.00',
            item_id: generateUuid(),
            error: ['', '', '', ''],
        }
    }
    const programSummaryColumns = programSummaryColumnsTmp.length
        ? [
              {
                  id: 'prog_name_display',
                  header: 'Program',
                  cell: (e) => (
                      <Link
                          onFollow={() => {
                              loadSelect(selectedProgram)
                              setProgramViewModalVisible(true)
                          }}
                      >
                          {e.program_name}
                      </Link>
                  ),
                  sortingField: 'program_name',
              },
              ...programSummaryColumnsTmp.slice(1),
          ]
        : []
    const [hcItems, setHcItems] = useState<any>([generateDefaultHc()])
    const [jobFunctionList, setJobFunctionList] = useState<any[]>([])
    const [initialHcItems, setInitialHcItems] = useState<any[]>([])
    const userGroups = useStore((state) => state.userGroups)
    const canEditPrograms = useStore((state) => state.canEditPrograms)
    const [groupOptions, setGroupOptions] = useState<any>([])
    const [selectedGroup, setSelectedGroup] = useState<any>({
        object_id: '',
        object_name: 'Select Group',
    })
    const [jobFunctionOptions, setJobFunctionOptions] = useState([
        { label: 'Select Job Function', value: '' },
    ])
    const [flashbarMessages, setFlashbarMessages] = useState<any[]>([])
    const [requestedPage, setRequestedPage] = useState(1)
    const getIsHcFieldsDisabled = () => {
        return (
            (canEditHc && isFteMonthsSelected && selectedProgram?.is_active) ||
            !selectedGroup.object_id
        )
    }
    const getIsFteMonthFieldsDisabled = () => {
        return (
            (canEditHc && !isFteMonthsSelected && selectedProgram?.is_active) ||
            !selectedGroup.object_id
        )
    }
    useEffect(() => {
        setGroupOptions(
            getGroupOptions(
                selectedBusinessEntity.business_entity_id,
                userGroups.filter((group) => group.is_egret),
            ),
        )
        getMetadataForPlan(apiClient, planId, setPlanMetadata, setSelectedPlan)
    }, [userGroups])

    const [programState, dispatchProgram] = useReducer(
        programReducer,
        generateInitialState(programAttributes),
    )

    const loadSelect = (target) => {
        dispatchProgram({
            type: REDUCER_ACTIONS.load,
            target: target,
        })
        return
    }

    const handleAddMessage = (message, barType) => {
        const newId = generateUuid()
        const newMessage = {
            type: barType,
            dismissible: true,
            dismissLabel: 'Dismiss message',
            onDismiss: () => {
                setFlashbarMessages(
                    flashbarMessages.filter((flashMessage) => flashMessage.id !== newId),
                )
            },
            content: <>{message}</>,
            id: newId,
        }
        setFlashbarMessages([newMessage, ...flashbarMessages])
        return message
    }

    const getDeliverableHeadcount = async () => {
        if (!selectedGroup.object_id) {
            setPageLoading(false)
            console.error("No group selected, can't get HC.")
            return
        }
        if (!currentDeliverable) {
            setPageLoading(false)
            console.error("Invalid deliverable, can't query.")
            return
        }
        const aggHc = {
            hc_ct: '0',
            hc_ff: '0',
            hc_total: '0',
            fte_month_ct: '0',
            fte_month_ff: '0',
            fte_month_total: '0',
            hc_ct_overhead: '0',
            hc_ff_overhead: '0',
            hc_total_overhead: '0',
            fte_month_ct_overhead: '0',
            fte_month_ff_overhead: '0',
            fte_month_total_overhead: '0',
        }
        await apiClient
            .get(
                `/group/${selectedGroup.object_id}/deliverable/${currentDeliverable.deliverable_id}/headcount-estimates?plan_id=${planId}&revision_id=${revisionId}&program_id=${programId}`,
            )
            .then((res) => {
                const jobFunctionHc = res.data
                setIsFteMonthsSelected(jobFunctionHc?.is_fte_months_selected || isFteMonthsSelected)
                let estimates = jobFunctionHc?.hc_estimates
                    ? jobFunctionHc.hc_estimates.map((hc) => ({
                          ...hc,
                          hc_ct: String(hc.hc_ct),
                          hc_ff: String(hc.hc_ff),
                          hc_total: String(hc.hc_total),
                          fte_month_ct: String(hc.fte_month_ct),
                          fte_month_ff: String(hc.fte_month_ff),
                          fte_month_total: String(hc.fte_month_total),
                          hc_ct_overhead: String(hc.hc_ct_overhead),
                          hc_ff_overhead: String(hc.hc_ff_overhead),
                          hc_total_overhead: String(hc.hc_total_overhead),
                          fte_month_ct_overhead: String(hc.fte_month_ct_overhead),
                          fte_month_ff_overhead: String(hc.fte_month_ff_overhead),
                          fte_month_total_overhead: String(hc.fte_month_total_overhead),
                          deliverable_note: jobFunctionHc?.deliverable_note || '',
                          item_id: generateUuid(),
                          error: ['', '', '', ''],
                      }))
                    : []
                const jobFunctions = estimates.map((hcItem) => hcItem.job_function)
                estimates = estimates.length ? estimates : [generateDefaultHc()]
                const jobOptions = jobFunctionList.length
                    ? jobFunctionList.map((job) => ({
                          label: job.display_name,
                          value: job.value,
                      }))
                    : [
                          {
                              label: 'Select Job Function',
                              value: '',
                          },
                      ]
                setHcItems([...estimates])
                setInitialHcItems([...estimates])
                setHcMetadata(jobFunctionHc)
                setJobFunctionOptions(
                    jobOptions.filter((jobOption) => !jobFunctions.includes(jobOption.value)),
                )
                const overHead = new bigDecimal(1 + overheadToApply / 100)
                estimates.forEach((hc) => {
                    aggHc.hc_ct = bigDecimal.add(aggHc.hc_ct, hc.hc_ct)
                    aggHc.hc_ff = bigDecimal.add(aggHc.hc_ff, hc.hc_ff)
                    aggHc.fte_month_ct = bigDecimal.add(aggHc.fte_month_ct, hc.fte_month_ct)
                    aggHc.fte_month_ff = bigDecimal.add(aggHc.fte_month_ff, hc.fte_month_ff)
                })
                aggHc.hc_total = bigDecimal.add(aggHc.hc_ct, aggHc.hc_ff)
                aggHc.fte_month_total = bigDecimal.add(aggHc.fte_month_ct, aggHc.fte_month_ff)
                aggHc.hc_total_overhead = bigDecimal.multiply(aggHc.hc_total, overHead.getValue())
                aggHc.fte_month_total_overhead = bigDecimal.multiply(
                    aggHc.fte_month_total,
                    overHead.getValue(),
                )
                aggHc.hc_ct_overhead = bigDecimal.multiply(aggHc.hc_ct, overHead.getValue())
                aggHc.fte_month_ct_overhead = bigDecimal.multiply(
                    aggHc.fte_month_ct,
                    overHead.getValue(),
                )
                aggHc.hc_ff_overhead = bigDecimal.multiply(aggHc.hc_ff, overHead.getValue())
                aggHc.fte_month_ff_overhead = bigDecimal.multiply(
                    aggHc.fte_month_ff,
                    overHead.getValue(),
                )
                setHcTotals(aggHc)
                setPageLoading(false)
            })
            .catch((err) => {
                console.error(err)
                setHcItems([generateDefaultHc()])
                setInitialHcItems([])
                setHcMetadata(undefined)
                setHcTotals(aggHc)
                setPageLoading(false)
            })
    }

    useEffect(() => {
        setPageLoading(true)
        refreshData()
    }, [selectedGroup, currentDeliverable])
    const getJobFunctions = async () => {
        await apiClient
            .get(`/settings?keys=job_function`)
            .then((res) => {
                setJobFunctionList(
                    res.data.sort((job1, job2) => (job1.display_name < job2.display_name ? -1 : 1)),
                )
            })
            .catch((err) => {
                console.error(err)
                setJobFunctionList([])
            })
    }

    const getBISData = () => {
        if (!selectedGroup.object_id) {
            setHrTeamData(EMPTY_HR_DATA)
            return
        }
        const amznTeamId = findGroupTeamID(
            userGroups.filter((group) => group.is_egret),
            selectedGroup,
        )
        const headcountByEmployeeType = getDefaultEstimate()
        apiClient
            .get(`/falcon/head_counts/amzn_team_id/${amznTeamId}?member_specs=egret`)
            .then((response) => {
                const res = response.data
                const hrTeamData = res[0]
                const hrMemberData = res[1]
                let formattedTeamData = convertMetadataToDict(hrTeamData, LIVE_HR_TEAM_DATA_KEY)
                if (!Object.keys(formattedTeamData).length) {
                    formattedTeamData = EMPTY_HR_DATA
                }
                setHrTeamData(formattedTeamData)
                setHrMemberData(formatHrMemberData(hrMemberData))
                setHcByEmployeeType(getHeadcountEmployeeValues(formattedTeamData))
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const updateHeadcountEdits = (newValue, formFieldId, itemId) => {
        let errMessage = ''
        const value = newValue.trim()
        const currentHeadcount = [...hcItems]
        if (formFieldId.includes('note')) {
            const updateHc: any[] = []
            currentHeadcount.forEach((hc) => {
                const newHc = { ...hc }
                newHc[formFieldId] = newValue
                updateHc.push(newHc)
            })
            setHcItems(updateHc)
            return
        }
        const isHcField =
            formFieldId !== 'job_function' &&
            (formFieldId.includes('hc') || formFieldId.includes('fte_month'))
        const estimateType = isHcField && formFieldId.includes('hc') ? 'hc' : 'fte_month'
        if (isHcField && newValue.trim().length) {
            const isValid = !isNaN(Number(newValue.trim()))
            errMessage = isValid ? '' : NUMERIC_VALIDATION
        }
        const targetItemIndex = hcItems.indexOf(hcItems.find((hcItem) => hcItem.item_id === itemId))
        const itemToUpdate = { ...currentHeadcount[targetItemIndex] }
        itemToUpdate[formFieldId] = value
        if (!errMessage) {
            const currentCt = !itemToUpdate[`${estimateType}_ct`].length
                ? '0'
                : itemToUpdate[`${estimateType}_ct`]
            const currentFf = !itemToUpdate[`${estimateType}_ff`].length
                ? '0'
                : itemToUpdate[`${estimateType}_ff`]
            const currentTotal = bigDecimal.add(currentCt, currentFf)
            itemToUpdate[`${estimateType}_total`] = currentTotal
            const overheadFactor = new bigDecimal(1 + overheadToApply / 100)

            itemToUpdate[`${estimateType}_ct_overhead`] = bigDecimal.multiply(
                currentCt,
                overheadFactor.getValue(),
            )
            itemToUpdate[`${estimateType}_ff_overhead`] = bigDecimal.multiply(
                currentFf,
                overheadFactor.getValue(),
            )
            itemToUpdate[`${estimateType}_total_overhead`] = bigDecimal.multiply(
                currentTotal,
                overheadFactor.getValue(),
            )
            // update the derived estimates
            const targetKey = estimateType === 'hc' ? 'fte_month' : 'hc'
            const estimateFactor = '12'
            ;['ct', 'ff', 'total'].forEach((hcType) => {
                itemToUpdate[`${targetKey}_${hcType}`] =
                    targetKey === 'fte_month'
                        ? bigDecimal.multiply(
                              itemToUpdate[`${estimateType}_${hcType}`],
                              estimateFactor,
                          )
                        : bigDecimal.divide(
                              itemToUpdate[`${estimateType}_${hcType}`],
                              estimateFactor,
                          )
                itemToUpdate[`${targetKey}_${hcType}_overhead`] = bigDecimal.multiply(
                    itemToUpdate[`${targetKey}_${hcType}`],
                    overheadFactor.getValue(),
                )
            })
        }
        currentHeadcount[targetItemIndex] = itemToUpdate
        if (isHcField) {
            itemToUpdate['error'][
                formFieldId.includes('ct') ? 0 : 1 + estimateType === 'hc' ? 0 : 2
            ] = errMessage
            const noErrors = !itemToUpdate['error'].filter((err) => err.length > 0).length
            if (noErrors) {
                updateTotalsAndOverhead(currentHeadcount)
            }
            setSaveEnabled(noErrors)
        }
        setHcItems(currentHeadcount)
    }

    const updateTotalsAndOverhead = (newItems) => {
        if (!newItems.length) {
            setHcTotals({
                hc_ct: '0',
                hc_ff: '0',
                hc_total: '0',
                fte_month_ct: '0',
                fte_month_ff: '0',
                fte_month_total: '0',
                hc_ct_overhead: '0',
                hc_ff_overhead: '0',
                hc_total_overhead: '0',
                fte_month_ct_overhead: '0',
                fte_month_ff_overhead: '0',
                fte_month_total_overhead: '0',
            })
            return
        }
        const overHead = new bigDecimal(1 + overheadToApply / 100)
        const hcTotalsCurrent = {
            ...hcTotals,
        }

        ;['hc_ct', 'hc_ff', 'hc_total', 'fte_month_ct', 'fte_month_ff', 'fte_month_total'].forEach(
            (estimateKey) => {
                hcTotalsCurrent[estimateKey] = newItems
                    .map((hcItem) => hcItem[estimateKey] || '0')
                    .reduce((hc1, hc2) => bigDecimal.add(hc1, hc2))
                hcTotalsCurrent[`${estimateKey}_overhead`] = bigDecimal.multiply(
                    hcTotalsCurrent[estimateKey],
                    overHead.getValue(),
                )
            },
        )
        setHcTotals(hcTotalsCurrent)
    }

    const clearAllHeadcount = () => {
        setHcItems(
            hcItems.map((hcItem) => ({
                ...hcItem,
                hc_ct: '0',
                hc_ff: '0',
                hc_total: '0',
                fte_month_ct: '0',
                fte_month_ff: '0',
                fte_month_total: '0',
                hc_ct_overhead: '0',
                hc_ff_overhead: '0',
                hc_total_overhead: '0',
                fte_month_ct_overhead: '0',
                fte_month_ff_overhead: '0',
                fte_month_total_overhead: '0',
                error: ['', '', '', ''],
            })),
        )
        setHcTotals({
            hc_ct: '0',
            hc_ff: '0',
            hc_total: '0',
            fte_month_ct: '0',
            fte_month_ff: '0',
            fte_month_total: '0',
            hc_ct_overhead: '0',
            hc_ff_overhead: '0',
            hc_total_overhead: '0',
            fte_month_ct_overhead: '0',
            fte_month_ff_overhead: '0',
            fte_month_total_overhead: '0',
        })
        setSaveEnabled(true)
    }
    const restoreToInitial = () => {
        setHcItems(initialHcItems.length ? [...initialHcItems] : [generateDefaultHc()])
        updateTotalsAndOverhead(initialHcItems)
        setSaveEnabled(true)
    }
    const handleDelete = async (hcItemsToDelete) => {
        const job_functions = hcItemsToDelete.map((hcItem) => hcItem.job_function)
        const jobFunctionDisplays = job_functions.map((job) => getJobFunctionDisplay(job))
        let messageType = 'error'
        let message = ''
        await apiClient
            .delete(
                `/group/${selectedGroup.object_id}/deliverable/${
                    currentDeliverable.deliverable_id
                }/headcount-estimates?plan_id=${planId}&revision_id=${revisionId}&program_id=${programId}&job_functions=${job_functions.join(
                    '__',
                )}`,
            )
            .then(() => {
                messageType = 'success'
                message = `Successfully removed estimates for ${jobFunctionDisplays.join(', ')}`
            })
            .catch((err) => {
                console.error(err)
                message = `Failed to remove estimates for ${jobFunctionDisplays.join(', ')}: ${
                    err.response.data
                }`
            })
        return [message, messageType]
    }
    const refreshData = async () => {
        getOverheadData()
        await getJobFunctions()
    }
    useEffect(() => {
        getDeliverableHeadcount()
    }, [jobFunctionList])
    const saveEdits = async () => {
        const hcToSave = hcItems.filter((hcItem) => hcItem.job_function.length)
        if (!hcToSave.length) {
            refreshData()
            return ['', '']
        }
        setPageLoading(true)
        const payload = {
            deliverable_id: currentDeliverable.deliverable_id,
            group_id: selectedGroup.object_id,
            deliverable_note: hcToSave[0]?.deliverable_note || '',
            program_id: programId,
            plan_id: planId,
            revision_id: revisionId,
            business_entity_id: selectedBusinessEntity.business_entity_id,
            requestor: userProps.userAlias,
            overhead: overheadData,
            is_fte_months_selected: isFteMonthsSelected,
            hc_estimates: hcToSave.map((hcItem) => ({
                job_function: hcItem.job_function,
                hc_ff: hcItem.hc_ff.length ? hcItem.hc_ff : '0',
                hc_ct: hcItem.hc_ct.length ? hcItem.hc_ct : '0',
                hc_total: hcItem.hc_total,
                fte_month_ff: hcItem.fte_month_ff.length ? hcItem.fte_month_ff : '0',
                fte_month_ct: hcItem.fte_month_ct.length ? hcItem.fte_month_ct : '0',
                fte_month_total: hcItem.fte_month_total,
                hc_ff_overhead: hcItem.hc_ff_overhead,
                hc_ct_overhead: hcItem.hc_ct_overhead,
                hc_total_overhead: hcItem.hc_total_overhead,
                fte_month_ff_overhead: hcItem.fte_month_ff_overhead,
                fte_month_ct_overhead: hcItem.fte_month_ct_overhead,
                fte_month_total_overhead: hcItem.fte_month_total_overhead,
            })),
        }
        let messageType = 'error'
        let message = ''
        await apiClient
            .post(
                `/group/${selectedGroup.object_id}/deliverable/${currentDeliverable.deliverable_id}/headcount-estimates`,
                JSON.stringify(payload),
            )
            .then(() => {
                refreshData()
                setPageLoading(false)
                messageType = 'success'
                message = `Successfully saved headcount estimates.`
            })
            .catch((err) => {
                console.error(err)
                setPageLoading(false)
                message = `Failed to save headcount estimates: ${err.response.data}`
            })
        return [message, messageType]
    }
    const getEditDiff = () => {
        const toDelete = initialHcItems.filter(
            (orig) =>
                orig.job_function.length &&
                !hcItems.some((hcItem) => hcItem.job_function === orig.job_function),
        )
        // check if an original item was edited or a new one was added
        const edits = hcItems.filter(
            (newItem) =>
                newItem.job_function.length &&
                initialHcItems.some(
                    (orig) =>
                        (orig.item_id === newItem.item_id &&
                            (orig.job_function !== newItem.job_function ||
                                orig.hc_ct !== newItem.hc_ct ||
                                orig.hc_ff !== newItem.hc_ff)) ||
                        !initialHcItems.some(
                            (orig) =>
                                orig.item_id === newItem.item_id && newItem.job_function.length,
                        ) ||
                        orig.deliverable_note !== newItem.deliverable_note,
                ),
        )
        return [toDelete, edits]
    }
    const processEdits = async () => {
        const changes = getEditDiff()
        const toDelete = changes[0]
        const edits = changes[1]
        if (!edits.length && !toDelete.length) {
            return
        }
        setPageLoading(true)
        const messages: string[] = []
        const messageTypes: string[] = []
        if (toDelete.length) {
            const deleteResults = await handleDelete(toDelete)
            messages.push(deleteResults[0])
            messageTypes.push(deleteResults[1])
        }
        const editCreateResults = await saveEdits()
        if (editCreateResults[0]) {
            messages.push(editCreateResults[0])
            messageTypes.push(editCreateResults[1])
        }
        if (messageTypes.length) {
            const messageType = [...new Set(messageTypes)]
            handleAddMessage(
                messages.join('. '),
                messageType.length > 1 ? 'warning' : messageTypes[0],
            )
        }
    }
    const onAddJobFunction = () => {
        setHcItems([...hcItems, generateDefaultHc()])
    }
    const onRemoveJobFunction = (hcItemToDelete) => {
        const filtered = hcItems.filter((hcItem) => hcItem.item_id !== hcItemToDelete.item_id)
        updateTotalsAndOverhead(filtered)
        setHcItems(filtered.length ? filtered : [generateDefaultHc()])
        if (!hcItemToDelete.job_function) {
            return
        }
        const jobFunctionMeta = jobFunctionList.find(
            (job) => job.value === hcItemToDelete.job_function,
        )
        setJobFunctionOptions([
            ...jobFunctionOptions,
            {
                value: hcItemToDelete.job_function,
                label: jobFunctionMeta?.display_name || '',
            },
        ])
    }
    const saveAndEstimateAnother = async () => {
        setPageLoading(true)
        await processEdits()
        updatePageState(currentPageIndex + 1)
    }
    const updatePageState = (newPage) => {
        setShowAlert(false)
        setCurrentDeliverable(deliverables[newPage - 1])
        setCurrentPageIndex(newPage)
    }
    const onPageSwitch = async (requestedPage) => {
        const changes = getEditDiff()
        const toDelete = changes[0]
        const edits = changes[1]
        if (edits.length || toDelete.length) {
            setShowAlert(true)
            return
        }
        updatePageState(requestedPage)
    }

    useEffect(() => {
        getOverheadData()
        if (!selectedGroup.object_id) {
            return
        }
        getBISData()
    }, [selectedGroup])

    const getOverheadData = () => {
        apiClient
            .get(`/plan/${planId}`)
            .then((res) => {
                if (res.data?.overhead_factor.length > 0) {
                    const ohFactor = Number(res.data.overhead_factor)
                    setOverheadData(ohFactor)
                    setOverheadToApply(subjectToOverhead ? ohFactor : 0)
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getJobFunctionDisplay = (jobId) => {
        const defaultDisplay = 'Select Job Function'
        if (!jobId) {
            return defaultDisplay
        }
        return (
            jobFunctionList.find((job) => job.value === jobId)?.display_name ||
            'Job Function Not Found'
        )
    }
    const viewOnly = !canEditHc || !currentDeliverable?.is_active
    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Programs', href: programPage },
                                {
                                    text: `${
                                        selectedProgram ? selectedProgram.program_name : ''
                                    } Deliverables`,
                                    href: `${deliverablePage}`,
                                },
                                { text: 'Headcount Estimates', href: '' },
                            ]}
                        />
                        <RevisionAlert
                            planId={planId}
                            selectedRevisionId={revisionId}
                            sourcePage={SOURCE_PAGE.ESTIMATE}
                            setIsLocked={setIsRevisionLocked}
                            apiClient={apiClient}
                        />
                    </SpaceBetween>
                </Box>
            }
        >
            <HCDetailModal
                visible={hrModalVisible}
                onVisibleChange={setHRModalVisible}
                hrTeamData={hrTeamData}
                hrMemberData={hrMemberData}
                employeeTypeData={hcByEmployeeType}
            />
            <Container>
                {!deliverables || !deliverables.length ? (
                    <Container>
                        <SpaceBetween direction='vertical' size='s'>
                            <SpaceBetween direction='vertical' size='xxs'>
                                <Box variant='h2'>No deliverables</Box>
                            </SpaceBetween>
                            Please select deliverables to estimate headcount.
                            <Button
                                onClick={() => {
                                    navigate(deliverablePage)
                                }}
                            >
                                Select Deliverables
                            </Button>
                        </SpaceBetween>
                    </Container>
                ) : (
                    <SpaceBetween size={'xxl'}>
                        <Flashbar items={flashbarMessages} stackItems={true} />
                        <GenericSummaryTable
                            wrapLines={false}
                            itemsToShow={selectedProgram ? [selectedProgram] : []}
                            columns={programSummaryColumns}
                            visibleColumns={filterVisibleColsByTerms(programSummaryVisibleColumns, [
                                'create',
                                'update',
                                'program_name',
                            ])}
                            nameField={'program_name'}
                            defaultNameField={`Program`}
                            objectType={'Program'}
                            actions={[
                                <SpaceBetween size={'s'} direction={'horizontal'}>
                                    <Select selectedOption={selectedPlan} disabled={true} />
                                    <Select
                                        selectedOption={
                                            selectedRevision ?? {
                                                label: 'Select Revision',
                                                value: '',
                                            }
                                        }
                                        options={selectedRevision ? [selectedRevision] : []}
                                        disabled={true}
                                    />
                                </SpaceBetween>,
                            ]}
                            isLoading={false}
                            includePagination={false}
                        />
                        <GenericSummaryTable
                            wrapLines={false}
                            itemsToShow={currentDeliverable ? [currentDeliverable] : []}
                            columns={deliverableSummaryColumns}
                            visibleColumns={filterVisibleColsByTerms(
                                deliverableSummaryVisibleColumns,
                                ['create', 'update'],
                            )}
                            nameField={'deliverable_name'}
                            objectType={'Deliverable'}
                            defaultNameField={`Deliverable ${currentPageIndex}`}
                            isLoading={false}
                            actions={[]}
                            includePagination={false}
                        />
                        <Container>
                            {!selectedGroup.object_id && (
                                <Alert>
                                    {
                                        'Please select a group from the dropdown to view or modify estimates.'
                                    }
                                </Alert>
                            )}
                            <Form
                                actions={
                                    canEditHc && currentDeliverable?.is_active ? (
                                        <SpaceBetween direction='horizontal' size='xs'>
                                            <Button
                                                onClick={restoreToInitial}
                                                variant='primary'
                                                disabled={!selectedGroup.object_id}
                                            >
                                                Discard Changes
                                            </Button>
                                            <Button
                                                onClick={clearAllHeadcount}
                                                variant='normal'
                                                disabled={!selectedGroup.object_id}
                                            >
                                                Clear All Estimates
                                            </Button>
                                            <Button
                                                variant='primary'
                                                onClick={processEdits}
                                                disabled={
                                                    !saveEnabled ||
                                                    selectedProgram.is_locked ||
                                                    hcItems.some(
                                                        (hcItem) =>
                                                            !hcItem.job_function &&
                                                            (hcItems.length > 1 ||
                                                                (initialHcItems.length === 1 &&
                                                                    !initialHcItems[0]
                                                                        .job_function)),
                                                    ) ||
                                                    !selectedGroup.object_id ||
                                                    (deliverables.length &&
                                                        !deliverables[currentPageIndex - 1]
                                                            .is_active)
                                                }
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant='primary'
                                                onClick={saveAndEstimateAnother}
                                                disabled={
                                                    !saveEnabled ||
                                                    selectedProgram.is_locked ||
                                                    hcItems.some(
                                                        (hcItem) =>
                                                            !hcItem.job_function &&
                                                            (hcItems.length > 1 ||
                                                                (initialHcItems.length === 1 &&
                                                                    !initialHcItems[0]
                                                                        .job_function)),
                                                    ) ||
                                                    currentPageIndex === numDelis ||
                                                    !selectedGroup.object_id ||
                                                    (deliverables.length &&
                                                        !deliverables[currentPageIndex - 1]
                                                            .is_active)
                                                }
                                            >
                                                Save and Estimate Another
                                            </Button>
                                        </SpaceBetween>
                                    ) : (
                                        <></>
                                    )
                                }
                            >
                                <Container
                                    header={
                                        <Header
                                            variant='h2'
                                            actions={
                                                <SpaceBetween size={'m'} direction={'horizontal'}>
                                                    {canEditHc && currentDeliverable?.is_active ? (
                                                        <Box float={'right'} margin={'xs'}>
                                                            <SpaceBetween
                                                                size={'xs'}
                                                                direction={'horizontal'}
                                                            >
                                                                <Box margin={{ top: 'n' }}>
                                                                    FTE Estimation
                                                                </Box>
                                                                <Toggle
                                                                    onChange={({ detail }) =>
                                                                        setIsFteMonthsSelected(
                                                                            detail.checked,
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        !selectedGroup.object_id
                                                                    }
                                                                    checked={isFteMonthsSelected}
                                                                >
                                                                    Effort Estimation (FTE-Months)
                                                                </Toggle>
                                                            </SpaceBetween>
                                                        </Box>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Button
                                                        variant={'icon'}
                                                        iconName={'refresh'}
                                                        onClick={() => {
                                                            refreshData()
                                                        }}
                                                        disabled={
                                                            !currentDeliverable ||
                                                            !selectedGroup.objectId
                                                        }
                                                    ></Button>
                                                    <Pagination
                                                        currentPageIndex={currentPageIndex}
                                                        pagesCount={numDelis}
                                                        onChange={({ detail }) => {
                                                            const newPage = detail.currentPageIndex
                                                            if (
                                                                newPage === currentPageIndex ||
                                                                numDelis < newPage
                                                            ) {
                                                                return
                                                            }
                                                            setRequestedPage(newPage)
                                                            onPageSwitch(newPage)
                                                        }}
                                                    />
                                                </SpaceBetween>
                                            }
                                        >
                                            {currentDeliverable
                                                ? `${currentDeliverable.deliverable_name} Estimates`
                                                : 'Estimates'}
                                        </Header>
                                    }
                                >
                                    {pageLoading ? (
                                        <Spinner size={'normal'} />
                                    ) : (
                                        <SpaceBetween direction='vertical' size='l'>
                                            {showAlert && (
                                                <Alert
                                                    statusIconAriaLabel='Warning'
                                                    action={
                                                        <SpaceBetween
                                                            size={'xs'}
                                                            direction={'horizontal'}
                                                        >
                                                            <Button
                                                                variant={'normal'}
                                                                onClick={async () => {
                                                                    await restoreToInitial()
                                                                    updatePageState(requestedPage)
                                                                }}
                                                            >
                                                                Discard Changes
                                                            </Button>
                                                            <Button
                                                                variant={'normal'}
                                                                onClick={async () => {
                                                                    await processEdits()
                                                                    updatePageState(requestedPage)
                                                                }}
                                                            >
                                                                Save
                                                            </Button>{' '}
                                                        </SpaceBetween>
                                                    }
                                                    header={'Leave Page?'}
                                                >{`Changes you made to ${
                                                    currentDeliverable
                                                        ? currentDeliverable.deliverable_name
                                                        : ''
                                                } estimates will not be saved.`}</Alert>
                                            )}
                                            {viewOnly && !isRevisionLocked && (
                                                <Alert>{`View only mode because ${!currentDeliverable?.is_active ? 'deliverable is inactive' : 'you are not registered to a resource manager role for the group.'}.`}</Alert>
                                            )}
                                            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 6 }]}>
                                                <SpaceBetween size={'xs'} direction={'vertical'}>
                                                    <FormField label={'Group'}>
                                                        <Select
                                                            filteringType={'auto'}
                                                            selectedOption={{
                                                                label: selectedGroup.object_name,
                                                                value: selectedGroup.object_id,
                                                            }}
                                                            options={groupOptions}
                                                            onChange={({ detail }) => {
                                                                setSelectedGroup({
                                                                    object_id:
                                                                        detail.selectedOption.value,
                                                                    object_name:
                                                                        detail.selectedOption.label,
                                                                })
                                                            }}
                                                            disabled={!groupOptions.length}
                                                        />
                                                    </FormField>
                                                    <Button
                                                        onClick={() => {
                                                            setHRModalVisible(true)
                                                        }}
                                                        disabled={!selectedGroup.object_id}
                                                    >
                                                        HC Detail
                                                    </Button>
                                                </SpaceBetween>
                                                <HRDataTable
                                                    selectedGroup={selectedGroup}
                                                    selectedPlanId={planId}
                                                    selectedRevId={revisionId}
                                                    overheadData={overheadData}
                                                    hrTeamData={hrTeamData}
                                                    hcTotals={hcTotals}
                                                    selectedDeliverable={currentDeliverable}
                                                    isDeliverableSubjectToOH={
                                                        selectedProgram.is_overhead_applied
                                                    }
                                                />
                                            </Grid>
                                            {viewOnly &&
                                            (!hcItems.length ||
                                                (hcItems.length === 1 &&
                                                    !hcItems[0].job_function)) ? (
                                                selectedGroup.object_id ? (
                                                    <Box variant={'h3'}>
                                                        {' '}
                                                        No estimates to display.
                                                    </Box>
                                                ) : (
                                                    <Box variant={'h3'}>
                                                        {' '}
                                                        Select a group to see estimates.
                                                    </Box>
                                                )
                                            ) : (
                                                hcItems.map((hcItem) => (
                                                    <Grid
                                                        gridDefinition={[
                                                            { colspan: 3 },
                                                            { colspan: 1 },
                                                            { colspan: 1 },
                                                            { colspan: 1 },
                                                            { colspan: 1.5 },
                                                            { colspan: 1.5 },
                                                            { colspan: 1.5 },
                                                        ]}
                                                    >
                                                        <FormField
                                                            label={
                                                                hcItem.item_id ===
                                                                hcItems[0].item_id ? (
                                                                    <SpaceBetween
                                                                        size={'xxxs'}
                                                                        direction={'horizontal'}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            Job Function
                                                                        </span>
                                                                        {addJobFunctionTooltip()}
                                                                    </SpaceBetween>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        >
                                                            {canEditHc &&
                                                            currentDeliverable?.is_active ? (
                                                                <Select
                                                                    filteringType={'auto'}
                                                                    disabled={
                                                                        !jobFunctionOptions.length ||
                                                                        !selectedGroup.object_id
                                                                    }
                                                                    options={jobFunctionOptions}
                                                                    selectedOption={{
                                                                        label: getJobFunctionDisplay(
                                                                            hcItems.find(
                                                                                (hc) =>
                                                                                    hc.item_id ===
                                                                                    hcItem.item_id,
                                                                            ).job_function,
                                                                        ),
                                                                        value: hcItems.find(
                                                                            (hc) =>
                                                                                hc.item_id ===
                                                                                hcItem.item_id,
                                                                        ).job_function,
                                                                    }}
                                                                    onChange={({ detail }) => {
                                                                        const selectedItemValue =
                                                                            detail.selectedOption
                                                                                .value
                                                                        const originalValue =
                                                                            hcItems.find(
                                                                                (hc) =>
                                                                                    hc.item_id ===
                                                                                    hcItem.item_id,
                                                                            ).job_function
                                                                        const originalValueDisplay =
                                                                            jobFunctionList.find(
                                                                                (job) =>
                                                                                    job.value ===
                                                                                    originalValue,
                                                                            )?.display_name || ''
                                                                        updateHeadcountEdits(
                                                                            selectedItemValue,
                                                                            'job_function',
                                                                            hcItem.item_id,
                                                                        )

                                                                        setJobFunctionOptions([
                                                                            ...jobFunctionOptions.filter(
                                                                                (option) =>
                                                                                    option.value !==
                                                                                    detail
                                                                                        .selectedOption
                                                                                        .value,
                                                                            ),
                                                                            ...(!originalValue
                                                                                ? []
                                                                                : [
                                                                                      {
                                                                                          label: originalValueDisplay,
                                                                                          value: originalValue,
                                                                                      },
                                                                                  ]),
                                                                        ])
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Input
                                                                    value={getJobFunctionDisplay(
                                                                        hcItems.find(
                                                                            (hc) =>
                                                                                hc.item_id ===
                                                                                hcItem.item_id,
                                                                        ).job_function,
                                                                    )}
                                                                    readOnly={true}
                                                                />
                                                            )}
                                                        </FormField>
                                                        <FormField
                                                            label={
                                                                hcItem.item_id ===
                                                                hcItems[0].item_id
                                                                    ? styleEstimateDisplay(
                                                                          'C&T HC (FTE)',
                                                                      )
                                                                    : ''
                                                            }
                                                            errorText={
                                                                hcItems.find(
                                                                    (hc) =>
                                                                        hc.item_id ===
                                                                        hcItem.item_id,
                                                                )?.error[0] || ''
                                                            }
                                                        >
                                                            <Input
                                                                disabled={getIsHcFieldsDisabled()}
                                                                readOnly={
                                                                    !canEditHc ||
                                                                    !currentDeliverable?.is_active
                                                                }
                                                                value={
                                                                    isFteMonthsSelected
                                                                        ? roundSingleItem(
                                                                              hcItems.find(
                                                                                  (hc) =>
                                                                                      hc.item_id ===
                                                                                      hcItem.item_id,
                                                                              )?.hc_ct || '',
                                                                          )
                                                                        : hcItems.find(
                                                                              (hc) =>
                                                                                  hc.item_id ===
                                                                                  hcItem.item_id,
                                                                          )?.hc_ct || ''
                                                                }
                                                                onChange={(event) => {
                                                                    updateHeadcountEdits(
                                                                        event.detail.value,
                                                                        'hc_ct',
                                                                        hcItem.item_id,
                                                                    )
                                                                }}
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            label={
                                                                hcItem.item_id ===
                                                                hcItems[0].item_id
                                                                    ? 'FF HC (FTE)'
                                                                    : ''
                                                            }
                                                            errorText={
                                                                hcItems.find(
                                                                    (hc) =>
                                                                        hc.item_id ===
                                                                        hcItem.item_id,
                                                                )?.error[1] || ''
                                                            }
                                                        >
                                                            <Input
                                                                readOnly={
                                                                    !canEditHc ||
                                                                    !currentDeliverable?.is_active
                                                                }
                                                                disabled={getIsHcFieldsDisabled()}
                                                                value={
                                                                    isFteMonthsSelected
                                                                        ? roundSingleItem(
                                                                              hcItems.find(
                                                                                  (hc) =>
                                                                                      hc.item_id ===
                                                                                      hcItem.item_id,
                                                                              )?.hc_ff || '',
                                                                          )
                                                                        : hcItems.find(
                                                                              (hc) =>
                                                                                  hc.item_id ===
                                                                                  hcItem.item_id,
                                                                          )?.hc_ff || ''
                                                                }
                                                                onChange={(event) => {
                                                                    updateHeadcountEdits(
                                                                        event.detail.value,
                                                                        'hc_ff',
                                                                        hcItem.item_id,
                                                                    )
                                                                }}
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            label={
                                                                hcItem.item_id ===
                                                                hcItems[0].item_id
                                                                    ? 'Total HC (FTE)'
                                                                    : ''
                                                            }
                                                        >
                                                            <Input
                                                                value={roundSingleItem(
                                                                    hcItems.find(
                                                                        (hc) =>
                                                                            hc.item_id ===
                                                                            hcItem.item_id,
                                                                    )?.hc_total || '',
                                                                )}
                                                                readOnly={true}
                                                                disabled={getIsHcFieldsDisabled()}
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            label={
                                                                hcItem.item_id ===
                                                                hcItems[0].item_id
                                                                    ? 'C&T Effort (FTE-Months)'
                                                                    : ''
                                                            }
                                                            errorText={
                                                                hcItems.find(
                                                                    (hc) =>
                                                                        hc.item_id ===
                                                                        hcItem.item_id,
                                                                )?.error[0] || ''
                                                            }
                                                        >
                                                            <Input
                                                                readOnly={
                                                                    !canEditHc ||
                                                                    !currentDeliverable?.is_active
                                                                }
                                                                disabled={getIsFteMonthFieldsDisabled()}
                                                                value={
                                                                    !isFteMonthsSelected
                                                                        ? roundSingleItem(
                                                                              hcItems.find(
                                                                                  (hc) =>
                                                                                      hc.item_id ===
                                                                                      hcItem.item_id,
                                                                              )?.fte_month_ct || '',
                                                                          )
                                                                        : hcItems.find(
                                                                              (hc) =>
                                                                                  hc.item_id ===
                                                                                  hcItem.item_id,
                                                                          )?.fte_month_ct || ''
                                                                }
                                                                onChange={(event) => {
                                                                    updateHeadcountEdits(
                                                                        event.detail.value,
                                                                        'fte_month_ct',
                                                                        hcItem.item_id,
                                                                    )
                                                                }}
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            label={
                                                                hcItem.item_id ===
                                                                hcItems[0].item_id
                                                                    ? 'FF Effort (FTE-Months)'
                                                                    : ''
                                                            }
                                                            errorText={
                                                                hcItems.find(
                                                                    (hc) =>
                                                                        hc.item_id ===
                                                                        hcItem.item_id,
                                                                )?.error[1] || ''
                                                            }
                                                        >
                                                            <Input
                                                                readOnly={
                                                                    !canEditHc ||
                                                                    !currentDeliverable?.is_active
                                                                }
                                                                disabled={getIsFteMonthFieldsDisabled()}
                                                                value={
                                                                    !isFteMonthsSelected
                                                                        ? roundSingleItem(
                                                                              hcItems.find(
                                                                                  (hc) =>
                                                                                      hc.item_id ===
                                                                                      hcItem.item_id,
                                                                              )?.fte_month_ff || '',
                                                                          )
                                                                        : hcItems.find(
                                                                              (hc) =>
                                                                                  hc.item_id ===
                                                                                  hcItem.item_id,
                                                                          )?.fte_month_ff || ''
                                                                }
                                                                onChange={(event) => {
                                                                    updateHeadcountEdits(
                                                                        event.detail.value,
                                                                        'fte_month_ff',
                                                                        hcItem.item_id,
                                                                    )
                                                                }}
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            label={
                                                                hcItem.item_id ===
                                                                hcItems[0].item_id
                                                                    ? 'Total Effort (FTE-Months)'
                                                                    : ''
                                                            }
                                                        >
                                                            <Input
                                                                value={roundSingleItem(
                                                                    hcItems.find(
                                                                        (hc) =>
                                                                            hc.item_id ===
                                                                            hcItem.item_id,
                                                                    )?.fte_month_total || '',
                                                                )}
                                                                readOnly={true}
                                                                disabled={getIsFteMonthFieldsDisabled()}
                                                            />
                                                        </FormField>
                                                        {canEditHc &&
                                                        currentDeliverable?.is_active ? (
                                                            <>
                                                                <FormField
                                                                    label={
                                                                        hcItem.item_id ===
                                                                            hcItems[0].item_id &&
                                                                        canEditHc
                                                                            ? 'Add'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <Button
                                                                        iconName='add-plus'
                                                                        variant='inline-icon'
                                                                        onClick={onAddJobFunction}
                                                                        disabled={
                                                                            (jobFunctionOptions.length ===
                                                                                1 &&
                                                                                !jobFunctionOptions[0]
                                                                                    .value) ||
                                                                            !selectedGroup.object_id
                                                                        }
                                                                    ></Button>
                                                                </FormField>
                                                                <FormField
                                                                    label={
                                                                        hcItem.item_id ===
                                                                            hcItems[0].item_id &&
                                                                        canEditHc
                                                                            ? 'Delete'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <Button
                                                                        iconName='remove'
                                                                        disabled={
                                                                            !selectedGroup.object_id
                                                                        }
                                                                        variant='inline-icon'
                                                                        onClick={() => {
                                                                            onRemoveJobFunction(
                                                                                hcItem,
                                                                            )
                                                                        }}
                                                                    ></Button>
                                                                </FormField>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </Grid>
                                                ))
                                            )}
                                            {viewOnly &&
                                            (!hcItems.length ||
                                                (hcItems.length === 1 &&
                                                    !hcItems[0].job_function)) ? (
                                                <></>
                                            ) : (
                                                <Grid
                                                    gridDefinition={[
                                                        { colspan: 3 },
                                                        { colspan: 1 },
                                                        { colspan: 1 },
                                                        { colspan: 1 },
                                                        { colspan: 1.5 },
                                                        { colspan: 1.5 },
                                                        { colspan: 1.5 },
                                                    ]}
                                                >
                                                    <Box
                                                        float={'right'}
                                                        fontWeight={'bold'}
                                                        margin={{
                                                            right: 'xs',
                                                            top: 's',
                                                            bottom: 's',
                                                        }}
                                                    >
                                                        Total
                                                    </Box>
                                                    <FormField label={''}>
                                                        <Input
                                                            value={roundSingleItem(hcTotals.hc_ct)}
                                                            readOnly={true}
                                                            disabled={getIsHcFieldsDisabled()}
                                                        />
                                                    </FormField>
                                                    <FormField label={''}>
                                                        <Input
                                                            value={roundSingleItem(hcTotals.hc_ff)}
                                                            readOnly={true}
                                                            disabled={getIsHcFieldsDisabled()}
                                                        />
                                                    </FormField>
                                                    <FormField label={''}>
                                                        <Input
                                                            value={roundSingleItem(
                                                                hcTotals.hc_total,
                                                            )}
                                                            readOnly={true}
                                                            disabled={getIsHcFieldsDisabled()}
                                                        />
                                                    </FormField>
                                                    <FormField label={''}>
                                                        <Input
                                                            value={roundSingleItem(
                                                                hcTotals.fte_month_ct,
                                                            )}
                                                            readOnly={true}
                                                            disabled={getIsFteMonthFieldsDisabled()}
                                                        />
                                                    </FormField>
                                                    <FormField label={''}>
                                                        <Input
                                                            value={roundSingleItem(
                                                                hcTotals.fte_month_ff,
                                                            )}
                                                            readOnly={true}
                                                            disabled={getIsFteMonthFieldsDisabled()}
                                                        />
                                                    </FormField>
                                                    <FormField label={''}>
                                                        <Input
                                                            value={roundSingleItem(
                                                                hcTotals.fte_month_total,
                                                            )}
                                                            readOnly={true}
                                                            disabled={getIsFteMonthFieldsDisabled()}
                                                        />
                                                    </FormField>
                                                </Grid>
                                            )}
                                            {viewOnly &&
                                            (!hcItems.length ||
                                                (hcItems.length === 1 &&
                                                    !hcItems[0].job_function)) ? (
                                                <></>
                                            ) : (
                                                <ColumnLayout borders={'horizontal'} columns={1}>
                                                    {
                                                        <Grid
                                                            gridDefinition={[
                                                                { colspan: 3 },
                                                                { colspan: 1 },
                                                                { colspan: 1 },
                                                                { colspan: 1 },
                                                                { colspan: 1.5 },
                                                                { colspan: 1.5 },
                                                                { colspan: 1.5 },
                                                            ]}
                                                        >
                                                            <Box
                                                                float={'right'}
                                                                fontWeight={'bold'}
                                                                margin={{
                                                                    right: 'xs',
                                                                    top: 's',
                                                                    bottom: 's',
                                                                }}
                                                            >
                                                                Total w/ OH
                                                            </Box>
                                                            <FormField label={''}>
                                                                <Input
                                                                    value={roundSingleItem(
                                                                        hcTotals.hc_ct_overhead,
                                                                    )}
                                                                    readOnly={true}
                                                                    disabled={getIsHcFieldsDisabled()}
                                                                />
                                                            </FormField>
                                                            <FormField label={''}>
                                                                <Input
                                                                    value={roundSingleItem(
                                                                        hcTotals.hc_ff_overhead,
                                                                    )}
                                                                    readOnly={true}
                                                                    disabled={getIsHcFieldsDisabled()}
                                                                />
                                                            </FormField>
                                                            <FormField label={''}>
                                                                <Input
                                                                    value={roundSingleItem(
                                                                        hcTotals.hc_total_overhead,
                                                                    )}
                                                                    readOnly={true}
                                                                    disabled={getIsHcFieldsDisabled()}
                                                                />
                                                            </FormField>
                                                            <FormField label={''}>
                                                                <Input
                                                                    value={roundSingleItem(
                                                                        hcTotals.fte_month_ct_overhead,
                                                                    )}
                                                                    readOnly={true}
                                                                    disabled={getIsFteMonthFieldsDisabled()}
                                                                />
                                                            </FormField>
                                                            <FormField label={''}>
                                                                <Input
                                                                    value={roundSingleItem(
                                                                        hcTotals.fte_month_ff_overhead,
                                                                    )}
                                                                    readOnly={true}
                                                                    disabled={getIsFteMonthFieldsDisabled()}
                                                                />
                                                            </FormField>
                                                            <FormField label={''}>
                                                                <Input
                                                                    value={roundSingleItem(
                                                                        hcTotals.fte_month_total_overhead,
                                                                    )}
                                                                    readOnly={true}
                                                                    disabled={getIsFteMonthFieldsDisabled()}
                                                                />
                                                            </FormField>
                                                            <div></div>
                                                        </Grid>
                                                    }
                                                    {
                                                        <Grid
                                                            gridDefinition={[
                                                                { colspan: { default: 3 } },
                                                                { colspan: { default: 6 } },
                                                                { colspan: { default: 2 } },
                                                            ]}
                                                        >
                                                            <Box
                                                                float={'right'}
                                                                fontWeight={'bold'}
                                                                margin={{
                                                                    right: 'xs',
                                                                    top: 's',
                                                                    bottom: 's',
                                                                }}
                                                            >
                                                                Estimation Note
                                                            </Box>
                                                            <Textarea
                                                                value={
                                                                    hcItems.length
                                                                        ? hcItems[0][
                                                                              'deliverable_note'
                                                                          ] || ''
                                                                        : ''
                                                                }
                                                                disabled={
                                                                    !selectedGroup.object_id ||
                                                                    viewOnly
                                                                }
                                                                onChange={(event) => {
                                                                    updateHeadcountEdits(
                                                                        event.detail.value,
                                                                        'deliverable_note',
                                                                        '',
                                                                    )
                                                                }}
                                                            />
                                                        </Grid>
                                                    }
                                                </ColumnLayout>
                                            )}
                                        </SpaceBetween>
                                    )}
                                </Container>
                            </Form>
                        </Container>
                    </SpaceBetween>
                )}
            </Container>
            <CreateEditModal
                isStlNotAdmin={!canAdmin && canEditPrograms}
                selectedRevision={selectedRevision}
                state={{
                    ...programState,
                    ['kingpin_goals']: selectedProgram?.kingpin_goals || [],
                    ['important_links']: selectedProgram?.important_links || [],
                }}
                selectedPlan={selectedPlan}
                inputChange={emptyFn}
                clearAllInputs={emptyFn}
                isDataUnmodified={true}
                isInputInvalid={false}
                onIsInputInvalidChanged={emptyFn}
                onIsDataUnmodifiedChanged={emptyFn}
                isAddingProgram={false}
                onAlertItemAdd={emptyFn}
                onDismiss={() => setProgramViewModalVisible(false)}
                visible={programViewModalVisible}
                onIsLoadingChange={false}
                isModalExpand={programModalExpanded}
                onIsModalExpandChange={setProgramModalExpanded}
                modalMode={MODAL_MODES.VIEW}
                programs={selectedProgram}
                selectedPrograms={selectedProgram}
                onSelectedProgramsChange={emptyFn}
                selectedProgramBusinessEntity={false}
                getLocalPrograms={false}
                getParentPrograms={false}
                initialKingpinGoals={
                    selectedProgram?.kingpin_goals?.length ? selectedProgram.kingpin_goals : []
                }
                initialImportantLinks={
                    selectedProgram?.important_links?.length ? selectedProgram.important_links : []
                }
                onImportantLinksChange={emptyFn}
                selectedPlanOptions={[]}
                onSelectPlanChange={emptyFn}
                selectedRevisionOptions={[]}
                onSelectRevisionChange={emptyFn}
                globalAttributesError={''}
                setGlobalAttributesError={emptyFn}
                allParentProgramPlans={[]}
                allParentProgramGlobalAttributes={[]}
                loadSelectProgram={emptyFn}
                hideLocalAttributes={false}
                refreshPage={emptyFn}
                fetchGlobalAttributesForPlan={emptyFn}
            />
        </ContentLayout>
    )
}

export default EstimateHeadcount
