import { TextContent } from '@amzn/awsui-components-react'
import { GRAND_TOTAL_COLOR_CODE, hideZerosAndTruncateNumbers } from '../summary/SummaryUtil'
import { Chip } from '@mui/material'
import { shortenStringWithEllipsis, tooltipWrapper } from '../../common/Util'
import { PLAN_REVISION_STATUS_IDS } from '../plan/Constants'
import _ from 'lodash'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import { ALERT_TYPES } from '../../Constant'
import {
    deliverableCellRenderer,
    deliverableFilterValueFormatter,
    programValueFormatter,
    SORT_OPTIONS,
} from '../summary/NewSummariesUtil'
import ProgramCellRenderer from '../summary/ProgramCellRenderer'
import { addCommentMenuItems } from '../comment/CommentUtil'

export const TIME_OUT_VALUE_MS = 60000
export const INACCESSIBLE_COLOR = '#e3dfdf'
export const DEFAULT_COLOR = '#ffffff'
export const NON_EDITABLE_COLOR = '#e8ebeb'
const INITIAL_BORDER_COLOR = '#110d0d'

export const HEARTBEAT_INTERVAL_MS = 3000
export const HEARTBEAT_TIMEOUT_MS = 50000

export enum HEADCOUNT_TYPE {
    ct = 'C&T',
    ff = 'FF',
}

export const getDifferenceArray = (array1, array2) => {
    const array2Ids = array2.map((hc) => (hc.headcount_id, hc.headcount_value))
    return array1.filter((item) => !array2Ids.includes((item.headcount_id, item.headcount_value)))
}
export const formatHistoryPanelSearchOptions = (items, label, type) => {
    return items
        .flatMap((item) => {
            return {
                label: label ? item[label] : item,
                type: type,
            }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
}

export const filterSandboxHistory = (items, filterValue) => {
    return items.filter((est: any) => {
        const label = filterValue.label
        switch (filterValue.type) {
            case 'Group':
                return est.group_name === label
            case 'Program':
                return est.program_name === label
            case 'Deliverable':
                return est.deliverable_name === label
            case 'User':
                return est.updated_by === label
            default:
                break
        }
    })
}

export const generateSubmitDisableReason = (locked, lockedRevision) => {
    if (lockedRevision) {
        return 'Latest Revision has been locked. Submission cannot be initiated.'
    } else {
        return locked
            ? "There is an ongoing submission. Please wait until it's complete to start a new submission."
            : 'There are no new headcounts to submit.'
    }
}

export const generateCellStyle = (params: any) => {
    if (params.node.footer) {
        return { background: GRAND_TOTAL_COLOR_CODE }
    }
    return { background: NON_EDITABLE_COLOR }
}

export function secondsSinceEpoch() {
    return Math.floor(Date.now() / 1000)
}

export const getCellIdentifier = (headcountId) => {
    const headcountIDArr = headcountId.split('@')
    if (headcountIDArr.length < 2) {
        console.error('Invalid headcount_id while updating row_selection', headcountIDArr)
        return
    }
    const columnId = headcountIDArr[0] // group_id
    const rowId = headcountIDArr[1] // deliverable_id#program_id
    if (!rowId || !columnId) {
        console.error('Invalid row_id or column_id while updating row_selection')
        return
    }
    return {
        columnId: columnId,
        rowId: rowId,
    }
}

export const getInitialColDefs = (comparator, setSortBy) => {
    return [
        {
            field: 'program_name',
            rowGroup: true,
            hide: 'true',
        },
        {
            field: 'program',
            headerName: 'Program',
            filter: 'agSetColumnFilter',
            showRowGroup: 'program_name',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                innerRenderer: ProgramCellRenderer,
            },
            cellStyle: (params) => generateCellStyle(params),
            filterValueGetter: programValueFormatter,
            mainMenuItems: [
                {
                    name: 'Sort By',
                    subMenu: [
                        { name: 'Name', action: () => setSortBy(SORT_OPTIONS.NAME) },
                        { name: 'Priority', action: () => setSortBy(SORT_OPTIONS.PRIORITY) },
                    ],
                },
            ],
            comparator: comparator,
            sort: 'asc',
        },
        {
            field: 'deliverable_name',
            headerName: 'Deliverable',
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            cellStyle: (params) => generateCellStyle(params),
            cellRenderer: (params) => deliverableCellRenderer(params, false),
            tooltipValueGetter: (params) => {
                if (!params.data || !Object.keys(params.data).includes('deliverable_name')) {
                    return ''
                }
                return params.data['deliverable_name']['name']
            },
            filterParams: {
                valueFormatter: deliverableFilterValueFormatter,
            },
            comparator: (valueA, valueB) => valueA?.name?.localeCompare(valueB?.name),
            // for the export case we need valueFormatter else it will print [Object Object]
            valueFormatter: (params) => {
                if (!params.data || !Object.keys(params.data).includes('deliverable_name')) {
                    return ''
                }
                return params.data?.deliverable_name?.name
            },
        },
        {
            headerName: 'Total (CT + FF)',
            field: 'total',
            cellRenderer: (params) => {
                return hideZerosAndTruncateNumbers(params)
            },
            aggFunc: 'sum',
            floatingFilter: false,
            suppressHeaderFilterButton: true,
            cellStyle: (params) => generateCellStyle(params),
        },
        {
            headerName: 'Total',

            children: [
                {
                    headerName: 'C&T',
                    field: 'total_ct',
                    aggFunc: 'sum',
                    cellRenderer: (params) => {
                        return hideZerosAndTruncateNumbers(params)
                    },
                    floatingFilter: false,
                    suppressHeaderFilterButton: true,
                    cellStyle: (params) => generateCellStyle(params),
                },
                {
                    field: 'total_ff',
                    headerName: 'FF',
                    aggFunc: 'sum',
                    cellRenderer: (params) => {
                        return hideZerosAndTruncateNumbers(params)
                    },
                    floatingFilter: false,
                    suppressHeaderFilterButton: true,
                    cellStyle: (params) => generateCellStyle(params),
                },
            ],
        },
    ]
}

export const generateSandboxHistoryBadges = (textToDisplay, color, textLength) => {
    const isShorten = textToDisplay.length > textLength
    const object = (
        <Chip
            sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
                backgroundColor: color,
            }}
            variant={'filled'}
            label={
                <TextContent>
                    <p>
                        {isShorten
                            ? shortenStringWithEllipsis(textToDisplay, textLength)
                            : textToDisplay}
                    </p>
                </TextContent>
            }
        />
    )
    return isShorten ? tooltipWrapper(textToDisplay, object) : object
}

export const generateHCCellStyle = (
    params,
    group,
    userGroupSet,
    hcType,
    selectedPlanRef,
    activeUsers,
) => {
    // user does not have permission to access the group
    if (params.node.footer) {
        return { background: GRAND_TOTAL_COLOR_CODE }
    } else if (params.node.group || isPinnedBottomRow(params)) {
        return { background: NON_EDITABLE_COLOR }
    } else {
        if (!userGroupSet.has(group.group_id) || selectedPlanRef.current.value['is_locked']) {
            return {
                backgroundColor: INACCESSIBLE_COLOR,
            }
        } else {
            let borderColor = INITIAL_BORDER_COLOR
            let borderWidth = 'thin'
            Object.keys(activeUsers).forEach((user) => {
                if (
                    params?.data &&
                    params?.data[`${group.group_id}_${hcType}_locked_user`] == user
                ) {
                    borderColor = activeUsers[user]
                    borderWidth = 'medium'
                }
            })
            return {
                borderWidth: borderWidth,
                borderColor:
                    params?.data && params?.data[`${group.group_id}_${hcType}_locked`]
                        ? borderColor
                        : DEFAULT_COLOR,
                backgroundColor:
                    params?.data && params?.data[`${group.group_id}_${hcType}_group_locked`]
                        ? INACCESSIBLE_COLOR
                        : DEFAULT_COLOR,
            }
        }
    }
}

export const isPinnedBottomRow = (params) => {
    // node id b-0 points to the bottom pinned row : BIS Data row
    return params.node?.id === 'b-0'
}

export const isHCCellEditable = (params, group, userGroupSet, selectedPlanRef, hcType) => {
    if (!params?.data || isPinnedBottomRow(params)) {
        return false
    }
    const is_plan_unlocked = !selectedPlanRef.current.value['is_locked']
    const is_user_allowed = userGroupSet.has(group.group_id)
    const is_cell_locked =
        params?.data[`${group.group_id}_${hcType}_locked`] !== undefined &&
        params?.data[`${group.group_id}_${hcType}_locked`]
    const is_group_locked = params?.data[`${group.group_id}_${hcType}_group_locked`]
    return is_plan_unlocked && is_user_allowed && !(is_cell_locked || is_group_locked)
}

export const generateBannerContent = (id, type, content, onDismiss, header = '') => {
    return {
        type: type,
        content: content,
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => onDismiss([]),
        id: id,
        header: header,
    }
}

export const isRevisionClosed = (latestRevision) => {
    if (_.isEmpty(latestRevision)) return
    return (
        latestRevision.is_locked ||
        latestRevision.revision_status === PLAN_REVISION_STATUS_IDS.PROGRAM_CREATION
    )
}

export const getDeliverableContextMenuItem = (
    params: GetContextMenuItemsParams,
    plan,
    apiClient,
    setMetadata,
    setIsDetailModalVisible,
    loadSelectObject,
    setBannerItems,
    userRolesMetaData,
    setCommentMode,
    setIsSidePanelVisible,
) => {
    const isFooter = params.node?.footer ?? false
    return !isFooter
        ? [
              {
                  name: `View Deliverable Details`,
                  action: () => {
                      const programId = params.node?.data['program_id']
                      const deliverableId = params.node?.data['deliverable_id']
                      apiClient
                          .get(
                              `/plan/${plan['plan_id']}/revision/${plan['revisions'][0]['revision_id']}/program/${programId}/deliverable/${deliverableId}`,
                          )
                          .then((res) => {
                              const deliverable = res.data
                              setMetadata(deliverable)
                              setIsDetailModalVisible(true)
                              loadSelectObject(deliverable, 'deliverable')
                          })
                          .catch((error) => {
                              console.error(error)
                              setBannerItems([
                                  generateBannerContent(
                                      'error_message_1',
                                      ALERT_TYPES.ERROR,
                                      `Deliverable details not found - Please make sure the program exists.`,
                                      setBannerItems,
                                  ),
                              ])
                          })
                  },
              },
              addCommentMenuItems(false, userRolesMetaData, setCommentMode, setIsSidePanelVisible),
          ]
        : []
}

export const getProgramContextMenuItem = (
    params: GetContextMenuItemsParams,
    plan,
    apiClient,
    setMetadata,
    setIsDetailModalVisible,
    loadSelectObject,
    setBannerItems,
    userRolesMetaData,
    setCommentMode,
    setIsSidePanelVisible,
) => {
    const isFooter = params.node?.footer ?? false
    return !isFooter
        ? [
              {
                  name: `View Program Details`,
                  action: () => {
                      // all programs that show up in sandbox will at least have one deliverable
                      const programId = params.node?.allLeafChildren[0]['data']['program_id']
                      apiClient
                          .get(
                              `/plan/${plan['plan_id']}/revision/${plan['revisions'][0]['revision_id']}/program/${programId}`,
                          )
                          .then((res) => {
                              let program = res.data
                              program = {
                                  ...program,
                                  stl_alias: program['stl_alias'] ? [program['stl_alias']] : [],
                                  registered_users: program['registered_users']
                                      ? program['registered_users']
                                      : [],
                              }
                              setMetadata(program)
                              setIsDetailModalVisible(true)
                              loadSelectObject(program, 'program')
                          })
                          .catch((error) => {
                              console.error(error)
                              setBannerItems([
                                  generateBannerContent(
                                      'error_message_1',
                                      ALERT_TYPES.ERROR,
                                      `Program details not found - Please make sure the program exists.`,
                                      setBannerItems,
                                  ),
                              ])
                          })
                  },
              },
              addCommentMenuItems(true, userRolesMetaData, setCommentMode, setIsSidePanelVisible),
          ]
        : []
}
