import { useAppContext } from '../../../context'
import HeaderTemplate from '../reusable/HeaderTemplate'
import {
    Alert,
    Box,
    ContentLayout,
    Flashbar,
    Link,
    Popover,
    SpaceBetween,
    StatusIndicator,
} from '@amzn/awsui-components-react'
import { useEffect, useState } from 'react'
import OrgList from './OrgList'
import { useNavigate } from 'react-router-dom'
import {
    ALERT_TYPES,
    DESCRIPTION_LENGTH_LIMIT,
    MODAL_MODES,
    STATUS_INDICATOR_TYPES,
} from '../../Constant'
import StatusInfoPopover from '../reusable/StatusInfoPopover'
import { AMZN_TEAM_BASE_URL, CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import DatePopover from '../reusable/DatePopover'
import { convertToLocalTime } from '../../common/Util'
import useStore from '../../Store'
import TextPopover from '../reusable/TextPopover'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import DeleteModal from '../reusable/DeleteModal'
import CreateOrg from './CreateOrg'
import { LIMITED_ACCESS_MESSAGE } from '../reusable/TextUtil'
import { ROLES } from '../../../role'

const OrgHome = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const [orgs, setOrgs] = useState([])
    const [groupList, setGroupList] = useState({})
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [modalMode, setModalMode] = useState<MODAL_MODES>(MODAL_MODES.NOT_SET)
    const [isOrgLoading, setIsOrgLoading] = useState(true)
    const [selectedOrgs, setSelectedOrgs] = useState<any>([])
    const [alertContent, setAlertContent] = useState<string>('')
    const [alertType, setAlertType] = useState<ALERT_TYPES>(ALERT_TYPES.INFO)
    const canAdmin = useStore((state) => state.canAdmin)

    const history = useNavigate()

    const orgListColumnDef = [
        {
            id: 'org_id',
            header: 'Org ID',
            cell: (e: any) => e.org_id,
        },
        {
            id: 'org_name',
            header: 'Org',
            cell: (e: any) => (
                <Link
                    onFollow={(event) => {
                        event.preventDefault()
                        history(`/org/${e.org_id}`)
                    }}
                    href={`/org/${e.org_id}`}
                >
                    {e.org_name}
                </Link>
            ),
            sortingField: 'org_name',
            exportColumnName: 'Org',
            maxWidth: 100,
        },
        {
            id: 'description',
            header: 'Description',
            cell: (e: any) => (
                <TextPopover text={e.description} lengthLimit={DESCRIPTION_LENGTH_LIMIT} />
            ),
            exportColumnName: 'Description',
            maxWidth: 200,
        },
        {
            id: 'number_of_groups',
            header: (
                <>
                    Egret <br></br> Groups
                </>
            ),
            cell: (e: any) => e.number_of_groups['egret'] || 0,
            sortingField: 'number_of_groups',
            exportColumnName: 'Egret Groups',
            maxWidth: 80,
        },
        {
            id: 'hr_permission_group',
            header: (
                <StatusInfoPopover
                    title='Headcount Source'
                    popoverContent='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq). Tracks team headcount.'
                    size='large'
                />
            ),
            cell: (e: any) =>
                e.hr_permission_group && e.hr_permission_group.length > 0 ? (
                    <Link
                        //TODO add api to get TEAMS name
                        href={`${AMZN_TEAM_BASE_URL}/${e.hr_permission_group}`}
                        external
                    >
                        {e.hr_permission_group_name ? e.hr_permission_group_name : 'Open'}
                    </Link>
                ) : (
                    <></>
                ),
            sortingField: 'hr_permission_group_name',
            exportColumnName: 'Headcount Source',
            maxWidth: 100,
        },
        {
            id: 'created_by',
            header: 'Created by',
            cell: (e) => e.created_by,
            sortingField: 'created_by',
        },
        {
            id: 'updated_by',
            header: 'Last Updated',
            cell: (e) => (
                <Popover
                    dismissButton={false}
                    position='left'
                    size='small'
                    triggerType='custom'
                    content={convertToLocalTime(e.updated_at)}
                >
                    {e.updated_by}
                </Popover>
            ),
            sortingField: 'updated_by',
            exportColumnName: 'Updated By',
            maxWidth: 100,
        },
        {
            id: 'created_at',
            header: 'Created at',
            cell: (e) => <DatePopover date={convertToLocalTime(e.created_at)} />,
            sortingField: 'created_at',
        },
        {
            id: 'updated_at',
            header: 'Updated at',
            cell: (e) => <DatePopover date={convertToLocalTime(e.updated_at)} />,
            exportColumnName: 'Updated At',
            sortingField: 'updated_at',
        },
        {
            id: 'primary_alias',
            header: 'Primary Contact',
            cell: (e) => e.primary_alias,
            sortingField: 'primary_alias',
            exportColumnName: 'Primary Contact',
            maxWidth: 80,
        },
        {
            id: 'registered_users',
            header: 'Org Managers',
            cell: (e) => {
                if (e.registered_users && e.registered_users.length > 0) {
                    return e.registered_users.toString()
                } else {
                    return '-'
                }
            },
            exportColumnName: 'Org Managers',
            maxWidth: 180,
        },
        {
            id: 'is_active',
            header: 'Active',
            cell: (e: any) =>
                e.is_active ? (
                    <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
                ) : (
                    <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
                ),
            sortingField: 'is_active',
        },
    ]

    const visibleColumns = [
        'org_name',
        'description',
        'number_of_groups',
        'hr_permission_group',
        'created_by',
        'updated_by',
        'created_at',
        'updated_at',
        'primary_alias',
        'registered_users',
        'is_active',
    ]

    const handleCreate = () => {
        setModalMode(MODAL_MODES.CREATE)
        setCreateModalVisible(true)
    }

    const handleEdit = () => {
        setModalMode(MODAL_MODES.EDIT)
        setCreateModalVisible(true)
    }

    const handleDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleCreateModalClose = () => {
        setCreateModalVisible(false)
    }

    const getGroupsUnderOrgs = () => {
        apiClient
            .get(`/business_entity/${selectedBusinessEntity.business_entity_id}/groups`)
            .then((res) => {
                // exclude archived groups from count and from export
                setGroupList(res.data.filter((gp) => !gp?.archived))
            })
            .catch((e) => {
                console.error(e)
            })
    }
    const deleteOrgRegisteredUsersPermissions = (registeredUsers) => {
        apiClient
            .delete(
                `/users/role/${ROLES.ORG_MANAGER}?aliases=${registeredUsers.join(
                    ',',
                )}&business_entity_id=${selectedBusinessEntity.business_entity_id}&object_ids=${
                    selectedOrgs[0].org_id
                }`,
            )
            .then(() => {
                setAlertType(ALERT_TYPES.SUCCESS)
                setAlertContent(
                    `Successfully removed registered user(s) from ${selectedOrgs[0].org_name}.`,
                )
            })
            .catch((error) => {
                console.error(error)
                setAlertType(ALERT_TYPES.ERROR)
                setAlertContent(
                    `Failed to remove registered user(s) from ${selectedOrgs[0].org_name}.`,
                )
            })
    }

    const deleteOrg = () => {
        setDeleteModalVisible(false)
        setIsOrgLoading(true)
        apiClient
            .delete(`/falcon/org/${selectedOrgs[0]['org_id']}`)
            .then(() => {
                const allCurrentUsers = [
                    ...selectedOrgs[0].registered_users,
                    ...(selectedOrgs[0].primary_alias ? [selectedOrgs[0].primary_alias] : []),
                ]
                if (allCurrentUsers.length) {
                    deleteOrgRegisteredUsersPermissions(allCurrentUsers)
                }
                getAllOrgsByBusinessEntity()
                setAlertType(ALERT_TYPES.SUCCESS)
                setAlertContent(`Successfully deleted organization ${selectedOrgs[0].org_name}.`)
                setSelectedOrgs([])
            })
            .catch((error) => {
                console.error(error)
                setAlertType(ALERT_TYPES.ERROR)
                setAlertContent(
                    `Failed to delete because organization ${error.response.data} Delete the groups before deleting the organization.`,
                )
                setSelectedOrgs([])
                setIsOrgLoading(false)
            })
    }

    const getAllOrgsByBusinessEntity = () => {
        setIsOrgLoading(true)
        apiClient
            .get(`/falcon/business-entity/${selectedBusinessEntity.business_entity_id}/orgs`)
            .then((response) => {
                const allOrgs = response.data
                setOrgs(allOrgs)
                setIsOrgLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsOrgLoading(false)
            })
    }

    useEffect(() => {
        BusinessEntityRefresh(selectedBusinessEntity.business_entity_id, getAllOrgsByBusinessEntity)
        getGroupsUnderOrgs()
    }, [selectedBusinessEntity])

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Organizations', href: '' },
                            ]}
                        />
                        {canAdmin ? (
                            <></>
                        ) : (
                            <Alert header='Limited Access'>
                                <LIMITED_ACCESS_MESSAGE
                                    item='organization'
                                    link={CREATE_TEAM_REQUEST}
                                />
                            </Alert>
                        )}
                        {alertContent === '' ? (
                            <></>
                        ) : (
                            <Flashbar
                                items={[
                                    {
                                        onDismiss: () => {
                                            setAlertContent('')
                                        },
                                        dismissible: true,
                                        content: alertContent,
                                        type: alertType,
                                    },
                                ]}
                            />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <DeleteModal
                title='Organization'
                visible={deleteModalVisible}
                onDismiss={() => setDeleteModalVisible(false)}
                onDelete={deleteOrg}
            />
            <Box margin={{ left: 's', right: 's' }}>
                <OrgList
                    canAdmin={canAdmin}
                    selectedOrgs={selectedOrgs}
                    onSelectedOrgsChange={setSelectedOrgs}
                    orgs={orgs}
                    columns={orgListColumnDef}
                    visibleColumns={visibleColumns}
                    onCreate={handleCreate}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    isLoading={isOrgLoading}
                    groups={groupList}
                />
            </Box>
            <CreateOrg
                modalMode={modalMode}
                modalVisible={createModalVisible}
                closeModalHandler={handleCreateModalClose}
                orgs={orgs}
                selectedOrgs={selectedOrgs}
                setSelectedOrgs={setSelectedOrgs}
                setAlertContent={setAlertContent}
                setAlertType={setAlertType}
                onIsOrgLoadingChange={setIsOrgLoading}
                refreshList={getAllOrgsByBusinessEntity}
            />
        </ContentLayout>
    )
}

export default OrgHome
