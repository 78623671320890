import { FormField, Textarea } from '@amzn/awsui-components-react'

interface FormFieldInputTemplateProps {
    state: any
    name: string
    id: string
    disable: boolean
    rows?: number
    info?: string | JSX.Element
    onStateErrorChange?: any
    onStateChange?: any
    stateError?: any
}

const FormFieldInputTemplate = (props: FormFieldInputTemplateProps) => {
    const { state, onStateChange, stateError, onStateErrorChange, name, id, disable, rows, info } =
        props
    return (
        <FormField
            label={name}
            errorText={stateError ? stateError[id] : ''}
            info={info ? info : undefined}
        >
            <Textarea
                value={state[id]}
                onChange={({ detail }) => {
                    const value = detail.value
                    const newState = { ...state }
                    const newStateError = { ...stateError }

                    newState[id] = detail.value
                    onStateChange(newState)

                    if (value.trim().length === 0) {
                        newStateError[id] = `${name} must be provided.`
                    } else {
                        newStateError[id] = ''
                    }
                    onStateErrorChange(newStateError)
                }}
                disabled={disable}
                spellcheck
                rows={rows ?? 4}
            ></Textarea>
        </FormField>
    )
}

export default FormFieldInputTemplate
