import { useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    Button,
    Box,
    Container,
    ColumnLayout,
    Grid,
    Header,
    Icon,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Spinner,
    Modal,
    Table,
    TokenGroup,
    Link,
} from '@amzn/awsui-components-react'
import {
    AUDIT_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
    getMatchesCountText,
    AUDIT_FILTERING_PROPERTIES_PROGRAM,
    AUDIT_FILTERING_PROPERTIES_DELIVERABLE,
    AUDIT_FILTERING_PROPERTIES_HC_ESTIMATE,
    AUDIT_FILTERING_PROPERTIES_DISCRETIONARY_SPEND,
} from '../reusable/UseCollectionUtil'
import {
    NO_USER_BUSINESS_ENTITY,
    OBJECT_TYPE,
    ONE_DAY_IN_MILLISECONDS,
    PHONE_TOOL_PREFIX,
    SYS_USER,
} from '../../Constant'
import useStore from '../../Store'
import _ from 'lodash'
import StatusIndicatorTemplate from '../reusable/StatusIndicatorTemplate'
import {
    ACTION_TYPES,
    DELIVERABLE__FIELDS,
    DELIVERABLE_REQUIRE_FIELDS,
    DISCRETIONARY_SPEND_FIELDS,
    DISCRETIONARY_SPEND_REQUIRE_FIELDS,
    HC_ESTIMATE_REQUIRE_FIELDS,
    PLAN_REQUIRE_FIELDS,
    PROGRAM_FIELDS,
    PROGRAM_REQUIRE_FIELDS,
    REVISION_REQUIRE_FIELDS,
    PLAN_FIELDS,
    REVISION_FIELDS,
    HC_ESTIMATE_FIELDS,
} from './Constant'
import { findTextDiff } from '../reusable/Utils'
import { IS_TEXT_AREA_REGEX } from '../reusable/Regex'

const AUDIT_KEY_REGEX = new RegExp('_sort|_id$')
const IS_REGISTERED_USERS = 'registered_users'
const Q3_OTHER_PROGRAMS = 'q3_other_programs'

const formatArrayTokenGroup = (isRegisteredUsers: boolean, isQ3OtherPrograms: boolean, array) => {
    if (!array || array.length === 0) {
        return [
            {
                label: '-',
                disabled: true,
            },
        ]
    }

    let fieldKeys: any[] = Object.keys(array[0])
    if (isQ3OtherPrograms) {
        fieldKeys = fieldKeys.filter((key) => key !== 'value')
    }
    const keyLength = fieldKeys.length

    if (isRegisteredUsers) {
        return array.map((item) => ({
            label: item,
            disabled: true,
        }))
    }

    return array.map((item) => ({
        label: item[fieldKeys[0]],
        description: keyLength >= 1 ? item[fieldKeys[1]] : '',
        labelTag: keyLength >= 2 ? item[fieldKeys[2]] : '',
        disabled: true,
    }))
}

const valueWithLabel = (
    isPrevious: boolean,
    isRegisteredUsers: boolean,
    isQ3OtherPrograms: boolean,
    isTextArea: boolean,
    label,
    prev,
    next,
) => {
    const value = isPrevious ? prev : next
    return (
        <Box>
            <Box variant='awsui-key-label'>{label}</Box>
            {typeof value === 'boolean' ? (
                <StatusIndicatorTemplate value={value} />
            ) : Array.isArray(value) ? (
                <TokenGroup
                    items={formatArrayTokenGroup(isRegisteredUsers, isQ3OtherPrograms, value)}
                    alignment='vertical'
                />
            ) : !isPrevious && isTextArea ? (
                <SpaceBetween direction='horizontal' size='xxxs'>
                    {findTextDiff(prev, next)}
                </SpaceBetween>
            ) : (
                value || '-'
            )}
        </Box>
    )
}

const valueWithLabelDifferences = (
    isRegisteredUsers,
    isQ3OtherPrograms,
    isTextArea,
    label,
    prev,
    next,
) => {
    return (
        <Box>
            <Box variant='awsui-key-label'>{label}</Box>
            {typeof next === 'boolean' || typeof prev === 'boolean' ? (
                <SpaceBetween direction={'horizontal'} size={'xxs'}>
                    <StatusIndicatorTemplate value={prev} />
                    <Icon name='caret-right-filled' variant='link' />
                    <StatusIndicatorTemplate value={next} />
                </SpaceBetween>
            ) : Array.isArray(next) || Array.isArray(prev) ? (
                <SpaceBetween direction={'horizontal'} size={'xxs'}>
                    <TokenGroup
                        items={formatArrayTokenGroup(isRegisteredUsers, isQ3OtherPrograms, prev)}
                        alignment='vertical'
                    />
                    <Box margin={{ top: 'm' }}>
                        <Icon name='caret-right-filled' variant='link' />
                    </Box>
                    <TokenGroup
                        items={formatArrayTokenGroup(isRegisteredUsers, isQ3OtherPrograms, next)}
                        alignment='vertical'
                    />
                </SpaceBetween>
            ) : (
                (
                    <SpaceBetween direction={'horizontal'} size={'xxs'}>
                        {prev || '-'}
                        <Icon name='caret-right-filled' variant='link' />
                        {isTextArea ? (
                            <SpaceBetween direction='horizontal' size='xxxs'>
                                {findTextDiff(prev, next)}
                            </SpaceBetween>
                        ) : (
                            next || '-'
                        )}
                    </SpaceBetween>
                ) || '-'
            )}
        </Box>
    )
}

const getObjectFields = (isRequiredFields: boolean, type: OBJECT_TYPE) => {
    let fields: string[] = []

    switch (type) {
        case OBJECT_TYPE.PLAN:
            fields = isRequiredFields ? PLAN_REQUIRE_FIELDS : PLAN_FIELDS
            break
        case OBJECT_TYPE.REVISION:
            fields = isRequiredFields ? REVISION_REQUIRE_FIELDS : REVISION_FIELDS
            break
        case OBJECT_TYPE.PROGRAM:
        case OBJECT_TYPE.PARENT_PROGRAM:
            fields = isRequiredFields ? PROGRAM_REQUIRE_FIELDS : PROGRAM_FIELDS
            break
        case OBJECT_TYPE.DELIVERABLE:
            fields = isRequiredFields ? DELIVERABLE_REQUIRE_FIELDS : DELIVERABLE__FIELDS
            break
        case OBJECT_TYPE.DISCRETIONARY_SPEND:
            fields = isRequiredFields
                ? DISCRETIONARY_SPEND_REQUIRE_FIELDS
                : DISCRETIONARY_SPEND_FIELDS
            break
        case OBJECT_TYPE.HC_ESTIMATE:
            fields = isRequiredFields ? HC_ESTIMATE_REQUIRE_FIELDS : HC_ESTIMATE_FIELDS
            break
    }

    return fields
}

const AuditTable = ({
    history,
    isLoading,
    jobFunctionList,
    startEndTimeStamp,
    onStartEndTimeStampChange,
    onAlertContentChange,
    getHistoryByTimestamp,
}) => {
    const [preferences, setPreferences] = useState({ pageSize: 50 })
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const validBE =
        selectedBusinessEntity.business_entity_id &&
        selectedBusinessEntity.business_entity_id !== NO_USER_BUSINESS_ENTITY

    const defaultEndTimestamp = new Date().getTime()
    const defaultStartTimestamp = defaultEndTimestamp - 7 * ONE_DAY_IN_MILLISECONDS

    const [modalVisible, setModalVisible] = useState(false)
    const [clickedItem, setClickedItem] = useState({
        prev_state: {},
        next_state: {},
        differences: {},
        object_type: '',
    })

    const getObjectLink = (item) => {
        const getValueHelper = (key: string) => {
            return item.prev_state[key] || item.next_state[key]
        }

        const headers: any[] = []
        const planId = getValueHelper('plan_id')
        const revisionId = getValueHelper('revision_id')
        const programId = getValueHelper('program_id')

        switch (item.object_type) {
            case OBJECT_TYPE.PLAN:
                headers.push({
                    name: 'Plan',
                    link: `/plan/${planId}/revisions`,
                    value: `${getValueHelper('year')} - ${getValueHelper('plan_type')}`,
                })
                break
            case OBJECT_TYPE.REVISION:
                headers.push({
                    name: 'Revision',
                    link: `/plan/${planId}/revision/${revisionId}`,
                    value: `Rev ${getValueHelper('revision_number')} - ${getValueHelper(
                        'revision_title',
                    )}`,
                })
                break
            case OBJECT_TYPE.PROGRAM:
                headers.push({
                    name: 'Program',
                    link: `/plan/${planId}/revision/${revisionId}/program/${programId}`,
                    value: getValueHelper('program_name'),
                })
                break
            case OBJECT_TYPE.PARENT_PROGRAM:
                const parentProgramId = getValueHelper('parent_program_id')
                headers.push({
                    name: 'Program',
                    link: `/plan/${planId}/revision/${revisionId}/program/${parentProgramId}?type=global`,
                    value: getValueHelper('program_name'),
                })
                break
            case OBJECT_TYPE.DELIVERABLE:
                headers.push({
                    name: 'Program',
                    link: `/plan/${planId}/revision/${revisionId}/program/${programId}`,
                    value: item.deliverable_program_name,
                })
                headers.push({
                    name: 'Deliverable',
                    link: '',
                    value: getValueHelper('deliverable_name'),
                })
                break
            case OBJECT_TYPE.DISCRETIONARY_SPEND:
                headers.push({
                    name: 'Program',
                    link: `/plan/${planId}/revision/${revisionId}/program/${programId}`,
                    value: item.spend_program_name,
                })
                headers.push({
                    name: 'Discretionary Spend',
                    link: '',
                    value: `${getValueHelper('ds_item_id_display')} - ${getValueHelper(
                        'expense_title',
                    )}`,
                })
                headers.push({
                    name: 'Group',
                    link: '',
                    value: item.spend_group_name,
                })
                headers.push({
                    name: 'Total Amount',
                    link: '',
                    value: item.total_expenditure,
                })
                break
            case OBJECT_TYPE.HC_ESTIMATE:
                headers.push({
                    name: 'Program',
                    link: `/plan/${planId}/revision/${revisionId}/program/${programId}`,
                    value: item.hc_estimate_program_name,
                })
                headers.push({
                    name: 'Deliverable',
                    link: '',
                    value: item.hc_estimate_deliverable_name,
                })
                headers.push({
                    name: 'Group',
                    link: '',
                    value: item.hc_estimate_group_name,
                })
                break
            default:
        }

        return (
            <SpaceBetween direction={'horizontal'} size={'s'}>
                {headers.map((header) => (
                    <SpaceBetween direction={'horizontal'} size={'xxs'}>
                        <Box variant='h5'>{`${header.name}:`}</Box>
                        {header.link ? (
                            <Link href={header.link}>{header.value}</Link>
                        ) : (
                            <Box variant='p'>{header.value}</Box>
                        )}
                    </SpaceBetween>
                ))}
            </SpaceBetween>
        )
    }

    const formatRenderState = (isPrevious: boolean, state, objectType: OBJECT_TYPE) => {
        const result: any[] = []
        let displayKey = ''

        for (const key of getObjectFields(true, objectType)) {
            const isRegisteredUsers = key === IS_REGISTERED_USERS
            const isQ3OtherPrograms = key === Q3_OTHER_PROGRAMS
            if (AUDIT_KEY_REGEX.test(key)) {
                continue
            } else {
                displayKey = _.startCase(key)
            }

            if (state[key] !== undefined) {
                if (key === 'job_function') {
                    const jobFunctionMeta = jobFunctionList.find(
                        (job) => job.value === state['job_function'],
                    )

                    result.push(
                        valueWithLabel(
                            isPrevious,
                            isRegisteredUsers,
                            isQ3OtherPrograms,
                            false,
                            displayKey,
                            '',
                            jobFunctionMeta ? jobFunctionMeta.reporting_name : '',
                        ),
                    )
                    continue
                }

                result.push(
                    valueWithLabel(
                        isPrevious,
                        isRegisteredUsers,
                        isQ3OtherPrograms,
                        false,
                        displayKey,
                        state[key],
                        state[key],
                    ),
                )
            }
        }

        return result.length ? result : '-'
    }

    const renderDifferencesState = (item) => {
        const objectType = item.object_type
        if (item.action === ACTION_TYPES.DELETE) {
            return (
                <Grid gridDefinition={[{ colspan: 9 }, { colspan: 1 }, { colspan: 2 }]}>
                    <Container>
                        <ColumnLayout columns={4} variant='text-grid'>
                            {formatRenderState(true, item.prev_state, objectType)}
                        </ColumnLayout>
                    </Container>
                    <Box margin={{ left: 'l' }}>
                        <Icon name='caret-right-filled' variant='link' size='medium' />
                    </Box>
                    <Container>-</Container>
                </Grid>
            )
        }
        if (item.action === ACTION_TYPES.CREATE) {
            return (
                <Grid gridDefinition={[{ colspan: 2 }, { colspan: 1 }, { colspan: 9 }]}>
                    <Container>-</Container>
                    <Box margin={{ left: 'l' }}>
                        <Icon name='caret-right-filled' variant='link' size='medium' />
                    </Box>
                    <Container>
                        <ColumnLayout columns={4} variant='text-grid'>
                            {formatRenderState(false, item.next_state, objectType)}
                        </ColumnLayout>
                    </Container>
                </Grid>
            )
        }

        const state = item.differences
        const result: any[] = []
        let displayKey = ''

        for (const key of getObjectFields(false, objectType)) {
            const isRegisteredUsers = key === IS_REGISTERED_USERS
            const isQ3OtherPrograms = key === Q3_OTHER_PROGRAMS
            const isTextArea = IS_TEXT_AREA_REGEX.test(key)
            if (AUDIT_KEY_REGEX.test(key)) {
                continue
            } else {
                displayKey = _.startCase(key)
            }

            if (state[key] !== undefined) {
                result.push(
                    valueWithLabelDifferences(
                        isRegisteredUsers,
                        isQ3OtherPrograms,
                        isTextArea,
                        displayKey,
                        state[key].prev,
                        state[key].next,
                    ),
                )
            }
        }
        return result.length ? (
            <ColumnLayout columns={3} variant='text-grid'>
                {result}
            </ColumnLayout>
        ) : (
            '-'
        )
    }

    const renderState = (isPrevious: boolean, item, objectType) => {
        const prev = item.prev_state
        const next = item.next_state
        const state = isPrevious ? prev : next
        const result: any[] = []
        let displayKey = ''

        if (state.length === 0) {
            return [
                {
                    label: '-',
                    disabled: true,
                },
            ]
        }

        for (const key of getObjectFields(false, objectType)) {
            const isRegisteredUsers = key === IS_REGISTERED_USERS
            const isQ3OtherPrograms = key === Q3_OTHER_PROGRAMS
            const isTextArea = IS_TEXT_AREA_REGEX.test(key)
            if (AUDIT_KEY_REGEX.test(key)) {
                continue
            } else {
                displayKey = _.startCase(key)
            }

            if (state[key] !== undefined) {
                if (key === 'job_function') {
                    const jobFunctionMeta = jobFunctionList.find(
                        (job) => job.value === state['job_function'],
                    )

                    result.push(
                        valueWithLabel(
                            isPrevious,
                            isRegisteredUsers,
                            isQ3OtherPrograms,
                            isTextArea,
                            displayKey,
                            '',
                            jobFunctionMeta ? jobFunctionMeta.reporting_name : '',
                        ),
                    )
                    continue
                }

                result.push(
                    valueWithLabel(
                        isPrevious,
                        isRegisteredUsers,
                        isQ3OtherPrograms,
                        isTextArea,
                        displayKey,
                        prev[key],
                        next[key],
                    ),
                )
            }
        }
        return result.length ? result : '-'
    }

    const getHistoryTimestamp = (operation: string, tokens) => {
        if (operation === 'or') {
            return
        } else {
            const filteredTokens = tokens.filter((token) => token.propertyKey === 'date')
            if (filteredTokens.length == 0) {
                onStartEndTimeStampChange({
                    start: defaultStartTimestamp,
                    end: defaultEndTimestamp,
                })
                getHistoryByTimestamp(defaultStartTimestamp, defaultEndTimestamp)
                return
            }

            let max = -Infinity
            let min = Infinity
            const lastFilteredTokens = filteredTokens[filteredTokens.length - 1]
            const timestamp = new Date(lastFilteredTokens.value).getTime()
            const nextDayTimestamp = timestamp + ONE_DAY_IN_MILLISECONDS

            switch (lastFilteredTokens.operator) {
                case '=': {
                    if (
                        timestamp >= startEndTimeStamp.start &&
                        nextDayTimestamp <= startEndTimeStamp.end
                    ) {
                        return
                    }

                    onStartEndTimeStampChange({
                        start: timestamp,
                        end: nextDayTimestamp,
                    })
                    getHistoryByTimestamp(timestamp, nextDayTimestamp)
                    onAlertContentChange('')
                    return
                }
                case '>=': {
                    min = timestamp
                    max = timestamp + 7 * ONE_DAY_IN_MILLISECONDS
                    break
                }
                case '>': {
                    min = nextDayTimestamp
                    max = timestamp + 7 * ONE_DAY_IN_MILLISECONDS
                    break
                }
                case '<=': {
                    min = timestamp - 7 * ONE_DAY_IN_MILLISECONDS
                    max = nextDayTimestamp
                    break
                }
                case '<': {
                    min = timestamp - 7 * ONE_DAY_IN_MILLISECONDS
                    max = timestamp
                    break
                }
                default: {
                }
            }

            if (min !== Infinity && max !== -Infinity && min <= max) {
                onStartEndTimeStampChange({ start: min, end: max })
                getHistoryByTimestamp(min, max)
            }
        }
    }

    const getFilteringProperties = (tokens) => {
        for (const token of tokens) {
            if (token['propertyKey'] === 'object_type') {
                if (token['operator'] === '=') {
                    let newFilteringProperties: any[]
                    switch (token['value']) {
                        case OBJECT_TYPE.PROGRAM:
                            newFilteringProperties = AUDIT_FILTERING_PROPERTIES_PROGRAM
                            break
                        case OBJECT_TYPE.DELIVERABLE:
                            newFilteringProperties = AUDIT_FILTERING_PROPERTIES_DELIVERABLE
                            break
                        case OBJECT_TYPE.HC_ESTIMATE:
                            newFilteringProperties = AUDIT_FILTERING_PROPERTIES_HC_ESTIMATE
                            break
                        case OBJECT_TYPE.DISCRETIONARY_SPEND:
                            newFilteringProperties = AUDIT_FILTERING_PROPERTIES_DISCRETIONARY_SPEND
                            break
                        default:
                            newFilteringProperties = AUDIT_FILTERING_PROPERTIES
                            break
                    }
                    setFilteringProperties(newFilteringProperties)
                    return
                }
            }
        }
        setFilteringProperties(AUDIT_FILTERING_PROPERTIES)
    }

    const columnDefinitions = [
        {
            id: 'date_time',
            header: 'Date Time',
            cell: (item) => item.date_time,
            sortingField: 'timestamp',
        },
        {
            id: 'object_type',
            header: 'Type',
            cell: (item) => item.object_type,
            sortingField: 'object_type',
        },
        {
            id: 'action',
            header: 'Action',
            cell: (item) => item.action,
            sortingField: 'action',
        },
        {
            id: 'user_alias',
            header: 'User',
            cell: (item) =>
                item.user_alias === SYS_USER ? (
                    item.user_alias
                ) : (
                    <Link external={true} href={`${PHONE_TOOL_PREFIX}${item.user_alias}`}>
                        {item.user_alias}
                    </Link>
                ),
            sortingField: 'user_alias',
        },
        {
            id: 'detail',
            header: 'Detail',
            cell: (item) => (
                <Link
                    onFollow={() => {
                        setClickedItem(item)
                        setModalVisible(true)
                    }}
                >
                    Detail
                </Link>
            ),
        },
        {
            id: 'differences',
            header: 'Differences',
            cell: (item) => (
                <Container header={<Header variant='h3'>{getObjectLink(item)}</Header>}>
                    {renderDifferencesState(item)}
                </Container>
            ),
            sortingField: 'differences',
        },
    ]

    const visibleColumns = [
        'date_time',
        'object_type',
        'action',
        'user_alias',
        'detail',
        'differences',
    ]

    const [query, setQuery] = useState<any>({
        tokens: [],
        operation: 'and',
    })
    const [filteringProperties, setFilteringProperties] = useState<any>(AUDIT_FILTERING_PROPERTIES)

    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(history, {
        propertyFiltering: {
            filteringProperties: filteringProperties,
            empty: isLoading ? (
                <Spinner />
            ) : (
                <EmptyState
                    title='No history.'
                    subtitle={
                        validBE
                            ? 'No history to display. '
                            : 'Select your business entity to see history.'
                    }
                    action={''}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={
                        <Button
                            onClick={() => {
                                actions.setFiltering('')
                                setFilteringProperties(AUDIT_FILTERING_PROPERTIES)
                            }}
                        >
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions[0],
                isDescending: true,
            },
        },
        pagination: { pageSize: preferences.pageSize },
    })

    return (
        <>
            <Modal
                onDismiss={() => setModalVisible(false)}
                visible={modalVisible}
                footer={
                    <Box float='right'>
                        <Button variant='link' onClick={() => setModalVisible(false)}>
                            Close
                        </Button>
                    </Box>
                }
                header='Previous State / Next State'
                size='max'
            >
                <ColumnLayout columns={2}>
                    <Container>
                        <ColumnLayout columns={4} variant='text-grid'>
                            {renderState(true, clickedItem, clickedItem.object_type)}
                        </ColumnLayout>
                    </Container>
                    <Container>
                        <ColumnLayout columns={4} variant='text-grid'>
                            {renderState(false, clickedItem, clickedItem.object_type)}
                        </ColumnLayout>
                    </Container>
                </ColumnLayout>
            </Modal>
            <Table
                {...collectionProps}
                items={items}
                trackBy='timestamp'
                stickyHeader={true}
                loading={isLoading}
                columnDefinitions={columnDefinitions}
                loadingText={'Loading History...'}
                visibleColumns={visibleColumns}
                pagination={
                    <Pagination
                        {...{
                            ...paginationProps,
                            disabled: history.length < preferences.pageSize,
                        }}
                    />
                }
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTER_I18NSTRING}
                        {...propertyFilterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                        query={query}
                        onChange={({ detail }) => {
                            const tokens = detail.tokens
                            setQuery(detail)
                            actions.setPropertyFiltering(detail)
                            getFilteringProperties(tokens)

                            getHistoryTimestamp(detail.operation, tokens)
                            onAlertContentChange('')
                        }}
                    />
                }
                header={
                    <Header
                        variant='h2'
                        actions={
                            <Button
                                iconName='refresh'
                                onClick={() => {
                                    onStartEndTimeStampChange({
                                        start: defaultStartTimestamp,
                                        end: defaultEndTimestamp,
                                    })
                                    getHistoryByTimestamp(
                                        defaultStartTimestamp,
                                        defaultEndTimestamp,
                                    )
                                }}
                            ></Button>
                        }
                    ></Header>
                }
            />
        </>
    )
}

export default AuditTable
