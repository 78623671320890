import { Box } from '@amzn/awsui-components-react'

interface EmptyModalSectionProps {
    headerTitle: string
    objectNames?: string
}
const EmptyModalSection = (props: EmptyModalSectionProps) => {
    return (
        <Box>
            <Box variant={'h3'}>{props.headerTitle}</Box>
            <Box variant={'p'}>
                {`No ${
                    !props.objectNames ? props.headerTitle.toLowerCase() : props.objectNames
                } added.`}
            </Box>
        </Box>
    )
}

export default EmptyModalSection
