import { useEffect, useState } from 'react'
import {
    Alert,
    Button,
    Container,
    ColumnLayout,
    FormField,
    Header,
    Select,
    SpaceBetween,
    Toggle,
    Wizard,
    Box,
    Link,
    SelectProps,
    FlashbarProps,
    Flashbar,
} from '@amzn/awsui-components-react'
import {
    ALERT_TYPES,
    SELECT_MOVE_PROGRAM_OPTION,
    SUMMARY_HEADCOUNT_TYPE,
} from '../../../../Constant'
import { useAppContext } from '../../../../../context'
import { useLocation } from 'react-router-dom'
import CopyDeliverableHcEstimateTable from './CopyDeliverableHcEstimateTable'
import CopyDeliverableSummaryTable from './CopyDeliverableSummaryTable'
import useStore from '../../../../Store'
import CopyDeliverableHcEstimateSummaryTable from './CopyDeliverableHcEstimateSummaryTable'
import CopyDeliverableCompareTable from './CopyDeliverableCompareTable'
import CopyDeliverableHcEstimateAggregateTable from './CopyDeliverableHcEstimateAggregateTable'
import { generateBannerContent } from '../../SandboxUtils'
import { SANDBOX_REVISION } from '../../ProgramConstants'
import { emptyFn } from '../../../reusable/Utils'
import { getCopyDeliverableSandboxValidationFailureMessage } from '../DelivUtil'

interface CopyDeliverableWizardProps {
    program: any
    businessEntity: any
    selectedDeliverables: any[]
    onSelectedDeliverablesChange: (selectedDeliverables: any[]) => void
    copyHcEstimates: any[]
    aggregateHcEstimates: any[]
    onAggregateHcEstimatesChange: (aggregateHcEstimates: any[]) => void
    selectedAggregateHcEstimates: any[]
    onSelectedAggregateHcEstimatesChange: (selectedAggregateHcEstimates: any[]) => void
    onCancel: () => void
    isDeactivateSourceDeliverables: boolean
    onIsDeactivateSourceDeliverablesChange: (isDeactivateSourceDeliverables: boolean) => void
    isDeactivateSourceProgram: boolean
    onIsDeactivateSourceProgramChange: (isDeactivateSourceProgram: boolean) => void
    handleAddAlertItem: (content: any, type: ALERT_TYPES) => void
    spends: any[]
    groupOptions: any[]
    selectedCopyProgramOption: any
    onSelectedCopyProgramOptionChange: (selectedCopyProgramOption: SelectProps.Option) => void
    selectCopyProgramName: string
    refreshList: () => void
    isCopyHcEstimatesLoading: boolean
}

const CopyDeliverableWizard = ({
    program,
    businessEntity,
    selectedDeliverables,
    onSelectedDeliverablesChange,
    copyHcEstimates,
    aggregateHcEstimates,
    onAggregateHcEstimatesChange,
    selectedAggregateHcEstimates,
    onSelectedAggregateHcEstimatesChange,
    onCancel,
    isDeactivateSourceDeliverables,
    onIsDeactivateSourceDeliverablesChange,
    isDeactivateSourceProgram,
    onIsDeactivateSourceProgramChange,
    handleAddAlertItem,
    spends,
    groupOptions,
    selectedCopyProgramOption,
    onSelectedCopyProgramOptionChange,
    selectCopyProgramName,
    refreshList,
    isCopyHcEstimatesLoading,
}: CopyDeliverableWizardProps) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const location = useLocation()
    const paths = location.pathname.split('/')
    const planId = paths[2]
    const revisionId = paths[4]
    const programId = paths[6]
    const spendUrl = `/plan/${planId}/revision/${revisionId}/program/${programId}/discretionary-spend`

    const totalSpends = spends
        .reduce((n, spend) => n + parseFloat(spend.total_expenditure || '0'), 0)
        .toLocaleString()

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const [orgs, setOrgs] = useState({})
    const [programAlertContent, setProgramAlertContent] = useState<string>('')
    const [programSelectStatus, setProgramSelectStatus] = useState<any>('loading')
    const [activeStepIndex, setActiveStepIndex] = useState(0)
    const [selectedProgramError, setSelectedProgramError] = useState<string>('')
    const [programOptions, setProgramOptions] = useState<any[]>([])
    const [includeHcEstimatesChecked, setIncludeHcEstimatesChecked] = useState(false)
    const [existingSandboxHeadcounts, setExistingSandboxHeadcounts] = useState<any>([])
    const [isValidationLoading, setIsValidationLoading] = useState<boolean>(false)
    const [bannerItems, setBannerItems] = useState<FlashbarProps.MessageDefinition[]>([])
    const [copyAllHcEstimatesChecked, setCopyAllHcEstimatesChecked] = useState(true)
    const [duplicateDeliverables, setDuplicateDeliverables] = useState<any[]>([])

    const getSelectedHcEstimate = () => {
        const result: any[] = []
        copyHcEstimates.forEach((hcEstimate) => {
            const deliverable_id = hcEstimate.deliverable_id
            const group_id = hcEstimate.group_id
            if (
                selectedAggregateHcEstimates.find((aggregateHcEstimates) => {
                    return (
                        aggregateHcEstimates.deliverable_id === deliverable_id &&
                        aggregateHcEstimates.group_id === group_id
                    )
                })
            ) {
                result.push(hcEstimate)
            }
        })

        return result
    }

    const getOrgs = () => {
        apiClient
            .get(`/falcon/business-entity/${businessEntity.business_entity_id}/orgs`)
            .then((res) => {
                const allOrgs = res.data.filter((org) => org.is_active)
                const orgIdMap = {}
                allOrgs.forEach((org) => {
                    orgIdMap[org.org_id] = org
                })
                setOrgs(orgIdMap)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getExistingSandboxHeadcounts = () => {
        const deliverable_ids = selectedDeliverables
            .map((deliv) => deliv['deliverable_id'])
            .join(',')
        apiClient
            .get(
                `/program/${programId}/deliverables/headcount-estimates?plan_id=${planId}&revision_id=${SANDBOX_REVISION}&deliverable_ids=${deliverable_ids}`,
            )
            .then((res) => {
                const result = res.data.filter((est) => !est.is_submitted)
                setExistingSandboxHeadcounts(result)
                if (!result.length) {
                    setBannerItems([
                        generateBannerContent(
                            'success_message_1',
                            ALERT_TYPES.SUCCESS,
                            `Copy deliverable action can be continued. There are no unsubmitted sandbox headcounts.`,
                            setBannerItems,
                        ),
                    ])
                } else {
                    setBannerItems([
                        generateBannerContent(
                            'error_message_1',
                            ALERT_TYPES.ERROR,
                            getCopyDeliverableSandboxValidationFailureMessage(result.length),
                            setBannerItems,
                        ),
                    ])
                }
            })
            .catch((e) => {
                console.error(e)
                setBannerItems([
                    generateBannerContent(
                        'error_message_1',
                        ALERT_TYPES.ERROR,
                        `Unable to validate sandbox headcounts state for this deliverable: ${e}`,
                        setBannerItems,
                    ),
                ])
            })
            .finally(() => setIsValidationLoading(false))
    }

    const formatExistingSandboxHeadcountItems = () => {
        if (!existingSandboxHeadcounts.length) {
            return
        }
        // key is in the format - deliverable_id#group_id, value is a list of hc estimates associated with the key
        const formattedEstimates = {}
        existingSandboxHeadcounts.forEach((est) => {
            const key = `${est.deliverable_id}#${est.group_id}`
            if (!Object.keys(formattedEstimates).includes(key)) {
                formattedEstimates[key] = {
                    deliverable_id: est.deliverable_id,
                    deliverable_name: est.deliverable_name,
                    org_name: orgs[est.org_id]['org_name'],
                    group_id: est.group_id,
                    group_name: est.group_name,
                    hc_ct: 0,
                    hc_ff: 0,
                    hc_total: 0,
                }
            }
            const value = est.headcount_value
            switch (est.headcount_type) {
                case SUMMARY_HEADCOUNT_TYPE.CT:
                    formattedEstimates[key]['hc_ct'] += value
                    break
                case SUMMARY_HEADCOUNT_TYPE.FF:
                    formattedEstimates[key]['hc_ff'] += value
                    break
                default:
                    break
            }
            formattedEstimates[key]['hc_total'] += value
        })

        return Object.keys(formattedEstimates).map((key) => formattedEstimates[key])
    }

    const handleSubmit = () => {
        const payload = {
            business_entity_id: selectedBusinessEntity.business_entity_id,
            program_id: programId,
            target_program_id: selectedCopyProgramOption.value,
            user: appContext.userProps.userAlias,
            is_deactivate_source_program: isDeactivateSourceProgram,
            is_deactivate_source_deliverables: isDeactivateSourceDeliverables,
            source_deliverables: selectedDeliverables,
            duplicate_deliverable_items: duplicateDeliverables,
            source_hc_estimates: getSelectedHcEstimate(),
        }

        apiClient
            .post(
                `/plan/${planId}/revision/${revisionId}/program/${programId}/deliverables/bulk-copy`,
                JSON.stringify(payload),
            )
            .then(() => {
                refreshList()
                handleAddAlertItem(
                    <SpaceBetween direction='horizontal' size='xxs'>
                        <Box>{`Successfully copied deliverables: ${selectedDeliverables.map(
                            (deliv) => deliv.deliverable_name,
                        )}.`}</Box>
                        Copied to
                        <Link
                            external
                            externalIconAriaLabel='Opens in a new tab'
                            href={`/plan/${planId}/revision/${revisionId}/program/${selectedCopyProgramOption.value}`}
                        >
                            {selectCopyProgramName}
                        </Link>
                        .
                    </SpaceBetween>,
                    ALERT_TYPES.SUCCESS,
                )
                onSelectedDeliverablesChange([])
                onAggregateHcEstimatesChange([])
                onSelectedAggregateHcEstimatesChange([])
                onSelectedCopyProgramOptionChange(SELECT_MOVE_PROGRAM_OPTION)
            })
            .catch((error) => {
                handleAddAlertItem(
                    `Failed to copied deliverables ${selectedDeliverables.map(
                        (deliv) => deliv.deliverable_name,
                    )}
          : ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
                console.error(error)
            })
            .finally(() => {
                onCancel()
                window.scrollTo(0, 0)
            })
    }

    const formatPrograms = (programs) => {
        return programs
            .filter((prog) => prog.program_id !== program.program_id)
            .map((prog) => {
                return {
                    label: `${prog.program_name} - ${prog.is_active ? 'active' : 'inactive'}`,
                    value: prog.program_id,
                    iconName: prog.is_active ? 'status-positive' : 'status-negative',
                }
            })
            .sort((a, b) => a.label.localeCompare(b.label))
    }

    const getLocalPrograms = () => {
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/programs`)
            .then((res) => {
                const programs = res.data
                setProgramOptions(formatPrograms(programs))
                setProgramSelectStatus('finished')
            })
            .catch((error) => {
                console.error(error)
                setProgramSelectStatus('error')
            })
    }

    const getDuplicateDeliverables = (deliverables) => {
        const duplicateDeliverables: any[] = []
        selectedDeliverables.forEach((sourceDeliv) => {
            for (const targetDeliv of deliverables) {
                if (sourceDeliv.deliverable_name === targetDeliv.deliverable_name) {
                    duplicateDeliverables.push({
                        deliverable_id: sourceDeliv.deliverable_id,
                        deliverable_name: sourceDeliv.deliverable_name,
                        differences: {
                            source: sourceDeliv,
                            target: targetDeliv,
                        },
                        action: 'merge',
                    })
                }
            }
        })

        return duplicateDeliverables
    }

    const getDeliverables = (targetProgramId) => {
        apiClient
            .get(
                `/plan/${planId}/revision/${revisionId}/program/${targetProgramId}/deliverables?is_true_program=false&is_global_program=false
                `,
            )
            .then((res) => {
                const deliverables = res.data
                setDuplicateDeliverables(getDuplicateDeliverables(deliverables))
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        if (program.program_id) {
            getLocalPrograms()
        }
    }, [program])

    useEffect(() => {
        getOrgs()
    }, [])

    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                    `Step ${stepNumber} of ${stepsCount}`,
                skipToButtonLabel: (step) => `Skip to ${step.title}`,
                navigationAriaLabel: 'Steps',
                cancelButton: 'Cancel',
                previousButton: 'Previous',
                nextButton: 'Next',
                submitButton: 'Copy deliverable(s)',
                optional: 'optional',
            }}
            onNavigate={({ detail }) => {
                let validated = true
                switch (detail.requestedStepIndex) {
                    case 1:
                        if (selectedCopyProgramOption.value === SELECT_MOVE_PROGRAM_OPTION.value) {
                            setSelectedProgramError('Selected copy to program required')
                            validated = false
                        }
                        if (existingSandboxHeadcounts.length) {
                            setBannerItems([
                                generateBannerContent(
                                    'error_message_1',
                                    ALERT_TYPES.ERROR,
                                    getCopyDeliverableSandboxValidationFailureMessage(
                                        existingSandboxHeadcounts.length,
                                    ),
                                    setBannerItems,
                                    'Unable to proceed',
                                ),
                            ])
                            validated = false
                        }
                        break
                    default:
                        break
                }
                if (validated) {
                    setActiveStepIndex(detail.requestedStepIndex)
                }
            }}
            onSubmit={() => handleSubmit()}
            onCancel={() => {
                setBannerItems([])
                onCancel()
            }}
            activeStepIndex={activeStepIndex}
            isLoadingNextStep={isValidationLoading}
            steps={[
                {
                    title: 'Choose program and HC Options',
                    content: (
                        <Container>
                            <SpaceBetween direction='vertical' size='s'>
                                {bannerItems.length ? <Flashbar items={bannerItems} /> : <></>}
                                {programAlertContent && (
                                    <Alert statusIconAriaLabel='Warning' type={ALERT_TYPES.WARNING}>
                                        {programAlertContent}
                                    </Alert>
                                )}
                                <FormField
                                    label='Program'
                                    description={'Copy to program in current plan and revision.'}
                                    errorText={selectedProgramError}
                                >
                                    <Select
                                        statusType={programSelectStatus}
                                        loadingText='Loading programs'
                                        selectedOption={selectedCopyProgramOption}
                                        onChange={({ detail }) => {
                                            onSelectedCopyProgramOptionChange(detail.selectedOption)
                                            setSelectedProgramError('')
                                            getDeliverables(detail.selectedOption.value)

                                            if (
                                                detail.selectedOption.iconName === 'status-negative'
                                            ) {
                                                setProgramAlertContent(
                                                    'Inactive copy to program selected. ',
                                                )
                                            } else {
                                                setProgramAlertContent('')
                                            }
                                        }}
                                        options={programOptions}
                                        filteringType='auto'
                                    />
                                </FormField>
                                <Toggle
                                    onChange={({ detail }) => {
                                        setIncludeHcEstimatesChecked(detail.checked)
                                        if (!detail.checked) {
                                            onSelectedAggregateHcEstimatesChange([])
                                        } else {
                                            setIsValidationLoading(true)
                                            setBannerItems([
                                                generateBannerContent(
                                                    'info_message_1',
                                                    ALERT_TYPES.INFO,
                                                    `Checking for unsubmitted sandbox estimates...`,
                                                    setBannerItems,
                                                ),
                                            ])
                                            getExistingSandboxHeadcounts()
                                            onSelectedAggregateHcEstimatesChange(
                                                aggregateHcEstimates,
                                            )
                                        }
                                    }}
                                    checked={includeHcEstimatesChecked}
                                >
                                    Include headcount estimates
                                </Toggle>
                                {includeHcEstimatesChecked && !isValidationLoading ? (
                                    <Toggle
                                        onChange={({ detail }) => {
                                            const checked = detail.checked
                                            setCopyAllHcEstimatesChecked(checked)
                                            if (checked) {
                                                onSelectedAggregateHcEstimatesChange(
                                                    aggregateHcEstimates,
                                                )
                                            } else {
                                                onSelectedAggregateHcEstimatesChange([])
                                            }
                                        }}
                                        checked={copyAllHcEstimatesChecked}
                                    >
                                        Copy all headcount estimates
                                    </Toggle>
                                ) : (
                                    <></>
                                )}
                                {existingSandboxHeadcounts.length && includeHcEstimatesChecked ? (
                                    <CopyDeliverableHcEstimateTable
                                        aggregateHcEstimates={formatExistingSandboxHeadcountItems()}
                                        selectedAggregateHcEstimates={[]}
                                        onSelectedAggregateHcEstimatesChange={emptyFn}
                                        isCopyHcEstimatesLoading={isValidationLoading}
                                        displayInSimpleTableFormat={true}
                                    />
                                ) : (
                                    <></>
                                )}
                                {!includeHcEstimatesChecked || copyAllHcEstimatesChecked ? (
                                    <></>
                                ) : (
                                    <CopyDeliverableHcEstimateTable
                                        aggregateHcEstimates={aggregateHcEstimates}
                                        selectedAggregateHcEstimates={selectedAggregateHcEstimates}
                                        onSelectedAggregateHcEstimatesChange={
                                            onSelectedAggregateHcEstimatesChange
                                        }
                                        isCopyHcEstimatesLoading={isCopyHcEstimatesLoading}
                                    />
                                )}
                            </SpaceBetween>
                        </Container>
                    ),
                },
                {
                    title: "Copy to program's deliverable(s)",
                    content: (
                        <ColumnLayout borders='horizontal' columns={1}>
                            <Header
                                variant='h3'
                                actions={
                                    <Button onClick={() => setActiveStepIndex(0)}>Edit</Button>
                                }
                            >
                                Step 1: Choose program and HC Options
                            </Header>
                            <CopyDeliverableCompareTable
                                duplicateDeliverables={duplicateDeliverables}
                                onDuplicateDeliverablesChange={setDuplicateDeliverables}
                            />
                        </ColumnLayout>
                    ),
                },
                {
                    title: 'Source deliverable(s) and HC estimate(s)',
                    content: (
                        <ColumnLayout borders='horizontal' columns={1}>
                            <SpaceBetween size='s'>
                                <Header
                                    variant='h3'
                                    actions={
                                        <Button onClick={() => setActiveStepIndex(0)}>Edit</Button>
                                    }
                                >
                                    Step 1: Choose program and HC Options
                                </Header>
                                <Header
                                    variant='h3'
                                    actions={
                                        <Button onClick={() => setActiveStepIndex(1)}>Edit</Button>
                                    }
                                >
                                    Step 2: Copy to program's deliverable(s)
                                </Header>
                            </SpaceBetween>
                            <SpaceBetween size='m'>
                                <CopyDeliverableSummaryTable
                                    deliverables={selectedDeliverables}
                                    isDeactivateSourceDeliverables={isDeactivateSourceDeliverables}
                                    onIsDeactivateSourceDeliverablesChange={
                                        onIsDeactivateSourceDeliverablesChange
                                    }
                                    isDeactivateSourceProgram={isDeactivateSourceProgram}
                                    onIsDeactivateSourceProgramChange={
                                        onIsDeactivateSourceProgramChange
                                    }
                                />
                                <CopyDeliverableHcEstimateSummaryTable
                                    selectedAggregateHcEstimates={selectedAggregateHcEstimates}
                                    selectedDeliverables={selectedDeliverables}
                                    groupOptions={groupOptions}
                                />
                            </SpaceBetween>
                        </ColumnLayout>
                    ),
                },
                {
                    title: 'Review and copy',
                    content: (
                        <ColumnLayout borders='horizontal' columns={1}>
                            <SpaceBetween size='s'>
                                <Header
                                    variant='h3'
                                    actions={
                                        <Button onClick={() => setActiveStepIndex(0)}>Edit</Button>
                                    }
                                >
                                    Step 1: Choose program and HC Options
                                </Header>
                                <Header
                                    variant='h3'
                                    actions={
                                        <Button onClick={() => setActiveStepIndex(1)}>Edit</Button>
                                    }
                                >
                                    Step 2: Copy to program's deliverable(s)
                                </Header>
                                <Header
                                    variant='h3'
                                    actions={
                                        <Button onClick={() => setActiveStepIndex(2)}>Edit</Button>
                                    }
                                >
                                    Step 3: Source deliverable(s) and HC estimate(s)
                                </Header>
                            </SpaceBetween>
                            <SpaceBetween size='m'>
                                <ColumnLayout columns={2}>
                                    <Box>
                                        <Box variant='awsui-key-label'>Source program</Box>
                                        {program.program_name}
                                    </Box>
                                    <Box>
                                        <Box variant='awsui-key-label'>Target program</Box>
                                        {selectedCopyProgramOption.label}
                                    </Box>
                                </ColumnLayout>
                                <CopyDeliverableHcEstimateAggregateTable
                                    deliverables={selectedDeliverables}
                                    hcEstimates={selectedAggregateHcEstimates}
                                />
                                <Box>
                                    <Box variant='awsui-key-label'>
                                        Deliverable(s) will be copied. Total:{' '}
                                        {selectedDeliverables.length}
                                    </Box>
                                </Box>
                                <Box>
                                    <Box variant='awsui-key-label'>
                                        HC estimate(s) by Group will be copied. Total:{' '}
                                        {selectedAggregateHcEstimates.length}
                                    </Box>
                                </Box>
                                <Toggle disabled checked={isDeactivateSourceDeliverables}>
                                    Deactivate source deliverable(s)
                                </Toggle>
                                <Toggle disabled checked={isDeactivateSourceProgram}>
                                    Deactivate source program
                                </Toggle>
                                <Container>
                                    <SpaceBetween size='s'>
                                        <Box>Total spends: ${totalSpends}</Box>
                                        {totalSpends !== '0' ? (
                                            <Alert type={ALERT_TYPES.WARNING}>
                                                <SpaceBetween direction='horizontal' size='xxs'>
                                                    Found spends under the program, for copy spend
                                                    go to
                                                    <Link
                                                        external
                                                        externalIconAriaLabel='Opens in a new tab'
                                                        href={spendUrl}
                                                        onFollow={() => {
                                                            localStorage.clear()
                                                            localStorage.setItem(
                                                                'copy_deliverable_program_id',
                                                                selectedCopyProgramOption.value,
                                                            )
                                                        }}
                                                    >
                                                        spend page
                                                    </Link>
                                                    .
                                                </SpaceBetween>
                                            </Alert>
                                        ) : (
                                            <></>
                                        )}
                                    </SpaceBetween>
                                </Container>
                            </SpaceBetween>
                        </ColumnLayout>
                    ),
                },
            ]}
        />
    )
}

export default CopyDeliverableWizard
