import { Box } from '@amzn/awsui-components-react'
import { IconButton, Tooltip } from '@mui/material'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import { COMMENT_PANEL_MODE } from './Constant'
import { Dispatch, SetStateAction } from 'react'

interface CommentIconProps {
    isVisible: boolean
    onVisibleChange: Dispatch<SetStateAction<boolean>>
    mode: COMMENT_PANEL_MODE
    onModeChange: Dispatch<SetStateAction<COMMENT_PANEL_MODE>>
}

const CommentIcon = (props: CommentIconProps) => {
    const { isVisible, onVisibleChange, mode, onModeChange } = props

    const handleCommentClick = () => {
        if (isVisible) {
            if (mode === COMMENT_PANEL_MODE.DISABLED) {
                onModeChange(COMMENT_PANEL_MODE.VIEW)
                return
            }
            onVisibleChange(false)
            onModeChange(COMMENT_PANEL_MODE.DISABLED)
            return
        }
        onVisibleChange(true)
        onModeChange(COMMENT_PANEL_MODE.VIEW)
    }

    // TODO remove once comment feature ready to relase
    const isProduction = process.env.NODE_ENV === 'production'
    if (isProduction) {
        return <></>
    }

    return (
        <Tooltip
            title={
                <Box variant='awsui-key-label' color='inherit'>
                    Comment
                </Box>
            }
            placement='bottom'
        >
            <IconButton
                aria-label={'history'}
                size={'large'}
                color='primary'
                onClick={() => {
                    handleCommentClick()
                    // TODO handle filters
                }}
            >
                <ChatBubbleOutlineRoundedIcon fontSize='large' />
            </IconButton>
        </Tooltip>
    )
}

export default CommentIcon
