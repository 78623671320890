import { FormField, Input } from '@amzn/awsui-components-react'

interface FormFieldInputTemplateProps {
    state: any
    name: string
    id: string
    disable: boolean
    placeholder?: string
    onStateErrorChange?: any
    onStateChange?: any
    stateError?: any
    info?: string | JSX.Element
}

const FormFieldInputTemplate = (props: FormFieldInputTemplateProps) => {
    const {
        state,
        onStateChange,
        stateError,
        onStateErrorChange,
        name,
        id,
        disable,
        placeholder,
        info,
    } = props
    return (
        <FormField
            label={name}
            errorText={stateError ? stateError[id] : ''}
            info={info ? info : undefined}
        >
            <Input
                value={state[id]}
                onChange={({ detail }) => {
                    const value = detail.value
                    const newState = { ...state }
                    const newStateError = { ...stateError }

                    newState[id] = detail.value
                    onStateChange(newState)

                    if (value.trim().length === 0) {
                        newStateError[id] = `${name} must be provided.`
                    } else {
                        newStateError[id] = ''
                    }
                    onStateErrorChange(newStateError)
                }}
                disabled={disable}
                placeholder={placeholder}
            ></Input>
        </FormField>
    )
}

export default FormFieldInputTemplate
