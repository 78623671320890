import {
    FormField,
    Wizard,
    FileUpload,
    FlashbarProps,
    Flashbar,
    SpaceBetween,
    ProgressBar,
    Container,
    Button,
    Alert,
    Checkbox,
} from '@amzn/awsui-components-react'
import React, { useState } from 'react'
import { ALERT_TYPES } from '../../Constant'
import { generateBannerContent } from './SandboxUtils'
import {
    fileTypeValidation,
    importStepOneValidation,
    importStepTwoValidation,
    validationNotInProgress,
} from './SandboxImportUtils'

interface SandboxImportWizard {
    setImportModalVisible: any
}

export const FILE_TYPE_ERROR =
    'Invalid file type - Please ensure the uploaded file is an excel file.'

const SandboxImportWizard = (props: SandboxImportWizard) => {
    const { setImportModalVisible } = props
    const [activeStepIndex, setActiveStepIndex] = React.useState(0)
    const [fileValue, setFileValue] = React.useState<any>([])
    const [importFileErrors, setImportFileErrors] = React.useState<any>([])
    const [bannerItems, setBannerItems] = useState<FlashbarProps.MessageDefinition[]>([])
    const [isValidationComplete, setIsValidationComplete] = React.useState<boolean>(true)
    const [successValidationCount, setSuccessValidationCount] = React.useState<number>(0)
    const [failValidationCount, setFailValidationCount] = React.useState<number>(0)
    const [importActionAcknowledged, setImportActionAcknowledged] = React.useState<boolean>(false)

    const fileContentValidation = () => {
        // todo: implement actual validation tests that addresses critical and non-critical situations
        setSuccessValidationCount((prev) => prev + 1)
        setIsValidationComplete(true)
    }

    const resetWizardState = () => {
        setImportModalVisible(false)
        setActiveStepIndex(0)
        setFileValue([])
        setImportFileErrors([])
        setBannerItems([])
        setSuccessValidationCount(0)
        setImportActionAcknowledged(false)
    }

    const onWizardStepNavigationHandler = (step) => {
        let validated = true
        switch (step) {
            case 1:
                validated = importStepOneValidation(importFileErrors, fileValue, setBannerItems)
                break
            case 2:
                validated = importStepTwoValidation(
                    successValidationCount,
                    failValidationCount,
                    isValidationComplete,
                    setBannerItems,
                )
                break
            default:
                break
        }
        if (validated) {
            setActiveStepIndex(step)
            setBannerItems([])
        }
    }

    return (
        <Wizard
            i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                    `Step ${stepNumber} of ${stepsCount}`,
                skipToButtonLabel: (step) => `Skip to ${step.title}`,
                navigationAriaLabel: 'Steps',
                cancelButton: 'Cancel',
                previousButton: 'Previous',
                nextButton: 'Next',
                submitButton: 'Import',
                optional: 'optional',
            }}
            onNavigate={({ detail }) => {
                onWizardStepNavigationHandler(detail.requestedStepIndex)
            }}
            onSubmit={() => {
                if (!importActionAcknowledged) {
                    setBannerItems([
                        generateBannerContent(
                            'error_message_1',
                            ALERT_TYPES.ERROR,
                            'Please acknowledge the import action before proceeding.',
                            setBannerItems,
                            'Unable to proceed',
                        ),
                    ])
                } else {
                    // TODO: add action to update the ag grid table upon import button click.
                    resetWizardState()
                }
            }}
            onCancel={() => {
                resetWizardState()
            }}
            activeStepIndex={activeStepIndex}
            steps={[
                {
                    title: 'Upload File',
                    content: (
                        <Container>
                            <SpaceBetween size={'xs'} direction={'vertical'}>
                                {bannerItems.length ? <Flashbar items={bannerItems} /> : <></>}
                                <FormField
                                    label='Choose a file to import.'
                                    description='Compatible with xlsx files.'
                                >
                                    <FileUpload
                                        onChange={({ detail }) => {
                                            const errors = !fileTypeValidation(detail.value)
                                                ? [FILE_TYPE_ERROR]
                                                : []
                                            setImportFileErrors(errors)
                                            setFileValue(detail.value)
                                        }}
                                        value={fileValue}
                                        fileErrors={importFileErrors}
                                        i18nStrings={{
                                            uploadButtonText: (e) =>
                                                e ? 'Choose files' : 'Choose file',
                                            dropzoneText: (e) =>
                                                e ? 'Drop files to import' : 'Drop file to import',
                                            removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                                            limitShowFewer: 'Show fewer files',
                                            limitShowMore: 'Show more files',
                                            errorIconAriaLabel: 'Error',
                                        }}
                                        showFileLastModified
                                        showFileSize
                                        showFileThumbnail
                                        tokenLimit={3}
                                        constraintText='Accepted file types: .xlsx'
                                    />
                                </FormField>
                            </SpaceBetween>
                        </Container>
                    ),
                },
                {
                    title: 'Validate HC Fields',
                    content: (
                        <Container>
                            <SpaceBetween direction={'vertical'} size={'xs'}>
                                {bannerItems.length ? <Flashbar items={bannerItems} /> : <></>}
                                {validationNotInProgress(
                                    successValidationCount,
                                    failValidationCount,
                                    isValidationComplete,
                                ) && (
                                    <FormField
                                        label='Run field validation on uploaded file'
                                        description={fileValue[0]?.name}
                                    >
                                        <Button
                                            variant={'primary'}
                                            onClick={() => {
                                                setIsValidationComplete(false)
                                                fileContentValidation()
                                            }}
                                        >
                                            Run Validation
                                        </Button>
                                    </FormField>
                                )}
                                {/*todo: add non critical error progress bar options*/}
                                {!validationNotInProgress(
                                    successValidationCount,
                                    failValidationCount,
                                    isValidationComplete,
                                ) ? (
                                    !isValidationComplete ? (
                                        <ProgressBar
                                            value={successValidationCount * 100}
                                            additionalInfo='All validation criteria must match entirely in order to successfully import HC edits.'
                                            description={fileValue[0]?.name}
                                            label='Running file validations ...'
                                        />
                                    ) : failValidationCount ? (
                                        <ProgressBar
                                            status='error'
                                            label='Validation Complete'
                                            resultText='One or more validations failed.'
                                        />
                                    ) : (
                                        <ProgressBar
                                            status='success'
                                            label='Validation Complete'
                                            resultText='Validations successful. File criteria matches existing fields within table.'
                                        />
                                    )
                                ) : (
                                    <></>
                                )}
                            </SpaceBetween>
                        </Container>
                    ),
                },
                {
                    title: 'Import File',
                    content: (
                        <Container>
                            <SpaceBetween direction={'vertical'} size={'xs'}>
                                {bannerItems.length ? <Flashbar items={bannerItems} /> : <></>}
                                <Alert
                                    statusIconAriaLabel='Warning'
                                    type='warning'
                                    header='Overwrite current headcount?'
                                >
                                    Importing headcount will overwrite all included existing
                                    headcount estimate values. This cannot be undone.
                                </Alert>
                                <Checkbox
                                    onChange={({ detail }) =>
                                        setImportActionAcknowledged(detail.checked)
                                    }
                                    checked={importActionAcknowledged}
                                >
                                    I acknowledge the overwrite behavior and would like to proceed.
                                </Checkbox>
                            </SpaceBetween>
                        </Container>
                    ),
                },
            ]}
        />
    )
}

export default SandboxImportWizard
