import { Box, ButtonDropdown, Form, Header, SpaceBetween } from '@amzn/awsui-components-react'
import { Avatar } from '@mui/material'
import { convertToLocalTime } from '../../common/Util'
import { ROLES } from '../../../role'
import _ from 'lodash'
import { COMMENT_PANEL_MODE, USER_ROLES } from './Constant'

const AWS_COLOR_BLUE_600 = '#006ce0'

export const generateCommentForms = (comments) => {
    const generateComment = (comment) => {
        return (
            <Box key={comment.comment_id} padding={{ vertical: 's' }}>
                <SpaceBetween size={'s'} direction={'vertical'}>
                    {/* TODO: add tags*/}
                    <Form
                        header={
                            <Header
                                actions={
                                    <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                        <Box
                                            float={'right'}
                                            variant={'small'}
                                            margin={{ top: 's' }}
                                        >
                                            {convertToLocalTime(
                                                new Date(comment.update_timestamp).toISOString(),
                                            )}
                                        </Box>
                                        <ButtonDropdown
                                            items={[
                                                { id: 'delete', text: 'Delete' },
                                                // TODO add more actions
                                            ]}
                                            ariaLabel='Control instance'
                                            variant='icon'
                                        />
                                    </SpaceBetween>
                                }
                            >
                                <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                    <Avatar
                                        sx={{
                                            marginTop: 0.5,
                                            height: 25,
                                            width: 25,
                                            bgcolor: AWS_COLOR_BLUE_600,
                                        }}
                                    />
                                    <Box margin={{ vertical: 'xs' }} variant={'h5'}>
                                        {comment.user_alias}
                                    </Box>
                                </SpaceBetween>
                            </Header>
                        }
                    >
                        {comment.text}
                    </Form>
                    <hr />
                </SpaceBetween>
            </Box>
        )
    }

    const sortedComments = comments
        .sort((a, b) => a.update_timestamp - b.update_timestamp)
        .reverse()

    return sortedComments.map((comment) => generateComment(comment))
}

export const addCommentMenuItems = (
    isProgram: boolean,
    userRolesMetaData,
    setCommentMode,
    setIsSidePanelVisible,
) => {
    // TODO remove once comment feature ready to relase
    const isProduction = process.env.NODE_ENV === 'production'
    if (isProduction) {
        return {
            name: `Commenting feature coming soon!`,
        }
    }

    const getSelectedCellData = (params) => {
        if (isProgram) {
            return {
                col_name: params.node.field,
                col_value: params.node.key,
            }
        }

        // deliverable child cell
        return {
            col_name: params.column.colId,
            col_value: params.value.name,
        }
    }

    const handleCellClick = (params, roleMetadata) => {
        setCommentMode(COMMENT_PANEL_MODE.EDIT)
        setIsSidePanelVisible(true)
        const selectedCol = getSelectedCellData(params)
        // TODO handle pre-select in edit comment panel with those data to add comment: selectedCol, roleMetadata.object_name, roleMetadata.object_id
    }

    const result: any = {
        name: `Add Comment`,
        subMenu: [],
    }
    const hasAdminRole = userRolesMetaData.ROLES.find((role) => role.id === ROLES.ADMIN)
    if (hasAdminRole) {
        result.subMenu.push({
            name: ROLES.ADMIN,
            action: (params) => {
                handleCellClick(params, {
                    object_name: ROLES.ADMIN,
                    object_id: ROLES.ADMIN,
                })
            },
        })
    }
    Object.keys(USER_ROLES).forEach((role) => {
        if (!_.isEmpty(userRolesMetaData[role])) {
            userRolesMetaData[role].forEach((roleMetadata) => {
                result.subMenu.push({
                    name: `${roleMetadata.object_name} - ${USER_ROLES[role]}`,
                    action: (params) => {
                        handleCellClick(params, roleMetadata)
                    },
                })
            })
        }
    })
    return result
}
