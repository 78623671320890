import { styleHeaderConditionally } from '../../common/Util'
import { Button, Badge } from '@amzn/awsui-components-react'
import { DISPLAY_TYPES } from '../../Constant'
import { HEADCOUNT_TYPE } from './SandboxUtils'
interface SandboxSummaryAttribute {
    id: string
    headerName?: string
    displayType?: DISPLAY_TYPES
    iconName?: string
    buttonVariant?: string
    event?: any
    tableVisible: boolean
    width?: number
}

const NAME_COLUMN_WIDTH = 150
const COL_WIDTH = 50
export const sandboxSummaryAttributes = (showFTEEstimation): SandboxSummaryAttribute[] => {
    return [
        {
            id: 'headcount_id',
            tableVisible: false,
        },
        {
            id: 'business_entity_id',
            tableVisible: false,
        },
        {
            id: 'plan_id',
            tableVisible: false,
        },
        {
            id: 'revision_id',
            tableVisible: false,
        },
        {
            id: 'program_id',
            tableVisible: false,
        },
        {
            id: 'program_name',
            tableVisible: false,
        },
        {
            id: 'org_id',
            tableVisible: false,
        },
        {
            id: 'org_name',
            tableVisible: false,
        },
        {
            id: 'deliverable_id',
            tableVisible: false,
        },
        {
            id: 'deliverable_name',
            headerName: 'Deliverable',
            displayType: DISPLAY_TYPES.STRING,
            tableVisible: true,
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'plan_sort',
            tableVisible: false,
        },
        {
            id: 'group_id',
            tableVisible: false,
        },
        {
            id: 'group_name',
            headerName: 'Group',
            displayType: DISPLAY_TYPES.STRING,
            tableVisible: true,
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'headcount_type',
            headerName: 'Type',
            displayType: DISPLAY_TYPES.BADGE,
            tableVisible: true,
            width: COL_WIDTH,
        },
        {
            id: 'previous_submitted_value',
            headerName: 'Previous Submitted',
            displayType: DISPLAY_TYPES.NUMBER,
            tableVisible: showFTEEstimation,
            width: COL_WIDTH,
        },
        {
            id: 'headcount_value',
            headerName: 'Current',
            displayType: DISPLAY_TYPES.NUMBER,
            tableVisible: showFTEEstimation,
            width: COL_WIDTH,
        },
        {
            id: 'previous_submitted_fte_month_value',
            headerName: 'Previous Submitted',
            displayType: DISPLAY_TYPES.NUMBER,
            tableVisible: !showFTEEstimation,
            width: COL_WIDTH,
        },
        {
            id: 'fte_month_value',
            headerName: 'Current',
            displayType: DISPLAY_TYPES.NUMBER,
            tableVisible: !showFTEEstimation,
            width: COL_WIDTH,
        },
        {
            id: 'is_submitted',
            tableVisible: false,
        },
        {
            id: 'updated_at',
            tableVisible: false,
        },
        {
            id: 'updated_by',
            headerName: 'Username',
            displayType: DISPLAY_TYPES.STRING,
            tableVisible: true,
            width: COL_WIDTH,
        },
        {
            id: 'remove',
            displayType: DISPLAY_TYPES.BUTTON,
            iconName: 'remove',
            event: (item) => {
                item.removed = true
                return item
            },
            buttonVariant: 'icon',
            tableVisible: true,
            width: COL_WIDTH,
        },
    ]
}

const renderCell = (item, attr) => {
    switch (attr.displayType) {
        case DISPLAY_TYPES.BUTTON:
            return (
                <Button
                    iconName={attr.iconName}
                    onClick={() => {
                        item = attr.event(item)
                    }}
                    variant={attr.buttonVariant}
                />
            )
        case DISPLAY_TYPES.BADGE:
            return <Badge> {HEADCOUNT_TYPE[item[attr.id]]} </Badge>
        case DISPLAY_TYPES.NUMBER:
            return item[attr.id]?.toFixed(2)
        case DISPLAY_TYPES.STRING:
        default:
            return item[attr.id]
    }
}

export const getSandboxSummaryColumnDefinitions = (showFTEEstimation) => {
    return sandboxSummaryAttributes(showFTEEstimation).flatMap((attr) => [
        {
            id: attr.id,
            header: styleHeaderConditionally(attr),
            cell: (item) => renderCell(item, attr),
            sortingField: attr.id,
            width: attr.width,
        },
    ])
}

export const getSandboxSummaryVisibleColumns = (showFTEEstimation) => {
    return sandboxSummaryAttributes(showFTEEstimation).flatMap((attr) =>
        attr.tableVisible ? [{ id: attr.id, visible: true }] : [],
    )
}
