import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react'

interface ModalTemplateProps {
    modalVisible: boolean
    onModalVisibleChange: (visible: boolean) => void
    title: string
    body: JSX.Element
    actionName?: string
    action: () => void
    cancelName?: string
}

const ModalTemplate = (props: ModalTemplateProps) => {
    const { modalVisible, onModalVisibleChange, title, body, actionName, action, cancelName } =
        props
    return (
        <Modal
            onDismiss={() => onModalVisibleChange(false)}
            visible={modalVisible}
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={() => onModalVisibleChange(false)}>
                            {cancelName ?? 'Cancel'}
                        </Button>
                        {actionName ? (
                            <Button variant='primary' onClick={() => action()}>
                                {actionName}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
            header={title}
        >
            {body}
        </Modal>
    )
}

export default ModalTemplate
