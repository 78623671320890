/* eslint-disable @typescript-eslint/no-non-null-assertion */

// Promise polyfill
import 'core-js/features/promise'

import jwtDecode, { JwtPayload } from 'jwt-decode'
import App from './components/App'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@amzn/storm-ui'

import '@amzn/awsui-global-styles/polaris.css'
import { getMidwayJwtToken } from './auth/MidwayJwtToken'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { AppContext, UserProps } from './context'
import { EgretAuthProvider } from './auth/EgretAuthProvider'
import CacheBuster from 'react-cache-buster'
const appVersion = require('../package.json').version

const cathodeVersions = require('@amzn/cathode-versions-javascript')
const BASE_URL = {
    prod: 'https://prod.api.egret.birds.amazon.dev',
    gamma: 'https://gamma.api.egret.birds.amazon.dev',
    beta: 'https://beta.api.egret.birds.amazon.dev',
    local: 'http://localhost:8080',
    'gamma-ag-grid': 'https://gamma.api.egret.birds.amazon.dev',
}

const WEBSOCKET_URL = {
    beta: 'wss://d2z0yzdk6e.execute-api.us-east-1.amazonaws.com/beta/',
    gamma: 'wss://qkzfqrst3d.execute-api.us-east-1.amazonaws.com/gamma/',
    'gamma-ag-grid': 'wss://qkzfqrst3d.execute-api.us-east-1.amazonaws.com/gamma/',
    prod: 'wss://by7sz1pmjd.execute-api.us-east-1.amazonaws.com/prod/',
}

;(async () => {
    const token = await getMidwayJwtToken()
    const decoded = jwtDecode<JwtPayload>(token)
    const userAlias = decoded.sub
    const appSettings = (await axios('/settings.json')).data
    const stage: string = appSettings['stage']
    const apiUrl: string = BASE_URL[stage]
    const wsUrl: string = WEBSOCKET_URL[stage]
    const isProduction = process.env.NODE_ENV === 'production'

    const apiClient = axios.create({
        baseURL: apiUrl,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        responseType: 'json',
        timeout: 29_000,
    })

    // Add Authorization header to all axios calls
    apiClient.interceptors.request.use(async (config) => {
        const jwtToken = await getMidwayJwtToken()
        config.headers.Authorization = `Bearer ${jwtToken}`
        return config
    })

    apiClient.interceptors.response.use(
        function (response) {
            if (response.status === StatusCodes.UNAUTHORIZED) {
                console.error('You are not authorized to perform the operation')
            }
            return response
        },
        function (error) {
            return Promise.reject(error)
        },
    )

    const userProps: UserProps = {
        userAlias,
    }

    //User analytics integration(Spectrometer)
    const cathodeConfig = {
        siteName: 'Egret',
        appName: 'EgretFrontendWebsite',
    }

    if (userAlias) {
        // Using logged in user as an unique id as cathode-customerId
        document.dispatchEvent(
            new CustomEvent('cathode-customerId', {
                detail: {
                    customerId: userAlias,
                },
            }),
        )
    }

    const scriptTags = cathodeVersions.getSpectrometerScriptTags(cathodeConfig, stage === 'prod')
    const rawBoomerangScript = scriptTags.boomerangLoaderScript
    const boomerangScript = rawBoomerangScript.substring(8, rawBoomerangScript.length - 9)
    const rawListenerScript = scriptTags.listenerScripts
    const listenerScript = rawListenerScript.substring(8, rawListenerScript.length - 9)
    const rawCathodeScript = scriptTags.cathodeScript
    const cathodeScript = rawCathodeScript.substring(8, rawCathodeScript.length - 9)
    const container = document.getElementById('app')
    const root = createRoot(container!)

    root.render(
        <CacheBuster currentVersion={appVersion} isEnabled={isProduction} isVerboseMode={false}>
            <AppContext.Provider value={{ apiClient, userProps }}>
                <EgretAuthProvider apiClient={apiClient} userAlias={userAlias}>
                    <ThemeProvider>
                        <div>
                            <App userAlias={userAlias} wsURLBase={wsUrl} />
                        </div>
                    </ThemeProvider>
                </EgretAuthProvider>
            </AppContext.Provider>
        </CacheBuster>,
    )

    window.eval(boomerangScript)
    window.eval(listenerScript)
    window.eval(cathodeScript)
})()
