import { useAppContext } from '../../../context'
import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    FormField,
    Header,
    Modal,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import { SelectProps } from '@amzn/awsui-components-react/polaris/select/interfaces'
import useStore from '../../Store'
import BusinessEntityRefresh from '../reusable/BusinessEntityRefresh'
import { ALERT_TYPES } from '../../Constant'

const SELECT_ORG_OPTION = {
    label: 'Select an org',
    value: '',
}

interface MoveGroupsProps {
    modalVisible: boolean
    closeModalHandler: () => void
    selectedGroups: any[]
    setSelectedGroups: (selectedGroups: any[]) => void
    setAlertItems: (alertItems: any[]) => void
}

const MoveGroups = ({
    modalVisible,
    closeModalHandler,
    selectedGroups,
    setSelectedGroups,
    setAlertItems,
}: MoveGroupsProps) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const [allOrgsOption, setAllOrgsOption] = useState<SelectProps.Option[]>([])
    const [selectedOrg, setSelectedOrg] = useState<SelectProps.Option>(SELECT_ORG_OPTION)

    const handleDismiss = () => {
        closeModalHandler()
        clearAllInput()
    }

    const clearAllInput = () => {
        setSelectedOrg(SELECT_ORG_OPTION)
    }

    const generateMoveGroupsPayload = () => {
        return {
            requester: appContext.userProps.userAlias,
            new_org_id: selectedOrg.value,
            new_org_name: selectedOrg.label,
            groups: selectedGroups,
        }
    }

    const getNameOfSelectedGroups = () => {
        return selectedGroups.map((group) => group.group_name).join(', ')
    }

    const handleMoveGroups = () => {
        if (selectedOrg.value === selectedGroups[0].org_id) {
            setAlertItems([
                {
                    type: ALERT_TYPES.ERROR,
                    content: `${selectedGroups[0].group_name} is already in "${selectedOrg.label}" organization`,
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () => setAlertItems([]),
                },
            ])
            setSelectedGroups([])
            handleDismiss()
            clearAllInput()
            return
        }

        const payload = generateMoveGroupsPayload()
        apiClient
            .post('/group/bulk-move', JSON.stringify(payload))
            .then(() => {
                setAlertItems([
                    {
                        type: ALERT_TYPES.SUCCESS,
                        content: `Successfully reassigns ${getNameOfSelectedGroups()} to ${
                            selectedOrg.label
                        }`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
            })
            .catch((error) => {
                console.error(error)
                setAlertItems([
                    {
                        type: ALERT_TYPES.ERROR,
                        content: `Failed to update ${getNameOfSelectedGroups()} to ${
                            selectedOrg.label
                        } - ${error}`,
                        dismissible: true,
                        dismissLabel: 'Dismiss message',
                        onDismiss: () => setAlertItems([]),
                    },
                ])
            })
            .finally(() => {
                setSelectedGroups([])
                handleDismiss()
                clearAllInput()
            })
    }

    const getAllOrgsByBusinessEntity = () => {
        apiClient
            .get(`/falcon/business-entity/${selectedBusinessEntity.business_entity_id}/orgs`)
            .then((response) => {
                setAllOrgsOption(
                    response.data.map((org) => {
                        return { label: org.org_name, value: org.org_id }
                    }),
                )
            })
            .catch((error) => {
                console.error(error)
            })
    }

    useEffect(() => {
        BusinessEntityRefresh(selectedBusinessEntity.business_entity_id, getAllOrgsByBusinessEntity)
    }, [selectedBusinessEntity])

    return (
        <Modal
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            header={
                <Box>
                    <Header variant='h1'>Move Group</Header>
                    <Header variant='h3'>Moving "{getNameOfSelectedGroups()}"</Header>
                </Box>
            }
            visible={modalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleMoveGroups}
                            disabled={selectedOrg.value === ''}
                        >
                            Move Groups
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField label='Org' description={'Select an org '}>
                    <Select
                        filteringType='auto'
                        options={allOrgsOption}
                        selectedOption={selectedOrg}
                        onChange={({ detail }) => {
                            setSelectedOrg(detail.selectedOption)
                        }}
                        selectedAriaLabel='Selected'
                    ></Select>
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default MoveGroups
