import { createContext, Dispatch, useContext } from 'react'
import { AuditState } from './useAuditState'

interface IContextProps {
    state: AuditState
    dispatch: Dispatch<any>
}

const AuditContext = createContext({} as IContextProps)

export function useAuditContext() {
    return useContext(AuditContext)
}

export default AuditContext
