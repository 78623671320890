import { Box, Container, ContentLayout, Header } from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'

const NoBusinessEntity = () => {
    return (
        <ContentLayout header={<HeaderTemplate />}>
            <Box margin={{ left: 'm', right: 'm' }}>
                <Container>
                    <Container>
                        <Header variant={'h2'}>Missing Business Entity</Header>
                        <>
                            Sorry, this is a one-time thing. Please select a business entity from
                            the top bar to begin using Egret.
                        </>
                    </Container>
                </Container>
            </Box>
        </ContentLayout>
    )
}

export default NoBusinessEntity
