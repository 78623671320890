import { useAppContext } from '../../../context'
import useStore from '../../Store'
import { useEffect, useState } from 'react'
import {
    ACCESS_CONTROL_VALIDATION,
    ALERT_TYPES,
    EMPTY_PERMISSION_GROUP_VALIDATION,
    MODAL_MODES,
    ORG_NAME_DUPLICATE_MESSAGE,
    ORG_NAME_VALIDATION_MESSAGE,
    validPermissionGroup,
} from '../../Constant'
import { generateUuid, isDuplicateByPropertyValue, isValidChanged } from '../../common/Util'
import {
    Box,
    Button,
    FormField,
    Icon,
    Input,
    Link,
    Modal,
    SpaceBetween,
    Textarea,
    Toggle,
    TokenGroup,
    TokenGroupProps,
} from '@amzn/awsui-components-react'
import { formatRegisteredUsersTokenGroup } from './OrgUtil'
import { ROLES } from '../../../role'
import { find_list_diff } from '../reusable/Utils'

interface CreateOrgProps {
    modalMode: string
    modalVisible: boolean
    closeModalHandler: () => void
    refreshList: () => void
    selectedOrgs: any[]
    orgs: any[]
    setSelectedOrgs: (selectedOrgs: any[]) => void
    setAlertContent: (alertContent: any) => void
    setAlertType: (ALERT_TYPES) => void
    onIsOrgLoadingChange: (isLoading: boolean) => void
}

const CreateOrg = ({
    modalMode,
    modalVisible,
    closeModalHandler,
    refreshList,
    selectedOrgs,
    orgs,
    setSelectedOrgs,
    setAlertContent,
    setAlertType,
    onIsOrgLoadingChange,
}: CreateOrgProps) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userProps.userAlias

    const orgNamePlaceHolder = '(e.g) Core Software'

    const selectBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const [orgName, setOrgName] = useState('')
    const [orgNameError, setOrgNameError] = useState('')
    const [orgDescription, setOrgDescription] = useState('')
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)
    const [hrDataPermissionGroup, setHrDataPermissionGroup] = useState('')
    const [hrDataPermissionGroupName, setHrDataPermissionGroupName] = useState('')
    const [hrPermissionGroupError, setHrPermissionGroupError] = useState('')
    const [previousRegisteredUsers, setPreviousRegisteredUsers] = useState<TokenGroupProps.Item[]>(
        [],
    )
    const [registeredUsers, setRegisteredUsers] = useState<TokenGroupProps.Item[]>([])
    const [registerUserInput, setRegisterUserInput] = useState('')
    const [registerUserInputError, setRegisterUserInputError] = useState('')
    const [primaryContact, setPrimaryContact] = useState<TokenGroupProps.Item[]>([])
    const [primaryContactInput, setPrimaryContactInput] = useState('')
    const [primaryContactInputError, setPrimaryContactInputError] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [groups, setGroups] = useState([])

    useEffect(() => {
        if (modalMode === MODAL_MODES.EDIT) {
            if (selectedOrgs === undefined || selectedOrgs.length === 0) {
                return
            }
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(selectedOrgs[0])
            const allRegisteredUsers = [
                ...selectedOrgs[0].registered_users,
                ...(selectedOrgs[0].primary_alias ? [selectedOrgs[0].primary_alias] : []),
            ]
            setPreviousRegisteredUsers(formatRegisteredUsersTokenGroup(allRegisteredUsers))
        } else {
            clearAllInput()
        }
    }, [modalMode, selectedOrgs])

    const fillAllInputWithSelectedItem = (org) => {
        if (!org) {
            return
        }
        const primaryAlias = org['primary_alias']
        setOrgName(org['org_name'])
        setOrgDescription(org['description'])
        setHrDataPermissionGroup(org['hr_permission_group'])
        setHrDataPermissionGroupName(org['hr_permission_group_name'])
        setRegisteredUsers(formatRegisteredUsersTokenGroup(org['registered_users']))
        setPrimaryContact(
            primaryAlias
                ? [
                      {
                          label: primaryAlias,
                          dismissLabel: 'Remove ' + primaryAlias,
                      },
                  ]
                : [],
        )
        setIsActive(org['is_active'])
    }

    const clearAllInput = () => {
        setOrgName('')
        setOrgDescription('')
        setHrDataPermissionGroup('')
        setHrDataPermissionGroupName('')
        setIsActive(true)
        setRegisteredUsers([])
        setRegisterUserInput('')
        setRegisterUserInputError('')
        setPrimaryContact([])
        setPrimaryContactInput('')
        setPrimaryContactInputError('')
    }

    const isPayloadValidated = (payload) => {
        if (orgName.trim().length === 0) {
            setOrgNameError(ORG_NAME_VALIDATION_MESSAGE)
            return false
        }
        return true
    }

    const handleCloseModal = (reason) => {
        if (reason === 'overlay') {
            // prevent close modal from clicking outside of modal
            return
        }
        closeModalHandler()
        if (modalMode === MODAL_MODES.EDIT && selectedOrgs && selectedOrgs.length) {
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(selectedOrgs[0])
        } else {
            clearAllInput()
        }
        setOrgNameError('')
    }

    const handleSubmit = () => {
        const payload = {
            org_id: generateUuid(),
            org_name: orgName,
            business_entity_id: selectBusinessEntity.business_entity_id,
            business_entity_name: selectBusinessEntity.business_entity_name,
            is_active: isActive,
            description: orgDescription,
            requester: userAlias,
            primary_alias: primaryContact.length > 0 ? primaryContact[0].label : '',
            registered_users: registeredUsers.map((item) => item.label),
            hr_permission_group_name: hrDataPermissionGroupName,
            hr_permission_group: hrDataPermissionGroup,
        }

        if (!isPayloadValidated(payload)) {
            return
        }

        onIsOrgLoadingChange(true)
        createUpdateOrg(payload)
            .then((res) => {
                const registerUserOrgId =
                    selectedOrgs.length > 0 ? selectedOrgs[0].org_id : payload.org_id
                const registerUserOrgName = payload.org_name

                const formattedPrevRegisteredUsers = previousRegisteredUsers.map(
                    (item) => item.label,
                )
                const newRegisteredUsers = [
                    ...payload.registered_users,
                    ...(payload.primary_alias ? [payload.primary_alias] : []),
                ]

                const remove_user_list = find_list_diff(
                    formattedPrevRegisteredUsers,
                    newRegisteredUsers,
                )
                if (remove_user_list.length > 0) {
                    removeOrgRegisteredUsersPermissions(remove_user_list, registerUserOrgId)
                }
                if (newRegisteredUsers.length) {
                    updateOrgRegisteredUsersPermissions(
                        newRegisteredUsers,
                        registerUserOrgId,
                        registerUserOrgName,
                    )
                }

                setAlertType(ALERT_TYPES.SUCCESS)
                if (modalMode === MODAL_MODES.EDIT) {
                    setAlertContent(
                        `Successfully updated organization ${selectedOrgs[0].org_name}.`,
                    )
                } else {
                    setAlertContent(`Successfully created organization ${orgName}.`)
                }
                clearAllInput()
                setPreviousRegisteredUsers([...registeredUsers, ...primaryContact])
                setSelectedOrgs([])
                refreshList()
            })
            .catch((error) => {
                setAlertType(ALERT_TYPES.ERROR)
                if (modalMode === MODAL_MODES.EDIT) {
                    setAlertContent(
                        `Failed to update organization ${selectedOrgs[0].org_name}: ${error.response.data}.`,
                    )
                } else {
                    setAlertContent(
                        `Failed to create organization ${orgName}: ${error.response.data}.`,
                    )
                }
                onIsOrgLoadingChange(false)
                console.error(error)
                clearAllInput()
            })
        closeModalHandler()
    }

    const removeOrgRegisteredUsersPermissions = (removedUsers, orgId) => {
        apiClient
            .delete(
                `/users/role/${ROLES.ORG_MANAGER}?aliases=${removedUsers.join(
                    ',',
                )}&business_entity_id=${
                    selectBusinessEntity.business_entity_id
                }&object_ids=${orgId}`,
            )
            .then((res) => {
                setAlertType(ALERT_TYPES.SUCCESS)
                setAlertContent(`Successfully removed registered user(s) from ${orgName}.`)
            })
            .catch((error) => {
                console.error(error)
                setAlertType(ALERT_TYPES.ERROR)
                setAlertContent(`Failed to remove register user(s) from ${orgName}.`)
            })
    }

    const updateOrgRegisteredUsersPermissions = (registeredUsers, orgId, orgName) => {
        apiClient
            .put(
                `/users/role/${ROLES.ORG_MANAGER}?aliases=${registeredUsers.join(
                    ',',
                )}&business_entity_id=${
                    selectBusinessEntity.business_entity_id
                }&object_ids=${orgId}&object_names=${orgName}`,
            )
            .then((res) => {
                setAlertType(ALERT_TYPES.SUCCESS)
                setAlertContent(`Successfully registered user(s) to ${orgName}.`)
            })
            .catch((error) => {
                console.error(error)
                setAlertType(ALERT_TYPES.ERROR)
                setAlertContent(`Failed to register user(s) to ${orgName}.`)
            })
    }

    const createUpdateOrg = (payload) => {
        return modalMode === MODAL_MODES.EDIT
            ? apiClient.put(`/falcon/org/${selectedOrgs[0].org_id}`, JSON.stringify(payload))
            : apiClient.post(
                  `/falcon/business-entity/${selectBusinessEntity.business_entity_id}/orgs`,
                  JSON.stringify(payload),
              )
    }

    const handleAddRegisterUser = () => {
        if (registeredUsers.length > 0) {
            for (let i = 0; i < registeredUsers.length; i++) {
                if (registeredUsers[i].label === registerUserInput) {
                    setRegisterUserInputError(
                        `User ${registerUserInput} is already registered for this team.`,
                    )
                    return
                }
            }
        }

        const newRegisteredUsers = [...registeredUsers]
        newRegisteredUsers.push({
            label: registerUserInput,
            dismissLabel: 'Remove ' + registerUserInput,
        })
        setRegisterUserInput('')
        setRegisterUserInputError('')
        setRegisteredUsers(newRegisteredUsers)
        setIsSaveDisabled(
            JSON.stringify(newRegisteredUsers.map((item) => item.label)) ===
                JSON.stringify(selectedOrgs['registered_users']),
        )
    }

    const handleAddPrimaryContact = () => {
        if (primaryContact.length > 0) {
            setPrimaryContactInputError(
                `Only one primary contact allowed. Remove the current one first.`,
            )
            return
        }

        setPrimaryContactInput('')
        setPrimaryContactInputError('')
        setPrimaryContact([
            {
                label: primaryContactInput,
                dismissLabel: 'Remove ' + primaryContactInput,
            },
        ])
        setIsSaveDisabled(primaryContactInput === selectedOrgs['primary_alias'])
    }

    return (
        <Modal
            onDismiss={({ detail }) => handleCloseModal(detail.reason)}
            header={modalMode === MODAL_MODES.EDIT ? 'Edit Organization' : 'Add an Organization'}
            visible={modalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleSubmit}
                            disabled={isSaveDisabled || orgNameError !== ''}
                        >
                            {modalMode === MODAL_MODES.EDIT ? 'Update' : 'Add'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField
                    label='Organization'
                    description='Name of the organization'
                    errorText={orgNameError}
                >
                    <Input
                        value={orgName}
                        placeholder={orgNamePlaceHolder}
                        onChange={({ detail }) => {
                            const value = detail.value
                            const isDuplicate = isDuplicateByPropertyValue(
                                'org_name',
                                selectedOrgs.length > 0 ? selectedOrgs[0].org_name : 'undefined',
                                orgs,
                                'org_name',
                                value.trim(),
                            )
                            let errMessage = ''
                            if (value.trim().length === 0) {
                                errMessage = ORG_NAME_VALIDATION_MESSAGE
                            } else if (isDuplicate) {
                                errMessage = ORG_NAME_DUPLICATE_MESSAGE
                            }
                            setOrgNameError(errMessage)
                            setOrgName(value)

                            if (modalMode === MODAL_MODES.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            if (
                                !isDuplicate &&
                                isValidChanged(value, selectedOrgs[0], 'org_name')
                            ) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Business Entity'
                    description='Business entity that the organization belongs to'
                >
                    <Input
                        readOnly={true}
                        value={selectBusinessEntity.business_entity_name}
                    ></Input>
                </FormField>
                <FormField
                    label='Organization Permission Group'
                    description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq).'
                    errorText={hrPermissionGroupError}
                >
                    <SpaceBetween direction='vertical' size='xs'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                                Find your permission group <Icon name={'external'} />{' '}
                            </Link>
                            <Link
                                href='https://permissions.amazon.com/a/team/new'
                                target={'_blank'}
                            >
                                Create a new permission group <Icon name={'external'} />{' '}
                            </Link>
                        </SpaceBetween>
                        <Input
                            value={hrDataPermissionGroup}
                            onChange={({ detail }) => {
                                const value = detail.value.trim()
                                let errorMessage = ''

                                if (value.length > 0 && !validPermissionGroup.test(value)) {
                                    errorMessage = ACCESS_CONTROL_VALIDATION
                                }

                                if (value.length === 0) {
                                    errorMessage = EMPTY_PERMISSION_GROUP_VALIDATION
                                }
                                setHrPermissionGroupError(errorMessage)
                                setHrDataPermissionGroup(detail.value)

                                if (modalMode === MODAL_MODES.CREATE) {
                                    setIsSaveDisabled(false)
                                    return
                                }
                                setIsSaveDisabled(
                                    !isValidChanged(value, selectedOrgs[0], 'hr_permission_group'),
                                )
                            }}
                        ></Input>
                    </SpaceBetween>
                </FormField>
                <FormField
                    label='Organization Access Control Name'
                    description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
                >
                    <Input
                        value={hrDataPermissionGroupName}
                        onChange={({ detail }) => {
                            setHrDataPermissionGroupName(detail.value)

                            if (modalMode === MODAL_MODES.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            setIsSaveDisabled(
                                !isValidChanged(
                                    detail.value,
                                    selectedOrgs[0],
                                    'hr_permission_group_name',
                                ),
                            )
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Primary Contact'
                    description='Primary contact of the organization (will be mentioned when send notification)'
                    errorText={primaryContactInputError}
                >
                    <SpaceBetween size='xs' direction='vertical'>
                        <TokenGroup
                            onDismiss={({ detail: { itemIndex } }) => {
                                setPrimaryContact([])
                                setIsSaveDisabled(selectedOrgs['primary_alias'] === '')
                            }}
                            items={primaryContact}
                        />
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Input
                                value={primaryContactInput}
                                onChange={({ detail }) => setPrimaryContactInput(detail.value)}
                                placeholder='Add primary contact'
                            ></Input>
                            <Button
                                onClick={() => handleAddPrimaryContact()}
                                disabled={!primaryContactInput}
                            >
                                Add Primary Contact
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </FormField>
                <FormField
                    label='Registered Users'
                    description='Registered users of the organization. We cannot remove users at this time.'
                    errorText={registerUserInputError}
                >
                    <SpaceBetween size='xs' direction='vertical'>
                        <TokenGroup
                            i18nStrings={{
                                limitShowFewer: 'Show fewer registered users',
                                limitShowMore: 'Show more registered users',
                            }}
                            onDismiss={({ detail: { itemIndex } }) => {
                                const temp = [
                                    ...registeredUsers.slice(0, itemIndex),
                                    ...registeredUsers.slice(itemIndex + 1),
                                ]
                                setRegisteredUsers(temp)
                                setIsSaveDisabled(
                                    JSON.stringify(temp.map((item) => item.label)) ===
                                        JSON.stringify(selectedOrgs['registered_users']),
                                )
                            }}
                            items={registeredUsers}
                            limit={5}
                        />
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Input
                                value={registerUserInput}
                                onChange={({ detail }) => setRegisterUserInput(detail.value)}
                                placeholder='Add Org Manager(s)'
                            ></Input>
                            <Button
                                onClick={() => handleAddRegisterUser()}
                                disabled={!registerUserInput}
                            >
                                Add User
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </FormField>
                <FormField
                    label='Organization Description'
                    description='Description of the organization'
                >
                    <Textarea
                        value={orgDescription}
                        onChange={({ detail }) => {
                            setOrgDescription(detail.value)
                            if (modalMode === MODAL_MODES.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            if (isValidChanged(detail.value, selectedOrgs[0], 'description')) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Textarea>
                </FormField>
                <FormField label='Active' description='Mark true if it is an active organization'>
                    <Toggle
                        onChange={({ detail }) => {
                            setIsActive(detail.checked)
                            setIsSaveDisabled(
                                !isValidChanged(detail.checked, selectedOrgs[0], 'is_active'),
                            )
                        }}
                        checked={isActive}
                    >
                        Is organization active?
                    </Toggle>
                </FormField>
            </SpaceBetween>
        </Modal>
    )
}

export default CreateOrg
