import Button from '@amzn/awsui-components-react/polaris/button'
import { useNavigate } from 'react-router-dom'
import { ButtonProps } from '@amzn/awsui-components-react/polaris/button/interfaces'
import { IconProps } from '@amzn/awsui-components-react/polaris/icon/interfaces'
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events'

const LinkButton = ({
    content,
    path = '/',
    onClick = undefined,
    variant = 'normal',
    iconName,
    iconAlign,
    external = false,
    disabled = false,
    dataCy,
}: {
    content?: string | undefined
    path: string
    onClick?: CancelableEventHandler<ButtonProps.ClickDetail>
    variant?: ButtonProps.Variant
    iconName?: IconProps.Name
    iconAlign?: ButtonProps.IconAlign
    external?: boolean | undefined
    disabled?: boolean
    dataCy?: string | undefined
}) => {
    const history = useNavigate()

    const handleClickLink = (event, path) => {
        // This prevent to send request to cloudfront to get react. Instead, it is acting like react-router Link
        event.preventDefault()
        if (!external) {
            history(path)
        } else {
            window.open(path, '_blank')
        }
    }

    return (
        <Button
            data-cy={dataCy}
            href={path}
            onFollow={(event) => {
                handleClickLink(event, path)
            }}
            onClick={onClick}
            variant={variant}
            iconName={iconName}
            iconAlign={iconAlign}
            disabled={disabled}
        >
            {content}
        </Button>
    )
}

export default LinkButton
