import { Modal, SpaceBetween } from '@amzn/awsui-components-react'
import _ from 'lodash'
import { ALERT_TEMPLATE, HR_DATA_MESSAGE } from '../../reusable/TextUtil'
import HCDetailTable from './HCDetailTable'

const HCDetailModal = (props) => {
    const { visible, onVisibleChange, hrTeamData, hrMemberData, employeeTypeData } = props
    const isHrMemberDataEmpty = (hrMemberData) => {
        for (const employeeType in hrMemberData) {
            if (!_.isEmpty(hrMemberData[employeeType])) {
                return false
            }
        }
        return true
    }

    return (
        <Modal
            onDismiss={() => onVisibleChange(false)}
            visible={visible}
            size='large'
            closeAriaLabel='Close modal'
            header='Headcount Detail'
        >
            <SpaceBetween direction='vertical' size='s'>
                <ALERT_TEMPLATE
                    text={
                        isHrMemberDataEmpty(hrMemberData) ? (
                            'Headcount detail resource data not available.'
                        ) : (
                            <HR_DATA_MESSAGE hrTeam={hrTeamData} />
                        )
                    }
                />
                {isHrMemberDataEmpty(hrMemberData) ? (
                    <></>
                ) : (
                    <HCDetailTable
                        hrMemberData={hrMemberData}
                        employeeTypeData={employeeTypeData}
                    />
                )}
            </SpaceBetween>
        </Modal>
    )
}

export default HCDetailModal
