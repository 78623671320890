import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Modal,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import { ACTION_TYPES } from '../../Constant'
import {
    generateUpdateText,
    getKeyProps,
    valueWithLabel,
    valueWithLabelDifferences,
} from '../../AuditUtil'
import { capitalizeFirstLetter, sortStringList } from '../../../../common/Util'
import { useAuditContext } from '../../AuditContext'
import { AUDIT_STATE_ACTIONS } from '../../useAuditState'
import { convertToMoneyFormat } from '../../../reusable/Utils'
import './RendererStyles.css'
import _ from 'lodash'

const DEFAULT_COLUMNS_NUM = 4
const COMPACT_COLUMNS_NUM = 2

const DetailsModal = () => {
    const { state, dispatch } = useAuditContext()
    const item = state.clickedItem
    const differences = item?.differences || {}
    const prev = item?.prev_state
    const next = item?.next_state
    const refObject = item?.action !== ACTION_TYPES.DELETE ? next : prev
    const differencesKeys = Object.keys(differences)
    const keysToUse = sortStringList(
        item.action !== ACTION_TYPES.UPDATE ? Object.keys(refObject || {}) : differencesKeys,
        item?.override_key_sort || [],
    )
    const diffKeyLength = keysToUse.length
    const changeTitle = generateUpdateText(
        keysToUse,
        state.clickedItem?.object_name || '',
        state.clickedItem?.action,
    )

    const getNumColumns = () => {
        if (diffKeyLength < 4) {
            return COMPACT_COLUMNS_NUM
        }
        return DEFAULT_COLUMNS_NUM
    }
    const getSizeOfModal = () => {
        if (_.isEmpty(item) || _.isEmpty(refObject)) {
            return 'small'
        }
        if (diffKeyLength < 4) {
            return 'large'
        }
        return item?.action === ACTION_TYPES.UPDATE ? 'max' : 'large'
    }

    const renderState = () => {
        if (_.isEmpty(item) || _.isEmpty(refObject)) {
            return [<>No details to show.</>]
        }
        const result: any[] = []
        const renderValueFunc =
            item.action !== ACTION_TYPES.UPDATE ? valueWithLabel : valueWithLabelDifferences

        for (let key of keysToUse) {
            const keyProps = getKeyProps(key)
            if (!keyProps) {
                continue
            }
            let prevVal = prev !== undefined && prev[key] !== undefined ? prev[key] : ''
            let nextVal = next !== undefined && next[key] !== undefined ? next[key] : ''
            if (key.includes('expenditure')) {
                prevVal = convertToMoneyFormat(prevVal || '0')
                nextVal = convertToMoneyFormat(nextVal || '0')
            }

            result.push(
                renderValueFunc({
                    ...keyProps,
                    usePrevious: item.action === ACTION_TYPES.DELETE,
                    prev: prevVal,
                    next: nextVal,
                }),
            )
        }
        return result.length ? result : '-'
    }

    return (
        <Modal
            onDismiss={() =>
                dispatch({
                    type: AUDIT_STATE_ACTIONS.SET_SINGLE,
                    payload: {
                        isDetailsModalVisible: false,
                    },
                })
            }
            visible={state.isDetailsModalVisible}
            footer={
                <Box float='right'>
                    <Button
                        variant='link'
                        onClick={() =>
                            dispatch({
                                type: AUDIT_STATE_ACTIONS.SET_SINGLE,
                                payload: {
                                    isDetailsModalVisible: false,
                                },
                            })
                        }
                    >
                        Close
                    </Button>
                </Box>
            }
            header={`${capitalizeFirstLetter(item?.action)}: ${item?.object_name}`}
            size={getSizeOfModal()}
        >
            <Container>
                <SpaceBetween size={'xxs'} direction={'vertical'}>
                    <p>{changeTitle + ':'}</p>
                    <ColumnLayout columns={getNumColumns()} borders={'none'}>
                        {renderState()}
                    </ColumnLayout>
                </SpaceBetween>
            </Container>
        </Modal>
    )
}
export default DetailsModal
