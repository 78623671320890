import { Popover } from '@amzn/awsui-components-react'

const DatePopover = (props: { date: string }) => {
    const { date } = props

    return (
        <Popover
            dismissButton={false}
            position='left'
            size='small'
            triggerType='custom'
            content={date}
        >
            {date.split(' ')[0]}
        </Popover>
    )
}

export default DatePopover
