import { Box, Button, Drawer, Header } from '@amzn/awsui-components-react'
import { Autocomplete, TextField } from '@mui/material'
import { generateCommentForms } from './CommentUtil'
import { COMMENT_PANEL_MODE } from './Constant'
import { Dispatch, SetStateAction } from 'react'

interface CommentPanelProps {
    mode: COMMENT_PANEL_MODE
    onIsVisibleChange: Dispatch<SetStateAction<boolean>>
    comments: any
}

const CommentPanel = (props: CommentPanelProps) => {
    const { mode, onIsVisibleChange, comments } = props

    return (
        <Drawer
            header={
                <Header
                    actions={
                        <Button
                            iconName='close'
                            variant='icon'
                            iconAlign={'right'}
                            onClick={() => {
                                onIsVisibleChange(false)
                            }}
                        />
                    }
                >
                    Comment
                </Header>
            }
        >
            {mode === COMMENT_PANEL_MODE.VIEW ? (
                <>
                    <Box margin={{ top: 's' }}>
                        <Autocomplete
                            multiple
                            value={[]}
                            options={[]}
                            groupBy={(option: any) => option.type}
                            onChange={(event, newValue) => {
                                // TODO complete server side filters
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Search Comment'
                                    placeholder='Orgs, Groups, Programs, Deliverables, Roles'
                                />
                            )}
                        />
                    </Box>
                    {generateCommentForms(comments)}
                </>
            ) : (
                <>TODO add edit mode panel</>
            )}
        </Drawer>
    )
}
export default CommentPanel
