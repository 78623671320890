import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { SelectProps } from '@amzn/awsui-components-react'

let useLocalDeliverableStore: any = (set) => ({
    hcSelectedDeliverables: [],
    programSummaryColumns: [],
    programSummaryVisibleColumns: [],
    deliverableSummaryColumns: [],
    deliverableSummaryVisibleColumns: [],
    selectedProgram: undefined,
    selectedRevision: undefined,
    canEstimateHc: false,
    programMetadata: {
        program_id: '',
        program_name: '',
        stl_alias: '',
        stl_name: '',
        is_true_program: true,
        program_theme: '',
        owned_by: '',
        description: '',
        important_links: [],
        kingpin_goals: [],
        updated_at: '',
        updated_by: '',
    },
    revisionOptions: [],
    setProgramMetadata: (value) => {
        set((state) => ({ ...state, programMetadata: { ...value } }))
    },
    setSelectedRevisionOptions: (value: SelectProps.Option) => {
        set((state) => ({ ...state, revisionOptions: value }))
    },
    setCanEstimateHc: (value) =>
        set((state) => ({
            ...state,
            canEstimateHc: value,
        })),
    setHcSelectedDeliverables: (value) =>
        set((state) => ({
            ...state,
            hcSelectedDeliverables: value,
        })),

    setProgramSummaryColumns: (value) =>
        set((state) => ({
            ...state,
            programSummaryColumns: value,
        })),
    setProgramSummaryVisibleColumns: (value) =>
        set((state) => ({
            ...state,
            programSummaryVisibleColumns: value,
        })),

    setDeliverableSummaryColumns: (value) =>
        set((state) => ({
            ...state,
            deliverableSummaryColumns: value,
        })),
    setDeliverableSummaryVisibleColumns: (value) =>
        set((state) => ({
            ...state,
            deliverableSummaryVisibleColumns: value,
        })),
    setSelectedProgram: (value) =>
        set((state) => ({
            ...state,
            selectedProgram: value,
        })),
    setSelectedProgramRevision: (value) =>
        set((state) => ({
            ...state,
            selectedRevision: value,
        })),
})

// TODO: remove it before production release
useLocalDeliverableStore = devtools(useLocalDeliverableStore)

export default useLocalDeliverableStore = create(useLocalDeliverableStore)
