import { SelectProps } from '@amzn/awsui-components-react'
import { useEffect, useReducer } from 'react'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import { getOrgOptions } from '../../common/Util'
import {
    convertToMoneyFormat,
    formatPlanOptionsWithData,
    formatRevisionOptions,
} from '../reusable/Utils'
import { GridApi } from 'ag-grid-community'
import { hideZerosAndTruncateNumbers, orgSummaryInitialColDef } from './SummaryUtil'

interface ExtendedPropsOption extends SelectProps.Option {
    data: object
}

interface SummaryItemData {
    options: SelectProps.Option[] | ExtendedPropsOption[]
    selected: SelectProps.Option | ExtendedPropsOption
    loading: boolean
}

interface SummaryData {
    plans: SummaryItemData
    orgs: SummaryItemData
    revisions: SummaryItemData
    deliverables: {
        data: any[]
        loading: boolean
        showScopedOnly: boolean
    }
    orgGroups: {
        data: any[]
        loading: boolean
    }
    spend: {
        data: any[]
        loading: boolean
        showScopedOnly: boolean
    }
    gridApi: any
    headcount: {
        loading: boolean
    }
    programs: {
        data: any[]
        showScopedOnly: boolean
    }
}

export enum ACTION_TYPES {
    PLAN = 'plans',
    REVISION = 'revisions',
    ORG_GROUP = 'orgGroups',
    ORG = 'orgs',
    DELIVERABLES = 'deliverables',
    HC = 'headcount',
    SPEND = 'spend',
    PROGRAM = 'programs',
}

const reducer = (state, action) => {
    // If action.type is valid
    if (Object.values(ACTION_TYPES).includes(action.type)) {
        return {
            ...state,
            [action.type]: {
                ...state[action.type],
                [action.payload.key]: action.payload.data,
            },
        }
    } else if (action.type === 'SET_MANY') {
        const newState = { ...state }
        Object.keys(action.payload).forEach((key) => {
            action.payload[key].forEach((item) => {
                newState[key][item.key] = item.data
            })
        })
        return newState
    } else if (action.type === 'GRID_API') {
        return {
            ...state,
            gridApi: action.payload,
        }
    } else {
        return state
    }
}

export function useSummaryData(is_revamped) {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const summaryData: SummaryData = {
        plans: {
            options: [],
            selected: {},
            loading: false,
        },
        deliverables: {
            data: [],
            loading: false,
            showScopedOnly: true,
        },
        orgs: {
            options: [],
            selected: { label: 'Select Org', value: '' },
            loading: false,
        },
        revisions: {
            options: [],
            selected: { label: 'Select Revision', value: '' },
            loading: false,
        },
        orgGroups: {
            data: [],
            loading: false,
        },
        gridApi: null,
        headcount: { loading: false },
        spend: { data: [], loading: true, showScopedOnly: true },
        programs: { data: [], showScopedOnly: true },
    }

    const [data, dispatch] = useReducer(reducer, summaryData)

    useEffect(() => {
        if (!data.gridApi) {
            return
        }

        if (
            data[ACTION_TYPES.ORG_GROUP].loading ||
            data[ACTION_TYPES.DELIVERABLES].loading ||
            data[ACTION_TYPES.PLAN].loading ||
            data[ACTION_TYPES.HC].loading
        ) {
            data.gridApi.showLoadingOverlay()
        } else {
            data.gridApi.hideOverlay()
            if (data.gridApi.getDisplayedRowCount() === 0) {
                data.gridApi.showNoRowsOverlay()
            }
        }
    }, [
        data[ACTION_TYPES.ORG_GROUP].loading,
        data[ACTION_TYPES.DELIVERABLES].loading,
        data[ACTION_TYPES.PLAN].loading,
        data[ACTION_TYPES.HC].loading,
    ])

    useEffect(() => {
        if (data[ACTION_TYPES.ORG].options.length) {
            dispatch({
                type: ACTION_TYPES.ORG,
                payload: { key: 'selected', data: { ...data[ACTION_TYPES.ORG].options[0] } },
            })
        }
    }, [data[ACTION_TYPES.ORG].options])

    useEffect(() => {
        if (data[ACTION_TYPES.REVISION].options.length) {
            dispatch({
                type: ACTION_TYPES.REVISION,
                payload: {
                    key: 'selected',
                    data: { ...data[ACTION_TYPES.REVISION].options[0] },
                },
            })
        }
    }, [data[ACTION_TYPES.REVISION].options])

    useEffect(() => {
        const planId = data[ACTION_TYPES.PLAN].selected.value
        if (!planId) {
            return
        }
        getPlanData(planId)
    }, [data[ACTION_TYPES.PLAN].selected])

    useEffect(() => {
        const businessEntityId = selectedBusinessEntity?.business_entity_id
        if (!data.gridApi || !businessEntityId) {
            return
        }
        dispatch({ type: ACTION_TYPES.PLAN, payload: { key: 'loading', data: true } })
        getOrgs(businessEntityId)
        getBusinessEntityPlans(businessEntityId)
    }, [data.gridApi, selectedBusinessEntity])

    useEffect(() => {
        if (!data.gridApi || !data[ACTION_TYPES.REVISION].selected) {
            return
        }
        const planId = data[ACTION_TYPES.PLAN].selected?.data.plan_id
        const revisionId = data[ACTION_TYPES.REVISION].selected.value
        dispatch({ type: ACTION_TYPES.DELIVERABLES, payload: { key: 'loading', data: true } })
        getPlanDeliverables(planId, revisionId)
    }, [data[ACTION_TYPES.REVISION].selected])

    useEffect(() => {
        dispatch({
            type: 'SET_MANY',
            payload: {
                [ACTION_TYPES.ORG_GROUP]: [
                    {
                        key: 'loading',
                        data: true,
                    },
                ],
                [ACTION_TYPES.SPEND]: [
                    {
                        key: 'loading',
                        data: true,
                    },
                ],
            },
        })

        getOrgGroups(data[ACTION_TYPES.ORG].selected.value)

        const planId = data[ACTION_TYPES.PLAN].selected.data?.plan_id
        const revisionId = data[ACTION_TYPES.REVISION].selected.value
        const orgId = data[ACTION_TYPES.ORG].selected.value

        getSpendSummaryTableData(planId, revisionId, orgId)
    }, [
        data[ACTION_TYPES.PLAN].selected,
        data[ACTION_TYPES.REVISION].selected,
        data[ACTION_TYPES.ORG].selected,
        data[ACTION_TYPES.SPEND].showScopedOnly,
    ])

    useEffect(() => {
        if (
            data.gridApi &&
            data[ACTION_TYPES.PLAN].selected.value &&
            !data[ACTION_TYPES.DELIVERABLES].loading
        ) {
            dispatch({ type: ACTION_TYPES.HC, payload: { key: 'loading', data: true } })
            if (is_revamped) {
                queryHCEstimateAPI(
                    data.gridApi,
                    data[ACTION_TYPES.PLAN].selected.data,
                    data[ACTION_TYPES.REVISION].selected.value,
                    data[ACTION_TYPES.ORG].selected.value,
                    data[ACTION_TYPES.ORG_GROUP].data,
                    data[ACTION_TYPES.DELIVERABLES].data,
                    data[ACTION_TYPES.DELIVERABLES].showScopedOnly,
                )
            } else {
                getHeadcountEstimateData(
                    data.gridApi,
                    data[ACTION_TYPES.PLAN].selected.data?.plan_id,
                    data[ACTION_TYPES.REVISION].selected.value,
                    data[ACTION_TYPES.ORG].selected.value,
                )
            }
        }
    }, [
        data.orgGroups.data,
        data[ACTION_TYPES.PROGRAM].data,
        data.gridApi,
        data[ACTION_TYPES.DELIVERABLES].showScopedOnly,
        data[ACTION_TYPES.DELIVERABLES].loading,
    ])

    const getPlanData = (planId: string) => {
        apiClient
            .get(`/plan/${planId}`)
            .then((res) => {
                if (res.data.revisions.length) {
                    const formattedRevisions = res.data.revisions.map(formatRevisionOptions)
                    dispatch({
                        type: ACTION_TYPES.REVISION,
                        payload: { key: 'options', data: formattedRevisions },
                    })
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getBusinessEntityPlans = (businessEntityId) => {
        apiClient
            .get(`/plan/business-entity/${businessEntityId}?year=`)
            .then((res) => {
                const formattedPlanOptions = res.data.map((plan) => formatPlanOptionsWithData(plan))
                if (formattedPlanOptions.length) {
                    const selectedPlan = formattedPlanOptions[0]
                    const revisionOptions = selectedPlan.data.revisions.map((rev: any) =>
                        formatRevisionOptions(rev),
                    )
                    dispatch({
                        type: 'SET_MANY',
                        payload: {
                            [ACTION_TYPES.REVISION]: [{ key: 'options', data: revisionOptions }],
                            [ACTION_TYPES.PLAN]: [
                                { key: 'selected', data: selectedPlan },
                                { key: 'options', data: formattedPlanOptions },
                            ],
                        },
                    })
                } else {
                    dispatch({
                        type: 'SET_MANY',
                        payload: {
                            [ACTION_TYPES.REVISION]: { key: 'options', data: [] },
                            [ACTION_TYPES.PLAN]: [
                                { key: 'selected', data: {} },
                                { key: 'options', data: [] },
                            ],
                        },
                    })
                }
            })
            .catch((err) => {
                console.error(err)
                dispatch({ type: ACTION_TYPES.PLAN, payload: { key: 'options', data: [] } })
            })
        dispatch({ type: ACTION_TYPES.PLAN, payload: { key: 'loading', data: false } })
    }

    const getOrgs = (businessEntityId: string) => {
        apiClient
            .get(`/falcon/business-entity/${businessEntityId}/orgs`)
            .then((res) => {
                const allOrgs = res.data.filter((org) => org.is_active)
                dispatch({
                    type: ACTION_TYPES.ORG,
                    payload: { key: 'options', data: getOrgOptions(allOrgs) },
                })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getOrgGroups = (orgId: string) => {
        if (!orgId) {
            return
        }
        apiClient
            .get(`/org/${orgId}/all-groups?ignore_archived=false`)
            .then((res) => {
                const allGroups = res.data.filter((group) => group.is_active && group.is_egret)
                dispatch({
                    type: ACTION_TYPES.ORG_GROUP,
                    payload: { key: 'data', data: allGroups },
                })
            })
            .catch((e) => {
                console.error(e)
            })
        dispatch({ type: ACTION_TYPES.ORG_GROUP, payload: { key: 'loading', data: false } })
    }

    const getPlanDeliverables = (planId: string, revisionId: string) => {
        if (!revisionId && !planId) {
            return
        }
        apiClient
            .get(`/plan/${planId}/revision/${revisionId}/deliverables`)
            .then((res) => {
                const programList = res.data
                const itemMap = {}
                Object.entries(programList).forEach((program: any) => {
                    for (const deliverable of program[1]) {
                        itemMap[deliverable.deliverable_id] = deliverable.deliverable_name
                        itemMap[deliverable.program_id] = program[0]
                    }
                })

                dispatch({
                    type: 'SET_MANY',
                    payload: {
                        [ACTION_TYPES.DELIVERABLES]: [
                            { key: 'data', data: res.data },
                            { key: 'loading', data: false },
                        ],
                        [ACTION_TYPES.PROGRAM]: [{ key: 'data', data: { ...itemMap } }],
                    },
                })
            })
            .catch((e) => {
                console.error(e)
                dispatch({
                    type: ACTION_TYPES.DELIVERABLES,
                    payload: { key: 'loading', data: false },
                })
            })
    }

    const getSpendSummaryTableData = (planId: string, revisionId: string, orgId: string) => {
        if (!(planId && revisionId && orgId)) {
            return
        }
        apiClient
            .get(
                `/org_discretionary_spend_summary/plan/${planId}/revision/${revisionId}/org/${orgId}`,
            )
            .then((res) => {
                const totalRow = { program_name: 'Total' }
                let summaryRows = res.data
                if (data[ACTION_TYPES.SPEND].showScopedOnly) {
                    summaryRows = summaryRows.filter((row) => row.is_estimated)
                }
                if (!summaryRows.length) {
                    dispatch({
                        type: 'SET_MANY',
                        payload: {
                            [ACTION_TYPES.SPEND]: [
                                { key: 'loading', data: false },
                                { key: 'data', data: [] },
                            ],
                        },
                    })
                    return
                }
                summaryRows.forEach((row) => {
                    Object.keys(row).forEach((element) => {
                        if (!Object.keys(totalRow).includes(element)) {
                            totalRow[element] = 0
                        }
                        if (element !== 'program_name') {
                            const rowValue = parseInt(row[element])
                            totalRow[element] += rowValue
                            if (!rowValue) {
                                row[element] = ''
                            } else {
                                row[element] = convertToMoneyFormat(rowValue)
                            }
                        }
                    })
                })
                Object.keys(totalRow).forEach((element) => {
                    if (element !== 'program_name' && !totalRow[element]) {
                        totalRow[element] = ''
                    } else if (element !== 'program_name') {
                        totalRow[element] = convertToMoneyFormat(totalRow[element])
                    }
                })
                dispatch({
                    type: 'SET_MANY',
                    payload: {
                        [ACTION_TYPES.SPEND]: [
                            { key: 'loading', data: false },
                            { key: 'data', data: [...summaryRows, ...[totalRow]] },
                        ],
                    },
                })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const generateRows = (orgData, deliverables) => {
        const compressedOrgData = {}
        const allowedGroupIds = new Set()

        data.orgGroups.data.forEach((group) => {
            allowedGroupIds.add(group.group_id)
        })

        // Accumulate based on program_id with deliverables under each program
        orgData.forEach((row) => {
            if (!compressedOrgData[row.program_id]) {
                compressedOrgData[row.program_id] = []
            }
            compressedOrgData[row.program_id].push({
                program_name: row.program_name,
                deliverable_id: row.deliverable_id,
                program_id: row.program_id,
                org_id: row.org_id,
                hc_ct: parseFloat(row.hc_ct),
                hc_ff: parseFloat(row.hc_ff),
                hc_ct_oh: parseFloat(row.hc_ct_overhead),
                hc_ff_oh: parseFloat(row.hc_ff_overhead),
                group_id: row.group_id,
            })
        })

        const groupOHData = { program_name: 'Total OH' }
        const programIdData = {}
        // Compress headcount estimate for different job functions
        Object.entries(compressedOrgData).forEach((program: any) => {
            const deliverableMap = {}
            for (let i = 0; i < program[1].length; i++) {
                const deliverable = program[1][i]
                if (!allowedGroupIds.has(deliverable.group_id)) {
                    continue
                }
                let deliverableMapVal = deliverableMap[deliverable.deliverable_id]
                if (!deliverableMapVal) {
                    deliverableMapVal = {
                        deliverable_id: deliverable.deliverable_id,
                        program_id: deliverable.program_id,
                        org_id: deliverable.org_id,
                        group_ids: [],
                    }
                }

                deliverableMapVal.group_ids.push(deliverable.group_id)
                if (!deliverableMapVal[`${deliverable.group_id}_ct`]) {
                    deliverableMapVal[`${deliverable.group_id}_ct`] = 0
                }

                if (!deliverableMapVal[`${deliverable.group_id}_ff`]) {
                    deliverableMapVal[`${deliverable.group_id}_ff`] = 0
                }

                deliverableMapVal[`${deliverable.group_id}_ct`] += +deliverable.hc_ct
                deliverableMapVal[`${deliverable.group_id}_ff`] += +deliverable.hc_ff

                deliverableMap[deliverable.deliverable_id] = deliverableMapVal

                if (!groupOHData[`${deliverable.group_id}_ct`]) {
                    groupOHData[`${deliverable.group_id}_ct`] = 0
                }
                if (!groupOHData[`${deliverable.group_id}_ff`]) {
                    groupOHData[`${deliverable.group_id}_ff`] = 0
                }

                groupOHData[`${deliverable.group_id}_ct`] += deliverable.hc_ct_oh
                groupOHData[`${deliverable.group_id}_ff`] += deliverable.hc_ff_oh
            }
            programIdData[program[0]] = deliverableMap
        })

        const flatData: any[] = []
        Object.entries(programIdData).forEach((program: any) => {
            for (let i = 0; i < Object.entries(program[1]).length; i++) {
                const row: any = Object.entries(program[1])[i][1]
                row['row_total'] = Object.keys(row).reduce((acc: any, val: any) => {
                    return acc + (val.endsWith('_ct') || val.endsWith('_ff') ? row[val] : 0)
                }, 0)
                row['program_name'] = data[ACTION_TYPES.PROGRAM].data[row['program_id']]
                row['deliverable_name'] = data[ACTION_TYPES.PROGRAM].data[row['deliverable_id']]

                if (
                    row['row_total'] !== 0 ||
                    (row['row_total'] === 0 && !data[ACTION_TYPES.DELIVERABLES].showScodepedOnly)
                ) {
                    flatData.push(row)
                }
            }
        })

        if (!data[ACTION_TYPES.DELIVERABLES].showScodepedOnly) {
            Object.entries(deliverables).forEach((programDelMap) => {
                const programName = programDelMap[0]
                const programDeliverables: any = programDelMap[1]
                Array.from(programDeliverables).forEach((deliverable: any) => {
                    if (!programIdData[deliverable.program_id]) {
                        flatData.push({
                            program_name: programName,
                            deliverable_name: deliverable.deliverable_name,
                            row_total: 0,
                        })
                    }
                })
            })
        }

        return { flatData, groupOHData }
    }

    const generateColDef = (groupOHData) => {
        const rowHCAggColDef = {
            headerName: `Total (C&T + FF)`,
            field: 'row_total',
            width: 100,
            aggFunc: 'sum',
            colId: 'TotalAggRow',
            cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
            pinned: 'left',
            headerTooltip: 'Total (C&T + FF)',
        }

        const colDef = [...orgSummaryInitialColDef]
        colDef.push(rowHCAggColDef)
        const bisData = { program_name: 'BIS Total' }
        data[ACTION_TYPES.ORG_GROUP].data.forEach((group) => {
            const isArchived = group?.archived
            const groupCt = groupOHData[`${group.group_id}_ct`] ?? 0
            const groupFf = groupOHData[`${group.group_id}_ff`] ?? 0
            if (isArchived && !(groupCt + groupFf)) {
                return
            }
            const suffix = isArchived ? ' (archived)' : ''
            const groupColDef = {
                headerName: `${group.group_name}${suffix}`,
                field: `${group.group_id}`,
                colId: `${group.group_id}_id`,
                groupId: `${group.group_id}`,
                valueFormatter: (params) => {
                    return params.value ? params.value : 0
                },
                width: 100,
                headerTooltip: `${group.group_name}`,
                children: [
                    {
                        colId: `${group.group_id}_ct_id`,
                        headerName: 'C&T',
                        field: `${group.group_id}_ct`,
                        aggFunc: 'sum',
                        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                    },
                    {
                        colId: `${group.group_id}_ff_id`,
                        headerName: 'FF',
                        field: `${group.group_id}_ff`,
                        aggFunc: 'sum',
                        cellRenderer: (params) => hideZerosAndTruncateNumbers(params),
                    },
                ],
            }

            colDef.push(groupColDef)
            bisData[`${group.group_id}_ct`] =
                'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
            bisData[`${group.group_id}_ff`] =
                'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
        })

        const gapData = {}
        Object.entries(bisData).forEach((value: any) => {
            const totalOHHC = groupOHData[value[0]] ?? 0
            const bisHC = value[1]
            gapData[value[0]] = totalOHHC - bisHC
        })
        gapData['program_name'] = 'Gap (Total w/ OH - BIS)'

        return { colDef, bisData, gapData }
    }

    const queryHCEstimateAPI = async (
        gridApi,
        plan: any,
        revisionId: string | undefined,
        orgId: string,
        orgGroups,
        deliverables,
        showScopedOnly,
    ) => {
        const planOverheadFactor = parseFloat(plan?.overhead_factor) / 100
        apiClient
            .get(
                `/org-headcount-estimates/org/${orgId}/plan/${plan.plan_id}/revision/${revisionId}`,
            )
            .then((response) => {
                const headcountData: any = []
                // Go through all the deliverables and add it to the program row_group
                Object.entries(deliverables).forEach((value) => {
                    const program_name = value[0]
                    const deliverables: any = value[1]
                    Array.from(deliverables).forEach((deliverable: any) => {
                        if (deliverable?.is_active) {
                            headcountData.push({
                                id: `${deliverable?.deliverable_id}#${deliverable?.program_id}`,
                                program_name: program_name,
                                deliverable_name: deliverable?.deliverable_name,
                                deliverable_id: deliverable?.deliverable_id,
                                program_id: deliverable?.program_id,
                                total: 0,
                                total_ct: 0,
                                total_ff: 0,
                                scoped: false,
                            })
                        }
                    })
                })

                // Go through all the groups in the org and add the groups as columns
                const colDef = [...orgSummaryInitialColDef]
                const bisData = { program_name: 'BIS Total' }
                orgGroups.forEach((group) => {
                    const suffix = group?.archived ? ' (archived)' : ''
                    const groupColDef = {
                        headerName: `${group.group_name}${suffix}`,
                        field: `${group.group_id}`,
                        valueFormatter: (params) => {
                            return params.value ? params.value : 0
                        },
                        children: [
                            {
                                headerName: 'C&T',
                                field: `${group.group_id}_ct`,
                                aggFunc: 'sum',
                                width: 100,
                                type: 'numericColumn',
                                filter: false,
                                floatingFilter: false,
                                cellRenderer: (params) => {
                                    return hideZerosAndTruncateNumbers(params)
                                },
                            },
                            {
                                headerName: 'FF',
                                field: `${group.group_id}_ff`,
                                aggFunc: 'sum',
                                width: 100,
                                type: 'numericColumn',
                                filter: false,
                                floatingFilter: false,
                                cellRenderer: (params) => {
                                    return hideZerosAndTruncateNumbers(params)
                                },
                            },
                        ],
                    }
                    colDef.push(groupColDef)
                    bisData[`${group.group_id}_ct`] =
                        'hr_headcount' in group ? parseFloat(group.hr_headcount['ct']) : 0
                    bisData[`${group.group_id}_ff`] =
                        'hr_headcount' in group ? parseFloat(group.hr_headcount['ff']) : 0
                })

                const groupOHData = { program_name: 'Total w/ OH' }
                // Go through all the existing headcount data and populate it in the row groups
                Array.from(response.data).forEach((headcountEstimate: any) => {
                    if ('headcount_type' in headcountEstimate) {
                        if (
                            headcountEstimate.headcount_type === 'ct' ||
                            headcountEstimate.headcount_type === 'ff'
                        ) {
                            const groupId = headcountEstimate.group_id
                            const type = headcountEstimate.headcount_type
                            headcountData.forEach((row) => {
                                if (
                                    row.program_id === headcountEstimate.program_id &&
                                    row.deliverable_id === headcountEstimate.deliverable_id
                                ) {
                                    const headcountValue = headcountEstimate.headcount_value
                                    const previousValue = headcountEstimate.previous_value
                                    row[`${groupId}_${type}`] = headcountValue
                                    row[`${groupId}_${type}_id`] = headcountEstimate.headcount_id
                                    row.total += headcountValue
                                    row[`total_${type}`] += headcountValue
                                    row.scoped = true
                                    row[`${groupId}_${type}_updated_by`] =
                                        headcountEstimate.updated_by
                                    row[`${groupId}_${type}_updated_at`] =
                                        headcountEstimate.updated_at
                                    row[`${groupId}_${type}_previous_value`] = previousValue
                                    row[`${groupId}_${type}_hc_val`] =
                                        headcountEstimate.headcount_value
                                    row[`${groupId}_${type}_fte_val`] =
                                        headcountEstimate.fte_month_value
                                    if (!groupOHData[`${groupId}_${type}`]) {
                                        groupOHData[`${groupId}_${type}`] = 0
                                    }
                                    groupOHData[`${groupId}_${type}`] +=
                                        headcountValue * (1 + planOverheadFactor)
                                }
                            })
                        }
                    }
                })

                const gapData = {}
                Object.entries(bisData).forEach((value: any) => {
                    const totalOHHC = groupOHData[value[0]] ?? 0
                    const bisHC = value[1]
                    gapData[value[0]] = totalOHHC - bisHC
                })
                gapData['program_name'] = 'Gap (Total w/ OH - BIS)'
                const rowdata = showScopedOnly
                    ? headcountData.filter((row) => row.total > 0)
                    : headcountData
                const filteredColDefs = colDef.filter((col) => {
                    const archived = col.headerName.includes('archived')
                    const groupId = col.field
                    const ctTotal = groupOHData[`${groupId}_ct`]
                    const ffTotal = groupOHData[`${groupId}_ff`]
                    if (!archived || (archived && ctTotal + ffTotal)) {
                        return col
                    }
                })
                gridApi.setGridOption('rowData', rowdata)
                gridApi.setGridOption('columnDefs', filteredColDefs)
                gridApi.setPinnedBottomRowData([bisData, groupOHData, gapData])
                gridApi.redrawRows()
            })
            .catch((err) => {
                console.error(err)
            })
    }

    const getHeadcountEstimateData = (
        gridApi: GridApi,
        planId: string,
        revisionId: string | undefined,
        orgId: string,
    ) => {
        if (!planId || !revisionId || !orgId || !gridApi) {
            dispatch({ type: ACTION_TYPES.HC, payload: { key: 'loading', data: false } })
            return
        }

        apiClient
            .get(`/plan-headcount-estimates?plan_id=${planId}&revision_id=${revisionId}&version=0`)
            .then((res) => {
                const summaryData = res.data
                gridApi.setGridOption('columnDefs', [])
                gridApi.setGridOption('rowData', [])
                const orgData = summaryData.filter((row) => row.org_id === orgId)
                const { flatData, groupOHData }: any = generateRows(
                    orgData,
                    data[ACTION_TYPES.DELIVERABLES].data,
                )
                const { colDef, bisData, gapData }: any = generateColDef(groupOHData)
                gridApi.setGridOption('columnDefs', colDef)
                gridApi.setGridOption('rowData', flatData)
                gridApi.setPinnedBottomRowData([bisData, groupOHData, gapData])
                gridApi.autoSizeAllColumns(true)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                dispatch({ type: ACTION_TYPES.HC, payload: { key: 'loading', data: false } })
            })
    }

    return {
        data,
        dispatch,
    }
}
