import { Box, Container, ContentLayout, Header, Link } from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { REQUEST_ACCESS_LINK } from '../../common/LinkUtil'

const Unauthorized = (businessEntity) => {
    const businessEntityName = businessEntity?.businessEntity?.business_entity_name || ''
    return (
        <ContentLayout header={<HeaderTemplate />}>
            <Box margin={{ left: 'm', right: 'm' }}>
                <Container>
                    <Container>
                        <Header variant={'h2'}>Insufficient Permissions</Header>
                        <>
                            {`You are not registered to any roles for ${
                                businessEntityName ? businessEntityName : 'Egret'
                            }. Please open a `}
                            <Link href={REQUEST_ACCESS_LINK}> ticket </Link>
                            {`to request access.`}
                        </>
                    </Container>
                </Container>
            </Box>
        </ContentLayout>
    )
}

export default Unauthorized
