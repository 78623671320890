import { Container, KeyValuePairs } from '@amzn/awsui-components-react'
import Modal from '@amzn/awsui-components-react/polaris/modal'
import { getProgramDetails } from './NewSummariesUtil'

export default (props: any) => {
    const { visible, setVisible, data}  = props
    return (
        <Modal
            visible={visible}
            onDismiss={() => setVisible(false)}
            header={`Program Details: ${data?.program_name}`}
        >
            
            <Container>
                <KeyValuePairs columns={3} items={getProgramDetails(data)} />
            </Container>
        </Modal>
    )
}
