import useStore from '../../../Store'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    DISCRETIONARY_SPEND_FILTERING_PROPERTIES,
    getMatchesCountText,
    PROPERTY_FILTER_I18NSTRING,
} from '../../reusable/UseCollectionUtil'
import {
    Alert,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    Select,
    SpaceBetween,
    Spinner,
    Table,
} from '@amzn/awsui-components-react'
import EmptyState from '../../reusable/EmptyState'
import { ALERT_TYPES, ALL_GROUP_SELECTION } from '../../../Constant'

interface DiscretionarySpendTableProps {
    selectedRevision: any
    onSelectedRevisionChange: (revision) => void
    revisionOptions: any[]
    program: any
    spends: any[]
    getSpends: (groupId: string, revisionId: string) => void
    selectedSpends: any[]
    onSelectedSpendsChange: (spends) => void
    isRevisionLocked: boolean
    onDelete: () => void
    isLoading: boolean
    columnDefinitions: any[]
    visibleColumns: any[]
    defaultVisibleColumns: any[]
    allGroupVisibleColumns: any[]
    onVisibleColumnsChange: (columns) => void
    selectedGroup: any
    onSelectedGroupChange: (group) => void
    groupOptions: any[]
    onMove: () => void
    selectedPlan: any
}

export const DiscretionarySpendTable = (props: DiscretionarySpendTableProps) => {
    const {
        selectedRevision,
        onSelectedRevisionChange,
        revisionOptions,
        program,
        spends,
        getSpends,
        selectedSpends,
        onSelectedSpendsChange,
        isRevisionLocked,
        onDelete,
        isLoading,
        columnDefinitions,
        visibleColumns,
        defaultVisibleColumns,
        allGroupVisibleColumns,
        onVisibleColumnsChange,
        selectedGroup,
        onSelectedGroupChange,
        groupOptions,
        onMove,
        selectedPlan,
    } = props

    const planId = selectedPlan.value.plan_id
    const history = useNavigate()
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const canAdmin = useStore((state) => state.canAdmin)

    const [preferences] = useState({ pageSize: 20 })

    const isButtonDisabled = () => {
        return (
            isRevisionLocked ||
            isLoading ||
            program.is_locked ||
            !program.is_active ||
            !selectedGroup?.object_id
        )
    }

    const actionButtonDropdown = [
        {
            text: 'Edit',
            id: 'edit',
            disabled: selectedSpends.length !== 1 || isButtonDisabled(),
        },
        {
            text: 'Delete',
            id: 'delete',
            disabled: selectedSpends.length === 0 || isButtonDisabled(),
        },
        {
            text: 'Move',
            id: 'move',
            disabled: selectedSpends.length === 0 || isButtonDisabled() || !canAdmin,
        },
    ]

    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(spends, {
        propertyFiltering: {
            filteringProperties: DISCRETIONARY_SPEND_FILTERING_PROPERTIES,
            empty: isLoading ? (
                <Spinner />
            ) : (
                <EmptyState
                    title='No expenses'
                    subtitle={
                        selectedBusinessEntity.business_entity_id
                            ? !selectedGroup?.object_id
                                ? 'Please select a group to view expenses.'
                                : 'No expenses to display. Create one!'
                            : 'Select business entity to view expense(s).'
                    }
                    action={
                        <Button
                            disabled={
                                isButtonDisabled() ||
                                selectedGroup.object_id === ALL_GROUP_SELECTION.value
                            }
                            onClick={() =>
                                history(
                                    `/plan/${planId}/revision/${selectedRevision.value ?? ''}/program/${program.program_id}/group/${selectedGroup.object_id}/discretionary-spend/create`,
                                )
                            }
                        >
                            Create New Expense
                        </Button>
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches expenses'
                    subtitle='We can not find a match expense.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: {},
            },
        },
        selection: {},
    })

    return (
        <SpaceBetween size={'m'}>
            {!selectedGroup?.object_id ? (
                <Alert>
                    {'Please select a group from the dropdown to view or modify expenses.'}
                </Alert>
            ) : program.is_locked ? (
                <Alert>{'View only mode for locked program.'}</Alert>
            ) : (
                <></>
            )}
            <Table
                {...collectionProps}
                items={items}
                stickyHeader={true}
                selectedItems={selectedSpends}
                onSelectionChange={({ detail }) => {
                    onSelectedSpendsChange(detail.selectedItems)
                }}
                isItemDisabled={(item: any) => {
                    return !item.ds_item_id
                }}
                loading={isLoading}
                wrapLines
                columnDefinitions={columnDefinitions}
                visibleColumns={visibleColumns}
                loadingText={'Loading Expenses'}
                selectionType={'multi'}
                columnDisplay={visibleColumns.map((vis) => ({
                    id: vis,
                    visible: true,
                }))}
                pagination={<Pagination {...paginationProps} />}
                filter={
                    <SpaceBetween direction={'horizontal'} size={'s'}>
                        <PropertyFilter
                            i18nStrings={PROPERTY_FILTER_I18NSTRING}
                            {...propertyFilterProps}
                            countText={getMatchesCountText(filteredItemsCount)}
                        />
                        <Select
                            selectedOption={{
                                label: selectedGroup.object_name,
                                value: selectedGroup.object_id,
                            }}
                            options={groupOptions}
                            onChange={({ detail }) => {
                                const value = detail.selectedOption.value
                                if (value === selectedGroup.object_id) {
                                    return
                                }

                                onSelectedGroupChange({
                                    object_id: value,
                                    object_name: detail.selectedOption.label,
                                })
                                if (value === ALL_GROUP_SELECTION.value) {
                                    onVisibleColumnsChange(allGroupVisibleColumns)
                                } else {
                                    onVisibleColumnsChange(defaultVisibleColumns)
                                }

                                history(
                                    `/plan/${planId}/revision/${selectedRevision.value ?? ''}/program/${
                                        program.program_id
                                    }/${!value ? '' : `group/${value}/`}discretionary-spend`,
                                )
                                getSpends(value || '', selectedRevision.value ?? '')
                                onSelectedSpendsChange([])
                            }}
                            disabled={!groupOptions.length || isLoading}
                            filteringType={'auto'}
                        />
                        <Select
                            placeholder={'Selected Plan'}
                            selectedOption={selectedPlan}
                            options={[]}
                            disabled={true}
                        />
                        <Select
                            selectedOption={selectedRevision}
                            onChange={({ detail }) => {
                                const option = detail.selectedOption
                                if (option.value === selectedRevision.value ?? '') {
                                    return
                                }
                                onSelectedRevisionChange(option)
                                const currentGpId = selectedGroup.object_id
                                history(
                                    `/plan/${planId}/revision/${
                                        detail.selectedOption.value
                                    }/program/${program.program_id}/${
                                        !currentGpId ? '' : `group/${currentGpId}/`
                                    }discretionary-spend`,
                                )
                                getSpends(selectedGroup.object_id, option.value || '')
                                onSelectedSpendsChange([])
                            }}
                            options={revisionOptions}
                            disabled={isLoading}
                        />{' '}
                    </SpaceBetween>
                }
                header={
                    <SpaceBetween size='s'>
                        {selectedGroup.object_id === ALL_GROUP_SELECTION.value && (
                            <Alert type={ALERT_TYPES.WARNING}>
                                Create expense disabled when all group selected.
                            </Alert>
                        )}
                        <Header
                            variant='h2'
                            actions={
                                <SpaceBetween size='s' direction='horizontal'>
                                    <Button
                                        variant='primary'
                                        onClick={() =>
                                            history(
                                                `/plan/${planId}/revision/${selectedRevision.value ?? ''}/program/${program.program_id}/group/${selectedGroup.object_id}/discretionary-spend/create`,
                                            )
                                        }
                                        disabled={
                                            isButtonDisabled() ||
                                            selectedGroup.object_id === ALL_GROUP_SELECTION.value
                                        }
                                    >
                                        {'Create Expense'}
                                    </Button>
                                    <ButtonDropdown
                                        items={actionButtonDropdown}
                                        onItemClick={({ detail }) => {
                                            if (detail.id === 'edit') {
                                                history(
                                                    `/plan/${planId}/revision/${selectedRevision.value ?? ''}/program/${program.program_id}/group/${selectedSpends[0].group_id}/discretionary-spend/${selectedSpends[0].ds_item_id}`,
                                                )
                                            } else if (detail.id === 'delete') {
                                                onDelete()
                                            } else if (detail.id === 'move') {
                                                onMove()
                                            }
                                        }}
                                        disabled={isLoading}
                                    >
                                        Actions
                                    </ButtonDropdown>
                                </SpaceBetween>
                            }
                        ></Header>
                    </SpaceBetween>
                }
            />
        </SpaceBetween>
    )
}

export default DiscretionarySpendTable
