import { Box, Header } from '@amzn/awsui-components-react'

import EnhancedBreadcrumbGroup from './EnhancedBreadcrumbGroup'

interface HeaderTemplateProps {
    items?: any[]
    actions?: any[]
    title?: string
    subtitle?: string
}

const HeaderTemplate = (props: HeaderTemplateProps) => {
    const { items, actions, title, subtitle } = props
    return (
        <Box margin={{ top: 's', left: 's', right: 's' }}>
            <EnhancedBreadcrumbGroup items={items} />
            <Header variant='h3' actions={actions}>
                {title || (items && items[items.length - 1].text) || 'Home'}
            </Header>
            {subtitle && <>{subtitle}</>}
        </Box>
    )
}

export default HeaderTemplate
