import {
    Box,
    Container,
    FormField,
    Grid,
    Header,
    SpaceBetween,
    Spinner,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import EmptyState from '../reusable/EmptyState'
import ExportTable from '../reusable/ExportTable'
import { generateExportVisibleColumns, generatePlanProgramHCColumnDef } from './SummaryUtil'
import { useCollection } from '@amzn/awsui-collection-hooks'

const PROGRAM_PRIORITY_INDEX = 0

interface PlanSummaryProgramHCTableProps {
    data: any
    isLoading: boolean
    planName: string
    programHCToggleChange: (checked: boolean) => void
    programHCToggle: boolean
}

const PlanSummaryProgramHCTable = (props: PlanSummaryProgramHCTableProps) => {
    const { data, isLoading, planName, programHCToggleChange, programHCToggle } = props
    const { items, allPageItems, collectionProps } = useCollection(data, {
        propertyFiltering: {
            filteringProperties: [],
            empty: isLoading ? (
                <Spinner />
            ) : (
                <EmptyState
                    title='No Program HC Summary Data.'
                    subtitle={`No Program HC data found for ${planName}`}
                    action={[]}
                />
            ),
        },
        sorting: {
            defaultState: {
                sortingColumn: generatePlanProgramHCColumnDef(planName)[PROGRAM_PRIORITY_INDEX],
            },
        },
    })

    return (
        <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }, { colspan: 2 }]}>
            <div></div>
            <Container>
                <Table
                    {...collectionProps}
                    columnDefinitions={generatePlanProgramHCColumnDef(planName)}
                    loadingText={`Loading Program HC Summary...`}
                    contentDensity={'compact'}
                    variant={'embedded'}
                    items={items}
                    loading={isLoading}
                    wrapLines
                    header={
                        <Grid gridDefinition={[{ colspan: 5 }, { colspan: 3 }, { colspan: 4 }]}>
                            <Header>
                                <Box variant={'h1'} margin={{ left: 'xxl' }} float={'left'}>
                                    {planName} Program HC Summary
                                </Box>
                            </Header>
                            <div></div>
                            <Box float={'right'} margin={'xl'}>
                                <SpaceBetween direction={'horizontal'} size={'xxl'}>
                                    <FormField label={'HC Estimated'}>
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <span>All</span>
                                            <Toggle
                                                checked={programHCToggle}
                                                onChange={({ detail }) => {
                                                    programHCToggleChange(detail.checked)
                                                }}
                                            >
                                                Estimated
                                            </Toggle>
                                        </SpaceBetween>
                                    </FormField>
                                    <ExportTable
                                        tableData={allPageItems}
                                        tableColumnDef={generatePlanProgramHCColumnDef(planName)}
                                        fileName={'EgretPlanProgramHCSummary'}
                                        visibleColumns={generateExportVisibleColumns(
                                            generatePlanProgramHCColumnDef(planName),
                                        )}
                                    />
                                </SpaceBetween>
                            </Box>
                        </Grid>
                    }
                />
            </Container>
            <div></div>
        </Grid>
    )
}

export default PlanSummaryProgramHCTable
