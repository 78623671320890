import { useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Spinner,
    Table,
} from '@amzn/awsui-components-react'
import {
    PLAN_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
    getMatchesCountText,
} from '../reusable/UseCollectionUtil'
import { NO_USER_BUSINESS_ENTITY } from '../../Constant'
import useStore from '../../Store'

interface PlanListProps {
    plans: any[]
    canAdmin: boolean
    columns: any[]
    visibleColumns: string[]
    selectedPlanMetadata: any
    setSelectedPlanMetadata: any
    onCreate: any
    onEdit: any
    onDelete: any
    isLoading: boolean
}

const PlanList = ({
    plans,
    canAdmin,
    columns,
    visibleColumns,
    selectedPlanMetadata,
    setSelectedPlanMetadata,
    onCreate,
    onEdit,
    onDelete,
    isLoading,
}: PlanListProps) => {
    const [preferences] = useState({ pageSize: 20 })
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const validBE =
        selectedBusinessEntity.business_entity_id &&
        selectedBusinessEntity.business_entity_id !== NO_USER_BUSINESS_ENTITY
    const [deleteMenuText, setDeleteMenuText] = useState('Delete') // es-lint-disable-line @typescript-eslint/no-unused-vars
    const {
        items,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(plans, {
        propertyFiltering: {
            filteringProperties: PLAN_FILTERING_PROPERTIES,
            empty: isLoading ? (
                <Spinner />
            ) : (
                <EmptyState
                    title='No plans.'
                    subtitle={
                        validBE
                            ? `No plans to display. ${canAdmin ? 'Create one!' : ''}`
                            : 'Select your business entity to see plans.'
                    }
                    action={
                        validBE && canAdmin ? (
                            <Button onClick={onCreate}>Create a new plan</Button>
                        ) : (
                            <></>
                        )
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        selection: plans.length >= preferences.pageSize ? {} : undefined,
        pagination: { pageSize: preferences.pageSize },
    })

    return (
        <Table
            {...collectionProps}
            items={items}
            trackBy='plan_id'
            stickyHeader={true}
            selectedItems={selectedPlanMetadata ? [selectedPlanMetadata] : []}
            onSelectionChange={({ detail }) => {
                setSelectedPlanMetadata(
                    detail.selectedItems.length ? detail.selectedItems[0] : undefined,
                )
            }}
            loading={isLoading}
            columnDefinitions={columns}
            loadingText={'Loading Plans'}
            visibleColumns={visibleColumns}
            selectionType={canAdmin ? 'single' : undefined}
            pagination={
                <Pagination
                    {...{
                        ...paginationProps,
                        disabled: plans.length < preferences.pageSize,
                    }}
                />
            }
            filter={
                <SpaceBetween direction={'horizontal'} size={'xs'}>
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTER_I18NSTRING}
                        {...propertyFilterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                </SpaceBetween>
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {canAdmin ? (
                                <Button
                                    variant='primary'
                                    onClick={onCreate}
                                    disabled={!validBE || isLoading}
                                >
                                    {'Create Plan'}
                                </Button>
                            ) : (
                                <></>
                            )}
                            {canAdmin ? (
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Edit',
                                            id: 'edit_plan',
                                            disabled: !selectedPlanMetadata,
                                        },
                                        {
                                            text: deleteMenuText,
                                            id: 'delete_plan',
                                            disabled: !selectedPlanMetadata,
                                        },
                                    ]}
                                    onItemClick={({ detail }) => {
                                        if (detail.id === 'edit_plan') {
                                            onEdit()
                                        } else if (detail.id === 'delete_plan') {
                                            onDelete()
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            ) : (
                                <></>
                            )}
                        </SpaceBetween>
                    }
                ></Header>
            }
        />
    )
}

export default PlanList
