import { useEffect, useState } from 'react'
import {
    Box,
    SpaceBetween,
    Button,
    Header,
    Drawer,
    TextContent,
} from '@amzn/awsui-components-react'
import { Avatar, Autocomplete, TextField, Chip } from '@mui/material'
import GenericSummaryTable from '../reusable/GenericSummaryTable'
import {
    filterSandboxHistory,
    formatHistoryPanelSearchOptions,
    generateSandboxHistoryBadges,
    getDifferenceArray,
    HEADCOUNT_TYPE,
} from './SandboxUtils'
import {
    HISTORY_BADGE_BLUE,
    HISTORY_BADGE_GREY,
    HISTORY_PANEL_GROUP_MAX_TEXT_LENGTH,
    HISTORY_PANEL_ORG_MAX_WIDTH,
    HISTORY_PANEL_PROG_DELI_MAX_TEXT_LENGTH,
} from '../summary/SummaryUtil'

const SandboxHistoryPanel = (props) => {
    const {
        setVisible,
        getSandboxHeadcounts,
        totalSandboxHeadcounts,
        setTotalSandboxHeadcounts,
        sandboxFilteredHeadcounts,
        setSandboxFilteredHeadcounts,
        newSandboxHeadcounts,
        setNewSandboxHeadcounts,
        historyPanelFilters,
        setHistoryPanelFilters,
        showFTEEstimation,
        activeUsers,
        selectedOrg,
        selectedPlan,
        orgGroups,
        programDeliverables,
    } = props
    const historyColumns = [
        {
            id: 'previous_value',
            header: 'Previous',
            cell: (item) =>
                (showFTEEstimation ? item.previous_value : item.previous_fte_month_value).toFixed(
                    2,
                ),
            sortingField: 'previous_value',
        },
        {
            id: 'headcount_value',
            header: 'Current',
            cell: (item) =>
                (showFTEEstimation ? item.headcount_value : item.fte_month_value).toFixed(2),
            sortingField: 'headcount_value',
        },
    ]
    const [searchOptions, setSearchOptions] = useState<any>([])
    const [sandboxHistoryEntries, setSandboxHistoryEntries] = useState<any>(<></>)
    const [userColorCodes, setUserColorCodes] = useState<any>(activeUsers)
    useEffect(() => {
        generateSearchOptions()
    }, [orgGroups, programDeliverables, activeUsers])

    useEffect(() => {
        if (!selectedPlan || !selectedOrg) {
            return
        }
        getSandboxHeadcounts()
    }, [selectedOrg, selectedPlan])

    useEffect(() => {
        if (!newSandboxHeadcounts.length) {
            return
        }
        // when new headcounts get added: show all new headcounts if no filters are set or show headcounts that matches the set filters (if any)
        const newEntries = getDifferenceArray(newSandboxHeadcounts, totalSandboxHeadcounts)
        setTotalSandboxHeadcounts(newEntries.concat(totalSandboxHeadcounts))
        if (historyPanelFilters.length && newSandboxHeadcounts.length) {
            const filteredNewHeadcounts = filterResult(historyPanelFilters, newEntries)
            setSandboxFilteredHeadcounts(sandboxFilteredHeadcounts.concat(filteredNewHeadcounts))
        }
    }, [newSandboxHeadcounts])

    useEffect(() => {
        // if there are filters, populate headcount panel with filtered items, if not, populate with all sandbox headcounts
        setSandboxHistoryEntries(
            generateHistoryHeadcountTables(
                historyPanelFilters.length ? sandboxFilteredHeadcounts : totalSandboxHeadcounts,
            ),
        )
    }, [sandboxFilteredHeadcounts, totalSandboxHeadcounts, historyPanelFilters, showFTEEstimation])

    const generateSearchOptions = () => {
        if (!orgGroups || !programDeliverables) {
            return []
        }
        const groupOptions = formatHistoryPanelSearchOptions(orgGroups, 'group_name', 'Group')
        const programOptions = formatHistoryPanelSearchOptions(
            Object.keys(programDeliverables),
            '',
            'Program',
        )
        let deliverableOptions = []
        Object.keys(programDeliverables).forEach((program) => {
            deliverableOptions = deliverableOptions.concat(
                programDeliverables[program].map((deliverable) => {
                    return {
                        label: deliverable.deliverable_name,
                        type: 'Deliverable',
                    }
                }),
            )
        })
        deliverableOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))
        const allUsers: Set<string> = new Set(
            totalSandboxHeadcounts
                .flatMap((headcount) => headcount.updated_by)
                .concat(Object.keys(activeUsers)),
        )
        const userOptions = formatHistoryPanelSearchOptions(Array.from(allUsers), '', 'User')
        setSearchOptions(groupOptions.concat(programOptions, deliverableOptions, userOptions))
        const userColorCodes = {}
        allUsers.forEach((user) => {
            if (Object.keys(activeUsers).includes(user)) {
                userColorCodes[user] = activeUsers[user]
            } else {
                userColorCodes[user] = `#${Math.floor(Math.random() * 16777215).toString(16)}`
            }
        })
        setUserColorCodes(userColorCodes)
    }

    const generateHistoryHeadcountTables = (estimates) => {
        const generateUniqueHistoryTableKey = (data, keyId) => {
            return `${data.headcount_id}#${data.updated_at}#${keyId}`
        }

        const sortedEstimates = estimates
            .filter(
                (est) =>
                    est.org_id === selectedOrg.object_id && est.plan_id === selectedPlan.plan_id,
            )
            .sort((a, b) => a.updated_at.localeCompare(b.updated_at))
            .reverse()

        return sortedEstimates.map((data, index) => {
            const date = data.updated_at.split('T')[0]
            const time = data.updated_at.split('T')[1].split(':')
            return (
                <Box key={generateUniqueHistoryTableKey(data, index)} padding={{ vertical: 's' }}>
                    <SpaceBetween size={'s'} direction={'vertical'}>
                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                            {generateSandboxHistoryBadges(
                                selectedOrg.object_name,
                                HISTORY_BADGE_GREY,
                                HISTORY_PANEL_ORG_MAX_WIDTH,
                            )}
                            {generateSandboxHistoryBadges(
                                data.group_name,
                                HISTORY_BADGE_GREY,
                                HISTORY_PANEL_GROUP_MAX_TEXT_LENGTH,
                            )}
                        </SpaceBetween>
                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                            {generateSandboxHistoryBadges(
                                data.program_name,
                                HISTORY_BADGE_BLUE,
                                HISTORY_PANEL_PROG_DELI_MAX_TEXT_LENGTH,
                            )}
                            {generateSandboxHistoryBadges(
                                data.deliverable_name,
                                HISTORY_BADGE_BLUE,
                                HISTORY_PANEL_PROG_DELI_MAX_TEXT_LENGTH,
                            )}
                        </SpaceBetween>
                        <Chip
                            variant={'outlined'}
                            label={
                                <TextContent>
                                    <p>{HEADCOUNT_TYPE[data.headcount_type]}</p>
                                </TextContent>
                            }
                        />
                        <GenericSummaryTable
                            itemsToShow={[data]}
                            actions={[]}
                            columns={historyColumns}
                            visibleColumns={[
                                { id: 'previous_value', visible: true },
                                { id: 'headcount_value', visible: true },
                            ]}
                            defaultNameField={''}
                            nameField={''}
                            isLoading={![data].length}
                            objectType={'Headcount'}
                            includeHeader={true}
                            wrapLines
                            variant={'borderless'}
                            includePagination={false}
                            customHeader={
                                <Header
                                    actions={
                                        <Box
                                            float={'right'}
                                            variant={'small'}
                                            margin={{ top: 's' }}
                                        >
                                            {date} {time[0]}:{time[1]}{' '}
                                        </Box>
                                    }
                                >
                                    <SpaceBetween size={'m'} direction={'horizontal'}>
                                        <SpaceBetween size={'xxs'} direction={'horizontal'}>
                                            <Avatar
                                                sx={{
                                                    marginTop: 0.5,
                                                    height: 25,
                                                    width: 25,
                                                    bgcolor: userColorCodes[data.updated_by],
                                                }}
                                            />
                                            <Box
                                                margin={{ vertical: 'xs' }}
                                                variant={'h5'}
                                                fontWeight={'bold'}
                                            >
                                                {data.updated_by}
                                            </Box>
                                        </SpaceBetween>
                                    </SpaceBetween>
                                </Header>
                            }
                        />
                        <hr />
                    </SpaceBetween>
                </Box>
            )
        })
    }

    const filterResult = (filters, itemsToFilter) => {
        let filteredResult: any[] = []
        filters.forEach((value: any) => {
            const filteredItems = filterSandboxHistory(itemsToFilter, value)
            const difference = getDifferenceArray(filteredItems, filteredResult)
            filteredResult = filteredResult.concat(difference)
        })
        return filteredResult
    }

    return (
        <Drawer
            header={
                <Header
                    actions={
                        <SpaceBetween direction='horizontal' size='xxs'>
                            <Button
                                variant='icon'
                                iconName='upload'
                                disabled
                                disabledReason={'To be implemented!'}
                            >
                                Export
                            </Button>
                            <Button
                                iconName='close'
                                variant='icon'
                                iconAlign={'right'}
                                onClick={() => {
                                    setVisible((prev) => !prev)
                                    setSandboxFilteredHeadcounts([])
                                    setNewSandboxHeadcounts([])
                                    setHistoryPanelFilters([])
                                }}
                            />
                        </SpaceBetween>
                    }
                >
                    History
                </Header>
            }
        >
            <Box margin={{ top: 's' }}>
                <SpaceBetween size={'s'} direction={'vertical'}>
                    <Autocomplete
                        multiple
                        value={historyPanelFilters}
                        options={searchOptions}
                        groupBy={(option: any) => option.type}
                        onChange={(event, newValue) => {
                            setHistoryPanelFilters(newValue)
                            if (!newValue.length) {
                                // if filters are cleared, reset the panel contents to show all sandbox headcounts
                                setSandboxFilteredHeadcounts(totalSandboxHeadcounts)
                                return
                            }
                            setSandboxFilteredHeadcounts(
                                filterResult(newValue, totalSandboxHeadcounts),
                            )
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Search History'
                                placeholder='Groups, Programs, Deliverables'
                            />
                        )}
                    />
                    <span>{sandboxHistoryEntries}</span>
                </SpaceBetween>
            </Box>
        </Drawer>
    )
}
export default SandboxHistoryPanel
