import { generateBannerContent } from './SandboxUtils'
import { ALERT_TYPES } from '../../Constant'

export const validationNotInProgress = (
    successValidationCount,
    failValidationCount,
    isValidationComplete,
) => {
    return !successValidationCount && !failValidationCount && isValidationComplete
}

export const fileTypeValidation = (value) => {
    const fileType = value[0]?.name?.split('.')[1]
    return fileType === 'xlsx'
}

export const importStepOneValidation = (importFileErrors, fileValue, setBannerItems) => {
    /* step one of import modal will not be cleared if:
        1. imported file has errors (i.e. wrong file type)
        2. there are no selected files to be importing from
    * */
    let bannerMessage = ''
    if (!fileValue.length) {
        bannerMessage = 'Please upload a file to proceed.'
    } else if (importFileErrors.length) {
        bannerMessage = 'Please ensure uploaded file is of correct type.'
    }
    if (bannerMessage) {
        setBannerItems([
            generateBannerContent(
                'error_message_1',
                ALERT_TYPES.ERROR,
                bannerMessage,
                setBannerItems,
                'Unable to proceed',
            ),
        ])
    }
    return bannerMessage === ''
}

export const importStepTwoValidation = (
    successValidationCount,
    failValidationCount,
    isValidationComplete,
    setBannerItems,
) => {
    /* step two of import modal will not be cleared if:
       1. validation has not been initiated on the selected import file
       2. if there are failed validations
   * */
    let bannerMessage = ''
    if (
        validationNotInProgress(successValidationCount, failValidationCount, isValidationComplete)
    ) {
        bannerMessage = 'Please run validations before proceeding.'
    } else if (failValidationCount) {
        bannerMessage = 'Please fix validation errors before proceeding with import.'
    }
    if (bannerMessage) {
        setBannerItems([
            generateBannerContent(
                'error_message_1',
                ALERT_TYPES.ERROR,
                bannerMessage,
                setBannerItems,
                'Unable to proceed',
            ),
        ])
    }

    return bannerMessage === ''
}
