import HeaderTemplate from '../reusable/HeaderTemplate'

import { Alert, Box, Container, ContentLayout, SpaceBetween } from '@amzn/awsui-components-react'
import LinkButton from '../reusable/LinkButton'

const NoRoutePage = () => {
    return (
        <ContentLayout
            header={
                <HeaderTemplate
                    items={[
                        { text: 'Home', href: '/' },
                        { text: 'Not Found Page', href: '/' },
                    ]}
                />
            }
        >
            <Box margin={{ left: 's', right: 's' }}>
                <Container>
                    <Alert dismissAriaLabel='Close alert' header='404 Page'>
                        <SpaceBetween size='m' direction='vertical'>
                            Oops, the page you requested was not found.
                            <LinkButton content='Home Page' path='/' />
                        </SpaceBetween>
                    </Alert>
                </Container>
            </Box>
        </ContentLayout>
    )
}

export default NoRoutePage
