import { COMPONENT_TYPES, PROGRAM_ATTRIBUTE_TYPES, STATUS_INDICATOR_TYPES } from '../../../Constant'
import {
    Box,
    Link,
    Popover,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
    Table,
    TextContent,
} from '@amzn/awsui-components-react'
import DatePopover from '../../reusable/DatePopover'
import { convertToLocalTime, convertToMoneyFormat, getLeadingNumZeros } from '../../reusable/Utils'
import { COST_CENTER_OPTIONS, EXPENSE_CATEGORY_OPTIONS } from './DiscretionarySpendConstant'
import './DiscretionarySpendAttributes.scss'
import Button from '@amzn/awsui-components-react/polaris/button'
import { sortNumericStrings, styleHeaderWithNoWrap } from '../../../common/Util'

interface SpendAttribute {
    id: string
    headerName?: string
    description?: string
    componentType?: COMPONENT_TYPES
    attributeType: PROGRAM_ATTRIBUTE_TYPES
    selections?: SelectProps.Option[]
    tableVisible: boolean
    required: boolean
    inPayload: boolean
    checkDuplicate?: boolean
    defaultValue?: any
    disabled?: boolean
}
export const DS_PREFIX = 'ds'
export const DS_TABLE = 'OP-Estimates'

export const spendAttributes: SpendAttribute[] = [
    {
        id: 'ds_item_id',
        headerName: 'Expense ID',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.INPUT_STRING,
        tableVisible: false,
        required: false,
        inPayload: true,
        disabled: true,
    },
    {
        id: 'ds_item_id_display',
        headerName: 'Expense ID',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.INPUT_STRING,
        tableVisible: true,
        required: false,
        inPayload: true,
        disabled: true,
    },
    {
        id: 'expense_title',
        headerName: 'Expense Title',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.INPUT_STRING,
        tableVisible: true,
        required: true,
        inPayload: true,
        checkDuplicate: true,
    },
    {
        id: 'description',
        headerName: 'Description',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.TEXTAREA,
        tableVisible: true,
        required: true,
        inPayload: true,
    },
    {
        id: 'expense_category',
        headerName: 'Expense Category',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.SELECT,
        selections: EXPENSE_CATEGORY_OPTIONS,
        tableVisible: true,
        required: false,
        inPayload: true,
    },
    {
        id: 'expense_type',
        headerName: 'Expense Type',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.INPUT_STRING,
        tableVisible: true,
        required: false,
        inPayload: true,
    },
    {
        id: 'cost_center',
        headerName: 'Cost Center',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.SELECT,
        selections: COST_CENTER_OPTIONS,
        tableVisible: true,
        required: false,
        inPayload: true,
    },
    {
        id: 'expense_note',
        headerName: 'Expense Notes',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.TEXTAREA,
        tableVisible: false,
        required: false,
        inPayload: true,
    },
    {
        id: 'revision_note',
        headerName: 'Revision Notes',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.TEXTAREA,
        tableVisible: false,
        required: false,
        inPayload: true,
    },
]

const months = [
    ['jan_expenditure', 'Jan'],
    ['feb_expenditure', 'Feb'],
    ['mar_expenditure', 'Mar'],
    ['apr_expenditure', 'Apr'],
    ['may_expenditure', 'May'],
    ['jun_expenditure', 'Jun'],
    ['jul_expenditure', 'Jul'],
    ['aug_expenditure', 'Aug'],
    ['sep_expenditure', 'Sep'],
    ['oct_expenditure', 'Oct'],
    ['nov_expenditure', 'Nov'],
    ['dec_expenditure', 'Dec'],
]

export const monthsVisibleColumns: any[] = months.map((month) => {
    return month[0]
})

export const monthsAttributes = () => {
    return months.flatMap((month) => [
        {
            id: month[0],
            headerName: month[1],
            attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
            componentType: COMPONENT_TYPES.INPUT_FLOAT,
            tableVisible: true,
            required: false,
            inPayload: true,
        },
    ])
}
export const forceTotalsRowToEndWithStringInput = (item1, item2, colName) => {
    if (!item1.ds_item_id) {
        return 1
    }
    if (!item2.ds_item_id) {
        return -1
    }
    return item1[colName]?.toLowerCase() < item2[colName]?.toLowerCase() ? -1 : 1
}

export const forceTotalsRowToEndWithNumericInput = (item1, item2, colName) => {
    if (!item1.ds_item_id) {
        return 1
    }
    if (!item2.ds_item_id) {
        return -1
    }
    return sortNumericStrings(item1[colName] ?? '0', item2[colName] ?? '0')
}

export const getMonthColumnDefinitions = () => {
    return monthsAttributes().flatMap((attr) => [
        {
            id: attr.id,
            header: `${attr.headerName}`,
            cell: (item) =>
                !item.ds_item_id ? (
                    <h4>{convertToMoneyFormat(renderCell(item, attr))}</h4>
                ) : (
                    convertToMoneyFormat(renderCell(item, attr))
                ),
            sortingComparator: (item1, item2) =>
                forceTotalsRowToEndWithNumericInput(item1, item2, attr.id),
            minWidth: 90,
            sortingField: attr.id,
        },
    ])
}

export const justificationAttributes: SpendAttribute[] = [
    {
        id: 'q1_justification',
        headerName: 'Justification Summary',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.TEXTAREA,
        tableVisible: false,
        required: false,
        inPayload: true,
    },
    {
        id: 'q2_result',
        headerName: 'What happens if approval is rejected?',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.TEXTAREA,
        tableVisible: false,
        required: false,
        inPayload: true,
    },
    {
        id: 'q3_other_programs',
        headerName: 'Select other programs benefiting from this expense',
        description: 'test description',
        attributeType: PROGRAM_ATTRIBUTE_TYPES.LOCAL,
        componentType: COMPONENT_TYPES.SELECT,
        tableVisible: false,
        required: false,
        inPayload: true,
    },
]

const renderCell = (item, attr) => {
    if (attr.id === 'expense_title' && (item.expense_note || item.revision_note)) {
        return (
            <Popover
                dismissButton={false}
                position='top'
                size='medium'
                triggerType='custom'
                content={
                    <SpaceBetween size='l'>
                        <Box>
                            <Box variant='awsui-key-label'>Expense Note</Box>
                            <Box>{item.expense_note}</Box>
                        </Box>
                        <div>
                            <Box variant='awsui-key-label'>Revision Note</Box>
                            <Box>{item.revision_note}</Box>
                        </div>
                    </SpaceBetween>
                }
            >
                <input id={'expense_title'} value={item[attr.id]} required />
            </Popover>
        )
    }

    switch (attr.componentType) {
        case COMPONENT_TYPES.TOGGLE:
            return item[attr.id] ? (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
            ) : (
                <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
            )
        case COMPONENT_TYPES.SELECT: {
            const value = attr.selections
                ? attr.selections.find((option) => option.value === item[attr.id])
                : undefined
            return value ? value.label : ''
        }
        case COMPONENT_TYPES.DATE:
            return <DatePopover date={convertToLocalTime(item[attr.id])} />
        case COMPONENT_TYPES.LINK:
            return (
                <Link external href={item[attr.id]}>
                    {item[attr.id]}
                </Link>
            )
        case COMPONENT_TYPES.TOKEN_GROUP:
            return item[attr.id].join(', ')
        default:
            return item[attr.id]
    }
}

export const getSpendColumnDefinitions = () => {
    return spendAttributes.flatMap((attr) => [
        {
            id: attr.id,
            header: styleHeaderWithNoWrap(attr.headerName),
            cell: (item) => renderCell(item, attr),
            sortingComparator: (item1, item2) =>
                forceTotalsRowToEndWithStringInput(item1, item2, attr.id),
            minWidth: attr.id === 'expense_type' ? 90 : 150,
            sortingField: attr.id,
        },
    ])
}

export const getSpendVisibleColumns = () => {
    return spendAttributes.flatMap((attr) => (attr.tableVisible ? [attr.id] : []))
}

// based on https://quip-amazon.com/WRziAQAUpgHc/Discretionary-Spend-Changes-Needed
export const EXPENSE_ID_STRING_LENGTH = 5

export const addHumanReadableExpenseId = (currentSpends, planYear) => {
    const sortedSpends = currentSpends
        .map((spend) => ({
            ...spend,
            created_at: spend.created_at ? new Date(spend.created_at) : -1,
        }))
        .sort((spend1, spend2) => (spend1.created_at < spend2.created_at ? -1 : 1))
    return sortedSpends.map((spend, idx) => ({
        ...spend,
        ds_item_id_display: `${planYear}-${getLeadingNumZeros(idx + 1, EXPENSE_ID_STRING_LENGTH)}`,
    }))
}

// based on https://quip-amazon.com/rbK1Aq0W7Qbo/Remarks-on-Egret-tool-29-Sep
const POPOVER_COLUMN_NAMES = ['Expense Category', 'Examples']
export const MONTH_POPOVERS = {
    ['expense_title']: 'Title in 2-3 words',
    ['expense_category']: (
        <Table
            variant={'borderless'}
            wrapLines={true}
            contentDensity={'compact'}
            sortingDisabled={true}
            columnDefinitions={['expense_category', 'examples'].map((colId, colIndex) => ({
                header: POPOVER_COLUMN_NAMES[colIndex],
                id: colId,
                cell: (item: any) => item[colId],
            }))}
            items={[
                {
                    expense_category: 'Temp & Contract Labor',
                    examples: 'Temporary employees, temporary  coaches',
                },
                {
                    expense_category: 'Professional  Services',
                    examples: (
                        <TextContent>
                            <p>
                                Yellow badged contingent workers (eg: Project-Based Contractor-PBC,
                                Outsourced Service Provider-OSP);
                            </p>
                            <p>3P operators;</p>
                            <p>Specialized service providers (eg: design work)</p>
                        </TextContent>
                    ),
                },
                {
                    expense_category: 'Prototype  Materials',
                    examples:
                        'Tangible assets  intended for prototyping work, with no alternative future use outside of  Research and Development',
                },
                {
                    expense_category: 'Software  Licenses',
                    examples: 'Software  licenses',
                },
                {
                    expense_category: 'Equipment  (Opex)',
                    examples: 'Equipment <$500 or  with <1 year useful life',
                },
                {
                    expense_category: 'Equipment  (Capex)',
                    examples: 'Capitalized equipment  (>$500 with >1 year useful life)',
                },
                {
                    expense_category: 'Tooling Assets',
                    examples: 'Capitalized tooling  or fixtures (>$500 with >1 year useful life)',
                },
                {
                    expense_category: 'Technology  Infrastructure',
                    examples: 'IT Equipment',
                },
                {
                    expense_category: 'Leasehold  Improvements',
                    examples: 'Building improvement  projects',
                },
            ]}
        />
    ),
    ['description']: (
        <TextContent>
            <Box>Please describe:</Box>
            <Box margin={'xs'}>What is it we intend to purchase</Box>
            <Box margin={'xs'}>Why we intend to purchase it</Box>
            <Box margin={'xs'}>The Project(s) it is intended for</Box>
            <Box margin={'xs'}>
                The type of expense (Engineering services/Engineering Materials/SW license)
            </Box>
            <Box margin={'xs'}>
                An approximate breakdown of the underlying calculations, for example for the
                purchase of:
            </Box>
            <Box margin={'xs'}>
                <ul>
                    <li>
                        Services based on deliverables: what would be the equivalent number of
                        hours, at $ per hour + z% contingency/buffer for travel and scope increase
                        (if supplier provided that information).
                    </li>
                    <li>Software licenses: x licenses at $y each, covering z months of usage</li>
                    <li>Engineering Alpha Builds: x stations at $y per station + $z for install</li>
                </ul>
            </Box>
        </TextContent>
    ),
    ['expense_note']:
        'This is a free form notes to comment on the expense, for example whether it is a one-time or recurrent purchase',
    ['revision_note']:
        'This is to comment on the change we need to make on a previously entered expense, for example for a quarterly guidance',
    ['q1_justification']: (
        <>
            Please refer to the{' '}
            <Link
                external={true}
                href={'https://quip-amazon.com/nD46AzjB3Iuk/Approval-Justification-examples'}
            >
                {' '}
                Approval Justification Examples
            </Link>
        </>
    ),
    ['q2_result']: (
        <>
            Please refer to the{' '}
            <Link
                external={true}
                href={'https://quip-amazon.com/nD46AzjB3Iuk/Approval-Justification-examples'}
            >
                {' '}
                Approval Justification Examples
            </Link>
        </>
    ),
}
const createPopover = (content, fixedWidth) => {
    return (
        <Popover
            dismissButton={true}
            triggerType='custom'
            content={content}
            size={'large'}
            fixedWidth={fixedWidth}
        >
            <Button variant='icon' iconName='status-info'></Button>
        </Popover>
    )
}
export const createInfoContent = (colId) => {
    if (MONTH_POPOVERS[colId] === undefined) {
        return undefined
    }
    return createPopover(MONTH_POPOVERS[colId], colId === 'expense_category')
}

export const createCostCenterInfo = (userAlias, phoneToolLink) => {
    const content = (
        <>
            Please check the cost center on your phone tool page,{' '}
            <Link external={true} href={phoneToolLink}>
                {' '}
                {userAlias}
            </Link>
            <>{". It's append to your org, just under your name."}</>
        </>
    )
    return createPopover(content, false)
}
