import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import qs from 'qs'
import { PLAN, REVISION, SUMMARY_TABS } from './Constant'
import { COLUMNS, PROGRAM, ROWS, TAB } from './apps/summary/PreferencesUtil'

interface QueryObject {
    [SUMMARY_TABS.GROUP]?: string
    [SUMMARY_TABS.ORG]?: string
    [COLUMNS]?: string
    [ROWS]?: string
    [TAB]?: string
    [PROGRAM]?: string
    [PLAN]?: string
    [REVISION]?: string
    [SUMMARY_TABS.SPEND]?: string
}

export const useQueryState = (): [any, (value: QueryObject) => void] => {
    const location = useLocation()
    const navigate = useNavigate()

    const setQuery = useCallback(
        (value) => {
            if (!value) return

            const existingQueries = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            })

            const newQueries = {}
            Object.entries(value).forEach(([key, val]) => {
                if (val) {
                    newQueries[key] = val
                }
            })

            const queryString = qs.stringify(
                { ...existingQueries, ...newQueries },
                { skipNulls: true },
            )

            navigate(`${location.pathname}?${queryString}`)
        },
        [navigate, location],
    )

    return [qs.parse(location.search, { ignoreQueryPrefix: true }), setQuery]
}

export function useQuery() {
    return new URLSearchParams(useLocation().search)
}
