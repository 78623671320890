import { ModuleRegistry, GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ClientSideRowModelModule } from 'ag-grid-community'
// if you are using row grouping or tree data, these two are required
import { InfiniteRowModelModule } from 'ag-grid-community'
import {
    RowGroupingModule,
    ExcelExportModule,
    SetFilterModule,
} from '@amzn/ag-bird/src/ag-grid-enterprise'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
LicenseManager.setLicenseKey(agGridLicense)
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])
import {
    Box,
    ContentLayout,
    Alert,
    Select,
    SelectProps,
    SpaceBetween,
    FormField,
    Toggle,
    Button,
    Container,
    Header,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { useEffect, useRef, useState } from 'react'
import { useAppContext } from '../../../context'
import { EMPTY_HR_DATA, EMPTY_REVISION_SELECTION, LIVE_HR_TEAM_DATA_KEY } from '../../Constant'
import useStore from '../../Store'
import { formatPlanOptionsWithData, formatRevisionOptions } from '../reusable/Utils'
import {
    DEFAULT_COL_WIDTH,
    DEFAULT_NAME_COL_WIDTH,
    EMPTY_BIS_ROW,
    formatSelection,
    GRAND_TOTAL_COLOR_CODE,
    hideZerosAndTruncateNumbers,
    NORMAL_CELL_COLOR_CODE,
} from './SummaryUtil'
import { convertMetadataToDict } from '../program/deliverables/DelivUtil'

const GroupSummary = (props) => {
    const { isRevamped } = props
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const [gridApi, setGridApi] = useState<any>()
    const [programs, setPrograms] = useState<any>([])
    const [groupOptions, setGroupOptions] = useState<any>([])
    const [groupOptionsMetadata, setGroupOptionsMetadata] = useState<any>({})
    const [selectedGroup, setSelectedGroup] = useState<any>(
        groupOptions.length
            ? { value: groupOptions[0].value, label: groupOptions[0].label }
            : { value: '', label: 'Select Group' },
    )
    const [revisionOptions, setRevisionOptions] = useState<SelectProps.Option[]>([])
    const [selectedRev, setSelectedRev] = useState<any>({})
    const [selectedPlan, setSelectedPlan] = useState<any>({})
    const [planOptions, setPlanOptions] = useState<SelectProps.Option[]>([])
    const [programDeliverableMap, setProgramDeliverableMap] = useState<any>({})
    const [jobFunctions, setJobFunctions] = useState<any>([])
    const [columnJobFunctions, setColumnJobFunctions] = useState<any>([])
    const [estimates, setEstimates] = useState<any>([])
    const [showScopedOnlyPrograms, setShowScopedOnlyPrograms] = useState<boolean>(true)
    const [initialRows, setInitialRows] = useState<any>({})
    const [summaryCols, setSummaryCols] = useState<any>(null)
    const [summaryRows, setSummaryRows] = useState<any>(null)
    const [visibleRows, setVisibleRows] = useState<any>(null)
    const [bisRow, setBisRow] = useState<any>(EMPTY_BIS_ROW)
    const [totalOhRow, setTotalOhRow] = useState<any>({
        program: 'Total w/ OH',
        total_ct_id: 0,
        total_ff_id: 0,
    })
    const usePrev = (value) => {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        }, [value])
        return ref.current
    }

    const prevSelectedPlan = usePrev(selectedPlan)
    const prevSelectedRev = usePrev(selectedRev)

    // TODO: reduce the amount of useEffect hooks by consolidating dependencies
    useEffect(() => {
        getBusinessEntityPlans()
        getGroupsinBE()
        getJobFunctions()
    }, [selectedBusinessEntity])

    useEffect(() => {
        if (!gridApi) {
            return
        }
        gridApi.showLoadingOverlay()
        setVisibleRows(
            showScopedOnlyPrograms ? summaryRows.filter((row) => row.scoped) : summaryRows,
        )
    }, [showScopedOnlyPrograms])

    useEffect(() => {
        if (!gridApi) {
            return
        }
        if (!visibleRows) {
            gridApi.showLoadingOverlay()
        } else if (!visibleRows.length) {
            gridApi.showNoRowsOverlay()
        }
    }, [visibleRows])

    useEffect(() => {
        if (!selectedPlan.value || !selectedRev.value) {
            return
        }
        if (prevSelectedPlan !== selectedPlan || prevSelectedRev !== selectedRev) {
            resetDataWhenPlanRevChanges()
            getProgramsInPlanRev()
            getDeliverablesInPlanRev()
        } else {
            resetDataWhenGroupChanges()
        }
        getGroupHCEstimates()
    }, [selectedGroup, selectedPlan, selectedRev, prevSelectedPlan, prevSelectedRev])

    useEffect(() => {
        if (!gridApi) {
            return
        }
        generateInitialRows()
    }, [columnJobFunctions, programDeliverableMap])

    useEffect(() => {
        if (gridApi && Object.keys(initialRows).length) {
            initialGroupSummary()
        }
    }, [estimates, initialRows])

    useEffect(() => {
        const gap = {
            program: 'Gap (Total w/ OH - BIS)',
            total_ct_id: totalOhRow.total_ct_id - bisRow.total_ct_id,
            total_ff_id: totalOhRow.total_ff_id - bisRow.total_ff_id,
        }
        if (gridApi) {
            gridApi.setPinnedBottomRowData([bisRow, totalOhRow, gap])
        }
    }, [bisRow, totalOhRow])

    const resetDataWhenPlanRevChanges = () => {
        setSummaryRows(null)
        setSummaryCols(null)
        setVisibleRows(null)
        setBisRow(EMPTY_BIS_ROW)
        setInitialRows({})
        setPrograms([])
        setProgramDeliverableMap({})
        setEstimates([])
    }

    const resetDataWhenGroupChanges = () => {
        setSummaryRows(null)
        setSummaryCols(null)
        setVisibleRows(null)
        setBisRow(EMPTY_BIS_ROW)
        setInitialRows({})
        setEstimates([])
    }
    const initialGroupSummary = () => {
        if (!estimates || !initialRows) {
            return
        }
        isRevamped ? generateHCRows() : generateRows()
        generateColumns()
        getBISData()
    }

    const initialProgramDeliverableRowSetUp = (existingPrograms, rows, jobEstimates) => {
        /*
         * programDeliverableMap maps programs names to a list of associated deliverables.
         * The map only contains programs that have deliverables under it.
         */
        Object.keys(programDeliverableMap).forEach((program) => {
            programDeliverableMap[program].forEach((deli) => {
                if (deli?.is_active) {
                    existingPrograms.add(deli.program_id)
                    rows[deli.deliverable_id] = Object.assign(
                        {},
                        {
                            program: program,
                            scoped: false,
                            deliverable: deli.deliverable_name,
                            total_ct_id: 0,
                            total_ff_id: 0,
                            total: 0,
                        },
                        isRevamped ? {} : jobEstimates,
                    )
                }
            })
        })
    }

    const generateInitialRows = () => {
        // rows will contain deliverable id or program id to the associated row information for that entity
        const rows: any = {}
        const jobEstimates: any = {}
        const existingPrograms = new Set()

        columnJobFunctions.forEach((job) => {
            // set up ct ff values for valid job functions in the selected group
            jobEstimates[`${job.value}_ct`] = 0
            jobEstimates[`${job.value}_ff`] = 0
        })
        initialProgramDeliverableRowSetUp(existingPrograms, rows, jobEstimates)
        setInitialRows(rows)
    }

    const generateHCRows = () => {
        let projectedCT = 0
        let projectedFF = 0
        estimates.forEach((est) => {
            const deliverableId = est.deliverable_id
            const hcType = est.headcount_type
            // update the initial row set up with estimate values
            if (Object.keys(initialRows).includes(deliverableId)) {
                initialRows[deliverableId][`total_${hcType}_id`] += parseFloat(est.headcount_value)
            }
        })
        const rows = Object.keys(initialRows).flatMap((deli) => {
            projectedCT += initialRows[deli].total_ct_id
            projectedFF += initialRows[deli].total_ff_id
            initialRows[deli].scoped =
                initialRows[deli].total_ct_id > 0 || initialRows[deli].total_ff_id > 0
            initialRows[deli].total = initialRows[deli].total_ct_id + initialRows[deli].total_ff_id
            return initialRows[deli]
        })
        const sortedRows = rows.sort((a, b) => a.program.localeCompare(b.program))
        setSummaryRows(sortedRows)
        setVisibleRows(showScopedOnlyPrograms ? sortedRows.filter((row) => row.scoped) : sortedRows)
        setTotalOhRow({
            program: 'Total w/ OH',
            total_ct_id: projectedCT,
            total_ff_id: projectedFF,
        })
    }

    const generateRows = () => {
        // todo: remove this function once we have fully shifted to revamped group summary
        let projectedCTwOH = 0
        let projectedFFwOH = 0
        let projectedCT = 0
        let projectedFF = 0
        const overheadFactor = 1 + parseInt(selectedPlan.data.overhead_factor) / 100
        estimates.forEach((est) => {
            const job_function = est.job_function
            const deliverable_id = est.deliverable_id
            // update the initial row set up values with each iterated estimate against the specific deliverable
            if (Object.keys(initialRows).includes(deliverable_id)) {
                // job function specific ct and ff values are aggregated
                initialRows[deliverable_id][`${job_function}_ct`] += parseFloat(est.hc_ct)
                initialRows[deliverable_id][`${job_function}_ff`] += parseFloat(est.hc_ff)
                initialRows[deliverable_id].total_ct_id += parseFloat(est.hc_ct)
                initialRows[deliverable_id].total_ff_id += parseFloat(est.hc_ff)
                initialRows[deliverable_id].overhead_applied = est.is_overhead_applied
            }
        })
        /* Business Logic:
           there are two types of ct/ff totals, if overhead (OH) is applied to a specific estimate,
           we need to perform ratio calculation to those estimates. To account for this logic,
           the blow calculates two types of projected hc totals - one without OH and one with OH
        */
        const rows = Object.keys(initialRows).flatMap((deli) => {
            if (initialRows[deli].overhead_applied) {
                projectedCTwOH += initialRows[deli].total_ct_id
                projectedFFwOH += initialRows[deli].total_ff_id
            } else {
                projectedCT += initialRows[deli].total_ct_id
                projectedFF += initialRows[deli].total_ff_id
            }
            initialRows[deli].scoped =
                initialRows[deli].total_ct_id > 0 || initialRows[deli].total_ff_id > 0
            initialRows[deli].total = initialRows[deli].total_ct_id + initialRows[deli].total_ff_id
            return initialRows[deli]
        })
        const sortedRows = rows.sort((a, b) => a.program.localeCompare(b.program))
        setSummaryRows(sortedRows)
        setVisibleRows(showScopedOnlyPrograms ? sortedRows.filter((row) => row.scoped) : sortedRows)
        // overhead calculation equation = (projected with overhead total) * (1 + overhead/100) + (projected w/o overhead)
        const totalwOH = {
            program: 'Total w/ OH',
            total_ct_id: projectedCTwOH * overheadFactor + projectedCT,
            total_ff_id: projectedFFwOH * overheadFactor + projectedFF,
        }
        setTotalOhRow(totalwOH)
    }

    const generateDynamicJobFunctionColumns = (jobFunctionCols) => {
        // todo: remove this function once we have fully shifted to revamped group summary
        columnJobFunctions.forEach((job) => {
            jobFunctionCols.push({
                headerName: job.reporting_name,
                children: [
                    {
                        field: `${job?.value}_ct`,
                        headerName: 'C&T',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        aggFunc: 'sum',
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                    {
                        field: `${job?.value}_ff`,
                        headerName: 'FF',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        aggFunc: 'sum',
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                ],
            })
        })
    }

    const generateColumns = () => {
        const columns: any[] = [
            {
                headerName: 'Program',
                field: 'program',
                rowGroup: true,
                hide: true,
            },
            {
                headerName: 'Deliverable',
                field: 'deliverable',
                initialWidth: DEFAULT_NAME_COL_WIDTH,
                pinned: 'left',
            },
            {
                headerName: 'Total (CT + FF)',
                field: 'total',
                initialWidth: DEFAULT_NAME_COL_WIDTH,
                pinned: 'left',
                cellRenderer: (params) => {
                    return hideZerosAndTruncateNumbers(params)
                },
                aggFunc: 'sum',
            },
            {
                headerName: 'Total',
                children: [
                    {
                        headerName: 'C&T',
                        field: 'total_ct_id',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        aggFunc: 'sum',
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                    {
                        field: 'total_ff_id',
                        headerName: 'FF',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        aggFunc: 'sum',
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                ],
            },
        ]

        if (isRevamped) {
            setSummaryCols(columns)
            return
        }

        const jobFunctionCols: any[] = []
        generateDynamicJobFunctionColumns(jobFunctionCols)
        setSummaryCols(
            columns.concat(
                jobFunctionCols.sort((a, b) => a.headerName.localeCompare(b.headerName)),
            ),
        )
    }

    const getBISData = () => {
        apiClient
            .get(
                `/falcon/head_counts/amzn_team_id/${groupOptionsMetadata[selectedGroup.value]?.hr_permission_group}?member_specs=egret`,
            )
            .then((res) => {
                const result = res.data
                if (!result) {
                    setBisRow({ program: 'BIS', total_ct_id: 0, total_ff_id: 0 })
                    return
                }
                const hrTeamData = result[0]
                let formattedTeamData: any = convertMetadataToDict(
                    hrTeamData,
                    LIVE_HR_TEAM_DATA_KEY,
                )
                if (!Object.keys(formattedTeamData).length) {
                    formattedTeamData = EMPTY_HR_DATA
                }
                // todo: need to refactor when supporting multiple groups in the future
                const bis = {
                    program: 'BIS',
                    total_ct_id: formattedTeamData.ct,
                    total_ff_id: formattedTeamData.ff,
                }
                setBisRow(bis)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getProgramsInPlanRev = () => {
        apiClient
            .get(`/plan/${selectedPlan.value}/revision/${selectedRev.value}/programs`)
            .then((res) => {
                setPrograms(res.data)
            })
            .catch((e) => {
                console.error(e)
                setPrograms([])
            })
    }

    const getDeliverablesInPlanRev = () => {
        apiClient
            .get(`/plan/${selectedPlan.value}/revision/${selectedRev.value}/deliverables`)
            .then((res) => {
                // returns object map of program to deliverables
                setProgramDeliverableMap(res.data)
            })
            .catch((e) => {
                console.error(e)
                setProgramDeliverableMap({})
            })
    }

    const getGroupsinBE = () => {
        apiClient
            .get(`/business_entity/${selectedBusinessEntity?.business_entity_id}/groups`)
            .then((res) => {
                const result = res.data
                let groups: any[] = []
                // users shouldn't be able to select archived groups
                Object.keys(result).forEach((org) => {
                    groups = groups.concat(result[org].filter((group) => !group?.archived))
                })
                groups.sort((a: any, b: any) => a.group_name.localeCompare(b.group_name))
                const formattedGroups = groups.flatMap((group: any) => {
                    return {
                        label: group.group_name,
                        value: group.group_id,
                    }
                })
                const groupMetadata = {}
                groups.forEach((group) => {
                    groupMetadata[group.group_id] = group
                })
                setGroupOptionsMetadata(groupMetadata)
                setGroupOptions(formattedGroups)
                setSelectedGroup(formattedGroups[0])
            })
            .catch((e) => {
                console.error(e)
                setGroupOptions([])
                setGroupOptionsMetadata({})
                setSelectedGroup({})
            })
    }

    const getBusinessEntityPlans = () => {
        apiClient
            .get(`/plan/business-entity/${selectedBusinessEntity?.business_entity_id}?year=`)
            .then((res) => {
                const formattedPlanOptions = res.data.map((plan) => formatPlanOptionsWithData(plan))
                if (formattedPlanOptions.length) {
                    const plan = formattedPlanOptions[0]
                    const revisionOptions = plan.data.revisions.map((rev: any) =>
                        formatRevisionOptions(rev),
                    )
                    setSelectedPlan(plan)
                    setRevisionOptions(revisionOptions)
                    setPlanOptions(formattedPlanOptions)
                    setSelectedRev(revisionOptions[0])
                } else {
                    setSelectedPlan({})
                    setRevisionOptions([])
                    setSelectedRev({})
                    setPlanOptions([])
                }
            })
            .catch((err) => {
                console.error(err)
                setPlanOptions([])
                setSelectedPlan({})
                setSelectedRev({})
                setRevisionOptions([])
            })
    }

    const getGroupHCEstimates = () => {
        const url = isRevamped
            ? `/group-headcount-estimates/${groupOptionsMetadata[selectedGroup.value]?.group_id}/plan/${selectedPlan.value}/revision/${selectedRev.value}`
            : `/group-headcount-estimates/${groupOptionsMetadata[selectedGroup.value]?.group_id}?plan_id=${selectedPlan.value}&revision_id=${selectedRev.value}`
        // todo: when refactoring to support multiple groups in the summary, we will have to rethink the way we fetch estimates for groups. Currently, this is fetching estimates for one group
        apiClient
            .get(url)
            .then((res) => {
                const result = res.data
                setEstimates(result)
                if (!isRevamped) {
                    const associatedJobFunctions = new Set(
                        result.flatMap((estimates) => {
                            return estimates.job_function
                        }),
                    )
                    setColumnJobFunctions(
                        jobFunctions.filter((job) => {
                            return associatedJobFunctions.has(job.value)
                        }),
                    )
                } else {
                    setColumnJobFunctions([])
                }
            })
            .catch((e) => {
                console.error(e)
                setEstimates([])
                setColumnJobFunctions([])
            })
    }

    const getJobFunctions = () => {
        // todo: remove this function once we have fully shifted to revamped group summary
        apiClient
            .get(`/settings?keys=job_function`)
            .then((res) => {
                setJobFunctions(res.data)
            })
            .catch((e) => {
                console.error(e)
                setJobFunctions([])
            })
    }

    const onGridReady = (params: any) => {
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns()
    }

    const handleClickExport = () => {
        gridApi?.exportDataAsExcel()
    }

    const gridOptions: GridOptions = {
        getRowStyle: (params) => {
            return {
                fontWeight: params.node.rowPinned || params.node.footer ? 'bold' : 'normal',
                background: params.node.footer ? GRAND_TOTAL_COLOR_CODE : NORMAL_CELL_COLOR_CODE,
            }
        },
        defaultColDef: { lockPosition: true },
        groupTotalRow: 'bottom',
        grandTotalRow: 'bottom',
        autoGroupColumnDef: {
            headerName: 'Program',
            field: 'program',
            minWidth: DEFAULT_NAME_COL_WIDTH,
            pinned: 'left',
        },
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
        suppressAggFuncInHeader: true,
        pagination: true,
    }

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Group Summary',
                                    href: isRevamped ? '/group-summary-revamped' : '/group-summary',
                                },
                            ]}
                        />
                        {(!selectedGroup.value || !selectedRev.value) && (
                            <Alert>
                                {
                                    'Please select a group and a revision from the dropdown to view HC estimation summary.'
                                }
                            </Alert>
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <Box margin={{ left: 's', right: 's' }}>
                <Container
                    header={
                        <Header
                            actions={
                                <SpaceBetween direction={'horizontal'} size={'xxl'}>
                                    <FormField label={'Deliverables'}>
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <span>All</span>
                                            <Toggle
                                                checked={showScopedOnlyPrograms}
                                                onChange={({ detail }) => {
                                                    setShowScopedOnlyPrograms(detail.checked)
                                                }}
                                            >
                                                Scoped
                                            </Toggle>
                                        </SpaceBetween>
                                    </FormField>
                                    <Button onClick={() => handleClickExport()}>Export</Button>
                                </SpaceBetween>
                            }
                        >
                            <SpaceBetween direction={'horizontal'} size={'s'}>
                                <Select
                                    selectedOption={selectedPlan}
                                    onChange={({ detail }) => {
                                        const option: any = detail.selectedOption
                                        const revisions = option.data?.revisions.map((rev: any) => {
                                            return formatRevisionOptions(rev)
                                        })
                                        if (revisions.length) {
                                            setRevisionOptions(revisions)
                                            setSelectedRev(revisions[0])
                                        } else {
                                            setRevisionOptions([])
                                            setSelectedRev(EMPTY_REVISION_SELECTION)
                                        }
                                        setSelectedPlan(option)
                                    }}
                                    options={planOptions}
                                    disabled={!planOptions.length}
                                />
                                <Select
                                    selectedOption={selectedRev}
                                    onChange={({ detail }) => {
                                        setSelectedRev(detail.selectedOption)
                                    }}
                                    options={revisionOptions}
                                    disabled={!revisionOptions.length}
                                />
                                <Select
                                    filteringType={'auto'}
                                    selectedOption={formatSelection(selectedGroup)}
                                    onChange={({ detail }) => {
                                        setSelectedGroup(detail.selectedOption)
                                    }}
                                    options={groupOptions}
                                    disabled={!groupOptions.length}
                                />
                            </SpaceBetween>
                        </Header>
                    }
                >
                    <Box margin={{ top: 's' }}>
                        <div className='ag-theme-quartz' style={{ height: 800 }}>
                            <AgGridReact
                                rowData={visibleRows}
                                columnDefs={summaryCols}
                                onGridReady={onGridReady}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </Box>
                </Container>
            </Box>
        </ContentLayout>
    )
}

export default GroupSummary
