import { useState } from 'react'
import { Header, Pagination, Spinner, Table } from '@amzn/awsui-components-react'
import { TableProps } from '@amzn/awsui-components-react/polaris/table/interfaces'
import { useCollection } from '@amzn/awsui-collection-hooks'

interface GenericSummaryTableProps {
    columns: any[]
    visibleColumns: any[]
    itemsToShow: any[]
    isLoading: boolean
    nameField: string
    defaultNameField: string
    objectType: string
    actions?: any[]
    wrapLines?: boolean
    filter?: any
    includeHeader?: boolean
    variant?: TableProps.Variant
    includePagination?: boolean
    onRowClick?: (event: any) => void
    useCustomHeader?: boolean
    customHeader?: any
}

const GenericSummaryTable = (props: GenericSummaryTableProps) => {
    const {
        columns,
        visibleColumns,
        itemsToShow,
        isLoading,
        nameField,
        defaultNameField,
        objectType,
        actions,
        filter = <></>,
        includeHeader = true,
        includePagination = true,
        variant = undefined,
        onRowClick = undefined,
        customHeader = <></>,
    } = props
    const [preferences] = useState({ pageSize: 20 })
    const { items, collectionProps, paginationProps } = useCollection(itemsToShow, {
        pagination: { pageSize: preferences.pageSize },
        selection: {},
    })
    return !items.length || !columns.length || !visibleColumns.length ? (
        <Spinner size={'normal'} />
    ) : (
        <Table
            {...collectionProps}
            columnDefinitions={columns}
            variant={variant ?? 'container'}
            items={items}
            loading={isLoading || !items || !items.length}
            loadingText={`Loading ${objectType} Metadata`}
            wrapLines={true}
            pagination={includePagination && <Pagination {...paginationProps} />}
            columnDisplay={visibleColumns}
            filter={filter}
            sortingDisabled
            header={
                includeHeader &&
                (customHeader !== <></> ? (
                    customHeader
                ) : (
                    <Header actions={actions && actions.length ? actions : []}>
                        {' '}
                        {items.length
                            ? `${items[0][nameField]} ${objectType}`
                            : defaultNameField}{' '}
                    </Header>
                ))
            }
            onRowClick={onRowClick}
        />
    )
}

export default GenericSummaryTable
