import { useCollection } from '@amzn/awsui-collection-hooks'
import { Header, Table } from '@amzn/awsui-components-react'
import EmptyState from '../../../reusable/EmptyState'
import _ from 'lodash'

const CopyDeliverableHcEstimateAggregateTable = ({ deliverables, hcEstimates }: any) => {
    const aggregatedHcEstimates = {}
    hcEstimates.forEach((hcEstimate) => {
        const deliverable_id = hcEstimate.deliverable_id
        if (!(deliverable_id in aggregatedHcEstimates)) {
            aggregatedHcEstimates[deliverable_id] = _.cloneDeep(hcEstimate)
        } else {
            const temp = aggregatedHcEstimates[deliverable_id]
            temp.hc_ct = +temp.hc_ct + +hcEstimate.hc_ct
            temp.hc_ff = +temp.hc_ff + +hcEstimate.hc_ff
            temp.hc_total = +temp.hc_total + +hcEstimate.hc_total
        }
    })
    const aggregatedHcEstimatesArray = Object.values(aggregatedHcEstimates)

    const columnDefinitions = [
        {
            id: 'deliverable_id',
            header: 'Deliverable ID',
            cell: (e: any) => e.deliverable_id,
        },
        {
            id: 'deliverable_name',
            header: 'Deliverable',
            cell: (e: any) => {
                const deliverable = deliverables.find(
                    (deliv) => deliv.deliverable_id === e.deliverable_id,
                )
                if (deliverable) {
                    return deliverable.deliverable_name
                }

                return 'N/A'
            },
            sortingField: 'deliverable_name',
        },
        {
            id: 'hc_ct',
            header: 'C&T',
            cell: (e: any) => e.hc_ct,
            sortingField: 'hc_ct',
        },
        {
            id: 'hc_ff',
            header: 'FF',
            cell: (e: any) => e.hc_ff,
            sortingField: 'hc_ff',
        },
        {
            id: 'hc_total',
            header: 'Total',
            cell: (e: any) => e.hc_total,
            sortingField: 'hc_total',
        },
    ]
    const visibleColumns = ['deliverable_name', 'hc_ct', 'hc_ff', 'hc_total']

    const { items, collectionProps } = useCollection(aggregatedHcEstimatesArray, {
        propertyFiltering: {
            filteringProperties: [],
            empty: (
                <EmptyState
                    title='No HC estimates'
                    subtitle={'`No HC estimate to found under select deliverables to display. '}
                    action={<></>}
                />
            ),
        },
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions[1],
            },
        },
    })

    return (
        <Table
            {...collectionProps}
            items={items}
            stickyHeader={true}
            columnDefinitions={columnDefinitions}
            visibleColumns={visibleColumns}
            header={<Header>Aggregated source headcount estimate(s)</Header>}
        />
    )
}

export default CopyDeliverableHcEstimateAggregateTable
