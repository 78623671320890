import { EMPTY_HR_DATA, EMPTY_REVISION_SELECTION, SUMMARY_HEADCOUNT_TYPE } from '../../Constant'
import { calculateGap, formatHrMemberData } from '../program/deliverables/DelivUtil'
import {
    groupDataCalculations,
    HC_GAP,
    nullOutZeroValues,
    planDataColumnDef,
    visibleColumns,
} from './SummaryUtil'
import { useAppContext } from '../../../context'
import useStore from '../../Store'
import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    ContentLayout,
    Grid,
    Header,
    Select,
    SelectProps,
    SpaceBetween,
    Spinner,
    Table,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import HCDetailModal from '../program/deliverables/HCDetailModal'
import EmptyState from '../reusable/EmptyState'
import PlanSummaryProgramHCTable from './PlanSummaryProgramHCTable'
import { formatPlanOptionsWithData, formatRevisionOptions } from '../reusable/Utils'

interface PlanHCSummary {
    priority: number
    program: string
    total: number
    ct: number
    ff: number
    is_scoped: boolean
}

const PlanSummary = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    // todo: refactor to use reducers instead of states
    const [selectedPlan, setSelectedPlan] = useState<any>()
    const [deliStatusMap, setDeliStatusMap] = useState<any>()
    const [programs, setPrograms] = useState<any>([])
    const [businessEntityPlans, setBusinessEntityPlans] = useState<any>([])
    const [planName, setPlanName] = useState<string>('')
    const [hrTeamData, setHrTeamData] = useState<any>(EMPTY_HR_DATA)
    const [hrMemberData, setHrMemberData] = useState<any>({})
    const [hcByEmployeeType, setHcByEmployeeType] = useState<any>({})
    const [isHrDataLoading, setIsHrDataLoading] = useState<boolean>(true)
    const [revisionOptions, setRevisionOptions] = useState<SelectProps.Option[]>([])
    const [hrModalVisible, setHRModalVisible] = useState<boolean>(false)
    const [showScopedOnlyProgramHeadcount, setShowScopedOnlyProgramHeadcount] =
        useState<boolean>(true)
    const [selectedRevision, setSelectedRevision] = useState<SelectProps.Option>(
        revisionOptions.length
            ? {
                  label: revisionOptions[0].label,
                  value: revisionOptions[0].value,
              }
            : { label: 'Select Revision', value: '' },
    )
    const [tableItems, setTableItems] = useState<any>([])
    const [aggHrData, setAggHrData] = useState({
        CT: 0,
        FF: 0,
        TOTAL: 0,
    })
    const [isProgramHCTableLoading, setIsProgramHCTableLoading] = useState<boolean>(true)
    const [programHCData, setProgramHCData] = useState<any>([])

    useEffect(() => {
        if (!selectedPlan || !selectedRevision.label) {
            return
        }
        setIsHrDataLoading(true)
        setIsProgramHCTableLoading(true)
        getBISData()
        getProgramsInPlan()
        getPlanDeliverables()
    }, [selectedPlan, selectedRevision])

    useEffect(() => {
        if (!selectedPlan || !selectedRevision.label || !deliStatusMap) {
            return
        }
        getPlanHeadcounts()
    }, [deliStatusMap, programs])

    useEffect(() => {
        generateGroupDataItems()
    }, [aggHrData])

    useEffect(() => {
        getBusinessEntityPlans()
    }, [])

    const getPlanDeliverables = () => {
        if (!selectedRevision && !selectedPlan) {
            return
        }
        apiClient
            .get(`/plan/${selectedPlan?.value}/revision/${selectedRevision?.value}/deliverables`)
            .then((res) => {
                const deliverableStatus = {}
                Object.entries(res.data).forEach((value) => {
                    const deliverables: any = value[1]
                    Array.from(deliverables).forEach((deliverable: any) => {
                        deliverableStatus[deliverable.deliverable_id] = deliverable.is_active
                    })
                })
                setDeliStatusMap(deliverableStatus)
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getProgramsInPlan = () => {
        apiClient
            .get(`/plan/${selectedPlan.data?.plan_id}/revision/${selectedRevision?.value}/programs`)
            .then((res) => {
                // map program metadata to its program id
                const programMetadata = {}
                res.data.map((prog) => {
                    programMetadata[prog.program_id] = prog
                })
                setPrograms(programMetadata)
            })
            .catch((e) => {
                console.error(e)
                setPrograms([])
            })
    }

    const generateHCData = (estimates) => {
        const estimatesByPrograms = {}
        const scopedPrograms = new Set()
        // aggData stores projected data that will be shown on the BIS table
        const aggData = {
            CT: 0,
            FF: 0,
            TOTAL: 0,
        }
        // have estimates sorted by program id
        estimates.forEach((est) => {
            scopedPrograms.add(est.program_id)
            const program_id = est.program_id
            const deliverable_id = est.deliverable_id
            // only create estimates for active
            if (deliStatusMap[deliverable_id]) {
                if (!Object.keys(estimatesByPrograms).includes(program_id)) {
                    estimatesByPrograms[program_id] = []
                }
                estimatesByPrograms[program_id].push(est)
            }
        })
        const hcData: PlanHCSummary[] = []
        // generate program hc summary row data for scoped programs
        Object.keys(estimatesByPrograms).forEach((prog) => {
            const rowData: PlanHCSummary = {
                priority: programs[prog]?.priority,
                ct: 0,
                ff: 0,
                total: 0,
                is_scoped: true,
                program: estimatesByPrograms[prog][0].program_name,
            }
            estimatesByPrograms[prog].forEach((est) => {
                switch (est.headcount_type) {
                    case SUMMARY_HEADCOUNT_TYPE.CT:
                        rowData.ct += est.headcount_value
                        break
                    case SUMMARY_HEADCOUNT_TYPE.FF:
                        rowData.ff += est.headcount_value
                        break
                    default:
                        break
                }
                rowData.total += est.headcount_value
            })
            rowData.is_scoped = rowData.total > 0
            aggData.TOTAL += rowData.total
            aggData.CT += rowData.ct
            aggData.FF += rowData.ff
            hcData.push(rowData)
        })
        // generate program hc summary row data for non-scoped programs
        Object.keys(programs).forEach((id) => {
            if (!scopedPrograms.has(id)) {
                const program = programs[id]
                const rowData: PlanHCSummary = {
                    priority: program.priority,
                    ct: 0,
                    ff: 0,
                    total: 0,
                    is_scoped: false,
                    program: program.program_name,
                }
                hcData.push(rowData)
            }
        })
        setAggHrData(aggData)
        return nullOutZeroValues(hcData)
    }

    const getPlanHeadcounts = () => {
        apiClient
            .get(
                `plan-headcount-estimates/plan/${selectedPlan.data?.plan_id}/revision/${selectedRevision?.value}`,
            )
            .then((res) => {
                setProgramHCData(generateHCData(res.data))
            })
            .catch((e) => {
                console.error(e)
                setProgramHCData([])
            })
            .finally(() => {
                setIsProgramHCTableLoading(false)
            })
    }

    const generatePlanName = (plan) => {
        return `${plan.year} ${plan.plan_type}`
    }

    const getBusinessEntityPlans = () => {
        apiClient
            .get(`/plan/business-entity/${selectedBusinessEntity?.business_entity_id}?year=`)
            .then((res) => {
                const formattedPlanOptions = res.data.map((plan) => formatPlanOptionsWithData(plan))
                if (formattedPlanOptions.length) {
                    const selectedPlan = formattedPlanOptions[0]
                    const revisionOptions = selectedPlan.data.revisions.map((rev: any) =>
                        formatRevisionOptions(rev),
                    )
                    setPlanName(generatePlanName(formattedPlanOptions[0].data))
                    setSelectedRevision(revisionOptions[0])
                    setRevisionOptions(revisionOptions)
                    setSelectedPlan(selectedPlan)
                    setBusinessEntityPlans(formattedPlanOptions)
                } else {
                    setPlanName('')
                    setSelectedRevision({})
                    setSelectedPlan({})
                    setRevisionOptions([])
                    setBusinessEntityPlans([])
                }
            })
            .catch((err) => {
                console.error(err)
                setPlanName('')
                setSelectedRevision({})
                setSelectedPlan({})
                setRevisionOptions([])
                setBusinessEntityPlans([])
            })
    }

    const getBISData = () => {
        apiClient
            .get(
                `/falcon/head_counts/business_entity_id/${selectedBusinessEntity.business_entity_id}`,
            )
            .then((response) => {
                const res = response.data
                const hrMemberData = res[1]
                const formattedHRMemberData = formatHrMemberData(hrMemberData)
                let formattedTeamData = {
                    ct: formattedHRMemberData.ct.length,
                    ff: formattedHRMemberData.ff.length,
                    employee: formattedHRMemberData.ct.length + formattedHRMemberData.ff.length,
                }
                if (!Object.keys(formattedTeamData).length) {
                    formattedTeamData = EMPTY_HR_DATA
                }
                setHrTeamData(formattedTeamData)
                setHrMemberData(formattedHRMemberData)
                setHcByEmployeeType({
                    ct: `${formattedHRMemberData.ct.length}`,
                    ff: `${formattedHRMemberData.ff.length}`,
                })
            })
            .catch((e) => {
                console.error(e)
                setHrTeamData(EMPTY_HR_DATA)
                setHrMemberData({})
                setHcByEmployeeType({})
            })
    }

    const generateGroupDataItems = () => {
        let items: any[] = []
        const planName = selectedPlan ? selectedPlan.data.plan_name : ''
        groupDataCalculations.forEach((calculation) => {
            items.push({
                plan_name: planName,
                calculation: calculation,
            })
        })
        const gap = {
            hc: calculateGap(aggHrData.TOTAL, hrTeamData.employee),
            ct: calculateGap(aggHrData.CT, hrTeamData.ct),
            ff: calculateGap(aggHrData.FF, hrTeamData.ff),
        }

        // create plan data table items
        items = items.map((item) => {
            let updated_item = {}
            switch (item.calculation) {
                case `Total`:
                    updated_item = {
                        ...item,
                        ...{ hc: aggHrData.TOTAL, ct: aggHrData.CT, ff: aggHrData.FF },
                    }
                    break
                case `Total w/ OH`:
                    // TODO: update contents once we know which headcounts have overhead applied.
                    updated_item = {
                        ...item,
                        ...{ hc: aggHrData.TOTAL, ct: aggHrData.CT, ff: aggHrData.FF },
                    }
                    break
                case `BIS`:
                    updated_item = {
                        ...item,
                        ...{
                            hc: hrTeamData.employee,
                            ct: hrTeamData.ct,
                            ff: hrTeamData.ff,
                        },
                    }
                    break
                case HC_GAP:
                    updated_item = {
                        ...item,
                        ...gap,
                    }
                    break
            }
            return updated_item
        })
        setTableItems(items)
        setIsHrDataLoading(false)
    }

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Plan Summary',
                                    href: `/plan/${selectedPlan?.value?.plan_id}/plan-summary`,
                                },
                            ]}
                        />
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween size={'xxl'} direction={'vertical'}>
                <Grid gridDefinition={[{ colspan: 2 }, { colspan: 8 }, { colspan: 2 }]}>
                    <div></div>
                    <Container>
                        <Table
                            columnDefinitions={planDataColumnDef}
                            visibleColumns={visibleColumns}
                            contentDensity={'compact'}
                            loadingText={`Loading summary...`}
                            variant={'embedded'}
                            items={!selectedRevision.label ? [] : tableItems}
                            empty={
                                isHrDataLoading ? (
                                    <Spinner />
                                ) : (
                                    <EmptyState
                                        title='No Summary Data.'
                                        subtitle={
                                            selectedRevision.label
                                                ? `No HC data found for Revision ${
                                                      selectedRevision?.label || ''
                                                  }`
                                                : `${
                                                      !selectedRevision.label
                                                          ? 'Please select a Revision from the Revision Dropdown.'
                                                          : ''
                                                  }`
                                        }
                                        action={[]}
                                    />
                                )
                            }
                            header={
                                <Grid
                                    gridDefinition={[
                                        { colspan: 5 },
                                        { colspan: 3 },
                                        { colspan: 4 },
                                    ]}
                                >
                                    <SpaceBetween size={'xxl'} direction={'vertical'}>
                                        <Box float={'left'} margin={'xl'}>
                                            <SpaceBetween size={'xxl'} direction={'vertical'}>
                                                <Header>
                                                    <Box variant={'h1'} float={'left'}>
                                                        {`${
                                                            tableItems.length ? planName + ' ' : ''
                                                        }Summary`}
                                                    </Box>
                                                </Header>
                                                <SpaceBetween size={'m'} direction={'horizontal'}>
                                                    <Select
                                                        filteringType={'auto'}
                                                        placeholder={'Selected Plan'}
                                                        selectedOption={selectedPlan}
                                                        onChange={({ detail }) => {
                                                            const option: any =
                                                                detail.selectedOption
                                                            const revisions: any =
                                                                option.data?.revisions
                                                            if (revisions.length) {
                                                                const revisionOptions =
                                                                    revisions.map((rev: any) =>
                                                                        formatRevisionOptions(rev),
                                                                    )
                                                                setRevisionOptions(revisionOptions)
                                                                setSelectedRevision(
                                                                    revisionOptions[0],
                                                                )
                                                            } else {
                                                                setRevisionOptions([])
                                                                setSelectedRevision(
                                                                    EMPTY_REVISION_SELECTION,
                                                                )
                                                            }
                                                            setSelectedPlan(option)
                                                            setPlanName(
                                                                generatePlanName(option.data),
                                                            )
                                                        }}
                                                        options={businessEntityPlans}
                                                        disabled={!revisionOptions.length}
                                                    />
                                                    <Select
                                                        filteringType={'auto'}
                                                        placeholder={'Selected Revision'}
                                                        selectedOption={selectedRevision}
                                                        onChange={({ detail }) => {
                                                            const option = detail.selectedOption
                                                            setSelectedRevision(option)
                                                        }}
                                                        options={revisionOptions}
                                                        disabled={!revisionOptions.length}
                                                    />
                                                </SpaceBetween>
                                            </SpaceBetween>
                                        </Box>
                                    </SpaceBetween>

                                    <div></div>
                                    <Box float={'right'} margin={'xl'}>
                                        <Button
                                            onClick={() => {
                                                setHRModalVisible(true)
                                            }}
                                            disabled={!selectedRevision.label}
                                        >
                                            HC Detail
                                        </Button>
                                        <HCDetailModal
                                            visible={hrModalVisible}
                                            onVisibleChange={setHRModalVisible}
                                            hrTeamData={hrTeamData}
                                            hrMemberData={hrMemberData}
                                            employeeTypeData={hcByEmployeeType}
                                        />
                                    </Box>
                                </Grid>
                            }
                            loading={isHrDataLoading}
                            wrapLines
                            sortingDisabled
                            filter={undefined}
                        />
                    </Container>
                    <div></div>
                </Grid>
                <Box margin={{ horizontal: 's' }} display={'block'}>
                    <PlanSummaryProgramHCTable
                        data={
                            showScopedOnlyProgramHeadcount
                                ? programHCData.filter((row) => row.is_scoped)
                                : programHCData
                        }
                        isLoading={isProgramHCTableLoading}
                        planName={planName}
                        programHCToggleChange={setShowScopedOnlyProgramHeadcount}
                        programHCToggle={showScopedOnlyProgramHeadcount}
                    />
                </Box>
            </SpaceBetween>
        </ContentLayout>
    )
}

export default PlanSummary
