import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import {
    ATL_BTL_ARROW_SIZE,
    ATL_COLOR,
    PROGRAM_BTL_COLOR,
    PROGRAM_PRIORITY_BADGE_SIZE,
    PROGRAM_PRIORITY_SIZE,
} from '../summary/NewSummariesUtil'
import styled from 'styled-components'

const CustomProgramBadge = styled.span<{ $atl: boolean }>`
    margin: 2rem;
    background: ${(props) => (props.$atl ? ATL_COLOR : PROGRAM_BTL_COLOR)};
    border-radius: 70%;
    padding: 0.7rem;
`

const IsOpGoalPriorityBadge = ({ isOpGoal, priority }) => {
    return (
        <>
            {
                <CustomProgramBadge $atl={isOpGoal}>
                    {isOpGoal ? (
                        <NorthIcon sx={{ fontSize: ATL_BTL_ARROW_SIZE }} />
                    ) : (
                        <SouthIcon sx={{ fontSize: ATL_BTL_ARROW_SIZE }} />
                    )}
                    <span style={{ width: PROGRAM_PRIORITY_BADGE_SIZE, display: 'inline-block' }}>
                        {' '}
                        <b style={{ fontSize: PROGRAM_PRIORITY_SIZE }}>{priority || '-'} </b>
                    </span>{' '}
                </CustomProgramBadge>
            }
        </>
    )
}

export default IsOpGoalPriorityBadge
