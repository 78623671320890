import { COMPONENT_TYPES, EMPTY_SELECTION } from '../../Constant'
import { SelectProps } from '@amzn/awsui-components-react'

export const SANDBOX_REVISION = 9999

export const KINGPIN_GOAL_OPTIONS: SelectProps.Option[] = [
    { label: 'LT', value: 'lt' },
    { label: 'CLT', value: 'clt' },
    { label: 'eLT', value: 'elt' },
    { label: 'S-Team', value: 's_team' },
    { label: 'Advertising', value: 'advertising' },
    { label: 'QBR', value: 'qbr' },
    { label: 'Team Goal', value: 'team_goal' },
    { label: 'OLT', value: 'olt' },
    { label: 'AWS', value: 'aws' },
]

export const generateInitialState = (attrList) => {
    const initialState = {}

    const initialData = (componentType: string, defaultValue) => {
        switch (componentType) {
            case COMPONENT_TYPES.TOGGLE:
                return defaultValue || false
            case COMPONENT_TYPES.SELECT:
                return defaultValue || EMPTY_SELECTION
            case COMPONENT_TYPES.INPUT_STRING:
                return defaultValue || ''
            default:
                return defaultValue || ''
        }
    }

    attrList.forEach((item) => {
        if (!item.componentType) {
            return
        }

        initialState[item.id] = initialData(item.componentType, item.defaultValue)
        if (item.required) {
            initialState[`${item.id}_error`] = initialData(
                COMPONENT_TYPES.INPUT_STRING,
                item.defaultValue,
            )
        }
    })
    return initialState
}

export const mapToTokenItem = (inputList) => {
    if (!inputList.length) {
        return []
    }
    return inputList.map((val) => ({
        label: val,
        dismissLabel: `Dismiss ${val}`,
        disabled: true,
    }))
}
