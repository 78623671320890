import { Button, Link, PropertyFilter, Table } from '@amzn/awsui-components-react'
import {
    HR_MEMBER_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../../reusable/UseCollectionUtil'
import { useCollection } from '@amzn/awsui-collection-hooks'
import EmptyState from '../../reusable/EmptyState'
import { PHONE_TOOL_PREFIX } from '../../../Constant'
import { getMatchesCountText } from '../../reusable/Utils'

const HCDetailEmployeeTypeTable = (props) => {
    const { hrMembers } = props

    const columnDefinitions = [
        {
            id: 'resource_name',
            header: 'Resource Name',
            cell: (item) => {
                if (!item.resource_name) {
                    return ''
                }
                return item.resource_name.replaceAll(',', ', ') || '-'
            },
            sortingField: 'resource_name',
        },
        {
            id: 'business_title',
            header: 'Business Title',
            cell: (item) => item.business_title || '-',
            sortingField: 'business_title',
        },
        {
            id: 'alias',
            header: 'Alias',
            cell: (item) => {
                if (!item.alias) {
                    return ''
                }
                return (
                    <Link
                        external
                        externalIconAriaLabel='Opens in a new tab'
                        href={PHONE_TOOL_PREFIX + item.alias}
                    >
                        {item.alias.toLowerCase() || '-'}
                    </Link>
                )
            },
            sortingField: 'alias',
        },
        {
            id: 'manager_alias',
            header: 'Manager Alias',
            cell: (item) => {
                if (!item.manager_alias) {
                    return ''
                }
                return (
                    <Link
                        external
                        externalIconAriaLabel='Opens in a new tab'
                        href={PHONE_TOOL_PREFIX + item.manager_alias}
                    >
                        {item.manager_alias.toLowerCase() || '-'}
                    </Link>
                )
            },
            sortingField: 'manager_alias',
        },
    ]

    const { items, actions, filteredItemsCount, collectionProps, propertyFilterProps } =
        useCollection(hrMembers, {
            propertyFiltering: {
                filteringProperties: HR_MEMBER_FILTERING_PROPERTIES,
                empty: (
                    <EmptyState
                        title='No hr resource'
                        subtitle='No hr resource to display.'
                        action={''}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title='No matches'
                        subtitle='We can’t find a match.'
                        action={
                            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
                        }
                    />
                ),
            },
            sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        })

    return (
        <Table
            {...collectionProps}
            columnDefinitions={columnDefinitions}
            items={items || []}
            variant='embedded'
            loadingText='Loading hr resource'
            stickyHeader={true}
            trackBy='resource_name'
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
        />
    )
}
export default HCDetailEmployeeTypeTable
