import { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { ORG_FILTERING_PROPERTIES, PROPERTY_FILTER_I18NSTRING } from '../reusable/UseCollectionUtil'
import {
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react'
import ExportTable from '../reusable/ExportTable'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import { getMatchesCountText } from '../reusable/Utils'
import EmptyState from '../reusable/EmptyState'
import { groupListColumnDef } from './OrgDetail'
import { groupExportVisibleColumns } from './OrgGroupList'

interface OrgListProps {
    canAdmin: boolean
    selectedOrgs: any[]
    onSelectedOrgsChange: (selectedItems: any[]) => void
    orgs: any[]
    columns: any[]
    visibleColumns: string[]
    onCreate: () => void
    onEdit: () => void
    onDelete: () => void
    isLoading: boolean
    groups: any
}

const OrgList = (props: OrgListProps) => {
    const {
        canAdmin,
        selectedOrgs,
        onSelectedOrgsChange,
        orgs,
        columns,
        visibleColumns,
        onCreate,
        onEdit,
        onDelete,
        isLoading,
        groups,
    } = props

    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(orgs, {
        propertyFiltering: {
            filteringProperties: ORG_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title='No orgs'
                    subtitle='No orgs to display.'
                    action={canAdmin ? <Button onClick={onCreate}>Create a new org</Button> : <></>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[1],
            },
        },
        selection: {},
    })

    const exportVisibleColumns = [
        'org_name',
        'description',
        'number_of_groups',
        'hr_permission_group',
        'updated_by',
        'updated_at',
        'primary_alias',
        'registered_users',
    ]

    return (
        <Table
            {...collectionProps}
            selectionType={canAdmin ? 'single' : undefined}
            resizableColumns={false}
            selectedItems={selectedOrgs}
            variant={'stacked'}
            onSelectionChange={({ detail }) => {
                onSelectedOrgsChange(detail.selectedItems)
            }}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Orgs'
            wrapLines={true}
            visibleColumns={visibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {canAdmin ? (
                                <Button variant='primary' onClick={onCreate}>
                                    New Organization
                                </Button>
                            ) : (
                                <Button
                                    href={CREATE_TEAM_REQUEST}
                                    iconAlign='right'
                                    iconName='external'
                                    target='_blank'
                                >
                                    Request New Organization
                                </Button>
                            )}
                            {canAdmin ? (
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Edit',
                                            id: 'edit_org',
                                            disabled: selectedOrgs.length !== 1,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete_org',
                                            disabled: selectedOrgs.length !== 1,
                                        },
                                    ]}
                                    onItemClick={({ detail }) => {
                                        if (detail.id === 'edit_org') {
                                            onEdit()
                                        } else if (detail.id === 'delete_org') {
                                            onDelete()
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            ) : (
                                <></>
                            )}
                            <ExportTable
                                enableDropdown={true}
                                includeAggregatedSubTableData={true}
                                parentSheetName={'Organizations'}
                                aggregatedSubDataSheetName={'All Groups'}
                                tableData={allPageItems}
                                visibleColumns={exportVisibleColumns}
                                tableColumnDef={columns}
                                fileName={'EgretOrgs'}
                                columnsWithObjectValues={{ number_of_groups: 'egret' }}
                                subTableData={groups}
                                subTableColumnDef={groupListColumnDef}
                                subTableVisibleColumns={groupExportVisibleColumns}
                                objectNameAttribute={'org_name'}
                            />
                        </SpaceBetween>
                    }
                ></Header>
            }
        />
    )
}

export default OrgList
