export const EMAIL_TO_TEAM = 'mailto:ar-ssds-ross-devs@amazon.com'
// TODO create Egret Wiki
export const EGRET_WIKI_LINK = ''
export const SLACK_AMAZON_OPERATIONS = 'https://amzn-operations.slack.com/archives/'
// TODO create Egret Slack
export const EGRET_SLACK_CHANNEL = 'https://amzn-operations.slack.com/archives/C05RSRTN75M'
export const AMZN_TEAM_BASE_URL = 'https://permissions.amazon.com/a/team'

export const FEATURE_REQUEST_LINK =
    'https://form.asana.com/?k=KreSjyLnWnTRR_obll-jQw&d=8442528107068&rr=353152'
export const REPORT_BUG_LINK =
    'https://t.corp.amazon.com/create/templates/65153289-e1f2-4a17-8593-4876cbe299ed'
export const REQUEST_ACCESS_LINK =
    'https://t.corp.amazon.com/create/templates/3b1016bc-c01a-4e9b-a36a-b02ae46d1798'
export const CREATE_PROGRAM_REQUEST = ''
export const CREATE_TEAM_REQUEST =
    'https://t.corp.amazon.com/create/templates/cae30799-9810-43ee-9fd5-b7899261d269'
export const ADMIN_ACCESS_REQUEST =
    'https://t.corp.amazon.com/create/templates/9ae799e9-6685-4a6c-b9b4-f8144e195381'
