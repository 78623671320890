import { Alert, AlertProps, Link, SpaceBetween } from '@amzn/awsui-components-react'

import {
    ADMIN_ACCESS_REQUEST,
    EGRET_SLACK_CHANNEL,
    SLACK_AMAZON_OPERATIONS,
} from '../../common/LinkUtil'
import { PERMISSION_GROUP_PREFIX } from '../../Constant'

interface AlertTemplateProps {
    text: string | JSX.Element
    header?: string
    type?: AlertProps.Type
    dismissible?: boolean
    onDismiss?: any
}

interface HrDataMessageProps {
    hrTeam: any
}

interface LimitedAccessMessageProps {
    item: string
    link: string
    slackId?: string
    slackName?: string
}

interface NoPermissionMessageProps {
    org?: any
}

export const ALERT_TEMPLATE = (props: AlertTemplateProps) => {
    const { header, text, type, dismissible, onDismiss } = props
    return (
        <Alert
            header={header}
            type={type}
            dismissible={dismissible}
            onDismiss={() => onDismiss({ content: '' })}
        >
            <SpaceBetween direction='horizontal' size='xxs'>
                {text}
            </SpaceBetween>
        </Alert>
    )
}

export const HR_DATA_MESSAGE = (props: HrDataMessageProps) => {
    const { hrTeam } = props
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Headcount detail resource for permission group
            <Link
                external
                externalIconAriaLabel='Opens in a new tab'
                href={PERMISSION_GROUP_PREFIX + hrTeam.hr_permission_group}
            >
                {hrTeam.hr_permission_group_name}
            </Link>
            . Contact
            <SLACK_CHANNEL_TEXT />
            for more information.
        </SpaceBetween>
    )
}

export const SLACK_CHANNEL_TEXT = () => {
    return (
        <Link external externalIconAriaLabel='Opens in a new tab' href={EGRET_SLACK_CHANNEL}>
            #egret-op-management-help-and-requests
        </Link>
    )
}

export const LIMITED_ACCESS_MESSAGE = (props: LimitedAccessMessageProps) => {
    const { item, link, slackId, slackName } = props
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            Read only mode for non-admin user. Contact
            <Link
                external
                externalIconAriaLabel='Opens in a new tab'
                href={slackId ? SLACK_AMAZON_OPERATIONS + slackId : EGRET_SLACK_CHANNEL}
            >
                #{slackName ? slackName : 'falcon-resource-allocation-help-and-requests'}
            </Link>
            {`for more information, request new ${item} `}
            <Link external externalIconAriaLabel='Opens in a new tab' href={link}>
                here
            </Link>
            , or request admin access
            <Link external externalIconAriaLabel='Opens in a new tab' href={ADMIN_ACCESS_REQUEST}>
                here
            </Link>
            .
        </SpaceBetween>
    )
}

export const NO_PERMISSION_MESSAGE = (orgData: NoPermissionMessageProps) => {
    const { org } = orgData
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            {org
                ? `No permission to access the organization group report under ${org.org_name}. Contact`
                : 'No permission to access the business entity. Contact'}
            <SLACK_CHANNEL_TEXT />
            for more information or to request permission.
        </SpaceBetween>
    )
}
