import { COMPONENT_TYPES, EMPTY_SELECTION } from '../../../Constant'
import { deliverableAttributes } from './DeliverableAttributes'
import bigDecimal from 'js-big-decimal'
import { convertToNumberFormat } from '../../reusable/Utils'

export const generateInitialDeliverableState = () => {
    const initialState = {}

    const initialData = (componentType: string, defaultValue) => {
        switch (componentType) {
            case COMPONENT_TYPES.TOGGLE:
                return defaultValue || false
            case COMPONENT_TYPES.SELECT:
                return defaultValue || EMPTY_SELECTION
            default:
                return defaultValue || ''
        }
    }

    deliverableAttributes.forEach((item) => {
        if (!item.componentType) {
            return
        }

        initialState[item.id] = initialData(item.componentType, item.defaultValue)
        if (item.required) {
            initialState[`${item.id}_error`] = initialData(
                COMPONENT_TYPES.INPUT_STRING,
                item.defaultValue,
            )
        }
    })
    return initialState
}

export const roundSingleItem = (estimate) => {
    if (!estimate.toString().length) {
        return estimate
    }

    return convertToNumberFormat(bigDecimal.round(estimate, 2))
}
