import { useEffect, useState } from 'react'
import { Alert, Box, ContentLayout, SpaceBetween } from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import useStore from '../../Store'
import { useAppContext } from '../../../context'
import {
    NO_USER_BUSINESS_ENTITY,
    OBJECT_TYPE,
    ONE_DAY_IN_MILLISECONDS,
    ALERT_TYPES,
} from '../../Constant'
import AuditTable from './AuditTable'
import { convertToLocalTime } from '../../common/Util'
import { ACTION_TYPES, FILTER_PROPERTIES_PREFIX } from './Constant'

const AuditPage = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const endTimestamp = new Date().getTime()
    const startTimestamp = endTimestamp - 7 * ONE_DAY_IN_MILLISECONDS

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)

    const [alertContent, setAlertContent] = useState('')
    const [startEndTimeStamp, setStartEndTimeStamp] = useState({
        start: startTimestamp,
        end: endTimestamp,
    })
    const [isLoading, setIsLoading] = useState(true)
    const [history, setHistory] = useState([])
    const [jobFunctionList, setJobFunctionList] = useState<any[]>([])

    const formatHistoryData = (history) => {
        const addFilterPropertiesHelper = (item, state) => {
            switch (item.object_type) {
                case OBJECT_TYPE.PROGRAM:
                    item.program_name = state.program_name
                    item.stl_alias = state.stl_alias
                    break
                case OBJECT_TYPE.DISCRETIONARY_SPEND:
                    item.ds_item_id_display = state.ds_item_id_display
                    item.total_expenditure = state.total_expenditure
                    break
                case OBJECT_TYPE.DELIVERABLE:
                    item.deliverable_name = state.deliverable_name
                    break
                default:
                    break
            }
        }

        return history.map((item) => {
            const dateTime = convertToLocalTime(new Date(Number(item.timestamp)).toISOString())
            item.date_time = dateTime
            item.date = dateTime.split(' ')[0]

            if (item.filter_properties) {
                for (const key in item.filter_properties) {
                    const objectType = item.object_type
                    if (Object.keys(FILTER_PROPERTIES_PREFIX).includes(objectType)) {
                        item[`${FILTER_PROPERTIES_PREFIX[objectType]}${key}`] =
                            item.filter_properties[key]
                    } else {
                        item[key] = item.filter_properties[key]
                    }
                }
            }

            if (item.action === ACTION_TYPES.DELETE) {
                addFilterPropertiesHelper(item, item.prev_state)
            } else {
                addFilterPropertiesHelper(item, item.next_state)
            }

            return item
        })
    }

    const getHistoryByTimestamp = (startTimestamp, endTimestamp) => {
        setIsLoading(true)
        apiClient
            .get(
                `/business-entity/${selectedBusinessEntity.business_entity_id}/audit?start_timestamp=${startTimestamp}&end_timestamp=${endTimestamp}`,
            )
            .then((res) => {
                setHistory(formatHistoryData(res.data))
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setIsLoading(false))
    }

    const getJobFunctions = async () => {
        await apiClient
            .get(`/settings?keys=job_function`)
            .then((res) => {
                setJobFunctionList(res.data)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    useEffect(() => {
        if (selectedBusinessEntity && selectedBusinessEntity !== NO_USER_BUSINESS_ENTITY) {
            getJobFunctions()
            getHistoryByTimestamp(startEndTimeStamp.start, startEndTimeStamp.end)
        }
    }, [selectedBusinessEntity])

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Audit Dashboard',
                                    href: '',
                                },
                            ]}
                        />
                        {alertContent ? (
                            <Alert type={ALERT_TYPES.ERROR} dismissible>
                                {alertContent}
                            </Alert>
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <AuditTable
                history={history}
                isLoading={isLoading}
                jobFunctionList={jobFunctionList}
                startEndTimeStamp={startEndTimeStamp}
                onStartEndTimeStampChange={setStartEndTimeStamp}
                onAlertContentChange={setAlertContent}
                getHistoryByTimestamp={getHistoryByTimestamp}
            />
        </ContentLayout>
    )
}

export default AuditPage
