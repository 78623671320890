import { useEffect, useState } from 'react'
import useStore from '../../Store'
import {
    Box,
    Button,
    Container,
    ColumnLayout,
    Grid,
    Modal,
    SpaceBetween,
    ExpandableSection,
    TokenGroup,
    Input,
    FormField,
    Select,
    SelectProps,
    Spinner,
} from '@amzn/awsui-components-react'
import { useAppContext } from '../../../context'
import {
    ALERT_TYPES,
    COMPONENT_TYPES,
    EMPTY_SELECTION,
    GLOBAL_BUSINESS_ENTITY,
    MODAL_MODES,
    PROGRAM_ATTRIBUTE_TYPES,
} from '../../Constant'
import { generateUuid } from '../../common/Util'
import {
    CHILD_PROGRAM_CREATE_UPDATE,
    getAttributesByRow,
    getProgramGlobalAttributes,
    getProgramLocalAttributes,
    PARENT_CHILD_PROGRAM_CREATE_UPDATE,
    PARENT_PROGRAM_CREATE_UPDATE,
    programAttributes,
} from './ProgramAttributes'
import GroupFormFieldGenerator from '../reusable/GroupFormFieldGenerator'
import { generateInitialState, KINGPIN_GOAL_OPTIONS } from './ProgramConstants'
import EmptyModalSection from '../reusable/EmptyModalSection'
import { formatRevisionOptions, getMetadataForRevision, objectMap } from '../reusable/Utils'
import Alert from '@amzn/awsui-components-react/polaris/alert'
import RevisionAlert, { SOURCE_PAGE } from '../reusable/RevisionAlert'
import { useNavigate } from 'react-router-dom'

interface CreateEditModalProps {
    isStlNotAdmin: boolean
    selectedRevision: any
    state: any
    selectedPlan: any
    selectedPlanOptions: any[]
    onSelectPlanChange: (plan: any) => void
    onSelectRevisionChange: (revision: any) => void
    selectedRevisionOptions: any[]
    inputChange: (key: any, value: any) => void
    clearAllInputs: () => void
    isInputInvalid: boolean
    onIsInputInvalidChanged: (isInputInvalid: boolean) => void
    isDataUnmodified: boolean
    onIsDataUnmodifiedChanged: (isDataUnmodified: boolean) => void
    isAddingProgram: boolean
    onAlertItemAdd: (content: string, type: ALERT_TYPES) => void
    onDismiss: () => void
    visible: boolean
    onIsLoadingChange: any
    isModalExpand: boolean
    onIsModalExpandChange: (isModalExpand: boolean) => void
    modalMode: string
    programs: any[]
    selectedPrograms: any[]
    onSelectedProgramsChange: (programs: any[]) => void
    selectedProgramBusinessEntity: any
    getLocalPrograms: any // used with inconsistent types
    getParentPrograms: any // used with inconsistent types
    initialKingpinGoals: any[]
    initialImportantLinks: any[]
    globalAttributesError: any
    setGlobalAttributesError: (error: any) => void
    isLoadingGlobalAttrs?: boolean
    isLoadingParentProgramPlans?: boolean
    allParentProgramPlans: any[]
    allParentProgramGlobalAttributes: any[]
    loadSelectProgram: any // used inconsistently
    hideLocalAttributes: boolean
    refreshPage: () => void
    fetchGlobalAttributesForPlan: () => void
    onImportantLinksChange?: any
    plans?: any
}

const CreateEditModal = (props: CreateEditModalProps) => {
    const {
        isStlNotAdmin,
        selectedRevision,
        state,
        selectedPlan,
        selectedPlanOptions,
        onSelectPlanChange,
        onSelectRevisionChange,
        selectedRevisionOptions,
        inputChange,
        clearAllInputs,
        isInputInvalid,
        onIsInputInvalidChanged,
        isDataUnmodified,
        onIsDataUnmodifiedChanged,
        isAddingProgram,
        onAlertItemAdd,
        onDismiss,
        visible,
        onIsLoadingChange,
        isModalExpand,
        onIsModalExpandChange,
        modalMode,
        programs,
        selectedPrograms,
        onSelectedProgramsChange,
        selectedProgramBusinessEntity,
        initialKingpinGoals,
        initialImportantLinks,
        globalAttributesError,
        setGlobalAttributesError,
        isLoadingGlobalAttrs,
        isLoadingParentProgramPlans,
        allParentProgramPlans,
        allParentProgramGlobalAttributes,
        loadSelectProgram,
        hideLocalAttributes,
        refreshPage,
        fetchGlobalAttributesForPlan,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userProps = appContext.userProps

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    // selectedPlan has label, value with value as selectedPlan.value
    const [selectedGoalType, setSelectedGoalType] = useState<SelectProps.Option>(EMPTY_SELECTION)
    // selectedPlan is taken from the program table dropdown
    // selectedPlanForModal tracks the user's selected plan while in create/edit modal
    // if user saves their changes, the plan they select in modal is reflect on the page
    const [selectedPlanForModal, setSelectedPlanForModal] = useState<any>(selectedPlan)
    const [selectedRevisionForModal, setSelectedRevisionForModal] = useState<any>(selectedRevision)
    const [revisionOptionsForModal, setRevisionOptionsForModal] =
        useState<any[]>(selectedRevisionOptions)
    const [goalId, setGoalId] = useState<string>('')
    const [goalLink, setGoalLink] = useState<string>('')
    const [goalLinkError, setGoalLinkError] = useState<string>('')
    const [importantLinkName, setImportantLinkName] = useState<string>('')
    const [importantLink, setImportantLink] = useState<string>('')
    const [importantLinkError, setImportantLinkError] = useState<string>('')
    const [formErrors, setFormErrors] = useState<string[]>([])
    const [isRevisionLocked, setIsRevisionLocked] = useState(false)
    const allBusinessEntities = useStore((state) => state.businessEntities)
    const navigate = useNavigate()
    const defaultPlanOption = {
        label: 'No reference',
        value: {
            ...(selectedPrograms.length ? selectedPrograms[0] : {}),
            ...generateInitialState(getProgramGlobalAttributes()),
            ['kingpin_goals']: [],
            ['important_links']: [],
        },
    }
    // used for initializing global attributes
    const [selectedReferencePlanOption, setSelectedReferencePlanOption] =
        useState<any>(defaultPlanOption)
    const [selectedRevisionMetadata, setSelectedRevisionMetadata] = useState<any>({})
    const referencePlanOptions = [
        ...(allParentProgramPlans?.map((planTypeYear, idx) => ({
            label: planTypeYear,
            value: allParentProgramGlobalAttributes[idx],
        })) || []),
        defaultPlanOption,
    ]
    useEffect(() => {
        if (!allParentProgramPlans?.length) {
            return
        }

        setSelectedReferencePlanOption({
            label: allParentProgramPlans[0],
            value: allParentProgramGlobalAttributes[0],
        })
    }, [allParentProgramGlobalAttributes])

    const isAttributeDisabled = () => {
        return isAddingProgram || modalMode === MODAL_MODES.VIEW || isRevisionLocked
    }
    const mapGoalsOrLinksToTokens = (objType, inputList) => {
        if (!inputList) {
            return
        }
        if (objType === 'kingpin_goals') {
            return inputList.map((goal) =>
                Object.fromEntries([
                    ['label', goal.goal_id],
                    ['description', goal.goal_link],
                    ['labelTag', goal.goal_type],
                    ['disabled', modalMode === MODAL_MODES.VIEW || isAddingProgram],
                    ['dismissLabel', 'Remove ' + goal.goal_id],
                ]),
            )
        } else {
            return inputList.map((link) =>
                Object.fromEntries([
                    ['label', link.link_name],
                    ['description', link.link],
                    ['disabled', modalMode === MODAL_MODES.VIEW || isAddingProgram],
                    ['dismissLabel', 'Remove ' + link.link_name],
                ]),
            )
        }
    }
    const initialStateGoals = mapGoalsOrLinksToTokens('kingpin_goals', initialKingpinGoals)
    const [kingpinGoals, setKingpinGoals] = useState<any[]>([...initialStateGoals])
    const [importantLinks, setImportantLinks] = useState<any[]>(
        mapGoalsOrLinksToTokens('important_links', initialImportantLinks),
    )
    useEffect(() => {
        if (!importantLinks.length) {
            setImportantLinks(mapGoalsOrLinksToTokens('important_links', initialImportantLinks))
        }
    }, [initialImportantLinks])
    useEffect(() => {
        if (!kingpinGoals.length) {
            setKingpinGoals(mapGoalsOrLinksToTokens('kingpin_goals', initialKingpinGoals))
        }
    }, [initialKingpinGoals])

    useEffect(() => {
        setSelectedPlanForModal(selectedPlan)
    }, [selectedPlan])

    useEffect(() => {
        const newPlan = selectedPlanForModal?.value
        const newPlanRevisions = newPlan?.revisions || []
        const newRevision = newPlanRevisions.length ? newPlanRevisions[0] : undefined
        setRevisionOptionsForModal(newPlanRevisions.map((rev) => formatRevisionOptions(rev)))
        setSelectedRevisionMetadata(newRevision)
        setSelectedRevisionForModal(formatRevisionOptions(newRevision))
    }, [selectedPlanForModal])

    const handleAddImportantLink = () => {
        const formId = 'important_links'
        if (importantLinks.length > 0) {
            for (let i = 0; i < importantLinks.length; i++) {
                const err = `Important link ${importantLink} already exists in ${importantLinks[i].label}.`
                if (importantLinks[i].description === importantLink.trim()) {
                    setImportantLinkError(err)
                    inputChange(`${formId}_error`, err)
                    setFormErrors([...formErrors, err])
                    return
                } else {
                    setFormErrors(
                        formErrors.filter((formErr) => !formErr.includes(importantLinks[i].label)),
                    )
                }
            }
        }

        const newImportantLinks = [...importantLinks]
        newImportantLinks.push({
            label: importantLinkName,
            description: importantLink,
            dismissLabel: 'Remove ' + importantLinkName,
        })
        clearImportantLinksInput()
        setImportantLinks(newImportantLinks)
        onIsDataUnmodifiedChanged(false)
    }

    const clearImportantLinksInput = () => {
        setImportantLinkName('')
        setImportantLink('')
        setImportantLinkError('')
    }

    const handleAddKingpinGoal = () => {
        const formId = 'kingpin_goals'
        if (kingpinGoals.length > 0) {
            for (let i = 0; i < kingpinGoals.length; i++) {
                const err = `Goal link ${goalLink} already exists in ${kingpinGoals[i].label}.`
                if (kingpinGoals[i].description === goalLink.trim()) {
                    setGoalLinkError(err)
                    inputChange(`${formId}_error`, err)
                    setFormErrors([...formErrors, err])
                    return
                } else {
                    setFormErrors(
                        formErrors.filter((formErr) => !formErr.includes(kingpinGoals[i].label)),
                    )
                }
            }
        }

        const newKingpinGoals = [...kingpinGoals]
        newKingpinGoals.push({
            label: goalId,
            description: goalLink,
            labelTag: selectedGoalType.label,
            dismissLabel: 'Remove ' + goalId,
        })
        clearGoalsInput()
        setKingpinGoals(newKingpinGoals)
        onIsDataUnmodifiedChanged(false)
    }
    useEffect(() => {
        if (modalMode === MODAL_MODES.VIEW) {
            return
        }
        inputChange(
            'kingpin_goals',
            kingpinGoals && kingpinGoals.length
                ? kingpinGoals.map((goal) => ({
                      goal_id: goal.label,
                      goal_link: goal.description,
                      goal_type: goal.labelTag,
                  }))
                : [],
        )
    }, [kingpinGoals])

    useEffect(() => {
        if (modalMode === MODAL_MODES.VIEW) {
            return
        }
        inputChange(
            'important_links',
            importantLinks && importantLinks.length
                ? importantLinks.map((link) => ({
                      link_name: link.label,
                      link: link.description,
                  }))
                : [],
        )
    }, [importantLinks])

    const clearGoalsInput = () => {
        setSelectedGoalType(EMPTY_SELECTION)
        setGoalId('')
        setGoalLink('')
        setGoalLinkError('')
    }
    const isInputsValid = (isProgram: boolean) => {
        if (!state) {
            return
        }
        let isValid = true
        for (const stateId in state) {
            if (stateId.endsWith('_error')) {
                continue
            }

            let errorMessage = ''
            const value = state[stateId]
            const attrDef: any = programAttributes.find((attr) => attr.id === stateId)
            if (!attrDef) {
                continue
            }
            const required = isStlNotAdmin ? attrDef.required : attrDef.adminRequired
            if (required) {
                if (!isProgram && attrDef.attributeType !== PROGRAM_ATTRIBUTE_TYPES.CORE) {
                    continue
                }

                if (attrDef.componentType === COMPONENT_TYPES.SELECT) {
                    errorMessage = !value.value ? `${attrDef.headerName} must be provided.` : ''
                } else if (
                    attrDef.componentType === COMPONENT_TYPES.INPUT_STRING ||
                    attrDef.componentType === COMPONENT_TYPES.INPUT_INT ||
                    attrDef.componentType === COMPONENT_TYPES.INPUT_FLOAT ||
                    attrDef.componentType === COMPONENT_TYPES.TEXTAREA
                ) {
                    errorMessage = !value ? `${attrDef.headerName} must be provided.` : ''
                }
            }

            if (errorMessage) {
                inputChange(`${stateId}_error`, errorMessage)
                isValid = false
            }
        }

        return isValid
    }

    useEffect(() => {
        if (selectedReferencePlanOption?.value && loadSelectProgram !== undefined) {
            const selectedGlobalAttrs = selectedReferencePlanOption?.value
            loadSelectProgram(selectedGlobalAttrs)
            setKingpinGoals(
                mapGoalsOrLinksToTokens('kingpin_goals', selectedGlobalAttrs?.kingpin_goals || []),
            )
            setImportantLinks(
                mapGoalsOrLinksToTokens(
                    'important_links',
                    selectedGlobalAttrs?.important_links || [],
                ),
            )
        }
    }, [selectedReferencePlanOption])

    useEffect(() => {
        if (!selectedRevisionForModal?.value || !selectedPlanForModal?.value.plan_id) {
            return
        }
        const planRevisions = (selectedPlanForModal.value?.revisions || []).map(
            (rev: any) => rev.revision_id,
        )
        if (!planRevisions.includes(selectedRevisionForModal.value)) {
            return
        }
        getMetadataForRevision(
            apiClient,
            selectedPlanForModal.value.plan_id,
            selectedRevisionForModal.value,
            setSelectedRevisionMetadata,
        )
    }, [selectedRevisionForModal, selectedPlanForModal])

    const handleCloseModal = (reason) => {
        if (reason === 'overlay') {
            // prevent close modal from clicking outside of modal
            return
        }
        onDismiss()
        onIsModalExpandChange(false)
        onIsInputInvalidChanged(false)
        onIsDataUnmodifiedChanged(true)
        clearAllInputs()
        clearGoalsInput()
        setKingpinGoals([])
        clearImportantLinksInput()
        setImportantLinks([])
        setFormErrors([])
        if (setGlobalAttributesError !== undefined) {
            setGlobalAttributesError('')
        }
    }

    const hideRevisionSection =
        !state.is_true_program ||
        isAddingProgram ||
        hideLocalAttributes ||
        (selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY &&
            (modalMode === MODAL_MODES.EDIT || modalMode === MODAL_MODES.VIEW))

    const handleSubmit = () => {
        if (!state) {
            return
        }
        const isProgram = state.is_true_program
        if (!isAddingProgram && !isInputsValid(isProgram)) {
            return
        }
        let payload = {}
        for (const stateId in state) {
            if (!stateId.endsWith('_error') && state[stateId] != undefined) {
                const attrDef = programAttributes.find((attr) => attr.id === stateId)
                const isToken = attrDef && attrDef?.componentType === COMPONENT_TYPES.TOKEN_GROUP
                if (state[stateId].label) {
                    payload[stateId] = state[stateId].value
                } else if (!isToken) {
                    payload[stateId] = state[stateId]
                } else {
                    let tokensToSave = (state[stateId] || []).map((token) => token.label)
                    if (!attrDef.saveTokensAsList) {
                        tokensToSave =
                            state[stateId] && state[stateId].length ? tokensToSave.join(',') : ''
                    }
                    payload[stateId] = tokensToSave
                }
            }
        }
        // state already contains parameters like kingpin goals
        payload = {
            ...payload,
            parent_program_id:
                modalMode === MODAL_MODES.EDIT || isAddingProgram
                    ? selectedPrograms[0].parent_program_id
                    : generateUuid(),
            program_id:
                modalMode === MODAL_MODES.EDIT ? selectedPrograms[0].program_id : generateUuid(),
            revision_id: selectedRevisionForModal?.value,
            stl_business_entity_ids: allBusinessEntities
                .map((be) => be.business_entity_id)
                .join(','),
            requester: userProps.userAlias,
            business_entity_id: selectedBusinessEntity.business_entity_id,
            plan_id: selectedPlanForModal?.value.plan_id,
        }
        if (!isProgram) {
            payload['priority'] = '0'
            payload['display_seq'] = '0'
        }

        onIsLoadingChange(true)
        createUpdateProgram(payload)
            .then(() => {
                if (isAddingProgram) {
                    onAlertItemAdd(
                        `Successfully added program ${
                            selectedPrograms[0].program_name
                        } to Revision ${selectedRevisionMetadata?.revision_number || ''}.`,
                        ALERT_TYPES.SUCCESS,
                    )
                } else if (modalMode === MODAL_MODES.EDIT) {
                    onAlertItemAdd(
                        `Successfully updated program ${state.program_name}.`,
                        ALERT_TYPES.SUCCESS,
                    )
                } else {
                    onAlertItemAdd(
                        `Successfully created program ${state.program_name}`,
                        ALERT_TYPES.SUCCESS,
                    )
                }
                handleCloseModal('')
                onSelectedProgramsChange([])

                onSelectPlanChange(selectedPlanForModal)
                onSelectRevisionChange(selectedRevisionForModal)
                navigate(
                    `/plan/${selectedPlanForModal?.value.plan_id}/revision/${selectedRevisionForModal?.value}`,
                    {
                        state: {
                            isAllProgramsSelected:
                                selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY,
                        },
                    },
                )
                refreshPage()
            })
            .catch((error) => {
                if (isAddingProgram) {
                    onAlertItemAdd(`Failed add program: ${error.response.data}`, ALERT_TYPES.ERROR)
                } else if (modalMode === MODAL_MODES.EDIT) {
                    onAlertItemAdd(
                        `Failed to update program ${selectedPrograms[0].program_name}: ${error.response.data}.`,
                        ALERT_TYPES.ERROR,
                    )
                } else {
                    onAlertItemAdd(
                        `Failed to create program ${state.program_name}: ${error.response.data}.`,
                        ALERT_TYPES.ERROR,
                    )
                }
                console.error(error)
                onIsLoadingChange(false)
            })
            .finally(() => {
                onDismiss()
            })
    }

    const createUpdateProgram = (payload) => {
        let program_type
        if (modalMode === MODAL_MODES.CREATE) {
            program_type = isAddingProgram
                ? CHILD_PROGRAM_CREATE_UPDATE
                : PARENT_CHILD_PROGRAM_CREATE_UPDATE
            return apiClient.post(
                `/plan/${selectedPlanForModal?.value.plan_id}/revision/${selectedRevisionForModal?.value}/programs?program_type=${program_type}`,
                JSON.stringify(payload),
            )
        }
        // can only edit core attributes from All Programs list
        program_type =
            selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                ? PARENT_PROGRAM_CREATE_UPDATE
                : PARENT_CHILD_PROGRAM_CREATE_UPDATE
        const program_id =
            selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
                ? selectedPrograms[0].parent_program_id
                : selectedPrograms[0].program_id
        return apiClient.put(
            `/plan/${selectedPlanForModal?.value.plan_id}/revision/${selectedRevisionForModal?.value}/program/${program_id}?program_type=${program_type}`,
            JSON.stringify(payload),
        )
    }

    const generateModalComponent = (rowNum, attrs) => {
        return (
            <GroupFormFieldGenerator
                isStlNotAdmin={isStlNotAdmin}
                attributes={attrs}
                state={state}
                inputChange={inputChange}
                items={programs}
                selectedItems={selectedPrograms}
                isInputInvalid={isInputInvalid}
                onIsInputInvalidChanged={onIsInputInvalidChanged}
                onIsDataUnmodifiedChanged={onIsDataUnmodifiedChanged}
                isAddingProgram={isAddingProgram}
                modalMode={!isRevisionLocked ? modalMode : MODAL_MODES.VIEW}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
                colNum={attrs.length}
            />
        )
    }
    const isPlanSelectorEnabled = modalMode === MODAL_MODES.CREATE
    const isEditingParentProgram =
        modalMode === MODAL_MODES.EDIT &&
        selectedProgramBusinessEntity.value === GLOBAL_BUSINESS_ENTITY
    const showingGlobalAttrs = state.is_true_program && !isEditingParentProgram
    return !state ? (
        <></>
    ) : (
        <Modal
            onDismiss={({ detail }) => {
                setSelectedPlanForModal(selectedPlan)
                setSelectedRevisionForModal(selectedRevision)
                handleCloseModal(detail.reason)
            }}
            visible={visible}
            size='max'
            closeAriaLabel='Close modal'
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button
                            variant='link'
                            onClick={() => {
                                setSelectedPlanForModal(selectedPlan)
                                setSelectedRevisionForModal(selectedRevision)
                                handleCloseModal('')
                            }}
                        >
                            Cancel
                        </Button>
                        {modalMode !== MODAL_MODES.VIEW && (
                            <Button
                                variant='primary'
                                onClick={handleSubmit}
                                disabled={
                                    (isDataUnmodified && !isAddingProgram) ||
                                    isInputInvalid ||
                                    !!formErrors.length ||
                                    isRevisionLocked
                                }
                            >
                                {isAddingProgram
                                    ? 'Add'
                                    : modalMode === MODAL_MODES.CREATE
                                      ? 'Create'
                                      : 'Update'}
                            </Button>
                        )}
                    </SpaceBetween>
                </Box>
            }
            header={
                isAddingProgram && selectedPrograms.length === 1
                    ? `Add ${selectedPrograms[0].program_name} to Revision ${
                          selectedRevisionMetadata?.revision_number ?? ''
                      }`
                    : modalMode === MODAL_MODES.CREATE
                      ? 'Create New Program'
                      : modalMode === MODAL_MODES.VIEW
                        ? 'Program Details'
                        : 'Edit Program'
            }
        >
            {isLoadingGlobalAttrs || isLoadingParentProgramPlans ? (
                <Spinner />
            ) : (
                <SpaceBetween direction='vertical' size='m'>
                    {showingGlobalAttrs && (
                        <Container header={<Box variant='h4'>Plan & Revision</Box>}>
                            <SpaceBetween size={'s'} direction={'vertical'}>
                                <RevisionAlert
                                    planId={selectedPlanForModal?.value?.plan_id}
                                    selectedRevisionId={selectedRevisionForModal?.value || ''}
                                    sourcePage={SOURCE_PAGE.CREATE_PROGRAM}
                                    setIsLocked={setIsRevisionLocked}
                                    apiClient={apiClient}
                                />
                                <ColumnLayout borders='horizontal' columns={1}>
                                    <FormField
                                        description={
                                            isPlanSelectorEnabled &&
                                            isAddingProgram &&
                                            `Select a plan to add ${state?.program_name || 'the program'} to.`
                                        }
                                    >
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <Select
                                                selectedOption={selectedPlanForModal}
                                                options={selectedPlanOptions}
                                                onChange={({ detail }) => {
                                                    setSelectedPlanForModal(detail.selectedOption)
                                                    if (isAddingProgram) {
                                                        fetchGlobalAttributesForPlan()
                                                    }
                                                }}
                                                disabled={!isPlanSelectorEnabled}
                                            ></Select>
                                            <Select
                                                selectedOption={selectedRevisionForModal}
                                                options={revisionOptionsForModal}
                                                onChange={({ detail }) => {
                                                    setSelectedRevisionForModal(
                                                        detail.selectedOption,
                                                    )
                                                }}
                                                disabled={!isPlanSelectorEnabled}
                                            ></Select>
                                        </SpaceBetween>
                                    </FormField>
                                </ColumnLayout>
                            </SpaceBetween>
                        </Container>
                    )}
                    <Container header={<Box variant='h4'>Common Attributes</Box>}>
                        <ColumnLayout borders='horizontal' columns={1}>
                            {objectMap(
                                getAttributesByRow(PROGRAM_ATTRIBUTE_TYPES.CORE),
                                generateModalComponent,
                            )}
                            {showingGlobalAttrs && (
                                <ColumnLayout borders={'none'} columns={1}>
                                    <Box variant='h4'>{`${selectedPlanForModal?.value.plan_type || ''} ${selectedPlanForModal?.value.year || ''} Attributes`}</Box>
                                    {showingGlobalAttrs &&
                                        globalAttributesError !== '' &&
                                        modalMode === MODAL_MODES.CREATE && (
                                            <Alert>{`${globalAttributesError}${
                                                allParentProgramPlans?.length &&
                                                '. You can select a reference plan to initialize global attributes.'
                                            }`}</Alert>
                                        )}
                                    {globalAttributesError !== '' &&
                                        modalMode === MODAL_MODES.CREATE && (
                                            <ColumnLayout columns={3}>
                                                <FormField
                                                    label={'Select a Reference Plan'}
                                                    description={
                                                        'Select a source plan to initialize global attributes. To leave attributes blank, select No reference.'
                                                    }
                                                >
                                                    <Select
                                                        options={referencePlanOptions}
                                                        selectedOption={selectedReferencePlanOption}
                                                        onChange={({ detail }) => {
                                                            setSelectedReferencePlanOption(
                                                                detail.selectedOption,
                                                            )
                                                        }}
                                                    ></Select>
                                                </FormField>
                                            </ColumnLayout>
                                        )}
                                    {objectMap(
                                        getAttributesByRow(PROGRAM_ATTRIBUTE_TYPES.GLOBAL),
                                        generateModalComponent,
                                    )}
                                </ColumnLayout>
                            )}
                            {showingGlobalAttrs && (
                                <>
                                    {modalMode === MODAL_MODES.VIEW ? (
                                        !importantLinks.length ? (
                                            <EmptyModalSection headerTitle={'Important Links'} />
                                        ) : (
                                            <>
                                                <Box variant={'h3'}>Important Links</Box>
                                                <TokenGroup
                                                    i18nStrings={{
                                                        limitShowFewer:
                                                            'Show fewer Important Links',
                                                        limitShowMore: 'Show more  Important Links',
                                                    }}
                                                    items={importantLinks}
                                                    limit={2}
                                                />
                                            </>
                                        )
                                    ) : (
                                        <Box>
                                            <Box float='right'>
                                                <SpaceBetween direction='horizontal' size='xs'>
                                                    <Button
                                                        onClick={() => handleAddImportantLink()}
                                                        disabled={
                                                            isAttributeDisabled() ||
                                                            !importantLinkName ||
                                                            !importantLink
                                                        }
                                                    >
                                                        Add Important Link
                                                    </Button>
                                                </SpaceBetween>
                                            </Box>

                                            <Box variant='h4'>
                                                Important Links<i> - optional</i>
                                            </Box>
                                            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                                                <FormField
                                                    label={'Link Name'}
                                                    description={'Text name of link'}
                                                >
                                                    <Input
                                                        value={importantLinkName}
                                                        onChange={({ detail }) =>
                                                            setImportantLinkName(detail.value)
                                                        }
                                                        disabled={isAttributeDisabled()}
                                                    ></Input>
                                                </FormField>
                                                <FormField
                                                    label={'Link'}
                                                    description={'URL'}
                                                    errorText={importantLinkError}
                                                >
                                                    <Input
                                                        value={importantLink}
                                                        onChange={({ detail }) =>
                                                            setImportantLink(detail.value)
                                                        }
                                                        disabled={isAttributeDisabled()}
                                                    ></Input>
                                                </FormField>
                                            </Grid>
                                            <TokenGroup
                                                i18nStrings={{
                                                    limitShowFewer: 'Show fewer Important Links',
                                                    limitShowMore: 'Show more  Important Links',
                                                }}
                                                onDismiss={({ detail: { itemIndex } }) => {
                                                    const temp = [
                                                        ...importantLinks.slice(0, itemIndex),
                                                        ...importantLinks.slice(itemIndex + 1),
                                                    ]
                                                    setImportantLinks(temp)
                                                    onIsDataUnmodifiedChanged(false)
                                                }}
                                                items={importantLinks}
                                                limit={2}
                                            />
                                        </Box>
                                    )}
                                    {modalMode === MODAL_MODES.VIEW ? (
                                        !kingpinGoals.length ? (
                                            <EmptyModalSection
                                                headerTitle={'Kingpin Goals'}
                                                objectNames={'Kingpin Goals'}
                                            />
                                        ) : (
                                            <>
                                                <Box variant={'h3'}>Kingpin Goals</Box>
                                                <TokenGroup
                                                    i18nStrings={{
                                                        limitShowFewer: 'Show fewer Kingpin Goals',
                                                        limitShowMore: 'Show more Kingpin Goals',
                                                    }}
                                                    items={kingpinGoals}
                                                    limit={2}
                                                />
                                            </>
                                        )
                                    ) : (
                                        <Box>
                                            <Box float='right'>
                                                <SpaceBetween direction='horizontal' size='xs'>
                                                    <Button
                                                        onClick={() => handleAddKingpinGoal()}
                                                        disabled={
                                                            isAttributeDisabled() ||
                                                            !selectedGoalType.value ||
                                                            !goalId ||
                                                            !goalLink
                                                        }
                                                    >
                                                        Add Kingpin Goal
                                                    </Button>
                                                </SpaceBetween>
                                            </Box>

                                            <Box variant='h4'>
                                                Kingpin Goals<i> - optional</i>
                                            </Box>
                                            <Grid
                                                gridDefinition={[
                                                    { colspan: 4 },
                                                    { colspan: 3 },
                                                    { colspan: 5 },
                                                ]}
                                            >
                                                <FormField label={'Goal Type'}>
                                                    <Select
                                                        selectedOption={selectedGoalType}
                                                        options={KINGPIN_GOAL_OPTIONS}
                                                        onChange={({ detail }) => {
                                                            setSelectedGoalType(
                                                                detail.selectedOption,
                                                            )
                                                        }}
                                                        disabled={isAttributeDisabled()}
                                                    ></Select>
                                                </FormField>
                                                <FormField
                                                    label={'Goal ID'}
                                                    description={'Text name of link'}
                                                >
                                                    <Input
                                                        value={goalId}
                                                        onChange={({ detail }) =>
                                                            setGoalId(detail.value)
                                                        }
                                                        disabled={isAttributeDisabled()}
                                                    ></Input>
                                                </FormField>
                                                <FormField
                                                    label={'Goal Link'}
                                                    description={'URL'}
                                                    errorText={goalLinkError}
                                                >
                                                    <Input
                                                        value={goalLink}
                                                        onChange={({ detail }) =>
                                                            setGoalLink(detail.value)
                                                        }
                                                        disabled={isAttributeDisabled()}
                                                    ></Input>
                                                </FormField>
                                            </Grid>
                                            <TokenGroup
                                                i18nStrings={{
                                                    limitShowFewer: 'Show fewer Kingpin Goals',
                                                    limitShowMore: 'Show more Kingpin Goals',
                                                }}
                                                onDismiss={({ detail: { itemIndex } }) => {
                                                    const temp = [
                                                        ...kingpinGoals.slice(0, itemIndex),
                                                        ...kingpinGoals.slice(itemIndex + 1),
                                                    ]
                                                    setKingpinGoals(temp)
                                                    onIsDataUnmodifiedChanged(false)
                                                }}
                                                items={kingpinGoals}
                                                limit={2}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        </ColumnLayout>
                    </Container>
                    {!hideRevisionSection && (
                        <>
                            <ExpandableSection
                                onChange={({ detail }) => onIsModalExpandChange(detail.expanded)}
                                expanded={isModalExpand}
                                headerText={<Box variant={'strong'}>{`Current Revision`}</Box>}
                            ></ExpandableSection>
                            {isModalExpand && (
                                <Container
                                    header={
                                        <Box variant='h4'>{`
                                            Revision ${
                                                selectedRevisionMetadata?.revision_title
                                                    ? `${selectedRevisionMetadata.revision_number} - ${selectedRevisionMetadata.revision_title}`
                                                    : ''
                                            }
                                         Attributes`}</Box>
                                    }
                                >
                                    <GroupFormFieldGenerator
                                        isStlNotAdmin={isStlNotAdmin}
                                        attributes={getProgramLocalAttributes()}
                                        state={state}
                                        inputChange={inputChange}
                                        items={programs}
                                        selectedItems={selectedPrograms}
                                        onIsInputInvalidChanged={onIsInputInvalidChanged}
                                        onIsDataUnmodifiedChanged={onIsDataUnmodifiedChanged}
                                        isInputInvalid={isInputInvalid}
                                        isAddingProgram={isAddingProgram}
                                        modalMode={!isRevisionLocked ? modalMode : MODAL_MODES.VIEW}
                                        formErrors={formErrors}
                                        setFormErrors={setFormErrors}
                                    />
                                </Container>
                            )}
                        </>
                    )}
                </SpaceBetween>
            )}
        </Modal>
    )
}

export default CreateEditModal
