import { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { Header, Pagination, SpaceBetween, Table, Toggle } from '@amzn/awsui-components-react'
import EmptyState from '../../../reusable/EmptyState'
import {
    getDeliverableColumnDefinitions,
    getDeliverableTableVisibleColumns,
} from '../DeliverableAttributes'

const CopyDeliverableSummaryTable = ({
    deliverables,
    isDeactivateSourceDeliverables,
    onIsDeactivateSourceDeliverablesChange,
    isDeactivateSourceProgram,
    onIsDeactivateSourceProgramChange,
}) => {
    const columnDefinitions = getDeliverableColumnDefinitions()
    const visibleColumns = getDeliverableTableVisibleColumns()

    const [preferences] = useState({ pageSize: 50 })
    const { items, collectionProps, paginationProps } = useCollection(deliverables, {
        propertyFiltering: {
            filteringProperties: [],
            empty: (
                <EmptyState
                    title='No deliverables'
                    subtitle={'`No deliverables to display. '}
                    action={<></>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions[5],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            items={items}
            wrapLines
            stickyHeader={true}
            columnDefinitions={columnDefinitions}
            visibleColumns={visibleColumns}
            loadingText={'Loading deliverables'}
            pagination={<Pagination {...paginationProps} />}
            header={
                <Header
                    actions={
                        <SpaceBetween size={'s'}>
                            <Toggle
                                onChange={({ detail }) => {
                                    onIsDeactivateSourceDeliverablesChange(detail.checked)
                                    if (!detail.checked) {
                                        onIsDeactivateSourceProgramChange(false)
                                    }
                                }}
                                checked={isDeactivateSourceDeliverables}
                            >
                                Deactivate source deliverable(s)
                            </Toggle>
                            {isDeactivateSourceDeliverables ? (
                                <Toggle
                                    onChange={({ detail }) => {
                                        onIsDeactivateSourceProgramChange(detail.checked)
                                    }}
                                    checked={isDeactivateSourceProgram}
                                >
                                    Deactivate source program
                                </Toggle>
                            ) : (
                                <></>
                            )}
                        </SpaceBetween>
                    }
                >
                    Deliverable(s) will be copied. Total: {deliverables.length}
                </Header>
            }
        />
    )
}

export default CopyDeliverableSummaryTable
