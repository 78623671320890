import Box from '@amzn/awsui-components-react/polaris/box'

interface EmptyStateProps {
    title: string
    subtitle: string
    action: any
}

const EmptyState = ({ title, subtitle, action }: EmptyStateProps) => {
    return (
        <Box textAlign='center' color='inherit'>
            <Box variant='strong' textAlign='center' color='inherit'>
                {title}
            </Box>
            <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
                {subtitle}
            </Box>
            {action}
        </Box>
    )
}

export default EmptyState
