import { Box, Header, SpaceBetween } from '@amzn/awsui-components-react'
import { getItemSumByKey } from '../../reusable/Utils'

const DiscretionarySpendTableFooter = (props: { spends: any }) => {
    const { spends } = props
    return (
        <Header
            variant='h3'
            actions={
                <Box margin={'m'}>
                    <SpaceBetween direction='horizontal' size='xxl'>
                        <Box variant={'h4'}>Total Expenditures:</Box>
                        <Box variant={'p'}>
                            {`$${getItemSumByKey(spends, 'total_expenditure').toLocaleString()}`}
                        </Box>
                    </SpaceBetween>
                </Box>
            }
        ></Header>
    )
}

export default DiscretionarySpendTableFooter
