import { Tabs } from '@amzn/awsui-components-react'
import { EMPLOYEE_MEMBER_DETAIL_TABS, EMPLOYEE_TYPE_DISPLAY_NAMES } from '../../../Constant'
import HCDetailEmployeeTypeTable from './HCDetailEmployeeTypeTable'

const HCDetailTable = (props) => {
    const { hrMemberData, employeeTypeData } = props

    const isTabDisable = (index: number) => {
        return hrMemberData ? hrMemberData[EMPLOYEE_MEMBER_DETAIL_TABS[index]]?.length === 0 : true
    }
    const tabItem = (label, index) => {
        return {
            label: `${label}: ${
                employeeTypeData ? employeeTypeData[EMPLOYEE_MEMBER_DETAIL_TABS[index]] : 0
            }`,
            id: EMPLOYEE_MEMBER_DETAIL_TABS[index],
            content: (
                <HCDetailEmployeeTypeTable
                    hrMembers={hrMemberData ? hrMemberData[EMPLOYEE_MEMBER_DETAIL_TABS[index]] : []}
                />
            ),
            disabled: isTabDisable(index),
        }
    }

    return (
        <Tabs
            tabs={EMPLOYEE_TYPE_DISPLAY_NAMES.map((employeeTypeName, index) => {
                return tabItem(employeeTypeName, index)
            })}
        />
    )
}

export default HCDetailTable
