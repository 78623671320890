import { createContext, useContext, useState } from 'react'
import { ROLES } from '../role'

export interface EgretAuthContextProps {
    userRoles: any
    canAdmin: any
    canScopeProgramsDeliverables: any
    canEditPrograms: any
    getUserRoles: any
    isAuthChecked: boolean
    redirectPathNoAuth: string
    redirectPathNoBE: string
}

const EgretAuthContext = createContext<EgretAuthContextProps>({
    userRoles: {},
    canAdmin: {},
    canScopeProgramsDeliverables: {},
    canEditPrograms: {},
    redirectPathNoAuth: '',
    redirectPathNoBE: '',
    getUserRoles: () => {},
    isAuthChecked: false,
})
export const EgretAuthProvider = ({ children, apiClient, userAlias }: any) => {
    const redirectPathNoAuth = '/unauthorized'
    const redirectPathNoBE = '/no-business-entity'
    const [userRoles, setUserRoles] = useState<any>({})
    const [canAdmin, setCanAdmin] = useState<any>({})
    const [canScopeProgramsDeliverables, setCanScopeProgramsDeliverables] = useState<any>({})
    const [canEditPrograms, setCanEditPrograms] = useState<any>({})
    const [isAuthChecked, setIsAuthChecked] = useState(false)
    const getUserRoles = async () => {
        await apiClient
            .get(`/user/${userAlias}`)
            .then((res) => {
                const userRolesDb = res.data
                setUserRoles(userRolesDb)
                setIsAuthChecked(true)
                const canAdminByBE: any = {}
                const canEditProgramsByBE: any = {}
                const canScopeProgramsDelivsByBE: any = {}
                Object.keys(userRolesDb).forEach((beId) => {
                    const rolesForBE = userRolesDb[beId]['ROLES'] || []
                    const beCanAdmin = Boolean(
                        rolesForBE.filter((role) => role.id === ROLES.ADMIN).length,
                    )
                    canAdminByBE[beId] = beCanAdmin
                    canEditProgramsByBE[beId] =
                        beCanAdmin ||
                        Boolean(rolesForBE.filter((role) => role.id === ROLES.STL).length)
                    canScopeProgramsDelivsByBE[beId] = Boolean(
                        rolesForBE.filter(
                            (role) =>
                                role.id === ROLES.ORG_MANAGER || role.id === ROLES.GROUP_MANAGER,
                        ).length,
                    )
                })
                setCanAdmin(canAdminByBE)
                setCanEditPrograms(canEditProgramsByBE)
                setCanScopeProgramsDeliverables(canScopeProgramsDelivsByBE)
            })
            .catch((err) => {
                setIsAuthChecked(true)
                setUserRoles({})
                console.error(err)
            })
    }

    return (
        <EgretAuthContext.Provider
            value={{
                userRoles,
                canAdmin,
                canScopeProgramsDeliverables,
                canEditPrograms,
                redirectPathNoAuth,
                redirectPathNoBE,
                getUserRoles,
                isAuthChecked,
            }}
        >
            {children}
        </EgretAuthContext.Provider>
    )
}
export const useAuth = () => {
    return useContext(EgretAuthContext)
}
