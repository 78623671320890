import {
    Icon,
    Popover,
    PopoverProps,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react'

interface StatusInfoPopoverProps {
    title: string
    popoverContent: any
    size?: PopoverProps.Size
}

const StatusInfoPopover = (props: StatusInfoPopoverProps) => {
    const { title, popoverContent, size } = props
    return (
        <SpaceBetween direction='horizontal' size='xxs'>
            <TextContent>
                <strong>{title}</strong>
            </TextContent>
            <Popover
                position='top'
                size={size}
                triggerType='custom'
                content={popoverContent}
                renderWithPortal
            >
                <Icon name='status-info' />
            </Popover>
        </SpaceBetween>
    )
}

export default StatusInfoPopover
