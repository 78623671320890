import { useEffect, useState } from 'react'
import { generateUuid, isDuplicateByPropertyValue, isValidChanged } from '../../common/Util'
import useStore from '../../Store'
import {
    Box,
    Button,
    Icon,
    Link,
    Toggle,
    TokenGroup,
    TokenGroupProps,
    FormField,
    Input,
    Modal,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react'
import {
    ACCESS_CONTROL_VALIDATION,
    ALERT_TYPES,
    EMPTY_PERMISSION_GROUP_VALIDATION,
    GROUP_NAME_DUPLICATE_MESSAGE,
    GROUP_NAME_VALIDATION_MESSAGE,
    MODAL_MODES,
    validPermissionGroup,
} from '../../Constant'
import { useAppContext } from '../../../context'
import { formatRegisteredUsersTokenGroup } from './OrgUtil'
import { ROLES } from '../../../role'
import { find_list_diff } from '../reusable/Utils'

interface CreateGroupProps {
    canAdmin: boolean
    modalMode: string
    modalVisible: boolean
    closeModalHandler: () => void
    orgId: string
    orgName: string
    groups: any[]
    refreshList: () => void
    onGroupLoadingChange: (isLoading: boolean) => void
    selectedGroups: any
    setSelectedGroups: (selectedGroups: any) => void
    setAlertContent: (alertContent: any) => void
    setAlertType: (ALERT_TYPES) => void
}

const CreateGroup = (props: CreateGroupProps) => {
    const {
        canAdmin,
        modalMode,
        modalVisible,
        closeModalHandler,
        orgId,
        orgName,
        groups,
        refreshList,
        onGroupLoadingChange,
        selectedGroups,
        setSelectedGroups,
        setAlertContent,
        setAlertType,
    } = props

    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const userAlias = appContext.userProps.userAlias

    const selectBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const [previousRegisteredUsers, setPreviousRegisteredUsers] = useState<TokenGroupProps.Item[]>(
        [],
    )
    const [registeredUsers, setRegisteredUsers] = useState<TokenGroupProps.Item[]>([])
    const [registerUserInput, setRegisterUserInput] = useState('')
    const [registerUserInputError, setRegisterUserInputError] = useState('')
    const [primaryContact, setPrimaryContact] = useState<TokenGroupProps.Item[]>([])
    const [primaryContactInput, setPrimaryContactInput] = useState('')
    const [primaryContactInputError, setPrimaryContactInputError] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [isEgret, setIsEgret] = useState(true)
    const [isFalcon, setIsFalcon] = useState(false)
    const [isSaveDisabled, setIsSaveDisabled] = useState(false)
    const [groupDescription, setGroupDescription] = useState('')
    const [hrDataPermissionGroup, setHrDataPermissionGroup] = useState('')
    const [hrDataPermissionGroupName, setHrDataPermissionGroupName] = useState('')
    const [hrPermissionGroupError, setHrPermissionGroupError] = useState('')
    const [groupName, setGroupName] = useState('')
    const [groupNameError, setGroupNameError] = useState('')

    const handleAddPrimaryContact = () => {
        if (primaryContact.length > 0) {
            setPrimaryContactInputError(
                `Only one primary contact allowed. Remove the current one first.`,
            )
            return
        }

        setPrimaryContactInput('')
        setPrimaryContactInputError('')
        setPrimaryContact([
            {
                label: primaryContactInput,
                dismissLabel: 'Remove ' + primaryContactInput,
            },
        ])
        setIsSaveDisabled(primaryContactInput === selectedGroups['primary_alias'])
    }

    const handleAddRegisterUser = () => {
        if (registeredUsers.length > 0) {
            for (let i = 0; i < registeredUsers.length; i++) {
                if (registeredUsers[i].label === registerUserInput) {
                    setRegisterUserInputError(
                        `User ${registerUserInput} is already registered for this team.`,
                    )
                    return
                }
            }
        }

        const newRegisteredUsers = [...registeredUsers]
        newRegisteredUsers.push({
            label: registerUserInput,
            dismissLabel: 'Remove ' + registerUserInput,
        })
        setRegisterUserInput('')
        setRegisterUserInputError('')
        setRegisteredUsers(newRegisteredUsers)
        setIsSaveDisabled(
            JSON.stringify(newRegisteredUsers.map((item) => item.label)) ===
                JSON.stringify(selectedGroups['registered_users']),
        )
    }

    const fillAllInputWithSelectedItem = (group) => {
        const primaryAlias = group['primary_alias']
        setGroupName(group['group_name'])
        setGroupDescription(group['description'])
        setHrDataPermissionGroup(group['hr_permission_group'])
        setHrDataPermissionGroupName(group['hr_permission_group_name'])
        setRegisteredUsers(formatRegisteredUsersTokenGroup(group['registered_users']))
        setPrimaryContact(
            primaryAlias
                ? [
                      {
                          label: primaryAlias,
                          dismissLabel: 'Remove ' + primaryAlias,
                      },
                  ]
                : [],
        )
        setIsActive(group['is_active'])
        setIsEgret(group['is_egret'])
        setIsFalcon(group['is_falcon'])
    }

    const handleCloseModal = (reason) => {
        if (reason === 'overlay') {
            // prevent close modal from clicking outside of modal
            return
        }
        closeModalHandler()
        if (modalMode === MODAL_MODES.EDIT) {
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(selectedGroups[0])
        } else {
            clearAllInput()
        }
        setGroupNameError('')
    }

    const isPayloadValidated = (payload) => {
        if (groupName.trim().length === 0) {
            setGroupNameError(GROUP_NAME_VALIDATION_MESSAGE)
            return false
        }
        return true
    }

    const clearAllInput = () => {
        setGroupName('')
        setGroupDescription('')
        setHrDataPermissionGroup('')
        setHrDataPermissionGroupName('')
        setIsActive(true)
        setIsFalcon(false)
        setIsEgret(true)
        setRegisteredUsers([])
        setRegisterUserInput('')
        setRegisterUserInputError('')
        setPrimaryContact([])
        setPrimaryContactInput('')
        setPrimaryContactInputError('')
    }

    const updateGroupRegisteredUsersPermissions = (registeredUsers, groupId, groupName) => {
        if (registeredUsers.length) {
            apiClient
                .put(
                    `/users/role/${ROLES.GROUP_MANAGER}?aliases=${registeredUsers.join(
                        ',',
                    )}&business_entity_id=${
                        selectBusinessEntity.business_entity_id
                    }&object_ids=${groupId}&object_names=${groupName}`,
                )
                .then((res) => {
                    setAlertType(ALERT_TYPES.SUCCESS)
                    setAlertContent(`Successfully registered user(s) to ${groupName}.`)
                })
                .catch((error) => {
                    console.error(error)
                    setAlertType(ALERT_TYPES.ERROR)
                    setAlertContent(`Failed to register user(s) to ${groupName}.`)
                })
        }
    }

    const removeGroupRegisteredUsersPermissions = (removedUsers, groupId) => {
        apiClient
            .delete(
                `/users/role/${ROLES.GROUP_MANAGER}?aliases=${removedUsers.join(
                    ',',
                )}&business_entity_id=${
                    selectBusinessEntity.business_entity_id
                }&object_ids=${groupId}`,
            )
            .then((res) => {
                setAlertType(ALERT_TYPES.SUCCESS)
                setAlertContent(`Successfully removed user(s) from ${groupName}.`)
            })
            .catch((error) => {
                console.error(error)
                setAlertType(ALERT_TYPES.ERROR)
                setAlertContent(`Failed to remove user(s) from ${groupName}.`)
            })
    }

    const createUpdateGroup = (payload) => {
        return modalMode === MODAL_MODES.EDIT
            ? apiClient.put(
                  `/falcon/orgs/${orgId}/groups/${selectedGroups[0].group_name}`,
                  JSON.stringify(payload),
              )
            : apiClient.post(`/falcon/orgs/${orgId}/groups`, JSON.stringify(payload))
    }

    const handleSubmit = () => {
        const payload = {
            business_entity_id: selectBusinessEntity.business_entity_id,
            business_entity_name: selectBusinessEntity.business_entity_name,
            org_id: orgId,
            org_name: orgName,
            group_id: generateUuid(),
            group_name: groupName.trim(),
            description: groupDescription,
            requester: userAlias,
            hr_permission_group: hrDataPermissionGroup,
            hr_permission_group_name: hrDataPermissionGroupName,
            primary_alias: primaryContact.length > 0 ? primaryContact[0].label : '',
            registered_users: registeredUsers.map((item) => item.label),
            is_active: isActive,
            is_falcon: isFalcon,
            is_egret: isEgret,
        }

        if (!isPayloadValidated(payload)) {
            return
        }

        onGroupLoadingChange(true)
        createUpdateGroup(payload)
            .then((res) => {
                const registerUserGroupId =
                    selectedGroups.length > 0 ? selectedGroups[0].group_id : payload.group_id
                const registerUserGroupName = payload.group_name
                const formattedPrevRegisteredUsers = previousRegisteredUsers.map(
                    (item) => item.label,
                )
                const newRegisteredUsers = [
                    ...payload.registered_users,
                    ...(payload.primary_alias ? [payload.primary_alias] : []),
                ]
                const remove_user_list = find_list_diff(
                    formattedPrevRegisteredUsers,
                    newRegisteredUsers,
                )
                if (remove_user_list.length > 0) {
                    removeGroupRegisteredUsersPermissions(remove_user_list, registerUserGroupId)
                }
                if (newRegisteredUsers.length) {
                    updateGroupRegisteredUsersPermissions(
                        newRegisteredUsers,
                        registerUserGroupId,
                        registerUserGroupName,
                    )
                }

                setAlertType(ALERT_TYPES.SUCCESS)
                if (modalMode === MODAL_MODES.EDIT) {
                    setAlertContent(`Successfully updated group ${selectedGroups[0].group_name}.`)
                } else {
                    setAlertContent(`Successfully created group ${groupName}.`)
                }
                clearAllInput()
                setPreviousRegisteredUsers([...registeredUsers, ...primaryContact])
                setSelectedGroups([])
                refreshList()
            })
            .catch((error) => {
                setAlertType(ALERT_TYPES.ERROR)
                if (modalMode === MODAL_MODES.EDIT) {
                    setAlertContent(
                        `Failed to update group ${selectedGroups[0].group_name}: ${error.response.data}.`,
                    )
                } else {
                    setAlertContent(`Failed to create group ${groupName}: ${error.response.data}.`)
                }
                onGroupLoadingChange(false)
                console.error(error)
            })
        closeModalHandler()
    }

    useEffect(() => {
        if (modalMode === MODAL_MODES.EDIT) {
            if (selectedGroups === undefined || selectedGroups.length === 0) {
                return
            }
            setIsSaveDisabled(true)
            fillAllInputWithSelectedItem(selectedGroups[0])
            const allRegisteredUsers = [
                ...(selectedGroups[0].registered_users || []),
                ...(selectedGroups[0].primary_alias || []),
            ]
            setPreviousRegisteredUsers(formatRegisteredUsersTokenGroup(allRegisteredUsers))
        } else {
            clearAllInput()
        }
    }, [modalMode, selectedGroups])

    return (
        <Modal
            onDismiss={({ detail }) => handleCloseModal(detail.reason)}
            header={modalMode === MODAL_MODES.EDIT ? 'Edit Group' : 'Add a Group'}
            visible={modalVisible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleSubmit}
                            disabled={isSaveDisabled || groupNameError !== ''}
                        >
                            {modalMode === MODAL_MODES.EDIT ? 'Update' : 'Add'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween direction='vertical' size='xs'>
                <FormField label='Group' description='Name of the group' errorText={groupNameError}>
                    <Input
                        value={groupName}
                        onChange={({ detail }) => {
                            const value = detail.value
                            const isDuplicate = isDuplicateByPropertyValue(
                                'group_name',
                                selectedGroups.length > 0
                                    ? selectedGroups[0].group_name
                                    : 'undefined',
                                groups,
                                'group_name',
                                value.trim(),
                            )
                            let errMessage = ''
                            if (value.trim().length === 0) {
                                errMessage = GROUP_NAME_VALIDATION_MESSAGE
                            } else if (isDuplicate) {
                                errMessage = GROUP_NAME_DUPLICATE_MESSAGE
                            }
                            setGroupNameError(errMessage)
                            setGroupName(value)

                            if (modalMode === MODAL_MODES.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            if (
                                !isDuplicate &&
                                isValidChanged(value, selectedGroups[0], 'group_name')
                            ) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Business Entity'
                    description='Business entity that the group belongs to'
                >
                    <Input
                        readOnly={true}
                        value={selectBusinessEntity.business_entity_name}
                    ></Input>
                </FormField>
                <FormField
                    label='Organization'
                    description='Organization that the group belongs to'
                >
                    <Input readOnly={true} value={orgName}></Input>
                </FormField>
                <FormField
                    label='Group Permission Group'
                    description='Amazon TEAMS ID (example: amzn1.abacus.team.hdobhgnhqu7ctplusltq).'
                    errorText={hrPermissionGroupError}
                >
                    <SpaceBetween direction='vertical' size='xs'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Link href='https://permissions.amazon.com/a/user' target={'_blank'}>
                                Find your permission group <Icon name={'external'} />{' '}
                            </Link>
                            <Link
                                href='https://permissions.amazon.com/a/team/new'
                                target={'_blank'}
                            >
                                Create a new permission group <Icon name={'external'} />{' '}
                            </Link>
                        </SpaceBetween>
                        <Input
                            value={hrDataPermissionGroup}
                            onChange={({ detail }) => {
                                const value = detail.value.trim()
                                let errorMessage = ''

                                if (value.length > 0 && !validPermissionGroup.test(value)) {
                                    errorMessage = ACCESS_CONTROL_VALIDATION
                                }

                                if (value.length === 0) {
                                    errorMessage = EMPTY_PERMISSION_GROUP_VALIDATION
                                }
                                setHrPermissionGroupError(errorMessage)
                                setHrDataPermissionGroup(detail.value)

                                if (modalMode === MODAL_MODES.CREATE) {
                                    setIsSaveDisabled(false)
                                    return
                                }
                                setIsSaveDisabled(
                                    !isValidChanged(
                                        value,
                                        selectedGroups[0],
                                        'hr_permission_group',
                                    ),
                                )
                            }}
                        ></Input>
                    </SpaceBetween>
                </FormField>
                <FormField
                    label='Access Control Name'
                    description='Amazon TEAM Name for the headcount data source. For example, Falcon-core-SW-HC'
                >
                    <Input
                        value={hrDataPermissionGroupName}
                        onChange={({ detail }) => {
                            setHrDataPermissionGroupName(detail.value)

                            if (modalMode === MODAL_MODES.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            setIsSaveDisabled(
                                !isValidChanged(
                                    detail.value,
                                    selectedGroups[0],
                                    'hr_permission_group_name',
                                ),
                            )
                        }}
                    ></Input>
                </FormField>
                <FormField
                    label='Primary Contact'
                    description='Primary contact of the group (will be mentioned when send notification)'
                    errorText={primaryContactInputError}
                >
                    <SpaceBetween size='xs' direction='vertical'>
                        <TokenGroup
                            onDismiss={({ detail: { itemIndex } }) => {
                                setPrimaryContact([])
                                setIsSaveDisabled(selectedGroups['primary_alias'] === '')
                            }}
                            items={primaryContact}
                        />
                        <SpaceBetween size='xs' direction='horizontal'>
                            <Input
                                value={primaryContactInput}
                                onChange={({ detail }) => setPrimaryContactInput(detail.value)}
                                placeholder='Add primary contact'
                            ></Input>
                            <Button
                                onClick={() => handleAddPrimaryContact()}
                                disabled={!primaryContactInput}
                            >
                                Add Primary Contact
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </FormField>

                <FormField
                    label='Registered Users'
                    description='Registered users of the group. We cannot remove users at this time.'
                    errorText={registerUserInputError}
                >
                    <SpaceBetween size='xs' direction='vertical'>
                        <TokenGroup
                            i18nStrings={{
                                limitShowFewer: 'Show fewer registered users',
                                limitShowMore: 'Show more registered users',
                            }}
                            onDismiss={({ detail: { itemIndex } }) => {
                                const temp = [
                                    ...registeredUsers.slice(0, itemIndex),
                                    ...registeredUsers.slice(itemIndex + 1),
                                ]
                                setRegisteredUsers(temp)
                                setIsSaveDisabled(
                                    JSON.stringify(temp.map((item) => item.label)) ===
                                        JSON.stringify(selectedGroups['registered_users']),
                                )
                            }}
                            items={registeredUsers}
                            limit={5}
                        />

                        <SpaceBetween size='xs' direction='horizontal'>
                            <Input
                                value={registerUserInput}
                                onChange={({ detail }) => setRegisterUserInput(detail.value)}
                                placeholder='Add Group Manager(s)'
                            ></Input>
                            <Button
                                onClick={() => handleAddRegisterUser()}
                                disabled={!registerUserInput}
                            >
                                Add Registered User
                            </Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </FormField>
                <FormField label='Group Description' description='Description of the group'>
                    <Textarea
                        value={groupDescription}
                        onChange={({ detail }) => {
                            setGroupDescription(detail.value)
                            if (modalMode === MODAL_MODES.CREATE) {
                                setIsSaveDisabled(false)
                                return
                            }
                            if (isValidChanged(detail.value, selectedGroups[0], 'description')) {
                                setIsSaveDisabled(false)
                            } else {
                                setIsSaveDisabled(true)
                            }
                        }}
                    ></Textarea>
                </FormField>
                <FormField label='Active' description='Mark true if it is an active group'>
                    <Toggle
                        onChange={({ detail }) => {
                            setIsActive(detail.checked)
                            setIsSaveDisabled(
                                !isValidChanged(detail.checked, selectedGroups[0], 'is_active'),
                            )
                        }}
                        checked={isActive}
                    >
                        Is group active?
                    </Toggle>
                </FormField>
                {canAdmin ? (
                    <SpaceBetween size={'xs'} direction={'vertical'}>
                        <FormField
                            label='Falcon Usage'
                            description='Mark true if this group is used in Falcon'
                        >
                            <Toggle
                                onChange={({ detail }) => {
                                    setIsFalcon(detail.checked)
                                    setIsSaveDisabled(
                                        !isValidChanged(
                                            detail.checked,
                                            selectedGroups[0],
                                            'is_falcon',
                                        ),
                                    )
                                }}
                                checked={isFalcon}
                            >
                                Is group used in Falcon?
                            </Toggle>
                        </FormField>
                        <FormField
                            label='Egret Usage'
                            description='Mark true if this group is used in Egret'
                        >
                            <Toggle
                                onChange={({ detail }) => {
                                    setIsEgret(detail.checked)
                                    setIsSaveDisabled(
                                        !isValidChanged(
                                            detail.checked,
                                            selectedGroups[0],
                                            'is_egret',
                                        ),
                                    )
                                }}
                                checked={isEgret}
                            >
                                Is group used in Egret?
                            </Toggle>
                        </FormField>
                    </SpaceBetween>
                ) : (
                    <></>
                )}
            </SpaceBetween>
        </Modal>
    )
}

export default CreateGroup
