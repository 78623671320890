import { ModuleRegistry, GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { ClientSideRowModelModule } from 'ag-grid-community'
// if you are using row grouping or tree data, these two are required
import { InfiniteRowModelModule } from 'ag-grid-community'
import {
    RowGroupingModule,
    ExcelExportModule,
    SetFilterModule,
    LicenseManager,
} from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'

import {
    Box,
    ContentLayout,
    Select,
    SelectProps,
    SpaceBetween,
    FormField,
    Toggle,
    Button,
    Container,
    Header,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../../context'
import { getProgramTableLocalVisibleColumns, programAttributes } from '../program/ProgramAttributes'
import { EMPTY_REVISION_SELECTION, SUMMARY_HEADCOUNT_TYPE } from '../../Constant'
import { renderDeliverableCell } from '../program/deliverables/DeliverableAttributes'
import useStore from '../../Store'
import { useNavigate } from 'react-router-dom'
import { formatPlanOptionsWithData, formatRevisionOptions } from '../reusable/Utils'
import GenericSummaryTable from '../reusable/GenericSummaryTable'
import {
    DEFAULT_COL_WIDTH,
    DEFAULT_NAME_COL_WIDTH,
    formatSelection,
    GRAND_TOTAL_COLOR_CODE,
    hideZerosAndTruncateNumbers,
    NORMAL_CELL_COLOR_CODE,
} from './SummaryUtil'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

const STLSummaryView = (props) => {
    const { isRevamped } = props
    const historyPrefix = isRevamped ? 'program-summary-revamped' : 'program-summary'
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    // todo: refactor to use reducer instead of states
    const paths = location.pathname.split('/')
    const isFullUrl = paths.length === 8
    const [urlIds, setUrlIds] = useState({
        planId: isFullUrl ? paths[3] : undefined,
        revisionId: isFullUrl ? paths[5] : undefined,
        programId: isFullUrl ? paths[7] : undefined,
    })

    const [gridApi, setGridApi] = useState<any>()
    const [programOptions, setProgramOptions] = useState<SelectProps.Option[]>([])
    const [programOptionsMetadata, setProgramOptionsMetadata] = useState<any>({})
    const [isProgramMetadataLoading, setIsProgramMetadataLoading] = useState<boolean>(true)
    const [selectedProgram, setSelectedProgram] = useState<any>({})
    const [revisionOptions, setRevisionOptions] = useState<SelectProps.Option[]>([])
    const [selectedRevision, setSelectedRevision] = useState<SelectProps.Option>(
        revisionOptions.length
            ? {
                  label: revisionOptions[0].label,
                  value: revisionOptions[0].value,
              }
            : { label: 'Select Revision', value: '' },
    )
    const [planOptions, setPlanOptions] = useState<any>([])
    const [selectedPlan, setSelectedPlan] = useState<any>({})
    const [deliverables, setDeliverables] = useState<any>([])
    const [estimates, setEstimates] = useState<any>([])
    const [orgs, setOrgs] = useState<any>([])
    const [orgMap, setOrgMap] = useState<any>({})
    const [programColumns, setProgramColumns] = useState<any>([])
    const [programVisibleColumns, setProgramVisibleColumns] = useState<any>([])
    const [summaryCols, setSummaryCols] = useState<any>(null)
    const [summaryRows, setSummaryRows] = useState<any>(null)
    const [deliverableMap, setDeliverableMap] = useState<any>({})
    const [showScopedOnlyDeliverables, setShowScopedOnlyDeliverables] = useState<boolean>(true)
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const history = useNavigate()

    useEffect(() => {
        getBusinessEntityPlans()
        getBusinessEntityOrgs()
    }, [])

    useEffect(() => {
        if (selectedPlan?.value && selectedRevision?.value) {
            getLocalPrograms()
        }
    }, [selectedPlan, selectedRevision])

    useEffect(() => {
        if (!gridApi || !selectedProgram.value) {
            return
        }
        gridApi.showLoadingOverlay()
        const programCols = getProgramColumnDefinitionsForDeliverables()
        setProgramColumns(programCols)
        setProgramVisibleColumns(getProgramTableLocalVisibleColumns())
        getDeliverables()
    }, [selectedProgram])

    useEffect(() => {
        if (!deliverables.length || !orgMap) {
            setEstimates([])
            return
        }
        getProgramHCEstimates()
    }, [deliverables, orgMap])

    useEffect(() => {
        if (gridApi) {
            initialSTLSummary(estimates, deliverables, orgs)
        }
    }, [orgs, deliverables, estimates])

    useEffect(() => {
        if (!gridApi || !summaryRows || !summaryCols) {
            return
        }
        calculateTotalRow()
    }, [summaryCols, summaryRows])

    useEffect(() => {
        if (!summaryRows && gridApi) {
            gridApi.showLoadingOverlay()
        }
    }, [summaryRows])

    const getProgramColumnDefinitionsForDeliverables = () => {
        return programAttributes.flatMap((attr) => [
            {
                id: attr.id,
                header: attr.headerName,
                cell: (item) => renderDeliverableCell(item, attr),
                sortingField: attr.id,
            },
        ])
    }

    const getProgramHCEstimates = () => {
        const deliverable_ids = deliverables.map((deliv) => deliv['deliverable_id']).join(',')
        const url = isRevamped
            ? `/program-headcount-estimates/program/${programOptionsMetadata[selectedProgram.value].program_id}/plan/${selectedPlan.value}/revision/${selectedRevision.value}`
            : `/program/${programOptionsMetadata[selectedProgram.value].program_id}/deliverables/headcount-estimates?plan_id=${selectedPlan.value}&revision_id=${selectedRevision.value}&deliverable_ids=${deliverable_ids}`
        apiClient
            .get(url)
            .then((res) => {
                const result = res.data
                const relatedOrgs = new Set(result.map((estimate) => orgMap[estimate.org_id]))
                setOrgs(relatedOrgs)
                setEstimates(result)
            })
            .catch((err) => {
                console.error(err)
                setEstimates([])
                setOrgs([])
            })
    }

    const getLocalPrograms = () => {
        apiClient
            .get(`/plan/${selectedPlan.value}/revision/${selectedRevision.value}/programs`)
            .then((res) => {
                const result = res.data
                const programs = result
                    .filter((prog) => prog?.is_active)
                    .flatMap((prog) => {
                        return {
                            label: prog.program_name,
                            value: prog.program_id,
                        }
                    })
                    .sort((a, b) => a.label.localeCompare(b.label))
                const currentProgram = programs.find((prog) => prog.value === urlIds.programId)
                setSelectedProgram(currentProgram ? currentProgram : programs[0])

                const programMetadata = {}
                result.forEach((prog) => {
                    programMetadata[prog.program_id] = prog
                })
                const currentUrlIds = {
                    planId: urlIds.planId || planOptions[0].value,
                    revisionId: urlIds.revisionId || revisionOptions[0].value,
                    programId:
                        urlIds.programId || (programs.length ? programs[0].value : undefined),
                }
                history(
                    `/${historyPrefix}/plan/${currentUrlIds.planId}/revision/${currentUrlIds.revisionId}/program/${currentUrlIds.programId}`,
                )
                setUrlIds(currentUrlIds)
                setProgramOptionsMetadata(programMetadata)
                setProgramOptions(programs)
                setIsProgramMetadataLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setProgramOptions([])
                setProgramOptionsMetadata({})
                setSelectedProgram({})
            })
    }

    const getDeliverables = () => {
        apiClient
            .get(
                `/plan/${selectedPlan.value}/revision/${selectedRevision.value}/program/${programOptionsMetadata[selectedProgram.value].program_id}/deliverables?is_true_program=false&is_global_program=false`,
            )
            .then((res) => {
                // note: returned result list is sorted by deliverable priority and name
                const result = res.data.filter((deli) => deli?.is_active)
                const deliIdNameMap = {}
                result.forEach((deli) => {
                    deliIdNameMap[deli.deliverable_id] = deli.deliverable_name
                })
                setDeliverableMap(deliIdNameMap)
                setDeliverables(result)
            })
            .catch((err) => {
                console.error(err)
                setDeliverables([])
                setDeliverableMap({})
            })
    }

    const getBusinessEntityOrgs = () => {
        apiClient
            .get(`/falcon/business-entity/${selectedBusinessEntity?.business_entity_id}/orgs`)
            .then((res) => {
                const result = res.data
                const orgIdNameMap = {}
                result.forEach((org) => {
                    orgIdNameMap[org.org_id] = org
                })
                setOrgMap(orgIdNameMap)
            })
            .catch((err) => {
                console.error(err)
                setOrgMap({})
            })
    }

    const getBusinessEntityPlans = () => {
        apiClient
            .get(`/plan/business-entity/${selectedBusinessEntity?.business_entity_id}?year=`)
            .then((res) => {
                const formattedPlanOptions = res.data.map((plan) => formatPlanOptionsWithData(plan))
                if (formattedPlanOptions.length) {
                    let selectedPlan = formattedPlanOptions[0]
                    let revisionOptions = selectedPlan.data.revisions.map((rev: any) =>
                        formatRevisionOptions(rev),
                    )

                    if (!urlIds.planId) {
                        setSelectedPlan(selectedPlan)
                        setSelectedRevision(revisionOptions[0])
                    } else {
                        selectedPlan = res.data.find((plan) => plan.plan_id === urlIds.planId)
                        revisionOptions = selectedPlan.revisions.map((rev: any) =>
                            formatRevisionOptions(rev),
                        )
                        setSelectedPlan(formatPlanOptionsWithData(selectedPlan))
                        setSelectedRevision(
                            revisionOptions.find((rev) => rev.value === urlIds.revisionId),
                        )
                    }
                    setRevisionOptions(revisionOptions)
                    setPlanOptions(formattedPlanOptions)
                } else {
                    setSelectedPlan({})
                    setRevisionOptions([])
                    setPlanOptions([])
                }
            })
            .catch((err) => {
                console.error(err)
                setPlanOptions([])
                setSelectedPlan({})
                setRevisionOptions([])
            })
    }

    const initialSTLSummary = (estimates: any[], deliverables: any[], orgs: any[]) => {
        if (!estimates || !orgs || !deliverables) {
            return
        }
        generateRows()
        generateColumns()
    }

    const generateDefaultDict = () => {
        return new Proxy(
            {},
            {
                get: (target, name) => (name in target ? target[name] : 0),
            },
        )
    }

    const generateScopedRowsRevamp = (data, scopedDeliverables) => {
        estimates.forEach((est) => {
            const org_id = est.org_id
            const deliverable_id = est.deliverable_id
            if (!(deliverable_id in deliverableMap)) {
                return
            }

            scopedDeliverables.add(deliverable_id)
            if (!Object.keys(data).includes(deliverable_id)) {
                data[deliverable_id] = generateDefaultDict()
                data[deliverable_id].deliverable = est.deliverable_name
            }
            const value = est.headcount_value
            switch (est.headcount_type) {
                case SUMMARY_HEADCOUNT_TYPE.CT:
                    data[deliverable_id].total_ct_id += parseFloat(value)
                    data[deliverable_id][`${org_id}_ct`] += parseFloat(value)
                    break
                case SUMMARY_HEADCOUNT_TYPE.FF:
                    data[deliverable_id].total_ff_id += parseFloat(value)
                    data[deliverable_id][`${org_id}_ff`] += parseFloat(value)
                    break
                default:
                    break
            }
            data[deliverable_id].total += parseFloat(value)
            data[deliverable_id].scoped =
                data[deliverable_id].total_ct_id > 0 || data[deliverable_id].total_ff_id > 0
        })
    }

    const generateScopedRows = (data, scopedDeliverables) => {
        // todo: delete this method once we have fully switched to using the new hc estimate table
        estimates.forEach((est) => {
            const deliverable_id = est.deliverable_id
            const org_id = est.org_id
            scopedDeliverables.add(deliverable_id)

            if (!Object.keys(data).includes(deliverable_id)) {
                data[deliverable_id] = generateDefaultDict()
                data[deliverable_id].deliverable = deliverableMap[deliverable_id]
            }

            data[deliverable_id].total_ct_id += parseFloat(est.hc_ct)
            data[deliverable_id].total_ff_id += parseFloat(est.hc_ff)
            data[deliverable_id].total += parseFloat(est.hc_ct) + parseFloat(est.hc_ff)
            data[deliverable_id][`${org_id}_ct`] += parseFloat(est.hc_ct)
            data[deliverable_id][`${org_id}_ff`] += parseFloat(est.hc_ff)
            data[deliverable_id].scoped =
                data[deliverable_id].total_ct_id > 0 || data[deliverable_id].total_ff_id > 0
        })
    }

    const generateRows = () => {
        if (!estimates) {
            return
        }
        // data object stores -> key: deliverable id, value: deliverable row information
        const data = {}
        const scopedDeliverables = new Set()
        if (isRevamped) {
            generateScopedRowsRevamp(data, scopedDeliverables)
        } else {
            // todo: remove else condition once fully migrated to new hc table
            generateScopedRows(data, scopedDeliverables)
        }
        // add deliverables with no estimates to the rows
        deliverables.forEach((deli) => {
            const deliverable_id = deli.deliverable_id
            if (!scopedDeliverables.has(deliverable_id)) {
                data[deliverable_id] = generateDefaultDict()
                data[deliverable_id].scoped = false
                data[deliverable_id].deliverable = deliverableMap[deliverable_id]
            }
        })
        const rowData = Object.keys(data).flatMap((key) => {
            return data[key]
        })

        const sortedRows = rowData.sort((a, b) => a.deliverable?.localeCompare(b.deliverable))
        setSummaryRows(sortedRows)
    }

    const generateColumns = () => {
        const columns: any[] = [
            {
                headerName: 'Deliverable',
                field: 'deliverable',
                initialWidth: DEFAULT_NAME_COL_WIDTH,
                pinned: 'left',
            },
            {
                headerName: 'Total (CT + FF)',
                field: 'total',
                initialWidth: DEFAULT_NAME_COL_WIDTH,
                pinned: 'left',
                cellRenderer: (params) => {
                    return hideZerosAndTruncateNumbers(params)
                },
            },
            {
                headerName: 'Total',
                children: [
                    {
                        headerName: 'C&T',
                        field: 'total_ct_id',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                    {
                        field: 'total_ff_id',
                        headerName: 'FF',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                ],
            },
        ]
        // TODO: stl summary revamp will need group sub children columns
        const orgColumns: any[] = []
        orgs.forEach((org) => {
            orgColumns.push({
                headerName: org?.org_name,
                children: [
                    {
                        field: `${org?.org_id}_ct`,
                        headerName: 'C&T',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                    {
                        field: `${org?.org_id}_ff`,
                        headerName: 'FF',
                        initialWidth: DEFAULT_COL_WIDTH,
                        suppressSizeToFit: true,
                        cellRenderer: (params) => {
                            return hideZerosAndTruncateNumbers(params)
                        },
                    },
                ],
            })
        })
        setSummaryCols(
            columns.concat(orgColumns.sort((a, b) => a.headerName.localeCompare(b.headerName))),
        )
    }

    const calculateTotalRow = () => {
        if (!summaryRows.length) {
            return
        }
        const total = generateDefaultDict()
        const totalCols = summaryCols?.splice(2)
        totalCols?.forEach((col) => {
            const fields = col.children.flatMap((item) => item.field)
            summaryRows.forEach((row) => {
                fields.forEach((field) => {
                    total[field] += row[field]
                })
            })
        })
        total['total'] = total['total_ct_id'] + total['total_ff_id']
        total['deliverable'] = 'Total'
        total['cellRenderer'] = (params) => {
            return hideZerosAndTruncateNumbers(params)
        }
        gridApi.setPinnedBottomRowData([total])
    }

    const onGridReady = (params: any) => {
        setGridApi(params.api)
        params.columnApi.autoSizeAllColumns()
    }

    const handleClickExport = () => {
        gridApi?.exportDataAsExcel()
    }

    const clearData = () => {
        setSummaryRows(null)
        setSummaryCols(null)
        setEstimates(null)
    }

    const gridOptions: GridOptions = {
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
        getRowStyle: (params) => {
            return params.node.id === 'b-0'
                ? { fontWeight: 'bold', background: GRAND_TOTAL_COLOR_CODE }
                : { fontWeight: 'normal', background: NORMAL_CELL_COLOR_CODE }
        },
        defaultColDef: { lockPosition: true },
    }

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Program Summary',
                                    href: '',
                                },
                            ]}
                        />
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween size={'s'} direction={'vertical'}>
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size={'xxl'} direction={'vertical'}>
                        <GenericSummaryTable
                            columns={programColumns}
                            visibleColumns={programVisibleColumns}
                            itemsToShow={[programOptionsMetadata[selectedProgram.value]]}
                            isLoading={isProgramMetadataLoading}
                            nameField={'program_name'}
                            defaultNameField={'Program'}
                            objectType={'Program'}
                            actions={undefined}
                            filter={
                                <SpaceBetween direction={'horizontal'} size={'s'}>
                                    <Select
                                        selectedOption={selectedPlan}
                                        onChange={({ detail }) => {
                                            const option: any = detail.selectedOption
                                            const revisions = option.data?.revisions.map(
                                                (rev: any) => {
                                                    return formatRevisionOptions(rev)
                                                },
                                            )
                                            if (revisions.length) {
                                                setRevisionOptions(revisions)
                                                setSelectedRevision(revisions[0])
                                            } else {
                                                setRevisionOptions([])
                                                setSelectedRevision(EMPTY_REVISION_SELECTION)
                                            }
                                            setSelectedPlan(option)
                                            clearData()
                                            setUrlIds({
                                                planId: option.value,
                                                revisionId: revisions[0].value,
                                                programId: urlIds.programId,
                                            })
                                            history(
                                                `/${historyPrefix}/plan/${option.value}/revision/${revisions[0].value}/program/${urlIds.programId}`,
                                            )
                                        }}
                                        options={planOptions}
                                        disabled={!planOptions.length}
                                    />
                                    <Select
                                        selectedOption={selectedRevision}
                                        onChange={({ detail }) => {
                                            const selectedOption = detail.selectedOption
                                            setSelectedRevision(selectedOption)
                                            clearData()
                                            history(
                                                `/${historyPrefix}/plan/${urlIds.planId}/revision/${selectedOption.value}/program/${urlIds.programId}`,
                                            )
                                        }}
                                        options={revisionOptions}
                                        disabled={!revisionOptions.length}
                                    />
                                    <Select
                                        filteringType={'auto'}
                                        selectedOption={formatSelection(selectedProgram)}
                                        onChange={({ detail }) => {
                                            const selectedOption: any = detail.selectedOption
                                            const programMetadata =
                                                programOptionsMetadata[selectedOption.value]
                                            const planId = programMetadata?.plan_id
                                            const revisionId = programMetadata?.revision_id
                                            const programId = selectedOption.value
                                            setSelectedProgram(selectedOption)
                                            setUrlIds({
                                                planId: planId,
                                                revisionId: revisionId,
                                                programId: programId,
                                            })
                                            clearData()
                                            history(
                                                `/${historyPrefix}/plan/${planId}/revision/${revisionId}/program/${programId}`,
                                            )
                                        }}
                                        options={programOptions}
                                        disabled={!programOptions.length}
                                    />
                                </SpaceBetween>
                            }
                            wrapLines
                            includePagination={false}
                        />
                    </SpaceBetween>
                </Box>
                <Box margin={{ left: 's', right: 's' }}>
                    <Container
                        header={
                            <Header
                                actions={
                                    <SpaceBetween direction={'horizontal'} size={'xxl'}>
                                        <FormField label={'Deliverables'}>
                                            <SpaceBetween size={'xs'} direction={'horizontal'}>
                                                <span>All</span>
                                                <Toggle
                                                    checked={showScopedOnlyDeliverables}
                                                    onChange={({ detail }) => {
                                                        setShowScopedOnlyDeliverables(
                                                            detail.checked,
                                                        )
                                                    }}
                                                >
                                                    Scoped
                                                </Toggle>
                                            </SpaceBetween>
                                        </FormField>
                                        <Button onClick={() => handleClickExport()}>Export</Button>
                                    </SpaceBetween>
                                }
                            ></Header>
                        }
                    >
                        <Box margin={{ top: 's' }}>
                            <div className='ag-theme-quartz' style={{ height: 500 }}>
                                <AgGridReact
                                    rowData={
                                        showScopedOnlyDeliverables
                                            ? summaryRows?.filter((row) => row.scoped)
                                            : summaryRows
                                    }
                                    columnDefs={summaryCols}
                                    onGridReady={onGridReady}
                                    gridOptions={gridOptions}
                                />
                            </div>
                        </Box>
                    </Container>
                </Box>
            </SpaceBetween>
        </ContentLayout>
    )
}

export default STLSummaryView
