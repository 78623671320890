import {
    Alert,
    Box,
    Container,
    ContentLayout,
    Select,
    SpaceBetween,
    TextContent,
    Toggle,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'

import { ModuleRegistry } from 'ag-grid-community'
import { ClientSideRowModelModule } from 'ag-grid-community'
import { ServerSideRowModelModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { ExcelExportModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { SetFilterModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { InfiniteRowModelModule } from 'ag-grid-community'
import './SummaryView.scss'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { RowGroupingModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

import Button from '@amzn/awsui-components-react/polaris/button'
import OrgSummarySpendTable from './OrgSummarySpendTable'
import { formatRevisionOptions } from '../reusable/Utils'
import { AgGridReact } from 'ag-grid-react'
import { GRAND_TOTAL_COLOR_CODE, NORMAL_CELL_COLOR_CODE } from './SummaryUtil'
import { ACTION_TYPES, useSummaryData } from './useSummaryData'

const OrgSummary = (props) => {
    const { isRevamped } = props
    const { data, dispatch } = useSummaryData(isRevamped)

    const setShowScopedOnlySpendEstimates = (checked: boolean) => {
        dispatch({ type: ACTION_TYPES.SPEND, payload: { key: 'showScopedOnly', data: checked } })
    }

    const onGridReady = (params: any) => {
        dispatch({ type: 'GRID_API', payload: params.api })
        params.api.autoSizeAllColumns()
    }

    const handleClickExport = async () => {
        data.gridApi?.exportDataAsExcel()
    }

    const getFormattedOrg = (org): any => {
        return {
            value: org.value,
            label: (
                <TextContent>
                    <p> {`${org.label}`} </p>
                </TextContent>
            ),
        }
    }

    return (
        <ContentLayout
            header={
                <Box margin={{ left: 's', right: 's' }}>
                    <SpaceBetween size='xs' direction='vertical'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                {
                                    text: 'Org Summary',
                                    href: `/plan/${data[ACTION_TYPES.PLAN].selected?.data?.plan_id}/org-summary`,
                                },
                            ]}
                        />
                        {!data[ACTION_TYPES.ORG].selected.value ||
                        !data[ACTION_TYPES.REVISION].selected.label ? (
                            <Alert>
                                {
                                    'Please select a org and a revision from the dropdown to view HC estimation summary.'
                                }
                            </Alert>
                        ) : (
                            <></>
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <Box margin={{ horizontal: 's' }} display={'block'}>
                <SpaceBetween direction={'vertical'} size={'s'}>
                    <Container>
                        <SpaceBetween direction={'vertical'} size={'xxl'}>
                            <div>
                                <Box float={'left'} margin={{ top: 'xs' }}>
                                    <SpaceBetween direction={'horizontal'} size={'s'}>
                                        <Select
                                            filteringType={'auto'}
                                            placeholder={'Selected Plan'}
                                            selectedOption={data[ACTION_TYPES.PLAN].selected}
                                            onChange={({ detail }) => {
                                                const option: any = detail.selectedOption
                                                const revisions: any =
                                                    option?.data.revisions.map(
                                                        formatRevisionOptions,
                                                    )
                                                if (revisions.length) {
                                                    dispatch({
                                                        type: 'SET_MANY',
                                                        payload: {
                                                            [ACTION_TYPES.REVISION]: [
                                                                { key: 'options', data: revisions },
                                                                {
                                                                    key: 'selected',
                                                                    data: revisions[0],
                                                                },
                                                            ],
                                                        },
                                                    })
                                                } else {
                                                    dispatch({
                                                        type: 'SET_MANY',
                                                        payload: {
                                                            [ACTION_TYPES.REVISION]: [
                                                                { key: 'options', data: [] },
                                                                {
                                                                    key: 'selected',
                                                                    data: {
                                                                        label: 'Select Revision',
                                                                        value: '',
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    })
                                                }
                                                dispatch({
                                                    type: ACTION_TYPES.PLAN,
                                                    payload: { key: 'selected', data: option },
                                                })
                                            }}
                                            options={data[ACTION_TYPES.PLAN].options}
                                            disabled={!data[ACTION_TYPES.REVISION].options.length}
                                        />
                                        <Select
                                            filteringType={'auto'}
                                            placeholder={'Selected Revision'}
                                            selectedOption={data[ACTION_TYPES.REVISION].selected}
                                            onChange={({ detail }) => {
                                                dispatch({
                                                    type: ACTION_TYPES.REVISION,
                                                    payload: {
                                                        key: 'selected',
                                                        data: detail.selectedOption,
                                                    },
                                                })
                                            }}
                                            options={data[ACTION_TYPES.REVISION].options}
                                            disabled={!data[ACTION_TYPES.REVISION].options.length}
                                        />
                                        <Select
                                            filteringType={'auto'}
                                            placeholder={'Selected Org'}
                                            selectedOption={getFormattedOrg(
                                                data[ACTION_TYPES.ORG].selected,
                                            )}
                                            options={data[ACTION_TYPES.ORG].options}
                                            onChange={({ detail }) => {
                                                dispatch({
                                                    type: ACTION_TYPES.ORG,
                                                    payload: {
                                                        key: 'selected',
                                                        data: detail.selectedOption,
                                                    },
                                                })
                                            }}
                                            disabled={!data[ACTION_TYPES.ORG].options.length}
                                        />
                                    </SpaceBetween>
                                </Box>
                                <Box float={'right'} margin={{ top: 'xs' }}>
                                    <SpaceBetween direction={'horizontal'} size={'xxl'}>
                                        <div>
                                            <SpaceBetween size={'xs'} direction={'horizontal'}>
                                                <span>All</span>
                                                <Toggle
                                                    checked={
                                                        data[ACTION_TYPES.DELIVERABLES]
                                                            .showScopedOnly
                                                    }
                                                    onChange={({ detail }) => {
                                                        dispatch({
                                                            type: ACTION_TYPES.DELIVERABLES,
                                                            payload: {
                                                                key: 'showScopedOnly',
                                                                data: detail.checked,
                                                            },
                                                        })
                                                    }}
                                                >
                                                    Scoped
                                                </Toggle>
                                            </SpaceBetween>
                                        </div>

                                        <Button onClick={() => handleClickExport()}>
                                            {`Export`}
                                        </Button>
                                    </SpaceBetween>
                                </Box>
                            </div>
                            <div
                                className='ag-theme-quartz' // applying the grid theme
                                style={{ height: '60vh' }} // the grid will fill the size of the parent container
                            >
                                <AgGridReact
                                    onGridReady={onGridReady}
                                    gridOptions={{
                                        getRowStyle: (params) => {
                                            return {
                                                fontWeight:
                                                    params.node.rowPinned || params.node.footer
                                                        ? 'bold'
                                                        : 'normal',
                                                background: params.node.footer
                                                    ? GRAND_TOTAL_COLOR_CODE
                                                    : NORMAL_CELL_COLOR_CODE,
                                            }
                                        },
                                        pagination: true,
                                        groupTotalRow: 'bottom',
                                        grandTotalRow: 'bottom',
                                        autoSizeStrategy: {
                                            type: 'fitCellContents',
                                        },
                                        autoGroupColumnDef: {
                                            headerName: 'Program',
                                            field: 'program_name',
                                            minWidth: 300,
                                            pinned: 'left',
                                        },
                                        suppressAggFuncInHeader: true,
                                        defaultColDef: {
                                            lockPosition: true,
                                            wrapHeaderText: true,
                                            autoHeaderHeight: true,
                                            initialWidth: 100,
                                        },
                                        tooltipShowDelay: 500,
                                    }}
                                />
                            </div>
                        </SpaceBetween>
                    </Container>
                    <Container>
                        <OrgSummarySpendTable
                            groups={data[ACTION_TYPES.ORG_GROUP].data}
                            planYear={data[ACTION_TYPES.PLAN].selected.data?.year}
                            planType={data[ACTION_TYPES.PLAN].selected.data?.plan_type}
                            data={data[ACTION_TYPES.SPEND].data}
                            orgName={
                                data[ACTION_TYPES.ORG].selected?.value
                                    ? data[ACTION_TYPES.ORG].selected.label
                                    : ''
                            }
                            spendToggle={data[ACTION_TYPES.SPEND].showScopedOnly}
                            setSpendToggleChange={setShowScopedOnlySpendEstimates}
                            isLoading={data[ACTION_TYPES.SPEND].loading}
                            setIsLoading={(newState: boolean) =>
                                dispatch({
                                    type: ACTION_TYPES.SPEND,
                                    payload: { key: 'loading', data: newState },
                                })
                            }
                        />
                    </Container>
                </SpaceBetween>
            </Box>
        </ContentLayout>
    )
}

export default OrgSummary
