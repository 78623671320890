import { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import {
    Header,
    Pagination,
    SpaceBetween,
    Table,
    RadioGroup,
    ColumnLayout,
    Box,
    Icon,
    Popover,
} from '@amzn/awsui-components-react'
import EmptyState from '../../../reusable/EmptyState'
import { findTextDiff } from '../../../reusable/Utils'
import StatusIndicatorTemplate from '../../../reusable/StatusIndicatorTemplate'
import { IS_TEXT_AREA_REGEX } from '../../../reusable/Regex'
import _ from 'lodash'

const ID_KEY_REGEX = new RegExp('created_|updated_|_sort|_id$')
const ACTION_FIELD_WIDTH = 120

const valueWithLabelDifferences = (isTextArea, label, prev, next) => {
    return (
        <Box>
            <Box variant='awsui-key-label'>{label}</Box>
            {typeof next === 'boolean' || typeof prev === 'boolean' ? (
                <SpaceBetween direction={'horizontal'} size={'xxs'}>
                    <StatusIndicatorTemplate value={prev} />
                    <Icon name='caret-right-filled' variant='link' />
                    <StatusIndicatorTemplate value={next} />
                </SpaceBetween>
            ) : (
                (
                    <SpaceBetween direction={'horizontal'} size={'xxs'}>
                        {prev || '-'}
                        <Icon name='caret-right-filled' variant='link' />
                        {isTextArea ? (
                            <SpaceBetween direction='horizontal' size='xxxs'>
                                {findTextDiff(prev, next)}
                            </SpaceBetween>
                        ) : (
                            next || '-'
                        )}
                    </SpaceBetween>
                ) || '-'
            )}
        </Box>
    )
}

const CopyDeliverableCompareTable = ({ duplicateDeliverables, onDuplicateDeliverablesChange }) => {
    const renderDifferencesState = (source, target) => {
        if (!source || !target) {
            return '-'
        }

        const result: any[] = []
        let displayKey = ''

        for (const key in source) {
            const isTextArea = IS_TEXT_AREA_REGEX.test(key)
            if (ID_KEY_REGEX.test(key)) {
                continue
            } else {
                displayKey = _.startCase(key)
            }

            if (
                source[key] !== undefined &&
                target[key] !== undefined &&
                source[key] !== target[key] &&
                (source[key] || target[key])
            ) {
                result.push(
                    valueWithLabelDifferences(isTextArea, displayKey, source[key], target[key]),
                )
            }
        }

        return result.length ? (
            <ColumnLayout columns={2} variant='text-grid'>
                {result}
            </ColumnLayout>
        ) : (
            '-'
        )
    }

    const columnDefinitions = [
        {
            id: 'deliverable_id',
            header: 'Deliverable ID',
            cell: (e: any) => e.deliverable_id,
        },
        {
            id: 'deliverable_name',
            header: 'Deliverable',
            cell: (e: any) => e.deliverable_name,
            sortingField: 'deliverable_name',
        },
        {
            id: 'differences',
            header: 'Differences',
            cell: (e: any) => renderDifferencesState(e.differences.source, e.differences.target),
        },
        {
            id: 'action',
            header: (
                <Popover
                    position='top'
                    size='medium'
                    triggerType='custom'
                    content={
                        <Box>
                            <Box>
                                Merge: aggregate all HC estimates from source and target program
                                (source HC estimates + target HC estimates) and keep target
                                deliverable attributes.
                            </Box>
                            <Box>
                                Overlay: completely replace target deliverable HC and attributes
                                with source deliverable HC and attributes.
                            </Box>
                            <Box>
                                Create new: uses all source deliverable attributes and HC and adds
                                the suffix you suggested above.
                            </Box>
                        </Box>
                    }
                >
                    <SpaceBetween size='xxxs' direction='horizontal'>
                        Action
                        <Icon name='status-info' />
                    </SpaceBetween>
                </Popover>
            ),
            cell: (e: any) => (
                <RadioGroup
                    onChange={({ detail }) => {
                        const tempDeliverables = [...duplicateDeliverables]
                        for (const deliv of tempDeliverables) {
                            if (deliv.deliverable_id === e.deliverable_id) {
                                deliv.action = detail.value
                                onDuplicateDeliverablesChange(tempDeliverables)
                                return
                            }
                        }
                    }}
                    value={e.action}
                    items={[
                        { value: 'merge', label: 'Merge' },
                        { value: 'overlay', label: 'Overlay' },
                        { value: 'create_new', label: 'Create New' },
                    ]}
                />
            ),
            width: ACTION_FIELD_WIDTH,
        },
    ]
    const visibleColumns = ['deliverable_name', 'differences', 'action']

    const [preferences] = useState({ pageSize: 50 })
    const { items, collectionProps, paginationProps } = useCollection(duplicateDeliverables, {
        propertyFiltering: {
            filteringProperties: [],
            empty: (
                <EmptyState
                    title='No duplicate deliverables'
                    subtitle={'`No duplicate deliverables found in target program to display. '}
                    action={<></>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions[1],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            items={items}
            stickyHeader={true}
            columnDefinitions={columnDefinitions}
            visibleColumns={visibleColumns}
            loadingText={'Loading deliverables'}
            pagination={<Pagination {...paginationProps} />}
            header={<Header>Duplicate deliverable(s)</Header>}
        />
    )
}

export default CopyDeliverableCompareTable
