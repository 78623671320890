import './ClearFilterComponent.scss'
import { Button } from '@amzn/awsui-components-react'

const ClearFilterComponent = (props) => {
    const { api } = props
    return (
        <Button
            className={'center_standard_height'}
            onClick={() => {
                const currentFilterModel = api.getFilterModel() ?? {}
                Object.keys(currentFilterModel).forEach((colName) => {
                    api.destroyFilter(colName)
                })

                api.setFilterModel(null)
                api.onFilterChanged()
            }}
        >
            Clear Filter(s)
        </Button>
    )
}
export default ClearFilterComponent
