import { Spinner } from '@amzn/awsui-components-react'

const LoadingScreen = () => {
    return (
        <div
            style={{
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Spinner size={'large'} />
        </div>
    )
}

export default LoadingScreen
