import { Box, FormField, Grid, SpaceBetween } from '@amzn/awsui-components-react'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../../../context'
import './HRDataTable.scss'
import { roundSingleItem } from './DeliverableConstants'
import { calculateGap, calculateProjectedOH } from './DelivUtil'
import bigDecimal from 'js-big-decimal'
import { HC_GAP } from '../../summary/SummaryUtil'

interface HRDataTableProps {
    selectedGroup: any
    selectedPlanId: any
    selectedRevId: any
    overheadData: any
    selectedDeliverable: any
    hrTeamData: any
    hcTotals: any
    isDeliverableSubjectToOH: any
}

const HRDataTable = (props: HRDataTableProps) => {
    const {
        selectedGroup,
        selectedPlanId,
        selectedRevId,
        overheadData,
        selectedDeliverable,
        hrTeamData,
        hcTotals,
        isDeliverableSubjectToOH,
    } = props
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const getDefaultCount = () => {
        return {
            CT: '0.00',
            FF: '0.00',
            TOTAL: '0.00',
        }
    }
    const [aggHrData, setAggHrData] = useState(getDefaultCount())
    const [aggHrDataWithOH, setAggHrDataWithOH] = useState(getDefaultCount())
    const [projectedWithOH, setProjectWithOH] = useState(getDefaultCount())
    const [projected, setProjected] = useState(getDefaultCount())
    const [gap, setGap] = useState(getDefaultCount())

    useEffect(() => {
        if (!selectedGroup.object_id) {
            return
        }
        getProjectedDataForGroup()
    }, [selectedGroup])

    useEffect(() => {
        if (!selectedGroup.object_id) {
            return
        }
        getProjectedAndGap()
    }, [hrTeamData, selectedGroup, hcTotals, aggHrData])

    const getProjectedAndGap = () => {
        const newProjected = getDefaultCount()
        const newProjectedOH = getDefaultCount()
        const newGap = getDefaultCount()
        const keyNames = ['CT', 'FF', 'TOTAL']
        const hcKeyNames = ['hc_ct', 'hc_ff', 'hc_total']
        const hrTeamDataKeyNames = ['ct', 'ff', 'employee']

        keyNames.forEach((keyName, idx) => {
            const hcKeyName = hcKeyNames[idx]
            const projected = bigDecimal.add(aggHrData[keyName], hcTotals[hcKeyName])
            const projectedOH = calculateProjectedOH(
                projected,
                isDeliverableSubjectToOH
                    ? bigDecimal.add(aggHrDataWithOH[keyName], hcTotals[hcKeyName])
                    : aggHrDataWithOH[keyName],
                overheadData,
            )
            newProjected[keyName] = roundSingleItem(projected)
            newProjectedOH[keyName] = roundSingleItem(projectedOH)
            newGap[keyName] = roundSingleItem(
                calculateGap(projectedOH, hrTeamData[hrTeamDataKeyNames[idx]] || 0),
            )
        })
        setProjected(newProjected)
        setProjectWithOH(newProjectedOH)
        setGap(newGap)
    }

    const getProjectedDataForGroup = () => {
        if (!selectedGroup.object_id) {
            return
        }
        apiClient
            .get(
                `/group-headcount-estimates/${selectedGroup.object_id}?plan_id=${selectedPlanId}&revision_id=${selectedRevId}`,
            )
            .then((res) => {
                let aggCT = '0'
                let aggFF = '0'
                let aggCTOH = '0'
                let aggFFOH = '0'
                const allEstimates = res.data
                allEstimates.forEach((estimate) => {
                    if (estimate.deliverable_id !== selectedDeliverable.deliverable_id) {
                        aggCT = bigDecimal.add(estimate.hc_ct, aggCT)
                        aggFF = bigDecimal.add(estimate.hc_ff, aggFF)
                        if (estimate.is_overhead_applied) {
                            aggCTOH = bigDecimal.add(estimate.hc_ct, aggCTOH)
                            aggFFOH = bigDecimal.add(estimate.hc_ff, aggFFOH)
                        }
                    }
                })
                setAggHrData({
                    CT: aggCT,
                    FF: aggFF,
                    TOTAL: bigDecimal.add(aggCT, aggFF),
                })
                // separately track HC total for estimates where OH is applied
                setAggHrDataWithOH({
                    CT: aggCTOH,
                    FF: aggFFOH,
                    TOTAL: bigDecimal.add(aggCTOH, aggFFOH),
                })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    return (
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 1 }, { colspan: 1 }, { colspan: 1 }]}>
            <FormField
                constraintText={
                    <Box>
                        <SpaceBetween size={'xxxs'} direction={'vertical'}>
                            <Box variant='small' float='left'>
                                {`Overhead: ${overheadData}%`}
                            </Box>
                            <Box variant='small' float='right'>
                                {'BIS: '}
                            </Box>
                        </SpaceBetween>
                        <Box variant='small' float='right'>
                            {'Projected: '}
                        </Box>
                        <hr className={'invisible-padding'}></hr>
                        <Box variant='small' float='right'>
                            {'Projected w/ OH: '}
                        </Box>
                        <hr className={'line-padding'}></hr>
                        <Box variant='small' float='right'>
                            {`${HC_GAP} :`}
                        </Box>
                    </Box>
                }
            ></FormField>

            <FormField
                constraintText={
                    <Box>
                        <SpaceBetween size={'xxxs'} direction={'vertical'}>
                            <Box variant='small' fontWeight={'heavy'} float='right'>
                                {'C&T'}
                            </Box>
                            <Box variant='small' float='right'>
                                {roundSingleItem(hrTeamData?.ct || 0.0)}
                            </Box>
                        </SpaceBetween>
                        <Box variant='small' float='right'>
                            {projected.CT}
                        </Box>
                        <hr className={'invisible-padding'}></hr>
                        <Box variant='small' float='right'>
                            {projectedWithOH.CT}
                        </Box>
                        <hr className={'line-padding'}></hr>
                        <Box variant='small' float='right'>
                            {gap.CT}
                        </Box>
                    </Box>
                }
            ></FormField>
            <FormField
                constraintText={
                    <Box>
                        <SpaceBetween size={'xxxs'} direction={'vertical'}>
                            <Box variant='small' fontWeight={'heavy'} float='right'>
                                {'FF'}
                            </Box>
                            <Box variant='small' float='right'>
                                {roundSingleItem(hrTeamData?.ff || 0.0)}
                            </Box>
                        </SpaceBetween>
                        <Box variant='small' float='right'>
                            {projected.FF}
                        </Box>
                        <hr className={'invisible-padding'}></hr>
                        <Box variant='small' float='right'>
                            {projectedWithOH.FF}
                        </Box>
                        <hr className={'line-padding'}></hr>
                        <Box variant='small' float='right'>
                            {gap.FF}
                        </Box>
                    </Box>
                }
            ></FormField>
            <FormField
                constraintText={
                    <Box>
                        <SpaceBetween size={'xxxs'} direction={'vertical'}>
                            <Box variant='small' fontWeight={'heavy'} float='right'>
                                {'Total'}
                            </Box>
                            <Box variant='small' float='right'>
                                {roundSingleItem(hrTeamData.employee || 0)}
                            </Box>
                        </SpaceBetween>
                        <Box variant='small' float='right'>
                            {projected.TOTAL}
                        </Box>
                        <hr className={'invisible-padding'}></hr>
                        <Box variant='small' float='right'>
                            {projectedWithOH.TOTAL}
                        </Box>
                        <hr className={'line-padding'}></hr>
                        <Box variant='small' float='right'>
                            {gap.TOTAL}
                        </Box>
                    </Box>
                }
            ></FormField>
        </Grid>
    )
}

export default HRDataTable
