import { isDuplicateByPropertyValue, isValidChanged } from '../../common/Util'
import { COMPONENT_TYPES } from '../../Constant'
import { getFloatDecimalValue, getIntValue } from './Utils'

const ComponentOnChangeTemplate = (
    isCheckDuplicate,
    isRequired,
    type,
    id,
    value,
    name,
    onValueChange,
    selected,
    items,
    isInputInvalid,
    onIsInputInvalidChanged,
    onIsDataUnmodifiedChanged,
    formErrors,
    setFormErrors,
) => {
    let errorMessage = ''
    const selectedItem = selected.length > 0 ? selected[0].id : 'undefined'

    const handleInputString = () => {
        const isDuplicate = isCheckDuplicate
            ? isDuplicateByPropertyValue(id, selectedItem, items, id, value.trim())
            : false

        if (value.trim().length === 0 && isRequired) {
            errorMessage = `${name} must be provided.`
        } else if (isDuplicate) {
            errorMessage = `That ${name} is taken. Try another.`
        }
        onValueChange(`${id}_error`, errorMessage)
        onValueChange(id, value)

        const formErrorsUpdated = !errorMessage
            ? formErrors.filter((err) => !err.includes(name))
            : [...formErrors, errorMessage]
        setFormErrors(formErrorsUpdated)

        onIsInputInvalidChanged(errorMessage.length || formErrorsUpdated.length)
        onIsDataUnmodifiedChanged(!isValidChanged(value, selectedItem, id))
    }

    const handleInputInt = () => {
        if (+value < 0) {
            return
        }

        const newValue = getIntValue(value)
        if (newValue === '0' && isRequired) {
            errorMessage = `${name} must be provided.`
        }
        onValueChange(`${id}_error`, errorMessage)
        onValueChange(id, newValue)
        const formErrorsUpdated = !errorMessage
            ? formErrors.filter((err) => !err.includes(name))
            : [...formErrors, errorMessage]
        setFormErrors(formErrorsUpdated)

        onIsInputInvalidChanged(errorMessage.length || formErrorsUpdated.length)
        onIsDataUnmodifiedChanged(!isValidChanged(newValue, selectedItem, id))
    }

    const handleInputFloat = () => {
        if (+value < 0) {
            return
        }

        const newValue = getFloatDecimalValue(value)
        if (newValue === '0' && isRequired) {
            errorMessage = `${name} must be provided.`
        }
        onValueChange(`${id}_error`, errorMessage)
        onValueChange(id, newValue)

        const formErrorsUpdated = !errorMessage
            ? formErrors.filter((err) => !err.includes(name))
            : [...formErrors, errorMessage]
        setFormErrors(formErrorsUpdated)

        onIsInputInvalidChanged(errorMessage.length || formErrorsUpdated.length)
        onIsDataUnmodifiedChanged(!isValidChanged(newValue, selectedItem, id))
    }

    const handleTextarea = () => {
        if (isRequired && value.trim().length === 0) {
            errorMessage = `${name} must be provided.`
        } else {
            errorMessage = ''
        }
        onValueChange(`${id}_error`, errorMessage)
        onValueChange(id, value)
        const formErrorsUpdated = !errorMessage
            ? formErrors.filter((err) => !err.includes(name))
            : [...formErrors, errorMessage]
        setFormErrors(formErrorsUpdated)

        onIsInputInvalidChanged(errorMessage.length || formErrorsUpdated.length)
        onIsDataUnmodifiedChanged(!isValidChanged(value, selectedItem, id))
    }

    const handleSelect = () => {
        onValueChange(id, value)
        onValueChange(`${id}_error`, '')

        onIsDataUnmodifiedChanged(false)
    }

    const handleTokenList = () => {
        // value should be a list of token group objects
        // this only works on the label level
        const labels = value.map((val) => val.label || '')
        const newList = value.filter(
            (val, idx) => val.label && labels.indexOf(val.label || '') === idx,
        )
        onValueChange(id, newList)
        onValueChange(`${id}_error`, '')

        onIsDataUnmodifiedChanged(false)
    }
    switch (type) {
        case COMPONENT_TYPES.INPUT_STRING:
            return handleInputString()
        case COMPONENT_TYPES.INPUT_INT:
            return handleInputInt()
        case COMPONENT_TYPES.INPUT_FLOAT:
            return handleInputFloat()
        case COMPONENT_TYPES.TEXTAREA:
            return handleTextarea()
        case COMPONENT_TYPES.SELECT:
            return handleSelect()
        case COMPONENT_TYPES.TOKEN_GROUP:
            return handleTokenList()
        default:
            return
    }
}

export default ComponentOnChangeTemplate
