export const enum COMMENT_PANEL_MODE {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    DISABLED = 'DISABLED',
}

export enum USER_ROLES {
    USER_ORGS = 'Org Manager',
    USER_REGISTERED_GROUPS = 'Group Manager',
    USER_PROGRAMS = 'STL',
}

// TODO delete once test done
export const TEST_COMMENTS = [
    {
        comment_id: 'comm_987654321',
        op_plan: 'OP2 2024',
        text: "This is a much longer comment that contains multiple sentences. It's useful for testing how the system handles longer text inputs.",
        update_timestamp: 1731134140,
        user_alias: 'yfeiwang',
        user_role: 'Admin',
        parent_program_id: 'prog_987654321',
        deliverable_id: 'del_456789123',
        org_id: 'org_123',
        group_id: 'group_456',
    },
    {
        comment_id: 'comm_123456789',
        op_plan: 'OP1 2025',
        text: 'Comment with special characters: @#$%^&*()',
        update_timestamp: 1733139871,
        user_alias: 'pmedhe',
        user_role: 'Group Manager',
        parent_program_id: 'prog_987654321',
        deliverable_id: 'del_456789123',
        org_id: 'org_123',
        group_id: 'group_456',
    },
]
