import { mapToOptions } from '../../common/Util'
import { generateUuid } from '../../common/Util'
import { STATUS_INDICATOR_TYPES } from '../../Constant'

export const current_year = new Date().getFullYear().toString()
const next_year = (new Date().getFullYear() + 1).toString()
const year_after_next = (new Date().getFullYear() + 2).toString()
export const DEFAULT_SELECT = 'Select'
export const OP1_PLAN = 'OP1'
export const OP2_PLAN = 'OP2'
export const OFF_CYCLE = 'Off Cycle'
export const PLAN_TYPE_MESSAGE = 'Plan type is required.'
export const PLAN_YEAR_MESSAGE = 'Plan year is required.'
export const NUMERIC_VALIDATION = 'Please enter a valid number, or leave blank.'

export const YEAR_OPTIONS = mapToOptions([current_year, next_year, year_after_next])
export const PLAN_TYPE_OPTIONS = mapToOptions([OP1_PLAN, OP2_PLAN, OFF_CYCLE])
export const PLAN_STATUS_TYPES = {
    IN_PROCESS: 'In Process',
    APPROVED: 'Approved',
}

export const STATUS_TO_INDICATOR_MAP = {
    IN_PROCESS: STATUS_INDICATOR_TYPES.IN_PROGRESS,
    APPROVED: STATUS_INDICATOR_TYPES.SUCCESS,
    PROGRAM_CREATION: STATUS_INDICATOR_TYPES.IN_PROGRESS,
    IN_REVIEW: STATUS_INDICATOR_TYPES.PENDING,
    CLOSED: STATUS_INDICATOR_TYPES.STOPPED,
}

export const STATUS_TO_BADGE_MAP = {
    IN_PROCESS: 'blue',
    APPROVED: 'green',
    PROGRAM_CREATION: 'blue',
    IN_REVIEW: 'grey',
    CLOSED: 'grey',
}

export const PLAN_REVISION_STATUS_TYPES = {
    PROGRAM_CREATION: 'Program Creation',
    IN_PROCESS: 'In Process',
    IN_REVIEW: 'In Review',
    CLOSED: 'Closed',
    APPROVED: 'Approved',
}
export const PLAN_REVISION_STATUS_IDS = {
    PROGRAM_CREATION: 'PROGRAM_CREATION',
    IN_PROCESS: 'IN_PROCESS',
    IN_REVIEW: 'IN_REVIEW',
    CLOSED: 'CLOSED',
    APPROVED: 'APPROVED',
}

export const PLAN_REVISION_STATUS_OPTIONS = Object.keys(PLAN_REVISION_STATUS_TYPES).map(
    (revStatusId) => ({
        ['label']: PLAN_REVISION_STATUS_TYPES[revStatusId],
        ['value']: revStatusId,
    }),
)

export interface Plan {
    plan_id: string
    plan_name: string
    year: string
    plan_type: string
    overhead_factor: string
    stl_leader: string
    admin_backup: string
    plan_status?: string
    is_plan_locked?: boolean
    business_entity_id: string
    create_date?: string
    created_by?: string
    updated_date?: string
    updated_by?: string
    revisions?: any[]
    number_of_revisions?: string
}
interface Revision {
    plan_id: string
    revision_number: number
    business_entity_id: string
    revision_title: string
    revision_description: string
    revision_id: number
    revision_name: string
    revision_status: string
    create_date?: string
    created_by?: string
    updated_date?: string
    updated_by?: string
    reference_revision_id?: number
    reference_revision_title?: string
}
export const generateDefaultPlan = (businessEntityId) => {
    const defaultPlan: Plan = {
        plan_id: generateUuid(),
        plan_name: '',
        year: DEFAULT_SELECT,
        plan_type: DEFAULT_SELECT,
        overhead_factor: '',
        stl_leader: '',
        admin_backup: '',
        business_entity_id: businessEntityId,
    }
    return defaultPlan
}

export const generateDefaultRevision = (plan, businessEntityId, revisions) => {
    if (!plan) {
        return {}
    }
    const totalRevisions = revisions.length
    const defaultRevision: Revision = {
        plan_id: plan.plan_id,
        revision_id: 0,
        revision_name: `Rev ${totalRevisions + 1}`,
        revision_number: totalRevisions + 1,
        revision_title: '',
        revision_description: '',
        revision_status: PLAN_REVISION_STATUS_IDS.PROGRAM_CREATION,
        business_entity_id: businessEntityId,
        reference_revision_id: 0,
        reference_revision_title: revisions.length
            ? `Rev ${revisions[0]['revision_number']} - ${revisions[0]['revision_title']}___${revisions[0]['revision_id']}`
            : '',
    }
    return defaultRevision
}

export const getReferenceRevisionOptions = (revisions) => {
    const optionList = [
        ...revisions.map((rev) => `Rev ${rev.revision_number} - ${rev.revision_title}`),
        'Empty Revision',
    ]
    const optionIds = [
        ...revisions.map(
            (rev) => `Rev ${rev.revision_number} - ${rev.revision_title}___${rev.revision_id}`,
        ),
        'Empty Revision___',
    ]
    return optionList.map((optionLabel, index) => ({
        label: optionLabel,
        value: optionIds[index],
    }))
}

export const getInitialPlanErrors = (plan: Plan) => {
    const formErrors: any = {}
    const attrs = ['year', 'plan_type']
    attrs.forEach((attr) => {
        if (!plan[attr] || plan[attr] === DEFAULT_SELECT) {
            formErrors[attr] = attr === 'year' ? PLAN_YEAR_MESSAGE : PLAN_TYPE_MESSAGE
        }
    })
    return formErrors
}
