import { useEffect, useState } from 'react'
import { useAuth } from '../auth/EgretAuthProvider'
import { NO_USER_BUSINESS_ENTITY } from '../components/Constant'
import { Outlet } from 'react-router'
import NoBusinessEntity from '../components/apps/home/NoBusinessEntity'
import Unauthorized from '../components/apps/home/Unauthorized'
import LoadingScreen from '../components/common/LoadingScreen'
const EgretAuthorization = ({ selectedBusinessEntity }) => {
    const { userRoles, getUserRoles, isAuthChecked } = useAuth()
    const [isLoading, setIsLoading] = useState(true)
    const [loadExceptionPath, setLoadExceptionPath] = useState(false)
    const [exceptionContent, setExceptionContent] = useState(<></>)

    useEffect(() => {
        setIsLoading(true)
        if (!isAuthChecked) {
            // still loading, wait...
            getUserRoles()
            return
        }
        if (!selectedBusinessEntity?.business_entity_id || userRoles === undefined) {
            // still loading, wait...
            return
        }
        if (selectedBusinessEntity.business_entity_id === NO_USER_BUSINESS_ENTITY) {
            // user has never selected a BE before. Prompt with BE selection page w/o changing URL.
            setIsLoading(false)
            setLoadExceptionPath(true)
            setExceptionContent(<NoBusinessEntity />)
            return
        }
        if (!userRoles[selectedBusinessEntity.business_entity_id]) {
            // user is not registered to BE. prompt with Unauthed page.
            setIsLoading(false)
            setLoadExceptionPath(true)
            setExceptionContent(<Unauthorized businessEntity={selectedBusinessEntity} />)
            return
        }
        // happy path & serving child content
        setLoadExceptionPath(false)
        setIsLoading(false)
    }, [selectedBusinessEntity, isAuthChecked])
    return isLoading ? <LoadingScreen /> : !loadExceptionPath ? <Outlet /> : exceptionContent
}

export default EgretAuthorization
