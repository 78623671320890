import EmptyState from '../../reusable/EmptyState'
import { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { Box, Header, Pagination, Table } from '@amzn/awsui-components-react'

export const DiscretionarySpendTable = (props: { spends: any[] }) => {
    const { spends } = props
    const totalSpends = spends.reduce(
        (n, spend) => n + parseFloat(spend.total_expenditure || '0'),
        0,
    )
    const columnDefinitions = [
        {
            id: 'ds_item_id_display',
            header: 'Expense ID',
            cell: (e: any) => e.ds_item_id_display,
            sortingField: 'ds_item_id_display',
        },
        {
            id: 'expense_title',
            header: 'Expense Title',
            cell: (e: any) => e.expense_title,
            sortingField: 'expense_title',
        },
        {
            id: 'total_expenditure',
            header: 'Total Expenditure',
            cell: (e: any) => (
                <Box float='right'>{`$${Number(e.total_expenditure).toLocaleString()}`}</Box>
            ),
        },
    ]

    const [preferences] = useState({ pageSize: 20 })
    const { items, collectionProps, paginationProps } = useCollection(spends, {
        propertyFiltering: {
            filteringProperties: [],
            empty: (
                <EmptyState title='No spends' subtitle={'`No spends to display. '} action={<></>} />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columnDefinitions[0],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            items={items}
            wrapLines
            stickyHeader={true}
            columnDefinitions={columnDefinitions}
            loadingText={'Loading spends'}
            pagination={<Pagination {...paginationProps} />}
            header={<Header>Spend(s) will be moved. Count: {spends.length}</Header>}
            footer={
                <Box margin={{ right: 'm' }}>
                    <Header actions={`Total spends: $${totalSpends.toLocaleString()}`} />
                </Box>
            }
        />
    )
}
export default DiscretionarySpendTable
