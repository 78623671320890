import { capitalizeFirstLetter, formatDbFieldName } from '../../common/Util'
import { convertToLocalTime, convertToMoneyFormat, findTextDiff } from '../reusable/Utils'
import { ACTION_TYPES } from './useNewSummaryData'
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import React, { useState } from 'react'
import _ from 'lodash'
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Modal,
    SpaceBetween,
    TextContent,
} from '@amzn/awsui-components-react'
import { COMPONENT_TYPES, PROGRAM_ATTRIBUTE_TYPES } from '../../Constant'

export const processSpendEstimates = (state, initialOrgs) => {
    const estimateTotalsByOrgGroup = {}
    const programData = state[ACTION_TYPES.PROGRAM]?.data
    const orgMap = state[ACTION_TYPES.GROUPS_BY_ORG].orgIdToNameMap
    const orgNameToIdMap = state[ACTION_TYPES.GROUPS_BY_ORG].orgNameToIdMap
    const groupMap = state[ACTION_TYPES.GROUPS_BY_ORG].groupMap
    const spendEstimatesByOrg = state[ACTION_TYPES.SPEND].data
    const allEstimateRows: any[] = []

    const estimatesForSelectedOrgs = initialOrgs.flatMap((orgName) => {
        let orgEstimates = []
        if (orgNameToIdMap.has(orgName)) {
            const orgId = orgNameToIdMap.get(orgName)?.org_id
            orgEstimates = spendEstimatesByOrg[orgId] ?? []
        }
        return orgEstimates
    })
    estimatesForSelectedOrgs.forEach((estimate) => {
        const selectedGroup = groupMap.get(estimate.group_id)
        const selectedProgram = programData[estimate.program_id] ?? {}
        const isGroupActive = selectedGroup?.is_active ?? false
        const isProgramActive = selectedProgram?.is_active ?? false
        if (isGroupActive && isProgramActive) {
            const orgGroupKey = `${estimate.org_id}_${estimate.group_id}`
            const suffix = selectedGroup?.archived ? ' (archived)' : ''
            const groupName = `${selectedGroup?.group_name ?? 'Group'}${suffix}`
            if (!estimateTotalsByOrgGroup[orgGroupKey]) {
                estimateTotalsByOrgGroup[orgGroupKey] = 0
            }
            estimateTotalsByOrgGroup[orgGroupKey] += parseInt(estimate.total_expenditure ?? '0')
            allEstimateRows.push({
                org_name: orgMap.get(estimate.org_id)?.org_name ?? 'Org',
                group_name: groupName,
                program_name: selectedProgram?.program_name ?? 'Program',
                row_id: estimate.ds_item_id,
                ...estimate,
            })
        }
    })
    return { allEstimateRows, estimateTotalsByOrgGroup }
}

export const filterAndSortEstimatesByPreferences = (allEstimateRows, orgGroupTotals) => {
    return allEstimateRows
        .filter((row) => {
            const isArchivedGroup = row.group_name.includes('archived')
            return (
                !isArchivedGroup ||
                (isArchivedGroup && orgGroupTotals[`${row.org_id}_${row.group_id}`])
            )
        })
        .sort((row1, row2) => {
            if (row1.group_name === row2.group_name) {
                return row1.program_name < row2.program_name ? -1 : 1
            }
            if (row1.org_name === row2.org_name) {
                return row1.group_name < row2.group_name ? -1 : 1
            }
            return row1.org_name < row2.org_name ? -1 : 1
        })
}
const MONTHLY_EXPENDITURE_FIELDS = Array(12)
    .fill(0)
    .map((_, monthIdx) => {
        const monthName = new Date(0, monthIdx)
            .toLocaleDateString(undefined, { month: 'short' })
            .toLowerCase()
        return `${monthName}_expenditure`
    })
const ALL_EXPENDITURE_FIELDS = ['total_expenditure', ...MONTHLY_EXPENDITURE_FIELDS]

const generateMonthlyExpenditureColumns = () => {
    return MONTHLY_EXPENDITURE_FIELDS.map((fieldName) => {
        if (!fieldName.includes('total')) {
            const monthName = fieldName.split('_')[0]
            return {
                headerName: capitalizeFirstLetter(monthName),
                field: fieldName,
                cellRenderer: (params) => convertToMoneyFormat(params.value),
                type: 'rightAligned',
                ...addNumericColumnParams(fieldName),
            }
        }
    })
}
const FORCE_LAST = ['created_by', 'created_at', 'updated_by', 'updated_at']
const COLUMNS_TO_IGNORE = ['group_name', 'program_name', 'org_name', ...FORCE_LAST]
const DISPLAY_OVERRIDES = {
    q1_justification: 'Justification Summary',
    q2_result: 'What happens if approval is rejected?',
    q3_other_programs: 'Other programs benefiting from this expense',
}
export const DetailsRenderer = (params: ICellRendererParams) => {
    const { data } = params
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false)
    const sortKeys = (key1, key2) => {
        if (!key1.includes('expenditure') && !key2.includes('expenditure')) {
            return key1 < key2 ? -1 : 1
        }
        if (key1.includes('expenditure') && key2.includes('expenditure')) {
            // sort by months, total at the end
            return ALL_EXPENDITURE_FIELDS.indexOf(key1) - ALL_EXPENDITURE_FIELDS.indexOf(key2)
        }
        return !key1.includes('expenditure') ? -1 : 1
    }
    const keysToDisplay = [
        ...(data !== undefined ? Object.keys(data) : [])
            .filter(
                (key) =>
                    !COLUMNS_TO_IGNORE.includes(key) &&
                    !(key.includes('id') || key.includes('sort')),
            )
            .sort(sortKeys),
        ...FORCE_LAST,
    ]
    const processFieldName = (fieldName) => {
        if (fieldName in DISPLAY_OVERRIDES) {
            return DISPLAY_OVERRIDES[fieldName]
        }
        return formatDbFieldName(fieldName)
    }
    const processFieldValue = (fieldName, value) => {
        if (_.isEmpty(value)) {
            return '-'
        }
        switch (fieldName) {
            case 'q3_other_programs':
                return value?.map((option) => option?.label ?? '').join(', ') ?? ''
            case 'updated_at':
            case 'created_at':
                return convertToLocalTime(value)
            default:
                return fieldName.includes('expenditure') ? convertToMoneyFormat(value) : value
        }
    }
    return (
        <Box>
            <Button variant={'link'} onClick={() => setIsDetailsModalVisible(true)}>
                {data?.ds_item_id_display}
            </Button>
            <Modal
                onDismiss={() => setIsDetailsModalVisible(false)}
                visible={isDetailsModalVisible}
                size={'large'}
                footer={
                    <Box float='right'>
                        <Button variant='link' onClick={() => setIsDetailsModalVisible(false)}>
                            Close
                        </Button>
                    </Box>
                }
                header={
                    <TextContent>
                        <h2>{data?.program_name}</h2>
                        <SpaceBetween size={'s'} direction={'horizontal'}>
                            <h4>{data?.org_name}</h4>
                            <h4>{data?.group_name}</h4>
                        </SpaceBetween>
                        <small>{`Expense ID: ${data?.ds_item_id_display}`}</small>
                    </TextContent>
                }
            >
                <Container>
                    <ColumnLayout columns={3}>
                        {data &&
                            keysToDisplay.map((field_name) => (
                                <SpaceBetween size={'s'} direction={'vertical'}>
                                    <Box variant='awsui-key-label'>
                                        {processFieldName(field_name)}
                                    </Box>

                                    {processFieldValue(field_name, data[field_name])}
                                </SpaceBetween>
                            ))}
                    </ColumnLayout>
                </Container>
            </Modal>
        </Box>
    )
}

const addNumericColumnParams = (fieldName) => {
    return {
        valueGetter: (params: ValueGetterParams) =>
            parseInt((params.data && params.data[fieldName]) ?? '0'),
        filter: 'agNumberColumnFilter',
        filterParams: {
            allowedCharPattern: '\\d\\,',
            numberParser: (text) => {
                return text == null ? null : parseFloat(text.replaceAll(',', ''))
            },
            numberFormatter: (value) => {
                return value == null ? null : convertToMoneyFormat(value.toString())
            },
        },
    }
}

export const generateToolSideBar = () => {
    return {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Show/Hide Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivots: true,
                    suppressPivotMode: true,
                    suppressColumnFilter: true,
                    suppressColumnSelectAll: true,
                    suppressColumnExpandAll: true,
                },
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
                toolPanelParams: {
                    suppressExpandAll: true,
                    suppressFilterSearch: true,
                },
            },
        ],
        defaultToolPanel: 'columns',
    }
}
const hiddenColumnParams = {
    hide: true,
    filter: false,
    suppressColumnsToolPanel: true,
}
export const SpendTableHiddenColumns = [
    {
        field: 'q1_justification',
        headerName: 'Justification Summary',
        ...hiddenColumnParams,
    },
    {
        field: 'q2_result',
        headerName: 'What happens if approval is rejected?',
        ...hiddenColumnParams,
    },
    {
        field: 'q3_other_programs',
        headerName: 'Other programs benefiting from this expense',
        valueGetter: (params) => {
            const otherPrograms = params?.data?.q3_other_programs ?? []
            return otherPrograms.map((option) => option?.label ?? '')?.join(', ')
        },
        ...hiddenColumnParams,
    },
    ...['expense_note', 'revision_note', 'description', ...FORCE_LAST].map((colName) => ({
        field: colName,
        headerName: formatDbFieldName(colName),
        ...hiddenColumnParams,
    })),
]
export const SPEND_HIDDEN_FIELD_IDS = SpendTableHiddenColumns.map((col) => col.field)
const DEFAULT_MIN_WIDTH = 100
const DEFAULT_MIN_WIDTH_SHORT_TEXT = 150
const DEFAULT_MIN_WIDTH_LONG_TEXT = 200

export const SpendTableColumns = [
    {
        headerName: 'Org',
        field: 'org_name',
        pinned: 'left',
        suppressColumnsToolPanel: true,
        filter: false,
    },
    {
        headerName: 'Group',
        field: 'group_name',
        pinned: 'left',
        minWidth: DEFAULT_MIN_WIDTH_SHORT_TEXT,
    },
    {
        headerName: 'Program',
        field: 'program_name',
        tooltipValueGetter: (params) => params.value,
        pinned: 'left',
        minWidth: DEFAULT_MIN_WIDTH_LONG_TEXT,
    },
    {
        headerName: 'Expense ID',
        field: 'ds_item_id_display',
        pinned: 'left',
        cellRenderer: DetailsRenderer,
        minWidth: DEFAULT_MIN_WIDTH_SHORT_TEXT,
    },
    {
        headerName: 'Expense Title',
        field: 'expense_title',
        cellRenderer: (params) => params.value,
        minWidth: DEFAULT_MIN_WIDTH_LONG_TEXT,
    },
    {
        headerName: 'Category',
        field: 'expense_category',
        cellRenderer: (params) => formatDbFieldName(params.value),
        valueFormatter: (params) => formatDbFieldName(params.value),
        filterParams: {
            valueFormatter: (params) => formatDbFieldName(params.value),
        },
        minWidth: DEFAULT_MIN_WIDTH_SHORT_TEXT,
    },
    {
        headerName: 'OPEX-CAPEX',
        field: 'expense_type',
        maxWidth: DEFAULT_MIN_WIDTH,
    },
    {
        headerName: 'Cost Center',
        field: 'cost_center',
        maxWidth: DEFAULT_MIN_WIDTH,
    },
    {
        headerName: 'Year Total',
        field: 'total_expenditure',
        type: 'rightAligned',
        cellRenderer: (params) => convertToMoneyFormat(params.value),
        ...addNumericColumnParams('total_expenditure'),
    },
    {
        headerName: 'Monthly Expenditure',
        children: generateMonthlyExpenditureColumns(),
    },
    ...SpendTableHiddenColumns,
].map((colDef) =>
    'filter' in colDef
        ? colDef
        : { ...colDef, filter: 'agSetColumnFilter', suppressSizeToFit: true },
)

export const DEFAULT_SPEND_COLUMN_PARAMETERS = {
    filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        defaultToNothingSelected: true,
        suppressSelectAll: true,
    },
}
