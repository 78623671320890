import { useEffect, useState } from 'react'
import {
    Box,
    FormField,
    Grid,
    Header,
    Pagination,
    SpaceBetween,
    Spinner,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import { boldSpecificRowContent, generateExportVisibleColumns } from './SummaryUtil'
import ExportTable from '../reusable/ExportTable'
import EmptyState from '../reusable/EmptyState'
import { useCollection } from '@amzn/awsui-collection-hooks'

interface OrgSummarySpendTableProps {
    groups: any[]
    planYear: string
    planType: string
    data: any[]
    orgName: string
    spendToggle: boolean
    setSpendToggleChange: (checked: boolean) => void
    isLoading: boolean
    setIsLoading: (checked: boolean) => void
}

const OrgSummarySpendTable = (props: OrgSummarySpendTableProps) => {
    const {
        groups,
        planYear,
        planType,
        data,
        orgName,
        spendToggle,
        setSpendToggleChange,
        isLoading,
        setIsLoading,
    } = props
    const [columns, setColumns] = useState([])
    const [preferences] = useState({ pageSize: 20 })

    useEffect(() => {
        generateColumns()
    }, [])

    const { items, allPageItems, collectionProps, paginationProps } = useCollection(data, {
        propertyFiltering: {
            filteringProperties: [],
            empty: isLoading ? (
                <Spinner />
            ) : (
                <EmptyState
                    title='No Spend Summary Data.'
                    subtitle={`No Spend data found for ${orgName}`}
                    action={[]}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
    })

    useEffect(() => {
        generateColumns()
    }, [planYear, planType, groups])
    const generateColumns = () => {
        const columns: any = []
        if (!planYear || !planType || !groups) {
            return columns
        }
        groups.forEach((group) => {
            const id = group.group_id
            const suffix = group?.archived ? ' (archived)' : ''
            columns.push({
                id: id,
                header: `${group.group_name}${suffix}`,
                cell: (e: any) => boldSpecificRowContent(e[id], e.program_name, 'Total'),
                exportColumnName: group.group_name,
                sortingField: id,
            })
        })
        columns.splice(0, 0, {
            id: 'spend',
            header: `${planYear} ${planType} Spend`,
            cell: (e: any) => boldSpecificRowContent(e.spend, e.program_name, 'Total'),
            exportColumnName: `${planYear} ${planType} Spend`,
            sortingField: 'spend',
        })
        columns.splice(0, 0, {
            id: 'program_name',
            header: 'Program',
            cell: (e: any) => boldSpecificRowContent(e.program_name, e.program_name, 'Total'),
            exportColumnName: 'Program',
            sortingField: 'program_name',
        })
        setColumns(columns)
    }

    return (
        <Table
            {...collectionProps}
            columnDefinitions={columns}
            loadingText={`Loading Spend Summary...`}
            variant={'embedded'}
            items={items}
            loading={isLoading}
            pagination={<Pagination {...paginationProps} />}
            wrapLines
            sortingDisabled
            stickyColumns={{ first: 2 }}
            filter={undefined}
            header={
                <Grid gridDefinition={[{ colspan: 5 }, { colspan: 3 }, { colspan: 4 }]}>
                    <Header>
                        <Box variant={'h1'} margin={{ left: 'xxl' }} float={'left'}>
                            {orgName} Spend Summary
                        </Box>
                    </Header>
                    <div></div>
                    <Box float={'right'} margin={'xl'}>
                        <SpaceBetween direction={'horizontal'} size={'xxl'}>
                            <FormField label={'Program Expenses'}>
                                <SpaceBetween size={'xs'} direction={'horizontal'}>
                                    <span>All</span>
                                    <Toggle
                                        checked={spendToggle}
                                        onChange={({ detail }) => {
                                            setIsLoading(true)
                                            setSpendToggleChange(detail.checked)
                                        }}
                                    >
                                        Estimated
                                    </Toggle>
                                </SpaceBetween>
                            </FormField>
                            <ExportTable
                                tableData={allPageItems}
                                tableColumnDef={columns}
                                fileName={'EgretOrgSpendSummary'}
                                visibleColumns={generateExportVisibleColumns(columns)}
                            />
                        </SpaceBetween>
                    </Box>
                </Grid>
            }
        />
    )
}

export default OrgSummarySpendTable
