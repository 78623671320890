import { StatusIndicator } from '@amzn/awsui-components-react'

import { STATUS_INDICATOR_TYPES } from '../../Constant'

const StatusIndicatorTemplate = (props: { value: any }) => {
    const { value } = props
    if (value === undefined) {
        return <>-</>
    }

    return value ? (
        <StatusIndicator type={STATUS_INDICATOR_TYPES.SUCCESS}>Yes</StatusIndicator>
    ) : (
        <StatusIndicator type={STATUS_INDICATOR_TYPES.WARNING}>No</StatusIndicator>
    )
}

export default StatusIndicatorTemplate
