import { TokenGroupProps } from '@amzn/awsui-components-react'

export const formatRegisteredUsersTokenGroup = (users: string[]) => {
    if (!users) {
        return []
    }
    // removing user registration is not yet supported - M2 goal
    const result: TokenGroupProps.Item[] = []
    users.forEach((user) => {
        result.push({
            label: user,
            dismissLabel: 'Remove ' + user,
            disabled: false,
        })
    })
    return result
}
