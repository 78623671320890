import { SelectProps } from '@amzn/awsui-components-react'

export const EXPENSE_CATEGORY_OPTIONS: SelectProps.Option[] = [
    { label: 'Temp & Contract Labor', value: 'temp_contract_labor' },
    { label: 'Professional Services', value: 'professional_services' },
    { label: 'Prototype Materials', value: 'prototype_materials' },
    { label: 'Software Licenses', value: 'software_licenses' },
    { label: 'Equipment (Opex)', value: 'equipment_opex' },
    { label: 'Equipment (Capex)', value: 'equipment_capex' },
    { label: 'Tooling Assets', value: 'tooling_assets' },
    { label: 'Technology Infrastructure', value: 'technology_infrastructure' },
    { label: 'Leasehold Improvements', value: 'leasehold_improvements' },
]

export const CAPEX_OPTIONS = [
    'equipment_capex',
    'tooling_assets',
    'technology_infrastructure',
    'leasehold_improvements',
]

// TODO move in into DB settings table
export const COST_CENTER_OPTIONS: SelectProps.Option[] = [
    { label: '1272 - Amazon Robotics Supply Chain', value: '1272' },
    { label: '5361 - AIT', value: '5361' },
    { label: '5341 - Systems & Products Fixed Field', value: '5341' },
    { label: '5346 - Global Safety & Support', value: '5346' },
    { label: '7001 - AR Global Field Services', value: '7001' },
    { label: '7002 - Manufacturing', value: '7002' },
    { label: '7003 - Production', value: '7003' },
    { label: '7004 - Field Quality & HW Reliability', value: '7004' },
    { label: '7005 - R&D - Engineering', value: '7005' },
    { label: '7007 - Robotic Storage Tech', value: '7007' },
    { label: '7008 - Robotic Sortation Tech', value: '7008' },
    { label: '7009 - Bus Ops & Strategy', value: '7009' },
    { label: '7091 - Tech Deploy', value: '7091' },
    { label: '7191 - Global Robotics Operations', value: '7191' },
    {
        label: '7223 - Robotics Digital Solutions & Technologies',
        value: '7223',
    },
    { label: '8611 - G&A Support', value: '8611' },
]

export const MONTHS = [
    'jan_expenditure',
    'feb_expenditure',
    'mar_expenditure',
    'apr_expenditure',
    'may_expenditure',
    'jun_expenditure',
    'jul_expenditure',
    'aug_expenditure',
    'sep_expenditure',
    'oct_expenditure',
    'nov_expenditure',
    'dec_expenditure',
]
export const MONTH_NAMES = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]
