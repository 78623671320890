import { useState } from 'react'
import EmptyState from '../reusable/EmptyState'
import {
    Box,
    Button,
    ButtonDropdown,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table,
    Toggle,
} from '@amzn/awsui-components-react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { getMatchesCountText } from '../reusable/Utils'
import { CREATE_TEAM_REQUEST } from '../../common/LinkUtil'
import {
    ADMIN_GROUP_FILTERING_PROPERTIES,
    COMMON_GROUP_FILTERING_PROPERTIES,
    PROPERTY_FILTER_I18NSTRING,
} from '../reusable/UseCollectionUtil'
import ExportTable from '../reusable/ExportTable'

const GROUP_NAME_INDEX = 0

interface OrgGroupListProps {
    canAdmin: boolean
    orgName: string
    groups: any[]
    selectedGroups: any[]
    onSelectedGroupsChange: (selectedGroups: any[]) => void
    isLoading: boolean
    columns: any[]
    visibleColumns: string[]
    onCreate: () => void
    onEdit: () => void
    onDelete: () => void
    onMove: (group: any) => void
    showAllGroups: boolean
    setShowAllGroups: (showAllGroups: boolean) => void
}

export const groupExportVisibleColumns = [
    'org_name',
    'group_name',
    'description',
    'hr_permission_group',
    'updated_by',
    'updated_at',
    'primary_alias',
    'registered_users',
]
const OrgGroupList = ({
    canAdmin,
    orgName,
    groups,
    selectedGroups,
    onSelectedGroupsChange,
    isLoading,
    columns,
    visibleColumns,
    onCreate,
    onEdit,
    onDelete,
    onMove,
    showAllGroups,
    setShowAllGroups,
}: OrgGroupListProps) => {
    const [preferences] = useState({ pageSize: 20 })
    const {
        items,
        allPageItems,
        filteredItemsCount,
        collectionProps,
        propertyFilterProps,
        paginationProps,
        actions,
    } = useCollection(groups, {
        propertyFiltering: {
            filteringProperties: canAdmin
                ? COMMON_GROUP_FILTERING_PROPERTIES.concat(ADMIN_GROUP_FILTERING_PROPERTIES)
                : COMMON_GROUP_FILTERING_PROPERTIES,
            empty: (
                <EmptyState
                    title={`No groups under ${orgName}`}
                    subtitle='No groups to display.'
                    action={<Button onClick={onCreate}>Create a new group</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {
            defaultState: {
                sortingColumn: columns[GROUP_NAME_INDEX],
            },
        },
        selection: {},
    })

    return (
        <Table
            {...collectionProps}
            resizableColumns={false}
            selectionType={canAdmin ? 'multi' : undefined}
            selectedItems={selectedGroups}
            onSelectionChange={({ detail }) => {
                onSelectedGroupsChange(detail.selectedItems)
            }}
            columnDefinitions={columns}
            items={items}
            loading={isLoading}
            loadingText='Loading Groups'
            variant={'stacked'}
            wrapLines={true}
            visibleColumns={visibleColumns}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <PropertyFilter
                    i18nStrings={PROPERTY_FILTER_I18NSTRING}
                    {...propertyFilterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                />
            }
            header={
                <Header
                    variant='h2'
                    actions={
                        <SpaceBetween size='s' direction='horizontal'>
                            {canAdmin ? (
                                <>
                                    <Box float={'right'} margin={'xs'}>
                                        <SpaceBetween size={'xs'} direction={'horizontal'}>
                                            <Toggle
                                                onChange={({ detail }) =>
                                                    setShowAllGroups(detail.checked)
                                                }
                                                checked={showAllGroups}
                                            >
                                                Show All Groups
                                            </Toggle>
                                        </SpaceBetween>
                                    </Box>
                                    <Button variant='primary' onClick={onCreate}>
                                        New Group
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    href={CREATE_TEAM_REQUEST}
                                    iconAlign='right'
                                    iconName='external'
                                    target='_blank'
                                >
                                    Request New Group
                                </Button>
                            )}
                            {canAdmin ? (
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Edit',
                                            id: 'edit_group',
                                            disabled: selectedGroups.length !== 1,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete_group',
                                            disabled: selectedGroups.length === 0,
                                        },
                                        {
                                            text: 'Move Group',
                                            id: 'move_group',
                                            disabledReason: 'Disable it for MLP',
                                            disabled: true,
                                        },
                                    ]}
                                    onItemClick={({ detail }) => {
                                        if (detail.id === 'edit_group') {
                                            onEdit()
                                        } else if (detail.id === 'delete_group') {
                                            onDelete()
                                        }
                                    }}
                                >
                                    Actions
                                </ButtonDropdown>
                            ) : (
                                <></>
                            )}
                            <ExportTable
                                tableData={allPageItems}
                                visibleColumns={groupExportVisibleColumns}
                                tableColumnDef={columns}
                                fileName={`${orgName}EgretGroups`}
                            />
                        </SpaceBetween>
                    }
                />
            }
        />
    )
}

export default OrgGroupList
