import { useEffect, useState } from 'react'
import { Alert, AlertProps, Badge, SpaceBetween, TextContent } from '@amzn/awsui-components-react'
import {
    PLAN_REVISION_STATUS_IDS,
    PLAN_REVISION_STATUS_TYPES,
    STATUS_TO_BADGE_MAP,
} from '../plan/Constants'

export const SOURCE_PAGE = {
    DELIVERABLE: 'deliverable',
    PROGRAM: 'program',
    ESTIMATE: 'estimate', // includes HC and spend
    CREATE_PROGRAM: 'create_program',
}
const RevisionAlert = ({
    planId,
    selectedRevisionId,
    sourcePage,
    setIsLocked,
    apiClient,
}: {
    planId: string
    selectedRevisionId: string
    sourcePage: string
    setIsLocked: any
    apiClient: any
}) => {
    const [alertType, setAlertType] = useState<AlertProps.Type>('info')
    const [revisionStatus, setRevisionStatus] = useState('')
    const [showAlert, setShowAlert] = useState(false)

    const checkRevisionLocked = () => {
        apiClient
            .get(`/plan/${planId}/revision?revision_id=${Number(selectedRevisionId)}`)
            .then((res) => {
                const currentStatus = res.data.revision_status
                const canEstimate = currentStatus === PLAN_REVISION_STATUS_IDS.IN_PROCESS
                const canCreateProgramsDeliverables = [
                    PLAN_REVISION_STATUS_IDS.IN_PROCESS,
                    PLAN_REVISION_STATUS_IDS.PROGRAM_CREATION,
                ].includes(currentStatus)
                const onEstimatesPage = sourcePage === SOURCE_PAGE.ESTIMATE
                const isLocked = !(onEstimatesPage ? canEstimate : canCreateProgramsDeliverables)
                setIsLocked(isLocked)
                setShowAlert(isLocked)
                setAlertType('info')
                setRevisionStatus(currentStatus)
            })

            .catch((error) => {
                console.error(error)
                console.error(
                    `Unable to retrieve revision information for revision ${selectedRevisionId} and plan ${planId}`,
                )
                setShowAlert(false)
            })
    }
    useEffect(() => {
        if (!selectedRevisionId || !planId) {
            return
        }
        checkRevisionLocked()
    }, [selectedRevisionId])

    return showAlert ? (
        <Alert type={alertType} header={'View Only'}>
            <TextContent>
                <SpaceBetween size={'xs'} direction={'horizontal'}>
                    <p>
                        {sourcePage === SOURCE_PAGE.CREATE_PROGRAM &&
                            'Cannot add program to the selected plan and revision. '}
                        The selected revision is marked{' '}
                        <Badge
                            color={STATUS_TO_BADGE_MAP[revisionStatus]}
                        >{`${PLAN_REVISION_STATUS_TYPES[revisionStatus]}  `}</Badge>
                    </p>
                </SpaceBetween>
            </TextContent>
        </Alert>
    ) : (
        <></>
    )
}

export default RevisionAlert
