import { Button, DateRangePicker, SpaceBetween } from '@amzn/awsui-components-react'
import {
    AUDIT_DATE_RANGE_PICKER_I18N_STRINGS,
    AUDIT_DATE_RANGE_PICKER_RELATIVE_OPTIONS,
    AUDIT_LOG_GROUPS,
} from './Constant'
import { useAuditContext } from './AuditContext'
import { AUDIT_STATE_ACTIONS } from './useAuditState'
import { sub, getTime, isAfter } from 'date-fns'
import './audit_tables/cell_renderers/RendererStyles.css'
import { isGridUnusable } from './AuditUtil'
enum DATE_RANGE_TYPES {
    ABSOLUTE = 'absolute',
    RELATIVE = 'relative',
}

const AuditDateRangePicker = () => {
    const { state, dispatch } = useAuditContext()

    const isLoading =
        state.plansMap.loading ||
        state.orgsMap.loading ||
        state.programsByPlan.loading ||
        state.deliverablesByPlan.loading ||
        state.groupsMap.loading ||
        state.revisionsMap.loading ||
        state[AUDIT_LOG_GROUPS.HEADCOUNT].loading ||
        state[AUDIT_LOG_GROUPS.SPEND].loading ||
        state[AUDIT_LOG_GROUPS.PROGRAM_DELIVERABLE].loading ||
        state[AUDIT_LOG_GROUPS.PLAN_REVISION].loading ||
        isGridUnusable(state.gridApi)

    const clearDateSelection = () => {
        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_DATE_RANGE,
            startTimestamp: '',
            endTimestamp: new Date().getTime().toString(),
            dateRangeValue: null,
        })
    }
    const convertRelativeDateSelectionToTimestamps = (userSelection) => {
        const userSelectionAbsAmount = { ...userSelection, amount: Math.abs(userSelection.amount) }
        const endTimestamp = new Date()
        const offSetDate = () => {
            return getTime(
                sub(new Date(), { [`${userSelection.unit}s`]: userSelectionAbsAmount.amount }),
            )
        }
        const startTimestamp = offSetDate()
        const endTime = endTimestamp.getTime()
        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_DATE_RANGE,
            startTimestamp: startTimestamp.toString(),
            endTimestamp: endTime.toString(),
            dateRangeValue: userSelectionAbsAmount,
        })
    }

    const convertAbsoluteDateSelectionToTimestamps = (userSelection) => {
        const startTimestamp = Date.parse(userSelection.startDate)
        const endTimestamp = Date.parse(userSelection.endDate)
        dispatch({
            type: AUDIT_STATE_ACTIONS.SET_DATE_RANGE,
            startTimestamp: startTimestamp.toString(),
            endTimestamp: endTimestamp.toString(),
            dateRangeValue: userSelection,
        })
    }

    const handleDateSelection = (userSelection) => {
        if (!userSelection) {
            clearDateSelection()
            return
        }
        switch (userSelection.type) {
            case DATE_RANGE_TYPES.ABSOLUTE:
                return convertAbsoluteDateSelectionToTimestamps(userSelection)
            case DATE_RANGE_TYPES.RELATIVE:
                return convertRelativeDateSelectionToTimestamps(userSelection)
            default:
                return
        }
    }

    return (
        <SpaceBetween size={'xs'} direction={'horizontal'}>
            <DateRangePicker
                onChange={({ detail }) => {
                    handleDateSelection(detail.value)
                }}
                disabled={isLoading}
                value={state.dateRangeValue}
                relativeOptions={AUDIT_DATE_RANGE_PICKER_RELATIVE_OPTIONS}
                isValidRange={(range: any) => {
                    if (range.type === DATE_RANGE_TYPES.ABSOLUTE) {
                        const [startDateWithoutTime] = range.startDate.split('T')
                        const [endDateWithoutTime] = range.endDate.split('T')
                        if (!startDateWithoutTime || !endDateWithoutTime) {
                            return {
                                valid: false,
                                errorMessage:
                                    'The selected date range is incomplete. Select a start and end date for the date range.',
                            }
                        }
                        const startDate = Date.parse(range.startDate)
                        const endDate = Date.parse(range.endDate)
                        if (isAfter(startDate, endDate)) {
                            return {
                                valid: false,
                                errorMessage: 'Invalid range: End date must be after start date.',
                            }
                        }
                    }
                    return { valid: true }
                }}
                i18nStrings={AUDIT_DATE_RANGE_PICKER_I18N_STRINGS}
                dateOnly
                placeholder='Filter by a date range'
                showClearButton={true}
                controlId={'audit-date-picker-revamp'}
            />
            {state?.dateRangeValue && (
                <Button
                    onClick={clearDateSelection}
                    iconName={'close'}
                    id={'audit-clear-date-range'}
                >
                    Clear Date
                </Button>
            )}
        </SpaceBetween>
    )
}
export default AuditDateRangePicker
