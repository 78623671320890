import { useEffect, useState } from 'react'
import RevisionList from './RevisionList'
import CreateRevision from './CreateRevision'
import { useAppContext } from '../../../../context'
import {
    Box,
    ContentLayout,
    Flashbar,
    Link,
    SpaceBetween,
    StatusIndicator,
} from '@amzn/awsui-components-react'
import { convertToLocalTime } from '../../../common/Util'
import { ALERT_TYPES, MODAL_MODES } from '../../../Constant'
import useStore from '../../../Store'
import HeaderTemplate from '../../reusable/HeaderTemplate'
import DeleteModal from '../../reusable/DeleteModal'
import DatePopover from '../../reusable/DatePopover'
import { PLAN_REVISION_STATUS_TYPES, STATUS_TO_INDICATOR_MAP } from '../Constants'
import { useNavigate } from 'react-router-dom'
import BusinessEntityRefresh from '../../reusable/BusinessEntityRefresh'

const SetupRevision = () => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const [selectedPlanMetadata, setSelectedPlanMetadata] = useState<any>(undefined)
    const [revisions, setRevisions] = useState([])
    const history = useNavigate()
    const [columnDefinitions, setColumnDefinitions] = useState<any[]>([])
    const [tableVisibleColumns, setTableVisibleColumns] = useState<any[]>([])
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [modalMode, setModalMode] = useState<MODAL_MODES>(MODAL_MODES.NOT_SET)
    const [selectedRevisionMetadata, setSelectedRevisionMetadata] = useState<any>(undefined)
    const [isRevisionLoading, setIsRevisionLoading] = useState(true)
    const [alertItems, setAlertItems] = useState<any[]>([])

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const canAdmin = useStore((state) => state.canAdmin)
    const isLastRevision = revisions.length === 1

    useEffect(() => {
        if (!selectedPlanMetadata) {
            return
        }
        if (selectedBusinessEntity.business_entity_id !== selectedPlanMetadata.business_entity_id) {
            history('/all-plans')
        }
    }, [selectedBusinessEntity.business_entity_id])

    const handleCreate = () => {
        setModalMode(MODAL_MODES.CREATE)
        setCreateModalVisible(true)
    }

    const handleEdit = () => {
        setModalMode(MODAL_MODES.EDIT)
        setCreateModalVisible(true)
    }

    const handleAddAlertContent = (message: string, alertType: any) => {
        const alertId = `${appContext.userProps.userAlias}-${Date.now()}`
        const newAlert = {
            onDismiss: () => {
                setAlertItems((items) => items.filter((item) => item.id !== alertId))
            },
            dismissible: true,
            content: message,
            type: alertType,
            id: alertId,
        }
        setAlertItems([newAlert, ...alertItems])
    }

    const handleDelete = () => {
        setDeleteModalVisible(true)
    }

    const handleCreateModalClose = () => {
        setSelectedRevisionMetadata(undefined)
        setCreateModalVisible(false)
    }

    useEffect(() => {
        buildColumnDefinitions()
    }, [])

    const onRevisionClick = (event, item) => {
        event.preventDefault()
        history(`/plan/${selectedPlanMetadata.plan_id}/revision/${item['revision_id']}`, {
            state: {
                from: 'revisions',
                revision_id: item.revision_id,
                revision_name: item.revision_name,
                revision_title: item.revision_title,
                plan_name: selectedPlanMetadata.plan_name,
                plan_id: selectedPlanMetadata.plan_id,
            },
        })
    }

    const buildColumnDefinitions = () => {
        let columnDefs: any[] = []
        // first define the table view and form views for predefined /common attributes
        const attributeInfo = [
            ['revision_id', ''],
            ['revision_name', 'Name'],
            ['revision_title', 'Title'],
            ['revision_description', 'Description'],
            ['revision_status', 'Status'],
            ['reference_revision_id', ''],
            ['reference_revision_title', 'Reference Revision'],
            ['created_by', 'Created By'],
            ['updated_by', 'Updated By'],
            ['created_at', 'Created At'],
            ['updated_at', 'Updated At'],
        ]
        const visibleColumns: any[] = attributeInfo.map((attr) => {
            const attrId = attr[0]
            if (!attrId.includes('_id')) {
                return attr[0]
            }
        })

        // these next fields are just metadata for the table view
        columnDefs = attributeInfo.map((attr) => {
            const [attrId, attrName] = attr
            let cellType = (item) => item[attrId]
            if (attrId === 'created_at' || attrId === 'updated_at') {
                cellType = (item) => <DatePopover date={convertToLocalTime(item.created_at)} />
            } else if (attrId === 'revision_status') {
                cellType = (item) => (
                    <StatusIndicator type={STATUS_TO_INDICATOR_MAP[item.revision_status]}>
                        {PLAN_REVISION_STATUS_TYPES[item.revision_status]}
                    </StatusIndicator>
                )
            } else if (attrId === 'revision_name') {
                // todo - reroute to programs page if user is not an admin
                cellType = (item) =>
                    selectedPlanMetadata ? (
                        <Link
                            onFollow={(event) => onRevisionClick(event, item)}
                            href={`/plan/${selectedPlanMetadata?.plan_id}/revision/${item['revision_id']}`}
                        >
                            {item.revision_name}
                        </Link>
                    ) : (
                        <></>
                    )
            }
            return {
                id: attrId,
                header: attrName,
                cell: cellType,
                sortingField: attrId,
            }
        })

        setColumnDefinitions(columnDefs)
        setTableVisibleColumns(visibleColumns)
    }

    const deleteRevision = () => {
        if (!selectedPlanMetadata) {
            return
        }

        setDeleteModalVisible(false)
        setIsRevisionLoading(true)
        apiClient
            .delete(
                `/plan/${selectedPlanMetadata.plan_id}/revision?revision_id=${selectedRevisionMetadata.revision_id}`,
            )
            .then(() => {
                if (isLastRevision) {
                    history('/all-plans')
                    localStorage.clear()
                    localStorage.setItem(
                        'deleted_last_revision_name',
                        selectedRevisionMetadata.revision_name,
                    )
                    localStorage.setItem(
                        'deleted_last_revision_plan_name',
                        selectedPlanMetadata.plan_name,
                    )
                    return
                }

                getAllRevisions()
                handleAddAlertContent(
                    `Successfully deleted revision ${selectedRevisionMetadata.revision_name} and updated existing rev numbers.`,
                    ALERT_TYPES.SUCCESS,
                )
                setSelectedRevisionMetadata(undefined)
            })
            .catch((error) => {
                handleAddAlertContent(
                    `Failed to delete revision from ${selectedBusinessEntity.business_entity_name}.`,
                    ALERT_TYPES.ERROR,
                )
                setSelectedRevisionMetadata(undefined)
                console.error(error.response.data)
            })
            .finally(() => setIsRevisionLoading(false))
    }

    const getAllRevisions = () => {
        const urlParams = window.location.href.split('/')
        const planId = urlParams.reverse()[1]
        setIsRevisionLoading(true)
        apiClient
            .get(`plan/${planId}`)
            .then((response) => {
                let plan = response.data
                if (!plan) {
                    setSelectedPlanMetadata(plan)
                    setIsRevisionLoading(false)
                    setRevisions([])
                    return
                }
                plan = {
                    ...plan,
                    ['plan_name']: `${plan['year']} ${plan['plan_type']}`,
                }
                const revisions = plan['revisions'] || []
                const getTitleForReferenceRevision = (revision) => {
                    const refRevisionId = revision?.reference_revision_id || 0
                    if (refRevisionId === 0) {
                        return ''
                    }
                    const referenceRev = revisions.filter(
                        (rev) => rev.revision_id === refRevisionId,
                    )
                    if (!referenceRev.length) {
                        return ''
                    }
                    return `Rev ${referenceRev[0].revision_number} - ${referenceRev[0].revision_title}`
                }
                setRevisions(
                    revisions.map((revision) => ({
                        ...revision,
                        ['revision_id']: Number(revision.revision_id),
                        ['reference_revision_id']: Number(revision?.reference_revision_id || 0),
                        ['revision_name']: `Rev ${revision.revision_number}`,
                        ['reference_revision_title']: getTitleForReferenceRevision(revision),
                    })),
                )
                setSelectedPlanMetadata(plan)
                setIsRevisionLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setRevisions([])
                setIsRevisionLoading(false)
                setSelectedPlanMetadata(undefined)
            })
    }

    useEffect(() => {
        if (!selectedPlanMetadata) {
            BusinessEntityRefresh(selectedBusinessEntity.business_entity_id, getAllRevisions)
        }
    }, [selectedBusinessEntity, selectedPlanMetadata])

    useEffect(() => {
        if (!selectedPlanMetadata) {
            setIsRevisionLoading(false)
            return
        }
        buildColumnDefinitions()
        setSelectedRevisionMetadata(undefined)
        setCreateModalVisible(false)
        setDeleteModalVisible(false)
        setIsRevisionLoading(false)
    }, [selectedBusinessEntity, selectedPlanMetadata])

    return (
        <ContentLayout
            header={
                <Box margin={{ top: 's', left: 's', right: 's' }}>
                    <SpaceBetween size='m'>
                        <HeaderTemplate
                            items={[
                                { text: 'Home', href: '/' },
                                { text: 'Plans', href: '/all-plans' },
                                {
                                    text: `${
                                        selectedPlanMetadata
                                            ? selectedPlanMetadata.plan_name
                                            : 'Plan'
                                    } Revisions`,
                                    href: '',
                                },
                            ]}
                        />
                        {!alertItems.length ? (
                            <></>
                        ) : (
                            <Flashbar items={alertItems} stackItems={true} />
                        )}
                    </SpaceBetween>
                </Box>
            }
        >
            <DeleteModal
                title={isLastRevision ? 'Revision and Plan' : 'Revision'}
                visible={deleteModalVisible}
                onDismiss={() => setDeleteModalVisible(false)}
                onDelete={deleteRevision}
                descriptionOverride={
                    isLastRevision
                        ? 'Deleting the last revision will also delete the plan. This is permanent, you cannot recover the data later.'
                        : undefined
                }
            />
            <Box margin={{ left: 's', right: 's' }}>
                <RevisionList
                    selectedPlanMetadata={selectedPlanMetadata}
                    canAdmin={canAdmin}
                    revisions={revisions}
                    selectedRevisionMetadata={selectedRevisionMetadata}
                    setSelectedRevisionMetadata={setSelectedRevisionMetadata}
                    columns={columnDefinitions}
                    visibleColumns={tableVisibleColumns}
                    onCreate={handleCreate}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    isLoading={isRevisionLoading}
                />
            </Box>
            <CreateRevision
                modalMode={modalMode}
                setModalMode={setModalMode}
                visible={createModalVisible}
                selectedPlanMetadata={selectedPlanMetadata}
                closeModalHandler={handleCreateModalClose}
                refreshList={getAllRevisions}
                handleAddAlertContent={handleAddAlertContent}
                revisions={revisions}
                selectedRevisionMetadata={selectedRevisionMetadata}
            />
        </ContentLayout>
    )
}
export default SetupRevision
