import { useState } from 'react'
import { useCollection } from '@amzn/awsui-collection-hooks'
import { Header, Pagination, Table } from '@amzn/awsui-components-react'
import EmptyState from '../../../reusable/EmptyState'

const CopyDeliverableHcEstimateSummaryTable = ({
    selectedAggregateHcEstimates,
    selectedDeliverables,
    groupOptions,
}) => {
    const columnDefinitions = [
        {
            id: 'deliverable_id',
            header: 'Deliverable ID',
            cell: (e: any) => e.deliverable_id,
        },
        {
            id: 'deliverable_name',
            header: 'Deliverable',
            cell: (e: any) => {
                const deliverable = selectedDeliverables.find(
                    (deliv) => deliv.deliverable_id === e.deliverable_id,
                )
                if (deliverable) {
                    return deliverable.deliverable_name
                }

                return 'N/A'
            },
            sortingField: 'deliverable_name',
        },
        {
            id: 'group_id',
            header: 'Group ID',
            cell: (e: any) => e.group_id,
        },
        {
            id: 'group_name',
            header: 'Group',
            cell: (e: any) => {
                const group = groupOptions.find((group) => group.value === e.group_id)
                if (group) {
                    return group.label
                }

                return 'N/A'
            },
            sortingField: 'group_name',
        },
        {
            id: 'hc_ct',
            header: 'C&T',
            cell: (e: any) => e.hc_ct,
            sortingField: 'hc_ct',
        },
        {
            id: 'hc_ff',
            header: 'FF',
            cell: (e: any) => e.hc_ff,
            sortingField: 'hc_ff',
        },
        {
            id: 'hc_total',
            header: 'Total',
            cell: (e: any) => e.hc_total,
            sortingField: 'hc_total',
        },
    ]
    const visibleColumns = ['deliverable_name', 'group_name', 'hc_ct', 'hc_ff', 'hc_total']

    const [preferences] = useState({ pageSize: 50 })
    const { items, collectionProps, paginationProps } = useCollection(
        selectedAggregateHcEstimates,
        {
            propertyFiltering: {
                filteringProperties: [],
                empty: (
                    <EmptyState
                        title='No HC estimates'
                        subtitle={'No HC estimates to display.'}
                        action={<></>}
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {
                defaultState: {
                    sortingColumn: columnDefinitions[3],
                },
            },
            selection: {},
        },
    )

    return (
        <Table
            {...collectionProps}
            items={items}
            wrapLines
            stickyHeader={true}
            columnDefinitions={columnDefinitions}
            visibleColumns={visibleColumns}
            loadingText={'Loading HC estimates'}
            pagination={<Pagination {...paginationProps} />}
            header={
                <Header>
                    HC Estimate(s) by Group will be copied. Total:
                    {selectedAggregateHcEstimates.length}
                </Header>
            }
        />
    )
}

export default CopyDeliverableHcEstimateSummaryTable
