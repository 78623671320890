import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    FormField,
    Header,
    Input,
    Modal,
    Select,
    SpaceBetween,
} from '@amzn/awsui-components-react'
import {
    YEAR_OPTIONS,
    PLAN_TYPE_OPTIONS,
    NUMERIC_VALIDATION,
    PLAN_YEAR_MESSAGE,
    PLAN_TYPE_MESSAGE,
    generateDefaultPlan,
    DEFAULT_SELECT,
} from './Constants'
import { useAppContext } from '../../../context'
import { ALERT_TYPES, MODAL_MODES } from '../../Constant'
import useStore from '../../Store'
import StatusInfoPopover from '../reusable/StatusInfoPopover'

interface CreatePlanDynamicProps {
    selectedPlanMetadata: any
    visible: boolean
    modalMode: string
    setModalMode: any
    closeModalHandler: any
    refreshList: any
    handleAddAlertContent: any
    plans: any
}

const CreatePlan = (props: CreatePlanDynamicProps) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient
    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const initialPlanSet = props.selectedPlanMetadata
        ? {
              ...props.selectedPlanMetadata,
          }
        : generateDefaultPlan(selectedBusinessEntity.business_entity_id)
    const [formEdits, setFormEdits] = useState<any>(initialPlanSet)

    const [formErrors, setFormErrors] = useState<any>({})

    const handleFormUpdate = (value, attributeId) => {
        // updates global context with new form edits
        const currentFormEdits = { ...formEdits }
        const currentFormErrors = { ...formErrors }
        const errorMessage = validateFormInput(value, attributeId)
        currentFormErrors[attributeId] = errorMessage
        if (!errorMessage && Object.keys(currentFormErrors).includes(attributeId)) {
            delete currentFormErrors[attributeId]
        }
        currentFormEdits[attributeId] = value
        setFormEdits(currentFormEdits)
        setFormErrors(currentFormErrors)
    }

    useEffect(() => {
        if (
            (!props.selectedPlanMetadata && props.modalMode === MODAL_MODES.EDIT) ||
            !props.visible
        ) {
            const defaultPlan = generateDefaultPlan(selectedBusinessEntity.business_entity_id)
            setFormEdits(defaultPlan)
            setFormErrors({})
            return
        }
        const initialPlan =
            props.modalMode === MODAL_MODES.EDIT
                ? { ...props.selectedPlanMetadata }
                : generateDefaultPlan(selectedBusinessEntity.business_entity_id)
        setFormEdits(initialPlan)
        setFormErrors({})
    }, [props.selectedPlanMetadata, props.visible])

    const validateFormInput = (newValue, attributeId) => {
        let errorMessage = ''
        if (newValue.trim().length && attributeId === 'overhead_factor') {
            const validNum = !isNaN(Number(newValue.trim()))
            errorMessage = validNum ? '' : NUMERIC_VALIDATION
        } else if ((attributeId === 'year' || attributeId === 'plan_type') && !newValue) {
            if (!newValue) {
                errorMessage = attributeId === 'year' ? PLAN_YEAR_MESSAGE : PLAN_TYPE_MESSAGE
            }
        }
        return errorMessage
    }

    useEffect(() => {
        if (props.modalMode === MODAL_MODES.EDIT) {
            if (props.selectedPlanMetadata === undefined) {
                return
            }
        }
    }, [props.modalMode])

    const handleDismiss = () => {
        props.closeModalHandler()
        setFormEdits(undefined)
        setFormErrors({})
    }
    const handleClickCreate = () => {
        // there will only be a single program per edit session,
        // but admin can add new program to their BE in the same session
        // build payload for edit/create program
        const payload = {
            ...formEdits,
            mode: props.modalMode,
            requestor: appContext.userProps.userAlias,
            business_entity_id: selectedBusinessEntity.business_entity_id,
        }
        createPlan(payload)
            .then(() => {
                props.handleAddAlertContent(
                    `Successfully ${
                        props.modalMode === MODAL_MODES.EDIT ? 'updated' : 'created'
                    } plan ${payload['year']} ${payload['plan_type']}.`,
                    ALERT_TYPES.SUCCESS,
                )

                props.refreshList()
            })
            .catch((error) => {
                // backend will return early if there is an error creating the core program
                console.error(error)
                props.handleAddAlertContent(
                    `Failed to ${props.modalMode === MODAL_MODES.EDIT ? 'update' : 'create'} plan ${
                        payload['year']
                    } ${payload['plan_type']}: ${error.response.data}`,
                    ALERT_TYPES.ERROR,
                )
                props.setModalMode('')
                handleDismiss()
                return
            })
        props.setModalMode('')
        handleDismiss()
    }

    const createPlan = (payload) => {
        return apiClient.post(`/plan/${formEdits.plan_id}`, JSON.stringify(payload))
    }

    const buildFormInput = () => {
        if (!formEdits) {
            return <></>
        }
        const formFields = ['year', 'plan_type', 'stl_leader', 'admin_backup', 'overhead_factor']
        const formFieldsDisplay = [
            'Year',
            'Plan Type (OP1/OP2/Off-Cycle)',
            'STL',
            'Admin Backup',
            'Overhead Factor',
        ]

        return formFields.map((formField, index) => {
            let input
            if (formField === 'year' || formField === 'plan_type') {
                input = (
                    <Select
                        selectedOption={{
                            ['value']: formEdits[formField],
                            ['label']: formEdits[formField],
                        }}
                        options={formField === 'year' ? YEAR_OPTIONS : PLAN_TYPE_OPTIONS}
                        onChange={({ detail }) => {
                            const selectedItemValue = detail.selectedOption.value
                            handleFormUpdate(selectedItemValue, formField)
                        }}
                    ></Select>
                )
            } else {
                input = (
                    <Input
                        value={formEdits[formField]}
                        onChange={({ detail }) => {
                            const value = detail.value
                            handleFormUpdate(value, formField)
                        }}
                    ></Input>
                )
            }
            return (
                <FormField
                    errorText={formErrors && formErrors[formField] ? formErrors[formField] : ''}
                    label={
                        formFieldsDisplay[index] === 'Overhead Factor' ? (
                            <StatusInfoPopover
                                title={formFieldsDisplay[index]}
                                popoverContent="The value is a percentage. (ex. 10 would mean it's 10%)"
                            />
                        ) : (
                            formFieldsDisplay[index]
                        )
                    }
                >
                    {input}
                </FormField>
            )
        })
    }
    return (
        <Modal
            key={formEdits && formEdits?.planId ? formEdits.planId : ''}
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            header={props.modalMode === MODAL_MODES.EDIT ? 'Edit Plan' : 'New Plan'}
            visible={props.visible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleClickCreate}
                            disabled={
                                (formErrors && Object.keys(formErrors).length) ||
                                (formEdits && formEdits?.year === DEFAULT_SELECT) ||
                                (formEdits && formEdits?.plan_type === DEFAULT_SELECT)
                            }
                        >
                            {props.modalMode === MODAL_MODES.EDIT ? 'Update' : 'Add'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Container>
                <SpaceBetween size={'xs'}>
                    <Header>Plan Details</Header>
                    {buildFormInput()}
                </SpaceBetween>
            </Container>
        </Modal>
    )
}

export default CreatePlan
