import {
    AppLayout,
    Box,
    Button,
    ButtonDropdown,
    Container,
    ContentLayout,
    Header,
    Icon,
    Select,
    SpaceBetween,
    Tabs,
} from '@amzn/awsui-components-react'
import HeaderTemplate from '../reusable/HeaderTemplate'
import { ACTION_TYPES, useNewSummaryData } from './useNewSummaryData'
import { formatRevisionOptions, generateToggleComponent } from '../reusable/Utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { PLAN, REVISION, SUMMARY_TABS, SUMMARY_TABS_OPTIONS } from '../../Constant'

import { ClientSideRowModelModule, InfiniteRowModelModule, ModuleRegistry } from 'ag-grid-community'
import {
    ExcelExportModule,
    LicenseManager,
    RowGroupingModule,
    ServerSideRowModelModule,
    SetFilterModule,
} from '@amzn/ag-bird/src/ag-grid-enterprise'
import './SummaryView.scss'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { formatSelection, GRAND_TOTAL_COLOR_CODE, NORMAL_CELL_COLOR_CODE } from './SummaryUtil'
import { AgGridReact } from 'ag-grid-react'
import Preferences from './Preferences'
import { useQuery, useQueryState } from '../../UseQuery'
import {
    COL_LIST,
    COLUMNS,
    parseArrayFromString,
    PROGRAM,
    ROW_LIST,
    ROWS,
    setOrgByGroupList,
    setOrgList,
    TAB,
} from './PreferencesUtil'
import {
    isLoading,
    nameComparator,
    priorityComparator,
    ProgramTableColumns,
    programValueFormatter,
    ROW_HEIGHT,
    setRowsAndColumns,
    SORT_OPTIONS,
    getPreferenceData,
} from './NewSummariesUtil'
import './SummariesAudits.scss'
import HCDetailModal from '../program/deliverables/HCDetailModal'
import ProgramCellRenderer from './ProgramCellRenderer'
import { summariesAndAuditSectionDescription } from '../home/HomePageConstants'
import ProgramModal from './ProgramModal'
import { generateToolSideBar, SPEND_HIDDEN_FIELD_IDS } from './NewSpendSummaryUtils'
import * as xlsx from 'xlsx'
import CommentIcon from '../comment/CommentIcon'
import { COMMENT_PANEL_MODE, TEST_COMMENTS } from '../comment/Constant'
import CommentPanel from '../comment/CommentPanel'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

const headerBgColor = '#16191f'

const SummariesAudits = () => {
    const { data, dispatch } = useNewSummaryData()
    const [quickFilterText, setQuickFilterText] = useState<string>('')

    const query = useQuery()
    const initialTab = query.get(TAB) || SUMMARY_TABS.PROGRAM
    const [qsTab, setQsTab] = useQueryState()

    const [selectedTab, setSelectedTab] = useState<SUMMARY_TABS>(initialTab as SUMMARY_TABS)
    const [isPreferencesVisible, setPreferencesVisible] = useState(false)
    const openPreferencesModal = () => setPreferencesVisible(true)
    const closePreferencesModal = () => setPreferencesVisible(false)
    const [orgs, setOrgs] = useState<{ id: string; label: string }[]>([])
    const [groups, setGroups] = useState<{ id: string; label: string; children: any[] }[]>([])
    const url = window.location.href
    const initialOrgs = useMemo(
        () =>
            parseArrayFromString(
                query.get(SUMMARY_TABS.ORG),
                initialTab === SUMMARY_TABS.PROGRAM
                    ? orgs?.map((org) => org.label)
                    : [orgs[0]?.label],
            ),
        [url, orgs],
    )

    const initialGroups = useMemo(
        () => parseArrayFromString(query.get(SUMMARY_TABS.GROUP), [groups[0]?.children[0].value]),
        [url, groups],
    )
    const initialRows = useMemo(() => parseArrayFromString(query.get(ROWS), ROW_LIST), [url])
    const initialColumns = useMemo(() => parseArrayFromString(query.get(COLUMNS), COL_LIST), [url])
    const [sortBy, setSortBy] = useState<SORT_OPTIONS>(SORT_OPTIONS.NAME)
    const comparator = useRef<any>(nameComparator)
    const [programModalVisible, setProgramModalVisible] = useState<boolean>(false)
    const [programTableColDefs, setProgramTableColDefs] = useState(
        ProgramTableColumns(setProgramModalVisible),
    )

    // comment side panel state
    const [isSidePanelVisible, setIsSidePanelVisible] = useState<boolean>(false)
    const [commentMode, setCommentMode] = useState<COMMENT_PANEL_MODE>(COMMENT_PANEL_MODE.DISABLED)
    const [comments, setComments] = useState<any[]>(TEST_COMMENTS)

    useEffect(() => {
        if (!data.gridApi) {
            return
        }

        if (isLoading(data)) {
            data.gridApi.showLoadingOverlay()
        } else {
            data.gridApi.hideOverlay()
            if (data.gridApi.getDisplayedRowCount() === 0) {
                data.gridApi.showNoRowsOverlay()
            }
        }
    }, [
        data[ACTION_TYPES.PROGRAM].loading,
        data[ACTION_TYPES.DELIVERABLES].loading,
        data[ACTION_TYPES.PLAN].loading,
        data[ACTION_TYPES.HC].loading,
        data[ACTION_TYPES.GROUPS_BY_ORG].loading,
        data[ACTION_TYPES.SPEND].loading,
    ])

    useEffect(() => {
        if (!data.gridApi) {
            return
        }

        comparator.current = sortBy === SORT_OPTIONS.NAME ? nameComparator : priorityComparator

        data.gridApi.updateGridOptions({
            ...data.gridApi.gridOptions,
            autoGroupColumnDef: {
                headerName: 'Program',
                field: 'program_name',
                minWidth: 300,
                pinned: 'left',
                filter: 'agSetColumnFilter',
                filterParams: {
                    comparator: (a, b) => a.localeCompare(b),
                },
                hide: true,
                floatingFilter: true,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    innerRenderer: ProgramCellRenderer,
                },
                valueFormatter: programValueFormatter,
                mainMenuItems: [
                    {
                        name: 'Sort By',
                        subMenu: [
                            { name: 'Name', action: () => setSortBy(SORT_OPTIONS.NAME) },
                            { name: 'Priority', action: () => setSortBy(SORT_OPTIONS.PRIORITY) },
                        ],
                    },
                ],
                comparator: comparator!.current,
                sort: 'asc',
                sortingOrder: ['asc', 'desc'],
            },
        })
    }, [sortBy])

    const onGridReady = (params: any) => {
        dispatch({ type: 'GRID_API', payload: params.api })
        params.api.autoSizeAllColumns()
    }

    useEffect(() => {
        if (!data.gridApi || isLoading(data)) {
            return
        }
        data.gridApi.updateGridOptions({
            sideBar: selectedTab === SUMMARY_TABS.SPEND ? generateToolSideBar() : undefined,
            groupTotalRow: selectedTab !== SUMMARY_TABS.SPEND ? 'bottom' : undefined,
            grandTotalRow: selectedTab !== SUMMARY_TABS.SPEND ? 'bottom' : undefined,
        })
        setRowsAndColumns(
            selectedTab,
            data,
            initialOrgs,
            initialGroups,
            initialColumns,
            initialRows,
        )
        data.gridApi.autoSizeAllColumns()
    }, [
        data[ACTION_TYPES.REVISION]?.selected,
        data[ACTION_TYPES.PROGRAM]?.selected,
        data[ACTION_TYPES.SCOPED],
        data?.gridApi,
        isLoading(data),
        selectedTab,
        initialOrgs,
        initialGroups,
        initialColumns,
        initialRows,
    ])

    useEffect(() => {
        // Load the column definitions and rows for the particular tab
        if (data[ACTION_TYPES.GROUPS_BY_ORG].loading) {
            return
        }

        setOrgs(setOrgList(data))
        setGroups(setOrgByGroupList(data))
    }, [data[ACTION_TYPES.GROUPS_BY_ORG].loading])

    return (
        <AppLayout
            disableContentPaddings={true}
            contentType={'table'}
            navigationHide={true}
            toolsHide={true}
            splitPanelOpen={isSidePanelVisible}
            splitPanelPreferences={{ position: 'side' }}
            splitPanel={
                <CommentPanel
                    mode={commentMode}
                    onIsVisibleChange={setIsSidePanelVisible}
                    comments={comments}
                />
            }
            content={
                <ContentLayout
                    defaultPadding
                    headerBackgroundStyle={headerBgColor}
                    headerVariant='high-contrast'
                    header={
                        <Box margin={{ left: 's', right: 's' }}>
                            <SpaceBetween size='xs' direction='vertical'>
                                <HeaderTemplate
                                    title='Summaries & Audits'
                                    subtitle={summariesAndAuditSectionDescription}
                                    items={[
                                        { text: 'Home', href: '/' },
                                        { text: `${selectedTab} Summary` },
                                    ]}
                                />
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <Container>
                        <SpaceBetween size='xs' direction='vertical'>
                            {buildTableHeader(
                                selectedTab,
                                data,
                                dispatch,
                                openPreferencesModal,
                                isSidePanelVisible,
                                setIsSidePanelVisible,
                                commentMode,
                                setCommentMode,
                            )}
                        </SpaceBetween>
                        <Tabs
                            onChange={({ detail }) => {
                                setSelectedTab(detail.activeTabId as SUMMARY_TABS)
                                setQsTab({ [TAB]: detail.activeTabId })
                            }}
                            activeTabId={selectedTab}
                            tabs={SUMMARY_TABS_OPTIONS}
                        />
                        {selectedTab === SUMMARY_TABS.PROGRAM && (
                            <>
                                <div
                                    className='ag-theme-quartz' // applying the grid theme
                                    // the grid will fill the size of the parent container
                                >
                                    <AgGridReact
                                        rowData={
                                            data[ACTION_TYPES.PROGRAM]?.selected?.data
                                                ? [data[ACTION_TYPES.PROGRAM]?.selected?.data]
                                                : []
                                        }
                                        columnDefs={programTableColDefs}
                                        domLayout='autoHeight'
                                    />
                                </div>
                                <ProgramModal
                                    data={data[ACTION_TYPES.PROGRAM]?.selected?.data}
                                    visible={programModalVisible}
                                    setVisible={setProgramModalVisible}
                                />
                            </>
                        )}
                        <div
                            className='ag-theme-quartz' // applying the grid theme
                            style={{ height: '60vh' }} // the grid will fill the size of the parent container
                        >
                            <Preferences
                                visible={isPreferencesVisible}
                                onDismiss={closePreferencesModal}
                                initialSelectedOrgs={initialOrgs}
                                initialSelectedGroups={initialGroups}
                                initialSelectedRows={initialRows}
                                initialSelectedColumns={initialColumns}
                                orgs={orgs}
                                groups={groups}
                                preferenceType={selectedTab}
                            />
                            <AgGridReact
                                quickFilterText={quickFilterText}
                                onGridReady={onGridReady}
                                gridOptions={{
                                    getRowStyle: (params) => {
                                        return {
                                            fontWeight:
                                                params.node.rowPinned ||
                                                params.node.footer /*|| params.node*/
                                                    ? 'bold'
                                                    : 'normal',
                                            background: params.node.footer
                                                ? GRAND_TOTAL_COLOR_CODE
                                                : NORMAL_CELL_COLOR_CODE,
                                        }
                                    },
                                    pagination: false,
                                    groupTotalRow:
                                        selectedTab !== SUMMARY_TABS.SPEND ? 'bottom' : undefined,
                                    grandTotalRow:
                                        selectedTab !== SUMMARY_TABS.SPEND ? 'bottom' : undefined,
                                    autoSizeStrategy: {
                                        type: 'fitCellContents',
                                    },
                                    autoGroupColumnDef: {
                                        headerName: 'Program',
                                        field: 'program_name',
                                        minWidth: 300,
                                        pinned: 'left',
                                        filter: 'agSetColumnFilter',
                                        filterParams: {
                                            comparator: (a, b) => a.localeCompare(b),
                                        },
                                        hide: true,
                                        floatingFilter: true,
                                        cellRenderer: 'agGroupCellRenderer',
                                        cellRendererParams: {
                                            innerRenderer: ProgramCellRenderer,
                                        },
                                        valueFormatter: programValueFormatter,
                                        mainMenuItems: [
                                            {
                                                name: 'Sort By',
                                                subMenu: [
                                                    {
                                                        name: 'Name',
                                                        action: () => setSortBy(SORT_OPTIONS.NAME),
                                                    },
                                                    {
                                                        name: 'Priority',
                                                        action: () =>
                                                            setSortBy(SORT_OPTIONS.PRIORITY),
                                                    },
                                                ],
                                            },
                                        ],
                                        comparator: comparator!.current,
                                        sort: 'asc',
                                        sortingOrder: ['asc', 'desc'],
                                    },
                                    suppressAggFuncInHeader: true,
                                    defaultColDef: {
                                        lockPosition: true,
                                        wrapHeaderText: true,
                                        autoHeaderHeight: true,
                                        initialWidth: 100,
                                        suppressHeaderMenuButton: false,
                                        suppressHeaderContextMenu: true,
                                        filter:
                                            selectedTab === SUMMARY_TABS.SPEND
                                                ? 'agSetColumnFilter'
                                                : undefined,
                                    },
                                    tooltipShowDelay: 500,
                                    sideBar:
                                        selectedTab !== SUMMARY_TABS.SPEND
                                            ? undefined
                                            : generateToolSideBar(),
                                }}
                                rowHeight={ROW_HEIGHT}
                                headerHeight={ROW_HEIGHT}
                                onColumnGroupOpened={(e) => e.api.autoSizeAllColumns()}
                                getRowId={(params: any) => params.data?.row_id}
                                groupMaintainOrder={true}
                            />
                        </div>
                    </Container>
                </ContentLayout>
            }
        ></AppLayout>
    )
}

function buildTableHeader(
    type: SUMMARY_TABS,
    data,
    dispatch,
    openPreferencesModal,
    isSidePanelVisible,
    setIsSidePanelVisible,
    commentMode,
    setCommentMode,
) {
    const [queries, setQueries] = useQueryState()

    const [hcModalVisible, setHCModalVisible] = useState<boolean>(false)

    const setScopedValue = (val: boolean) => {
        dispatch({
            type: ACTION_TYPES.SCOPED,
            payload: val,
        })
    }

    const getExportFileName = (isDetailsExport) => {
        const defaultSheetName = `${type}Summary`
        if (
            !data[ACTION_TYPES.PLAN].selected?.data ||
            !data[ACTION_TYPES.REVISION].selected?.value
        ) {
            return defaultSheetName
        }
        const plan = data[ACTION_TYPES.PLAN].selected?.data
        const planName = `${plan?.year ?? ''}${plan?.plan_type ?? 'Plan'}`
        const revisions = plan?.revisions ?? []
        const selectedRev = revisions.find(
            (rev) => rev.revision_id === data[ACTION_TYPES.REVISION].selected.value,
        )
        const revName = `Revision${selectedRev?.revision_number ?? ''}`
        const suffix = isDetailsExport ? '-WithDetails' : ''
        return `${defaultSheetName}${suffix}-${planName}-${revName}.xlsx`
    }

    const getExportSheetName = () => {
        return type
    }

    const handleClickExport = (isDetailsExport, params?: any) => {
        data.gridApi?.exportDataAsExcel({
            sheetName: getExportSheetName,
            fileName: getExportFileName(isDetailsExport),
            ...params,
        })
    }
    const getSpendColumnsWithDetails = () => {
        if (!data.gridApi) {
            return
        }
        const allColumns = data.gridApi?.getColumns()
        return allColumns.filter((col) => {
            const colDef = col.getColDef()
            if (col.visible || SPEND_HIDDEN_FIELD_IDS.includes(colDef.field)) {
                return col
            }
        })
    }

    const handleClickExportWithPreferences = async () => {
        const excelBinary = data.gridApi?.getDataAsExcel()
        const arrayBuffer = await excelBinary.arrayBuffer()

        const workbook = xlsx.read(new Uint8Array(arrayBuffer), { type: 'array' })

        const preferenceData = getPreferenceData(queries, data)

        xlsx.utils.book_append_sheet(workbook, preferenceData, 'Export Preferences')

        const xlsxData = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' })

        const blob = new Blob([xlsxData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = getExportFileName(false)

        document.body.appendChild(link)
        link.click()

        URL.revokeObjectURL(url)
        document.body.removeChild(link)
    }

    return (
        <>
            <HCDetailModal
                visible={hcModalVisible}
                onVisibleChange={setHCModalVisible}
                // TODO -> complete data
                hrTeamData={{}}
                hrMemberData={{}}
                employeeTypeData={{}}
            />
            <Box>
                <Box float='left'>
                    <Header>{type} Summary</Header>
                </Box>
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='s'>
                        <Box padding={{ top: 's' }}>
                            <SpaceBetween direction='horizontal' size='s'>
                                <CommentIcon
                                    isVisible={isSidePanelVisible}
                                    onVisibleChange={setIsSidePanelVisible}
                                    mode={commentMode}
                                    onModeChange={setCommentMode}
                                />
                                <Box padding={{ top: 's' }}>
                                    {type !== SUMMARY_TABS.SPEND ? (
                                        <Button
                                            disabled={isLoading(data)}
                                            disabledReason={
                                                'Fetching data, please wait before exporting.'
                                            }
                                            onClick={() => handleClickExportWithPreferences()}
                                        >
                                            Export
                                        </Button>
                                    ) : (
                                        <ButtonDropdown
                                            items={[
                                                {
                                                    text: 'Export With Details',
                                                    id: 'export',
                                                    disabled: isLoading(data),
                                                },
                                            ]}
                                            mainAction={{
                                                text: 'Export',
                                                onClick: () => {
                                                    handleClickExport(false, {
                                                        skipColumnGroupHeaders: true,
                                                    })
                                                },
                                                disabled: isLoading(data),
                                            }}
                                            onItemClick={() => {
                                                handleClickExport(true, {
                                                    skipColumnGroupHeaders: true,
                                                    columnKeys: getSpendColumnsWithDetails(),
                                                })
                                            }}
                                            disabled={isLoading(data)}
                                            disabledReason={
                                                'Fetching data, please wait before exporting.'
                                            }
                                        ></ButtonDropdown>
                                    )}
                                </Box>
                                <Box padding={{ top: 's' }}>
                                    <Button onClick={openPreferencesModal}>
                                        Preferences <Icon name='settings' />
                                    </Button>
                                </Box>
                            </SpaceBetween>
                        </Box>
                    </SpaceBetween>
                </Box>
            </Box>
            <SpaceBetween direction='horizontal' size='m'>
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Plan'}
                    selectedOption={data[ACTION_TYPES.PLAN].selected}
                    onChange={({ detail }) => {
                        const option: any = detail.selectedOption
                        const revisions: any = option?.data.revisions.map(formatRevisionOptions)
                        setQueries({ [PLAN]: detail.selectedOption.value })
                        if (revisions.length) {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: revisions },
                                        { key: 'selected', data: revisions[0] },
                                    ],
                                },
                            })
                        } else {
                            dispatch({
                                type: 'SET_MANY',
                                payload: {
                                    [ACTION_TYPES.REVISION]: [
                                        { key: 'options', data: [] },
                                        {
                                            key: 'selected',
                                            data: {
                                                label: 'Select Revision',
                                                value: '',
                                            },
                                        },
                                    ],
                                },
                            })
                        }
                        dispatch({
                            type: ACTION_TYPES.PLAN,
                            payload: { key: 'selected', data: option },
                        })
                    }}
                    options={data[ACTION_TYPES.PLAN].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                <Select
                    filteringType={'auto'}
                    placeholder={'Selected Revision'}
                    selectedOption={data[ACTION_TYPES.REVISION].selected}
                    onChange={({ detail }) => {
                        setQueries({ [REVISION]: detail.selectedOption.value })
                        dispatch({
                            type: ACTION_TYPES.REVISION,
                            payload: {
                                key: 'selected',
                                data: detail.selectedOption,
                            },
                        })
                    }}
                    options={data[ACTION_TYPES.REVISION].options}
                    disabled={!data[ACTION_TYPES.REVISION].options.length}
                />
                {type === SUMMARY_TABS.PROGRAM && (
                    <Select
                        options={data[ACTION_TYPES.PROGRAM].options}
                        selectedOption={formatSelection(data[ACTION_TYPES.PROGRAM].selected)}
                        onChange={({ detail }) => {
                            setQueries({ [PROGRAM]: detail.selectedOption.value })
                            dispatch({
                                type: ACTION_TYPES.PROGRAM,
                                payload: { key: 'selected', data: detail.selectedOption },
                            })
                        }}
                        filteringType='auto'
                    />
                )}
                {type !== SUMMARY_TABS.SPEND &&
                    generateToggleComponent(
                        'All',
                        'Scoped',
                        data[ACTION_TYPES.SCOPED],
                        setScopedValue,
                    )}
            </SpaceBetween>
        </>
    )
}

export default SummariesAudits
