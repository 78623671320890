import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    FormField,
    Header,
    Input,
    Modal,
    Select,
    SpaceBetween,
    Textarea,
} from '@amzn/awsui-components-react'
import {
    generateDefaultRevision,
    getReferenceRevisionOptions,
    PLAN_REVISION_STATUS_OPTIONS,
    PLAN_REVISION_STATUS_TYPES,
} from '../Constants'
import { useAppContext } from '../../../../context'
import { ALERT_TYPES, MODAL_MODES } from '../../../Constant'
import useStore from '../../../Store'
import LoadingScreen from '../../../common/LoadingScreen'

interface CreateRevisionDynamicProps {
    selectedPlanMetadata: any
    selectedRevisionMetadata: any
    visible: boolean
    modalMode: string
    setModalMode: any
    closeModalHandler: any
    refreshList: any
    handleAddAlertContent: any
    revisions: any
}

const CreateRevision = (props: CreateRevisionDynamicProps) => {
    const appContext = useAppContext()
    const apiClient = appContext.apiClient

    const selectedBusinessEntity = useStore((state) => state.selectedBusinessEntity)
    const selectedBusinessEntityId = selectedBusinessEntity.business_entity_id
    const [isLoading, setIsLoading] = useState(false)
    const initialRevision = props.selectedRevisionMetadata
        ? {
              ...props.selectedRevisionMetadata,
          }
        : generateDefaultRevision(
              props.selectedPlanMetadata,
              selectedBusinessEntityId,
              props.revisions,
          )
    const [formEdits, setFormEdits] = useState<any>(initialRevision)
    const handleFormUpdate = (value, attributeId) => {
        // updates global context with new form edits
        const currentFormEdits = { ...formEdits }
        currentFormEdits[attributeId] = value
        setFormEdits(currentFormEdits)
    }

    useEffect(() => {
        if (
            (!props.selectedRevisionMetadata && props.modalMode === MODAL_MODES.EDIT) ||
            !props.visible
        ) {
            const defaultRevision = generateDefaultRevision(
                props.selectedPlanMetadata,
                selectedBusinessEntityId,
                props.revisions,
            )
            setFormEdits(defaultRevision)
            return
        }
        const initialRevision =
            props.modalMode === MODAL_MODES.EDIT
                ? { ...props.selectedRevisionMetadata }
                : generateDefaultRevision(
                      props.selectedPlanMetadata,
                      selectedBusinessEntityId,
                      props.revisions,
                  )
        setFormEdits(initialRevision)
    }, [props.selectedPlanMetadata, props.visible])

    useEffect(() => {
        if (props.modalMode === MODAL_MODES.EDIT) {
            if (props.selectedRevisionMetadata === undefined) {
                return
            }
        }
    }, [props.modalMode, props.visible])

    const handleDismiss = () => {
        props.closeModalHandler()
        setFormEdits(undefined)
    }
    const handleClickCreate = () => {
        // there will only be a single program per edit session,
        // but admin can add new program to their BE in the same session
        // build payload for edit/create program
        const payload = {
            ...formEdits,
            reference_revision_id:
                props.modalMode === MODAL_MODES.CREATE
                    ? Number(formEdits['reference_revision_title'].split('___')[1])
                    : 0,
            business_entity_id: props.selectedPlanMetadata.business_entity_id,
            mode: props.modalMode,
            requestor: appContext.userProps.userAlias,
            plan_name: props.selectedPlanMetadata.plan_name,
        }
        setIsLoading(payload.reference_revision_id > 0)
        createRevision(payload)
            .then(() => {
                props.handleAddAlertContent(
                    `Successfully ${
                        props.modalMode === MODAL_MODES.EDIT ? 'updated' : 'created'
                    } revision ${formEdits['revision_name']} for plan ${payload['plan_name']}.`,
                    ALERT_TYPES.SUCCESS,
                )

                props.refreshList()
                setIsLoading(false)
            })
            .catch((error) => {
                // backend will return early if there is an error creating the core program
                console.error(error)
                props.handleAddAlertContent(
                    `Failed to ${
                        props.modalMode === MODAL_MODES.EDIT ? 'update' : 'create'
                    } revision ${formEdits['revision_name']} for plan ${payload['plan_name']}: ${
                        error.response.data
                    }`,
                    ALERT_TYPES.ERROR,
                )
                props.setModalMode('')
                handleDismiss()
                setIsLoading(false)
                return
            })
        props.setModalMode('')
        handleDismiss()
    }

    const createRevision = async (payload) => {
        await apiClient.post(
            `/plan/${props.selectedPlanMetadata.plan_id}/revision?revision_id=${formEdits.revision_id}`,
            JSON.stringify(payload),
        )
    }

    const buildFormInput = () => {
        if (!formEdits) {
            return <></>
        }
        const formFields = [
            'revision_title',
            'revision_description',
            'revision_status',
            ...(props.modalMode === MODAL_MODES.CREATE ? ['reference_revision_title'] : []),
        ]
        const formFieldsDisplay = [
            'Title',
            'Description',
            'Status',
            ...(props.modalMode === MODAL_MODES.CREATE ? ['Create Revision From'] : []),
        ]

        return formFields.map((formField, index) => {
            let input
            const isRevStatus = formField === 'revision_status'
            if (isRevStatus || formField === 'reference_revision_title') {
                const options = isRevStatus
                    ? PLAN_REVISION_STATUS_OPTIONS
                    : getReferenceRevisionOptions(props.revisions)
                input = (
                    <Select
                        selectedOption={
                            options.length === 1
                                ? options[0]
                                : {
                                      ['value']: formEdits[formField],
                                      ['label']: isRevStatus
                                          ? PLAN_REVISION_STATUS_TYPES[formEdits[formField]]
                                          : formEdits[formField].split('___')[0],
                                  }
                        }
                        options={options}
                        onChange={({ detail }) => {
                            const selectedItemValue = detail.selectedOption.value
                            handleFormUpdate(selectedItemValue, formField)
                        }}
                    ></Select>
                )
            } else if (formField === 'revision_title') {
                input = (
                    <Input
                        value={formEdits[formField]}
                        onChange={({ detail }) => {
                            const value = detail.value
                            handleFormUpdate(value, formField)
                        }}
                    ></Input>
                )
            } else if (formField === 'revision_description') {
                input = (
                    <Textarea
                        value={formEdits[formField]}
                        onChange={({ detail }) => {
                            const value = detail.value
                            handleFormUpdate(value, formField)
                        }}
                    ></Textarea>
                )
            }
            return (
                <FormField key={`${formFieldsDisplay[index]}-key`} label={formFieldsDisplay[index]}>
                    {input}
                </FormField>
            )
        })
    }

    return (
        <Modal
            key={formEdits && formEdits?.planId ? formEdits.planId : ''}
            onDismiss={({ detail }) => {
                if (detail.reason === 'overlay') {
                    // prevent close modal from clicking outside of modal
                    return
                }
                handleDismiss()
            }}
            header={props.modalMode === MODAL_MODES.EDIT ? 'Edit Revision' : 'New Revision'}
            visible={props.visible}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={handleDismiss}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleClickCreate}
                            disabled={(formEdits && !formEdits['revision_title']) || isLoading}
                        >
                            {props.modalMode === MODAL_MODES.EDIT ? 'Update' : 'Add'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <Container>
                <SpaceBetween size={'xs'}>
                    <Header>Revision Details</Header>
                    {!isLoading ? buildFormInput() : <LoadingScreen />}
                </SpaceBetween>
            </Container>
        </Modal>
    )
}

export default CreateRevision
