import { EMPLOYEE_TYPE_GENERIC, HR_MEMBER_DATA_KEY } from '../../../Constant'
import bigDecimal from 'js-big-decimal'
import { loadSelectedState, REDUCER_ACTIONS } from '../ProgramSharedUtils'
import { generateInitialDeliverableState } from './DeliverableConstants'
import { Link } from '@amzn/awsui-components-react'

export const getCopyDeliverableSandboxValidationFailureMessage = (count) => {
    return (
        <>
            Please ensure there are no unsubmitted sandbox headcounts before proceeding with the
            copy deliverable action. There are {count} unsubmitted sandbox headcounts.{' '}
            <Link external externalIconAriaLabel='Opens in a new tab' href={`/sandbox`}>
                Navigate to Sandbox.
            </Link>
        </>
    )
}

export const convertMetadataToDict = (metadata, keyValueMap) => {
    const result = {}
    metadata.map((value, index) => {
        result[keyValueMap[index]] = value
    })
    return result
}

export const isHRObjectInvalid = (hr_obj) => {
    if (!hr_obj) {
        return true
    }

    return !hr_obj['resource_name'] || !hr_obj['alias']
}

export const formatHrMemberData = (hrMembers) => {
    const result = Object.fromEntries(
        EMPLOYEE_TYPE_GENERIC.map((employeeType) => [employeeType, [] as any[]]),
    )
    hrMembers.forEach((hrMember) => {
        switch (hrMember[9]) {
            case 'Fixed Corp&Tech': {
                const hr_ct_employee_obj = convertMetadataToDict(hrMember, HR_MEMBER_DATA_KEY)
                if (isHRObjectInvalid(hr_ct_employee_obj)) {
                    return
                }

                result[EMPLOYEE_TYPE_GENERIC[3]].push(hr_ct_employee_obj)
                break
            }
            case 'Fixed Field': {
                const hr_ff_employee_obj = convertMetadataToDict(hrMember, HR_MEMBER_DATA_KEY)
                if (isHRObjectInvalid(hr_ff_employee_obj)) {
                    return
                }

                result[EMPLOYEE_TYPE_GENERIC[4]].push(hr_ff_employee_obj)
                break
            }
            default: {
                const hr_intern_obj = convertMetadataToDict(hrMember, HR_MEMBER_DATA_KEY)
                if (isHRObjectInvalid(hr_intern_obj)) {
                    return
                }
                result[EMPLOYEE_TYPE_GENERIC[1]].push(hr_intern_obj)
            }
        }
    })
    return result
}

export const getHeadcountEmployeeValues = (teamData) => {
    return Object.fromEntries(
        EMPLOYEE_TYPE_GENERIC.map((employeeType) => [
            employeeType,
            teamData[employeeType].toString(),
        ]),
    )
}

export const calculateProjectedOH = (projectionTotal, projectionTotalForOH, overheadToApply) => {
    let result = bigDecimal.multiply(
        projectionTotalForOH,
        bigDecimal.add(1, bigDecimal.divide(overheadToApply, 100)),
    )
    const nonOHTotal = bigDecimal.subtract(projectionTotal, projectionTotalForOH)
    result = bigDecimal.add(nonOHTotal, result)
    return result
}

export const calculateGap = (projection, bis) => {
    return bigDecimal.subtract(projection, bis)
}

export const reducer = (currState, action) => {
    switch (action.type) {
        case REDUCER_ACTIONS.input:
            return {
                ...currState,
                [action.target]: action.value,
            }
        case REDUCER_ACTIONS.initial:
            return generateInitialDeliverableState()
        case REDUCER_ACTIONS.load:
            return loadSelectedState(currState, action.target, false, 'deliverable')
        default:
            return currState
    }
}
