import { ICellRendererParams } from 'ag-grid-community'
import { PHONE_TOOL_PREFIX, SYS_USER } from '../../../../Constant'
import { Button, Icon, Link } from '@amzn/awsui-components-react'
import { formatFieldName, sortStringList } from '../../../../common/Util'
import {
    formatObjectStateAsDifferences,
    generateHeadcountUpdateText,
    generateUpdateText,
} from '../../AuditUtil'
import { CustomTooltipProps } from 'ag-grid-react'
import './RendererStyles.css'
import { ACTION_TYPES, AUDIT_LOG_GROUPS } from '../../Constant'
import _ from 'lodash'
import { convertToMoneyFormat } from '../../../reusable/Utils'

const MAX_NUMBER_FIELD_NAMES_DISPLAYED_INLINE = 4
const DIFF_COUNT_TO_DISPLAY_INLINE = 1
const MAX_STRING_LENGTH = 25
const DEFAULT_EMPTY_STATE = <></>

export function AliasRenderer(params: ICellRendererParams) {
    if (params.value === SYS_USER) {
        return 'System'
    }

    return (
        <Link external={true} href={`${PHONE_TOOL_PREFIX}${params.value}`}>
            {params.value}
        </Link>
    )
}

export interface RendererProps {
    omitDetailsButton?: boolean
    fieldDetailsContent: any // JSX component
    data: any
    fieldDetailsClassName?: string
    differencesKeys: string[]
}

export function renderChangeField(props: RendererProps) {
    const { omitDetailsButton, fieldDetailsContent, data, fieldDetailsClassName, differencesKeys } =
        props

    return (
        <div>
            <div className='change-summary'>
                <span>
                    {data?.log_group !== AUDIT_LOG_GROUPS.HEADCOUNT
                        ? generateUpdateText(differencesKeys, data.item_name, data.action)
                        : generateHeadcountUpdateText(differencesKeys, data.item_name, data.action)}
                </span>
                {!omitDetailsButton && (
                    <Button onClick={data.on_details_click} variant={'inline-link'}>
                        Details
                    </Button>
                )}
            </div>
            <div className={fieldDetailsClassName ?? 'field-details'}>{fieldDetailsContent}</div>
        </div>
    )
}

const renderFieldNames = (changedFieldNames: string[]) => {
    // this helper is used to render a list of first 6 changed attributes in the Changes row
    let changedFieldsDisplay = sortStringList(changedFieldNames)
    if (changedFieldsDisplay.length > MAX_NUMBER_FIELD_NAMES_DISPLAYED_INLINE) {
        const changesDiff = changedFieldsDisplay.length - MAX_NUMBER_FIELD_NAMES_DISPLAYED_INLINE
        changedFieldsDisplay = [
            ...changedFieldsDisplay.slice(0, MAX_NUMBER_FIELD_NAMES_DISPLAYED_INLINE),
            `+ ${changesDiff} More...`,
        ]
    } else if (changedFieldsDisplay.length < MAX_NUMBER_FIELD_NAMES_DISPLAYED_INLINE) {
        const changesDiff = MAX_NUMBER_FIELD_NAMES_DISPLAYED_INLINE - changedFieldsDisplay.length
        changedFieldsDisplay = [...changedFieldsDisplay, ...new Array(changesDiff).fill(' ')]
    }
    return changedFieldsDisplay.map((field, idx) =>
        // add comma between field names when displaying in the table
        idx < MAX_NUMBER_FIELD_NAMES_DISPLAYED_INLINE - 1 &&
        idx < changedFieldNames.length - 1 &&
        !_.isEmpty(field)
            ? field + ','
            : field,
    )
}

const renderPrevAfterTextField = (inputVal) => {
    return inputVal?.toString() || '-'
}

const renderDifferencesInline = (
    differencesObject: { [p: string]: DifferencesObject } | null,
    keyName,
    divClassName?,
) => {
    if (!differencesObject) {
        return DEFAULT_EMPTY_STATE
    }
    const prev = differencesObject[keyName]['prev'] ?? ''
    const next = differencesObject[keyName]['next'] ?? ''
    const renderFieldWithUnits = (fieldVal) => {
        let valToDisplay = fieldVal
        if (keyName.includes('expenditure')) {
            valToDisplay = convertToMoneyFormat(fieldVal || '0')
        } else if (keyName === 'headcount_value' || keyName === 'fte_month_value') {
            valToDisplay = Number(fieldVal || '0')?.toFixed(2)
        }
        return renderPrevAfterTextField(valToDisplay)
    }

    return (
        <>
            <span>{formatFieldName(keyName)}</span>
            <span>
                <div className={divClassName ?? 'field-comparison'}>
                    <span className={`right-justify`}>{renderFieldWithUnits(prev)}</span>
                    <span className={'center-justify'}>
                        <Icon variant={'normal'} name={'caret-right-filled'} />
                    </span>

                    <span className={`left-justify`}>{renderFieldWithUnits(next)}</span>
                </div>
            </span>
        </>
    )
}

export function DifferencesRenderer(params: ICellRendererParams) {
    const { data } = params

    const differences: { [p: string]: DifferencesObject } | null = getChangesToUse(data)

    if (!differences) {
        return DEFAULT_EMPTY_STATE
    }
    // by default, will display everything in the diff object
    // but can also display specific fields that are of customer interest
    // example - for spend, display only total_expenditure
    let differencesToDisplay = { ...differences }
    const allDifferenceKeys = Object.keys(differences)
    if (!_.isEmpty(data?.override_display_keys)) {
        const filteredDiffEntries = Object.entries(differences).filter(([keyName, _]) =>
            data.override_display_keys.includes(keyName),
        )
        if (filteredDiffEntries.length) {
            differencesToDisplay = Object.fromEntries(filteredDiffEntries)
        }
    }
    const changedFieldsToDisplay = Object.keys(differencesToDisplay)
    // note that differences to display + all differences are typically equal
    // only case where they are not is for spend dashboard
    // ie, display total expenditure change inline, but there may be more changes as well
    switch (changedFieldsToDisplay.length) {
        case 0:
            return renderChangeField({
                data: data,
                fieldDetailsContent: '-',
                differencesKeys: changedFieldsToDisplay,
            })
        case DIFF_COUNT_TO_DISPLAY_INLINE:
            // display the change directly in the table
            const targetKey = changedFieldsToDisplay[0]

            return renderChangeField({
                data: data,
                fieldDetailsContent: renderDifferencesInline(differencesToDisplay, targetKey),
                fieldDetailsClassName: 'field-details-inline',
                omitDetailsButton: !data?.include_details,
                differencesKeys: allDifferenceKeys,
            })

        default:
            const changedFieldsSorted = renderFieldNames(changedFieldsToDisplay)

            return renderChangeField({
                data: data,
                fieldDetailsContent: changedFieldsSorted.map((fieldName) => (
                    <span>{formatFieldName(fieldName)}</span>
                )),
                differencesKeys: changedFieldsToDisplay,
            })
    }
}

const getChangesToUse = (data): { [p: string]: DifferencesObject } | null => {
    switch (data?.action) {
        case ACTION_TYPES.DELETE:
            return formatObjectStateAsDifferences(
                false,
                data?.prev_state || {},
                data?.default_val ?? '-',
            )
        case ACTION_TYPES.CREATE:
        case ACTION_TYPES.COPY:
            return formatObjectStateAsDifferences(
                true,
                data?.next_state || {},
                data?.default_val ?? '-',
            )
        case ACTION_TYPES.UPDATE:
            return data?.differences
        default:
            console.error(`Action provided not support: ${data?.action}.`)
            return data?.differences
    }
}
export interface DifferencesObject {
    prev: string
    next: string
}

export const DifferencesTooltip = (params: CustomTooltipProps) => {
    const displayBoldClass = (inputVal) => {
        return inputVal === '-' ? ' no-content' : ''
    }
    const { data } = params

    const differences: { [p: string]: DifferencesObject } | null = getChangesToUse(data)

    if (!differences || Object.keys(differences).length !== DIFF_COUNT_TO_DISPLAY_INLINE) {
        return undefined
    }
    const differencesKey = Object.keys(differences)[0]
    const prevVal = renderPrevAfterTextField(differences[differencesKey]['prev'])
    const nextVal = renderPrevAfterTextField(differences[differencesKey]['next'])

    if (prevVal.length < MAX_STRING_LENGTH && nextVal.length < MAX_STRING_LENGTH) {
        return undefined
    }
    return (
        <div className={'details-tooltip'}>
            <div>{formatFieldName(differencesKey)}</div>
            <div className={'field-comparison'}>
                <span className={`left-justify-tooltip ${displayBoldClass(prevVal)}`}>
                    {prevVal}
                </span>
                <span className={'center-justify-tooltip'}>
                    <Icon variant={'normal'} name={'caret-right-filled'} />
                </span>

                <span className={`left-justify-tooltip ${displayBoldClass(nextVal)}`}>
                    {nextVal}
                </span>
            </div>
        </div>
    )
}
