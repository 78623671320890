import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import IsOpGoalPriorityBadge from '../reusable/IsOpGoalPriortyBadge'

import { ModuleRegistry } from 'ag-grid-community'
import { ClientSideRowModelModule } from 'ag-grid-community'
import { RowEvent, ServerSideRowModelModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { ExcelExportModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { SetFilterModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { InfiniteRowModelModule } from 'ag-grid-community'
import './SummaryView.scss'
import { LicenseManager } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { agGridLicense } from '@amzn/ag-bird/src/ag-grid-license/ag-grid-license'
import { RowGroupingModule } from '@amzn/ag-bird/src/ag-grid-enterprise'
import { CustomCellRendererProps } from 'ag-grid-react'

LicenseManager.setLicenseKey(agGridLicense)

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ExcelExportModule,
    SetFilterModule,
    InfiniteRowModelModule,
    RowGroupingModule,
])

export default (props: CustomCellRendererProps) => {
    const { node, value } = props

    let priority, isOpGoal
    if (node.group && node.level >= 0) {
        const children = node?.allLeafChildren
        if (!_.isEmpty(children)) {
            priority = children[0].data['priority']?.toUpperCase()
            isOpGoal = children[0].data['is_op_goal']
        }
    }
    const [expanded, setExpanded] = useState(node.expanded)

    useEffect(() => {
        const expandListener = (event: RowEvent) => setExpanded(event.node.expanded)

        node.addEventListener('expandedChanged', expandListener)

        return () => {
            node.removeEventListener('expandedChanged', expandListener)
        }
    }, [])

    const onClick = useCallback(() => node.setExpanded(!node.expanded), [node])

    return (
        <div
            style={{
                paddingLeft: `${node.level * 15}px`,
            }}
        >
            {node.group && (
                <>
                    {!node.footer && (
                        <IsOpGoalPriorityBadge isOpGoal={isOpGoal} priority={priority} />
                    )}
                    <div
                        style={{
                            cursor: 'pointer',
                            transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                            display: 'inline-block',
                        }}
                        onClick={onClick}
                    ></div>
                    &nbsp;
                    {node.footer ? (value ? `${value} Total` : 'Total') : value}
                </>
            )}
            {node.rowPinned == 'bottom' && <>{value}</>}
        </div>
    )
}
