import { NO_USER_BUSINESS_ENTITY } from '../../Constant'

const BusinessEntityRefresh = (BusinessEntityId: string, func) => {
    const validBE = BusinessEntityId && BusinessEntityId !== NO_USER_BUSINESS_ENTITY
    if (validBE) {
        return func()
    }
}

export default BusinessEntityRefresh
